import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterLeadInsert;
var getters = {
    GetAsterLeadInsert: state => state.AsterLeadInsert.getdata
};
const mutations = {
    [mutation_type.AsterLeadInsert]: (state, response) => {
        state.AsterLeadInsert.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {
    }
};
const actions = {
    Editdata: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                commit(mutation_type.AsterLeadInsert, query);
            } catch (error) {
                reject(error);
            }
        });
    },
    BulkEditSave: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            api.put(`/aster/lead/bulk/edit`, payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    commit(mutation_type.CommitNothing, {});
                    reject(error);
                });
        });
    },
    BulkDeleteSave: ({ commit }, payload) => {
        
        return new Promise((resolve, reject) => {
            try {
                api.post(`/aster/lead/bulk/delete`, payload).then((response) => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    getcallhistory: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                let api_url = `/doocity/timeline/call_activity?id=${query.id}&phone_number=${query.phone_number}&skip=${query.skip}&take=${query.take}&module=${query.module}`

                api.get(api_url).then((response) => {
                    resolve(response.data.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },

    getedithistory: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                let api_url = `/doocity/history?id=${query.id}&skip=${query.skip}&take=${query.take}&module=${query.module}`
                if (query.module === "lead") {
                    api_url += `&templateName=${query.templateName}`
                }
                api.get(api_url).then((response) => {
                    resolve(response.data.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    getwahistory: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                let api_url = `/whatsapp/history?msg_from=${query.phone_number}&session_id=${query.session_id}`
                
                api.get(api_url).then((response) => {
                    resolve(response.data.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};