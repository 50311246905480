import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterLead;
import auth0Variables from '../../utils/auth0-variables';
const auth0Config = auth0Variables();
const trigger_by = auth0Config.Api_trigger;
var getters = {
    GetAsterLead: state => state.AsterLead.template_name,
    GetAsterLeadView: state => state.AsterLead.Lead,
    EditLead: state => state.AsterLead.EditLead
};
const mutations = {
    [mutation_type.AsterLead]: (state, response) => {
        state.AsterLead.template_name = response;
        state.AsterLead.Lead = response;
    },
    [mutation_type.CommitNothing]: () => { },
    EditLead: (state, response) => {

        state.AsterLead.EditLead = response;
    }
};
const actions = {
    Editfields: ({ commit }, payload) => {
        commit('EditLead', payload);
    },
    UpdateLeadsTableHeader: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api.put(`/leads/tablefield`, payload).then(response => {
                    resolve(response)
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        })
    },
    GetAsterLeadField: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                let url = `/aster/form/template/fields?template_name=${payload.titleData}`
                if (payload.show_on_view !== undefined) {
                    url += `&show_on_view=${payload.show_on_view}`
                }
                if (payload.show_on_popup !== undefined) {
                    url += `&show_on_popup=${payload.show_on_popup}`
                }
                api.get(url).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    LeadTags: ({ commit }) => {
        console.log('hwllo lead tag working')
        return new Promise((resolve, reject) => {
            try {
                api.get(`/tags/fetch?status=active`).then(response => {
                    // api.get(`/ticketshistory/fetch?skip=0&take=10&ticket_id=96`,payload).then(response => {
                    //    console.log(response)
                    resolve(response.data)
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        })
    },

    // edited
    GetAsterLeadData: ({ commit }, query) => {
        return new Promise((resolve, reject) => {

            const apiUrl = `/aster/forms`

            try {
                api.post(apiUrl, query).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    if (response.status === 204) {
                        resolve({ data: [], totalCount: 0 });
                    } else {
                        resolve(response.data);
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetAsterLead: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form/template`).then(response => {
                    const Lead = response.data;
                    commit(mutation_type.AsterLead, Lead);
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterAsterLeadData: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form/template?template_name=${query}`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    GetDispo: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/disposition?skip=0&take=15&active=Y`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }

        });
    },
    GetAsterCustommoduleData: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/formbuilder/aster/form/fetch?template_name=${query.name}&skip=${query.skip}&take=${query.take}`, query.bodydata).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    if (response.data.statusCode === 204) {
                        resolve({ data: [], totalCount: 0 });
                    } else {
                        resolve(response.data);
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertAsterData: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                payload.trigger_by = trigger_by
                api
                    .post(`/aster/form`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })

                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    insertCustomData: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/formbuilder/aster/form`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })

                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    insertimage: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api.defaults.headers["Content-Type"] = "multipart/form-data";
                api
                    .post(`/formbuilder/aster/imageupload`, payload)
                    .then(response => {
                        api.defaults.headers["Content-Type"] = "application/json";
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })

                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    FilterAsterLead: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/aster/form?template_name=${query.template_name}&name=${query.name}&value=${query.value}`).then(response => {
                    if (response.data.statusCode === 204) {
                        resolve({ data: [], totalCount: 0 });
                    } else {
                        resolve(response.data);
                    }
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    FiltercustomModuleData: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/formbuilder/aster/form/fetch?template_name=${query.template_name}&name=${query.name}&value=${query.value}`, query.bodydata).then(response => {
                    if (response.data.statusCode === 204) {
                        resolve({ data: [], totalCount: 0 });
                    } else {
                        resolve(response.data);
                    }
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    FilterAsterLeads: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/asterdialer/lead/filter`, query).then(response => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    DynamicFilter_Custommodule: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                api.post(`/asterdialer/Custommodule/filter`, query).then(response => {
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    updateAsterLead: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {

            try {
                let id = payload.id;
                let name = payload.template_name;
                delete payload.id;
                delete payload.template_name;
                api
                    .put(`/aster/form/${name}/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateCustomModule: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {

            try {
                let id = payload.id;
                let name = payload.template_name;
                delete payload.id;
                delete payload.template_name;
                api
                    .put(`formbuilder/aster/form/${name}/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteAsterLead: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/form/${payload.template_name}/${payload.delete_id}/${payload.modified_by}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteCustomModuledata: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/formbuilder/aster/form/${payload.template_name}/${payload.delete_id}`)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    Viewdata: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                commit(mutation_type.AsterLead, query);
                localStorage.setItem("templetname", JSON.stringify({ templetname: query }))
            } catch (error) {
                reject(error);
            }
        });
    },
    // Viewdata: ({ commit }) => {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             api.get(`/aster/form/template`).then(response => {
    //                 const Lead = response.data;
    //                 commit(mutation_type.AsterLead, Lead);
    //                 resolve(response.data);
    //             });
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });
    // },
    AssignAgent: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .put(`/aster/lead/assign`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    Getfiledata: (_, payload) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(payload);
            reader.onload = async (event) => {
                try {
                    var csvdata = event.target.result;
                    var rowData
                    if (payload.name.split(".")[1] === "txt") {
                        rowData = csvdata.split('\n')[0].split("\t");
                    } else {
                        rowData = csvdata.split('\n')[0].split(",");
                    }
                    resolve(rowData);
                } catch (err) {
                    reject(err);
                }
            };

        })
    },
    bulkdeleteAsterLead: (_, payload) => {
        console.log(payload, 'this is front end payload')
        return new Promise((resolve, reject) => {
            try {
                api.post(`/aster/all/lead/bulkdelete`, payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },


};
export default {
    state,
    getters,
    mutations,
    actions
};