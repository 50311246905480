<template>
    <div>
      <section-definition
        :title="page.title"
        :extraOptions="page.options"
        :icon="page.icon"
        :breadcrumbs="page.breadcrumbs"
        :addNewData="page.addnew"
        :exportOptions="page.exportOptions"
        @openFilter="filterClick"
        @refreshPage="refreshThisPage"
        @addNew="AddNewRule"
        :style="styleObject"
      >
      </section-definition>
      <loader v-if="page.loading"></loader>
      <v-container v-else fluid grid-list-xl page>
        <v-data-table
          :headers="headers"
          :items="ticket_data"
          class="elevation-1 fixed-layout"
          :pagination.sync="pagination"
          :total-items="pagecount"
          :loading="tableLoading"
          :rows-per-page-items="[10]"
        >
          <template v-slot:no-data style="text-align: center">
            <div style="text-align: center">No Data Available</div>
          </template>
          <template v-slot:items="props" >
            <td class="checkEmpty p0">{{ props.item.name.charAt(0).toUpperCase() + props.item.name.slice(1) }}</td>
            <td class="checkEmpty p0">{{ props.item.description }}</td>
            <td class="checkEmpty p0">{{ props.item.status }}</td>
            <td class="text-xs-center checkEmpty p0">
                
              <v-icon v-if="props.item.is_default == 0 && page.canedit"
                color="blue"
                small
                class="mr-4"
                @click="editItem(props.item)"
                >edit</v-icon
              >
              <v-icon v-if="props.item.is_default == 0 && page.candelete"
                color="red"
                small
                class="mr-4"
                @click="deleteItem(props.item.id)"
                >delete
              </v-icon>
              <v-icon v-if="props.item.is_default == 1 && page.canedit"
                color="gray"
                small
                class="mr-4"
                >edit</v-icon
              >
              <v-icon v-if="props.item.is_default == 1 && page.candelete"
                color="gray"
                small
                class="mr-4"
                >delete
              </v-icon>
            </td>
          </template>  
        
        </v-data-table>
      </v-container>
      <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Delete Ticket Status</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you sure to delete this Ticket Status?</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <template>
        <v-dialog
          persistent
          :value="page.isAddDisposition"
          scrollable
          max-width="500px"
        >
          <v-card>
            <v-toolbar dark color="primary" class="modal-header--sticky">
              <v-card-title>
                <span class="headline">Create Ticket Status</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="DisportionMain" v-model="valid">
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="addDisposition.payload.name"
                        required
                        label="Name"
                        type="text"
                        :rules="[(v) => !!v || 'name is required']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="addDisposition.payload.description"
                        required
                        label="Description"
                        type="text"
                      
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-switch
                        v-model="addDisposition.payload.is_closed"
                        color="primary"
                        flat
                        label="is closed"
                      ></v-switch>
                    </v-flex>
                    <!-- <v-flex xs12>
                      <v-select v-model="addDisposition.payload.active" required label="Status" :items="status"
                        :rules="[(v) => !!v || 'Status is required']"></v-select>
                    </v-flex> -->
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="modal-footer--sticky">
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="saveAddPausecode()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template>
        <v-dialog
          persistent
          :value="page.isEditDisposition"
          scrollable
          max-width="500px"
        >
          <v-card>
            <v-toolbar dark color="primary" class="modal-header--sticky">
              <v-card-title>
                <span class="headline">Update Ticket Status</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="valid">
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editDisposition.name"
                        required
                        label="Name"
                        readonly
                        type="text"
                        :rules="[(v) => !!v || 'name is required']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="editDisposition.description"
                        required
                        label="Description"
                        type="text"
                       
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-switch
                        v-model="editDisposition.is_closed"
                        flat
                        color="primary"
                        label="is closed"
                      ></v-switch>
                    </v-flex>
                    <v-flex xs12>
                      <v-select
                        v-model="editDisposition.status"
                        required
                        label="Status"
                        :items="status"
                        :rules="[(v) => !!v || 'Status is required']"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="saveEditRules()"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-navigation-drawer
        fixed
        disable-resize-watcher
        right
        v-model="page.isFilterOpen"
      >
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat color="white" @click="page.isFilterOpen = false">
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div
          class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
        >
          <v-container class="sidenavContainer">
            <div class="autocompleteDiv">
              <!-- <v-select v-model="filter.payload.dispo_id" :items="filtervalue" label="dispo_id" small></v-select> -->
              <v-autocomplete
                v-model="filter.payload.name"
                :items="filtervalue"
                :loading="filter.isLoading"
                :search-input.sync="filter.search"
                label="Name"
                hide-no-data
                hide-selected
              ></v-autocomplete>
              <v-select
                v-model="filter.payload.status"
                label="Status"
                :items="status"
              ></v-select>
            </div>
          </v-container>
          <v-container row class="sidenavFooter">
            <v-btn flat @click="reset()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
          </v-container>
        </div>
      </v-navigation-drawer>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
      <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import loader from "@/components/Widgets/loader";
  import { mapGetters } from "vuex";
  export default {
    components: {
      loader,
    },
  
    data() {
      return {
        valid: true,
        styleObject: {
          height: "80px",
          "margin-top": "-8px",
        },
        page: {
          title: "Ticket Status",
          icon: "fa fa-ticket fa-2x",
          loading: false,
          isAddDisposition: false,
          isFilterOpen: false,
          playingRecord: false,
          breadcrumbs: [],
          exportOptions: [],
          options: { filter: true, refresh: true },
          addnew: [],
          isEditDisposition: false,
          temp: { key: "addpausecode", label: "Add PauseCode" },
          candelete: false,
          canedit: false,
        },
        pagecount: 0,
        headers: [
          {
            text: "Name",
            align: "left",
            class: "p0",
            sortable: false,
            value: "dispo_id",
          },
          {
            text: "Description",
            align: "left",
            class: "p0",
            sortable: false,
            value: "description",
          },
          {
            text: "Status",
            align: "left",
            class: "p0",
            sortable: false,
            value: "active",
          },
          {
            text: "Action",
            align: "center",
            sortable: false,
            value: "btn",
            width: "20%",
          },
        ],
        ticket_data: [],
        pagination: {
          rowsPerPage: 10,
        },
        snackbar: {
          show: false,
          timeout: 6000,
          text: "",
        },
        DeleteRowdata: false,
        showRowData: false,
        DataRow: false,
        tableLoading: false,
        crmdomain: "",
        filtervalue: [],
        filter: {
          payload: {
            status: "",
            name: "",
          },
          options: {
            status: ["Active", "Inactive"],
          },
          isLoading: false,
          search: "",
        },
        isProcessing: false,
        call_status: [],
        editDisposition: {
          id: "",
          name: "",
          description: "",
          status: "",
          is_closed: "",
          is_default:0,
        },
        addDisposition: {
          valid: false,
          payload: {
           name: "",
           description: "",
           status: "Active",
           is_closed: false,
           is_default:0,
           modified_by:JSON.parse(localStorage.apiInfo).user_email,
           created_by:JSON.parse(localStorage.apiInfo).user_email,
          },
          options: {
            status: ["Active", "Inactive"],
          },
        },
        status: ["Active", "Inactive"],
      };
    },
    methods: {
      refreshThisPage: function () {
        var scope = this;
        scope.page.loading = true;
        scope.pagination.page = 1;
        scope.filter.payload.status = "";
        scope.filter.payload.name = "";
        scope.getDataFromApi();
      },
      filterClick: function () {
        let scope = this;
        scope.page.isFilterOpen = true;
      },
      // closeEditRules: function () {
      //     this.page.isEditDisposition = false;
      //   },
      reset() {
        var scope = this;
        scope.filter.payload.status = "";
        scope.filter.payload.name = "";
        scope.filter.search = ""
        this.getDataFromApi()
        this.page.isFilterOpen = true;
      },
      getDataFromApi() {
        var scope = this;
        scope.tableLoading = true;
  
        this.page.isFilterOpen = false;
        scope.filtervalue = [];
  
        let take = this.pagination.rowsPerPage;
        let skip;
        if (this.pagination.page === 1) {
          skip = 0;
        } else {
          skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
        }
        if (scope.filter.payload.status === "Active") {
          // scope.filter.payload.active = "Y";
        }
        if (scope.filter.payload.status === "Inactive") {
          // scope.filter.payload.active = "N";
        }
        let query = {
          skip: skip,
          take: take,
          is_default:0,
          status:this.filter.payload.status,
          name:this.filter.search,
        };
        console.log(query.name,"queryyyyyyyyyyyyyy")
        this.skip = skip;
  
        this.$store
          .dispatch("GetTicketStatus",query)
          .then((response) => {
            //console.log(response,"ttttttttttt");  
            
            this.pagecount = response.data.count;
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data.data;
            if (datas !== "" && datas !== undefined && datas !== null) {
              for (let i = 0; i < datas.length; i++) {
                if (datas[i].active == "Y") {
                  datas[i].active = "Active";
                } else {
                  datas[i].active = "InActive";
                }
              }
            }
            scope.ticket_data = datas;
             console.log(scope.ticket_data,"scope.ticket_data");
          })
          .catch(function (error) {
            scope.page.isAddDisposition = false;
            scope.showSnackBar("error" + error);
            this.page.isEditDisposition = false;
          });
      },
      AddNewRule: function () {
        this.page.isAddDisposition = true;
      },
      closeEditRules: function () {
        this.page.isEditDisposition = false;
      },
      closeAddPausecode: function () {
        this.page.isAddDisposition = false;
        this.$refs.DisportionMain.reset();                    
      },
      saveEditRules() {
        var scope = this;
  
  
        if (scope.editDisposition.is_closed === true) {
          scope.editDisposition.is_closed = "Y";
        }
        if (scope.editDisposition.is_closed === false) {
          scope.editDisposition.is_closed = "N";
        }
        if(scope.editDisposition.is_default==='Open'||scope.editDisposition.is_default==='Closed'||scope.editDisposition.is_default==='Pending'||scope.editDisposition.is_default==='Resolve'){
          scope.editDisposition.is_default=1;
        }
        else{
          scope.editDisposition.is_default=0;
        }
  
        scope.$store
          .dispatch("updateTicketStatus", scope.editDisposition)
          .then(() => {
            scope.page.isAddDisposition = false;
            scope.showSnackBar("Updated Successfully");
            this.page.isEditDisposition = false;
            this.getDataFromApi();
          })
          .catch(function (error) {
            scope.page.isAddDisposition = false;
            scope.showSnackBar("error" + error);
            this.page.isEditDisposition = false;
          });
        // this.getDataFromApi();
      },
      saveAddPausecode() {
        var scope = this;
        // scope.addDisposition.payload.active="Active"
        // if (scope.addDisposition.payload.active === "Active") {
        //   scope.addDisposition.payload.active = "Y";
        // }
        // if (scope.addDisposition.payload.active === "Inactive") {
        //   scope.addDisposition.payload.active = "N";
        // }
  
        if (scope.addDisposition.payload.is_closed === true) {
          scope.addDisposition.payload.is_closed = "Y";
        }
        if (scope.addDisposition.payload.is_closed === false) {
          scope.addDisposition.payload.is_closed = "N";
        }
        if(scope.editDisposition.is_default==='Open'||scope.editDisposition.is_default==='Closed'||scope.editDisposition.is_default==='Pending'||scope.editDisposition.is_default==='Resolve'){
          scope.editDisposition.is_default=1;
        }
        else{
          scope.editDisposition.is_default=0;
        }
        scope.$store
          .dispatch("InsertTicketStatus", scope.addDisposition.payload)
          .then(() => {
            scope.page.isAddDisposition = false;
            scope.showSnackBar("Created Successfully");
            this.getDataFromApi();
            this.$refs.DisportionMain.reset();
            scope.addDisposition.payload.is_closed = false;
          })
          .catch(function (error) {
            scope.page.isAddDisposition = false;
            if (error.response && error.response.status === 409) { //// changes
            scope.showSnackBar("Name already exists");
          } else{
            scope.showSnackBar("Error" + error);
            scope.addDisposition.payload.name = "";
            scope.addDisposition.payload.description = "";
            scope.addDisposition.payload.status = "";
            scope.addDisposition.payload.is_closed = false;
          }
  
          });
        // this.getDataFromApi();
      },
      showSnackBar(message) {
        let scope = this;
        scope.snackbar.text = message;
        scope.snackbar.show = true;
        setTimeout(() => {
          scope.snackbar.text = "";
        }, scope.snackbar.timeout);
      },
      deleteItem(id) {
        this.DeleteRowdata = true;
        this.delete_id = id;
      },
      editItem(item) {
        let scope = this;
        this.page.isEditDisposition = true;
        this.editDisposition.id = item.id;
        this.editDisposition.name = item.name;
        this.editDisposition.description = item.description;
        this.editDisposition.status  = item.status;
       
  
  
        if (item.is_closed === "Y") {
          scope.editDisposition.is_closed = true;
        }
        if (item.is_closed === "N") {
          scope.editDisposition.is_closed = false;
        }
        // if(scope.editDisposition.is_default==='Open'|scope.editDisposition.is_default==='Closed'|scope.editDisposition.is_default==='Pending'|scope.editDisposition.is_default==='Resolve'){
        //   scope.editDisposition.is_default=1;
        // }
        // else{
        //   scope.editDisposition.is_default=0;
        // }
      },
      savedelete() {
        let scope = this;
        scope.isProcessing = true;
        this.$store
          .dispatch("deleteTicketStatus", this.delete_id)
          .then((response) => {
            scope.isProcessing = false;
            if (response.data.message == "success") {
              scope.getDataFromApi();
              scope.showSnackBar("ticket Deleted");
            } else {
              scope.showSnackBar("Error! Unable Delete ticket");
            }
          })
          .catch(() => {
            scope.isProcessing = false;
            scope.showSnackBar("Error! Unable Delete list");
          });
        this.closedelete();
      },
      closedelete() {
        this.DeleteRowdata = false;
      },
      closeShow() {
        this.showRowData = false;
      },
    },
    computed: {
      ...mapGetters(["CallFlow"]),
      
    },
    mounted() {
      let scope = this;
      this.page.loading = false;
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "configurations:ticketstatus:create"
        ) !== -1
      ) {
        this.page.addnew = [{ label: "Add New" }];
      }
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "configurations:ticketstatus:delete"
        ) !== -1
      ) {
        scope.page.candelete = true;
      }
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "configurations:ticketstatus:edit"
        ) !== -1
      ) {
        scope.page.canedit = true;
      }
      const project_config = JSON.parse(localStorage.projectConfig);
      this.crmdomain = project_config.projects[0].config[0].crm_domain;
      let data = { model: "ticket_status", column: ["name"] };
    
      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.filtervalue = datas[0].name;
        })
        .catch((err) => {
          console.log(err);
        });
  
      this.getDataFromApi();
    },
    watch: {
      pagination: {
        handler() {
          if (this.pagination.page > 1 || this.skip >= 10) {
            this.getDataFromApi();
          }else {
          this.getDataFromApi().then(() => {
            this.ticket_data = this.ticket_data.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
          });
        }
        },
        deep: true,
      },
      "filter.search"(val) {
        if (this.page.isFilterOpen) {
          let scope = this;
          val =  val == null ? undefined : val
          let data = { model: "ticket_status", column: ["name"] };
  
          data["search"] = val;
  
          this.$store
            .dispatch("GetModuleNameList", data)
            .then((response) => {
              scope.tableLoading = false;
              scope.page.loading = false;
              var datas = response.data;
              this.filtervalue = datas[0].name;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    },
  };
  </script>
  <style>
  .file-dummy {
    width: 100%;
    padding: 30px;
    border: 2px dashed;
    text-align: center;
    transition: #2196f333 0.3s ease-in-out;
    cursor: pointer;
    font-weight: bold;
  }
  </style>