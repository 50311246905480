<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :exportOptions="page.exportOptions" @openFilter="openCallLogFilter" @refreshPage="refreshCallLogPage"
      @exportData="exportCallDetailReport" :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page id="table">
      <v-data-table :headers="headers" :items="calldetails_data1" class="elevation-1" :pagination.sync="pagination"
        :total-items="calldetailtotal" :rows-per-page-items="[5, 10, 25]">
        <!-- <template v-slot:items="props">
   
    <td class="checkEmpty p0">{{ props.item.calldate }}</td>
    <td class="checkEmpty p0">{{ props.item.uniqueid }}</td>
    <td class="checkEmpty p0">{{ props.item.phone_number }}</td>
    <td class="checkEmpty p0">{{ props.item.campaign }}</td>
    <td class="checkEmpty p0">{{ props.item.call_type }}</td>
    <td class="checkEmpty p0">{{ props.item.agent }}</td>
    <td class="checkEmpty p0" v-if="props.item.station != 'null'">
    {{ props.item.station }}
    </td>
    <td class="checkEmpty p0" v-else></td>
    <td class="checkEmpty p0" v-if="props.item.dispo_status != 'null'">
    {{ props.item.dispo_status }}
    </td>
    <td class="checkEmpty p0" v-else></td>
    <td class="checkEmpty p0">{{ props.item.status }}</td>
    <td class="checkEmpty p0">{{ props.item.duration }}</td>
    <td class="checkEmpty p0">{{ props.item.talk_times }}</td>
    <td class="checkEmpty p0">{{ props.item.hangup_by }}</td>
    <td class="checkEmpty p0">{{ props.item.wait_time }}</td>
    <td class="checkEmpty p0">{{ props.item.end_time }}</td>
    <td class="checkEmpty p0">{{ props.item.hangup_cause }}</td>
    </template> -->
        <template v-slot:items="props">
          <td class="checkEmpty p0" v-for="(property, index) in Object.keys(props.item)" :key="index">
            {{ headers[index].value == property &&  headers[index].masking ? ChartoMaskingChar(props.item[property]) :props.item[property] }}
          </td>
        </template>
      </v-data-table>
    </v-container>
    <!-- <div>
    <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1000">
    <pdfexport
    v-if="dialog"
    size="a3"
    :header="pdf.header"
    :pdfdata="pdf_data"
    @closeModel="closeModel"
    title="Call Detail Report"
    ></pdfexport>
    </v-dialog>
    </v-layout>
    </div> -->

    <v-navigation-drawer fixed disable-resize-watcher right  v-model="page.isFilterOpen" class="filterNavigation">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">

        <v-container class="sidenavContainer">
          <CallDetailFilter v-if="page.isFilterOpen" @getDataFrom="getDataFrom" :appliedfilter="appliedfilter" :teambool="teambool" :teamvalues="teamvalues">
          </CallDetailFilter>
        </v-container>

        <v-container row class="sidenavFooter">
          <v-btn flat @click="resetvalue()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-bottom-sheet v-model="page.playingRecord" hide-overlay persistent lazy inset max-width="500" class="align-left"
      id="barging-div" transparent>
      <v-card tile class="player-div">
        <v-toolbar color="light" small>
          <audio controls autoplay v-if="page.playingRecord">
            <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <v-spacer></v-spacer>
          <div class="row justify-fe">
            <v-btn icon>
              <i class="fas fa-download"></i>
            </v-btn>
            <v-btn icon>
              <v-icon @click="togglePlayingRecordSheet('close', {})">close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import CallDetailFilter from "./Components/CallDetailFilter";
// import pdfexport from "./Components/pdfexport";
import { mapGetters } from "vuex";
import moment from "moment";
import { MaskCharacter } from "@/utils/helpers";
export default {
  components: {
    loader,
    CallDetailFilter,
    // pdfexport,
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    // this.$store.dispatch("reportuserlist").then(() => {
    // // this.getDataFromApi();
    // this.dateformate();
    // });
    let teams =  JSON.parse(localStorage.getItem("Team"))
    let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))
    if (teams.length > 0 && team_agent_user.length > 0) {
      this.teambool = true
      this.teamvalues= team_agent_user
    }else{
      this.teambool = false
      this.teamvalues = []
    }
    this.dateformate();
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:calldetail:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:view"
          ) !== -1
        ) {
          scope.masking = true;
        }
      }.bind(scope),
      1
    );
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["calldetails_data", "queue","MaskingModules"]),
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },

      page: {
        title: "Call Log Report",
        icon: "fa-address-card fa-2x",
        loading: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
      },
      pagination: {
        rowsPerPage: 10,
      },
      headers: [

      ],
      calldetailreport: [],
      calldetails_data1: [],
      deletedata: ["acw_time", "did_number", "queue", "queue_enter_time", "answer_time", "last_app", "comments", "billsec"],
      calldetailtotal: 0,
      skip: 0,
      take: 0,
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        agent: "",
        detail: "calldetails",
        descending: "",
        export: "exportcalldetails",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      txt_data: [],
      // pdf_data: [],
      from_date: "",
      to_date: "",
      // pdf: {
      // item: [],
      // },

      // pdf: {
      // header: [],
      // item: [],
      // },
      dialog: false,
      // pdf_flag: false,

      page_flag: false,
      appliedfilter: {
        phonenumber: "",
        type: "",
        station: "",
        agent: "",
        did: "",
        queue: "",
        dispo: "",
        status: "",
        fromdate: "",
        todate: "",
        flag: "",
        uniqueid: "",
        operator: "",
        value: "",
        Durationtype: "",
        list_id: "",
        answerStatus: "",
      },
      Filename:"CallDetailReport",
      masking:false,
      teambool:false,
      teamvalues:[]
    };
  },
  watch: {
    pagination: {
      handler() {

        if (this.pagination.page > 1 || this.payload.skip >= 10) {
          this.getDataFromApi();

        }
      },
      deep: true,
    },
  },
  methods: {
    openCallLogFilter: function () {
      this.page.isFilterOpen = true;
    },
    closeModel: function () {
      this.dialog = false;
      // this.pdf.header = [];
    },
    refreshCallLogPage: function () {
      var scope = this;
      scope.dateformate();
      this.pagination.page = 1
      this.skip = 1
      scope.page.loading = true;
      scope.refreshvalue();
      scope.appliedfilter.list_id = "";
      scope.appliedfilter.phonenumber = "";
      scope.appliedfilter.type = "";
      scope.appliedfilter.station = "";
      scope.appliedfilter.agent = "";
      scope.appliedfilter.did = "";
      scope.appliedfilter.queue = "";
      scope.appliedfilter.dispo = "";
      scope.appliedfilter.status = "";
      scope.appliedfilter.uniqueid = "";
      scope.appliedfilter.campaign = "";
      scope.appliedfilter.operator = "";
      scope.appliedfilter.value = "";
      scope.appliedfilter.Durationtype = "";
      scope.appliedfilter.answerStatus = "";
      scope.getDataFromApi();
    },
    exportCallDetailReport: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        // this.pdf_flag = true;
      }
    },
    togglePlayingRecordSheet: function (event, item) {
      if (event == "close") {
        this.page.playingRecord = false;
        this.playingItem = item;
      } else {
        this.page.playingRecord = true;
        this.playingItem = item;
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD HH:mm:ss");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 5;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    getDataFromApi() {
      let desiredOrder = [
        "calldate",
        "campaign",
        "did_number",
        "phone_number",
        "call_type",
        "queue",
        "agent",
        "agent_name",
        "team",
        "station",
        "status",
        "dispo_status",
        "talk_times",
        "duration",
        "end_time",
        "wait_time",
        "hangup_by",
        "hangup_cause",
        "uniqueid",
      ]
      var scope = this;
      this.payload.to_date = this.to_date;
      this.payload.from_date = this.from_date;
      this.payload.value = this.appliedfilter.value
      this.payload.operator = this.appliedfilter.operator
      this.payload.Durationtype = this.appliedfilter.Durationtype
      this.payload.answerStatus = this.appliedfilter.answerStatus
      var { page, rowsPerPage } = this.pagination;
      let teams =  JSON.parse(localStorage.getItem("Team"))
      let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))

      let dataa =[]
      let team_name = ""
      if (teams.length > 0) {
        team_name = teams.map(elm => elm.name).flat()
        dataa = team_agent_user
      }else{
        dataa = JSON.parse(localStorage.getItem("agent_emails"));
      }
      scope.payload.agent = dataa;
      if (rowsPerPage == -1) {
        this.payload.take = this.total;
      } else {
        this.payload.take = rowsPerPage;
      }
      if (page != "" && page != undefined && page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }

      this.payload.skip = (this.skip - 1) * this.payload.take;

      return new Promise((resolve) => {
        var { descending } = scope.pagination;
        this.payload.descending = descending;
        this.payload.teams_name = team_name
        scope.$store.dispatch("calldetails", this.payload).then((response) => {

          var datas = response.data;
          scope.calldetails_data1 = datas;
          let values_ = scope.calldetails_data1
          let values = []

          for (let i = 0; i < values_.length; i++) {
            let originalObject = values_[i]
            delete originalObject.acw_time
            delete originalObject.queue_enter_time
            delete originalObject.answer_time
            delete originalObject.last_app
            delete originalObject.comments
            delete originalObject.billsec

            const rearrangedObject = {};
            const unmatchedKeys = Object.keys(originalObject).filter(key => !desiredOrder.includes(key));

            desiredOrder.forEach(key => {
              if (originalObject.hasOwnProperty(key)) {
                rearrangedObject[key] = originalObject[key];
              }
            });

            unmatchedKeys.forEach(key => {
              rearrangedObject[key] = originalObject[key];
            });

          
            values.push(rearrangedObject)

          }

          scope.calldetails_data1 = values;
          if (values.length) {
            scope.headers = [];
             Object.keys(values[0]).forEach((element) => {
             
              scope.headers.push({
                text: element.split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),
                align: "left",
                sortable: false,
                class: "p0",
                value: element,
                masking: scope.masking && element == this.MaskingModules.MaskingModules[this.Filename].find(elm => elm == element)
              });
            });
          }
          scope.page.loading = false;
          scope.total = response.total;
          if (scope.page_flag) {
            this.pagination.page = 1;
            scope.page_flag = false;
          }
          scope.calldetailtotal = scope.total;
          resolve(scope.total);
          if (response.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
        scope.page.isFilterOpen = false
      });

      // })
      // .catch(() => {

      // });
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
    },
    resetvalue() {
      this.skip = 0;
      var to_date = new Date();
      to_date = moment(to_date).format();
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 5;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format();
      this.refreshvalue();
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.phonenumber = "";
      this.appliedfilter.type = "";
      this.appliedfilter.station = "";
      this.appliedfilter.agent = "";
      this.appliedfilter.did = "";
      this.appliedfilter.queue = "";
      this.appliedfilter.dispo = "";
      this.appliedfilter.status = "";
      this.appliedfilter.campaign = "";
      this.appliedfilter.flag = true;
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      this.appliedfilter.uniqueid = "";
      this.appliedfilter.template = "";
      this.appliedfilter.value = "";
      this.appliedfilter.Durationtype = "";
      this.appliedfilter.operator = "";
      this.appliedfilter.list_id = "";
      this.appliedfilter.answerStatus = "";
      if (this.appliedfilter.flag) {
        this.appliedfilter.fromdate = from_date;
        this.appliedfilter.todate = to_date;
        this.appliedfilter.flag = false;
      }
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    export(option) {
      let scope = this
      console.log(this.appliedfilter,"rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
      this.excel_data = [];
      this.txt_data = [];
      // this.pdf_data = [];
      let dataa = JSON.parse(localStorage.getItem("group_users"));
      let teams =  JSON.parse(localStorage.getItem("Team"))
      let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))
      let team_name = ""
      if (teams.length > 0) {
         team_name = teams.map(elm => elm.name).flat()
         dataa = team_agent_user
      }
      var export_date = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        calldetail: "exportCallDetail",
        team_user: dataa,
        answerStatus:this.appliedfilter.answerStatus,
        team_name: team_name
      };
      this.$store.dispatch("exportdatafetch", export_date).then((response) => {
        this.exports = response;
        for (var i = 0; i < this.exports.length; i++) {
          if(scope.masking){
            scope.MaskingModules.MaskingModules[scope.Filename].forEach((elm)=>{
              response[i][elm] =  scope.ChartoMaskingChar(response[i][elm])
            })
          }
          this.key = Object.keys(response[i]);
          this.value = Object.values(response[i]);
          var values = this.value;

          // this.key.map((res) => {
          // this.pdf.header.push({
          // text: res,
          // align: "left",
          // sortable: true,
          // value: res,
          // });
          // });

          this.excel_data.push({
            header: values,
          });
          this.txt_data.push(Object.values(response[i]));
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = this.key;
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`; 
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date1 = new Date();
          date1 = moment(date1).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "CallDetails" + date1 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
        if (option == "CSV") {
          let csvContent = '';
          csvContent += `${this.key} \r\n`;
          // csvContent += "Date,Uniqueid,DID,Queue,Phone Number,Call type ,Queue Enter Time ,Answer Time ,Application ,Agent ,Station,Dispo Status,Status ,Duration ,Talk Time,Comments \r\n";
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let data1 = [];
            data.forEach((elm) => {
              if (typeof elm === "string") {
                data1.push(elm.replace(/'|"|#|\n/g, " "));
              } else {
                data1.push(elm);
              }
            });
            for (let i = 0; i < data1[0].length; i++) {
              if (
                data1[0][i] != null &&
                data1[0][i] != undefined &&
                data1[0][i] != "" && typeof data1[0][i] == "string"
              ) {
                if (data1[0][i].includes(",")) {
                  data1[0][i] = `"${data1[0][i]}"`
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "CallDetails" + date + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      });
    },
    refreshvalue() {
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.$store.commit("FILTER_TYPE", { type: "" });
      this.$store.commit("FILTER_PHONE", { phone: "" });
      this.$store.commit("FILTER_CAMPAIGN", { campaign: "" });
      this.$store.commit("FILTER_GROUP", { group: "" });
      this.$store.commit("FILTER_STATION", { station: "" });
      this.$store.commit("FILTER_DID", { did: "" });
      this.$store.commit("FILTER_QUEUE", { queue: "" });
      this.$store.commit("FILTER_DISPO", { dispo: "" });
      this.$store.commit("FILTER_STATUS", { status: "" });
      this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
      this.$store.commit("FILTER_LISTID", { list_id: "" });
      this.$store.commit("FILTER_TEMPLATE", { template: "" });
      this.page_flag = true;
    },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    }
  },

  beforeDestroy() {
    // this.$store.commit('FILTER_FLAG');
    this.$store.commit("FILTER_AGENT", { agent: "" });
    this.$store.commit("FILTER_TYPE", { type: "" });
    this.$store.commit("FILTER_PHONE", { phone: "" });
    this.$store.commit("FILTER_CAMPAIGN", { campaign: "" });
    this.$store.commit("FILTER_GROUP", { group: "" });
    this.$store.commit("FILTER_STATION", { station: "" });
    this.$store.commit("FILTER_DID", { did: "" });
    this.$store.commit("FILTER_QUEUE", { queue: "" });
    this.$store.commit("FILTER_DISPO", { dispo: "" });
    this.$store.commit("FILTER_STATUS", { status: "" });
    this.$store.commit("FILTER_UNIQUEID", { uniqueid: "" });
    this.$store.commit("FILTER_LISTID", { list_id: "" });
    this.$store.commit("FILTER_TEMPLATE", { template: "" });
  },
};
</script>