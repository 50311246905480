/* eslint-disable no-empty-pattern */
import config from "@/config/index";

import api from '../../utils/axios-util';
import * as mutation_type from "../mutation-types";
const usersvalue = config.overall_report_state.filters;
const state = config.liveAgents;
var extension_payload = [];
var availextension_payload = [];
var user_payload = [];
var campaign_payload = [];
var queue = [];
var getters = {
    Live_agents_response: state => state.liveagents.response,
    queue_dropdown: state => state.filter.queue,
    extension_dropdown: state => state.filter.extension,
    availextension_dropdown: state => state.filter.availextension,
    type: state => state.filter.type,
    status: state => state.filter.status,
    user: state => state.filter.user,
    queue: state => state.filter.queue,
    campaign: state => state.filter.campaign,
    checkingtoken: state => state.token.checkingtoken,
    Live_agent_monitor_data: state => state.liveagentmonitordata,
    Live_agent_monitor_type:state => state.liveagentmonitortype,
    Live_agent_monitor_bool: state => state.callconnectstatus,
    Callbargeflag: state => state.CallActionflag

}
const mutations = {
    [mutation_type.LIVE_AGENTS]: (state, payload) => {
        state.liveagents.response = payload.data.data;
    },
    [mutation_type.CHECKING_TOKEN]: (state) => {
        state.token.checkingtoken = true;
    },
    [mutation_type.QUEUE_LISTS]: (state, payload) => {
        for (var i = 0; i < payload.data.count; i++) {
            queue.push(payload.data.data[i].name);
        }
        state.filter.queue = queue;
    },
    [mutation_type.EXTENSION_LISTS]: (state, payload) => {
        for (var i = 0; i < payload.data.count; i++) {
            extension_payload.push(payload.data.data[i].name);
        }
        state.filter.extension = extension_payload;
    },
    [mutation_type.AVAILEXTENSION_LISTS]: (state, payload) => {
        for (var i = 0; i < payload.data.data.length; i++) {
            availextension_payload.push(payload.data.data[i].name);
        }
        availextension_payload.push("Other");
        state.filter.availextension = availextension_payload;
    },
    [mutation_type.USER_LISTS]: (state, payload) => {
        for (var i = 0; i < payload.data.count; i++) {
            user_payload.push(payload.data.data[i].name);
        }
        state.filter.user = user_payload;
        // config.auth0.settings.userList = user_payload;
    },
    [mutation_type.CAMPAIGN_LISTS]: (state, payload) => {
        for (var i = 0; i < payload.data.count; i++) {
            campaign_payload.push(payload.data.data[i].campaign_name);
        }
        state.filter.campaign = campaign_payload;
    },
    [mutation_type.LIVE_AGENTS_MONITOR_DATA]: (state, payload) => {
        state.liveagentmonitordata = payload
    },
    [mutation_type.LIVE_AGENTS_MONITOR_TYPE]: (state, payload) => {
        state.liveagentmonitortype = payload
    },
    [mutation_type.LIVE_AGENTS_MONITOR_BOOL]: (state, payload) => {
        state.callconnectstatus = payload
    },
    [mutation_type.BARGE_CALL_FLAG]: (state, payload) => {
        state.CallActionflag = payload
    },
};
const actions = {
    async liveagents({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            try {
                let agent = '';
                let team_user = '';
                let team_name = "";
                if (payload.agent === '' || payload.agent === 0) {
                    let teams =  JSON.parse(localStorage.getItem("Team"))
                    let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))
                    if (teams.length > 0 && team_agent_user.length > 0) {
                        team_user = team_agent_user
                    }else{
                        team_user = JSON.parse(localStorage.getItem("group_users"));
                        if(team_user===null){
                            team_user = usersvalue.agent
                        }
                    }
                } else {
                    agent = payload.agent;
                }

                if(payload.team_name != undefined && payload.team_name != "" && payload.team_name){
                    team_name = payload.team_name
                }
                api.get("/realtime/liveAgents?phone_number=" + payload.number + "&queue=" + payload.Queue + "&agent=" + agent + "&team_user=" + team_user + "&campaign=" + payload.Campaign + "&call_type=" + payload.Type + "&status=" + payload.Status + "&station=" + payload.Extension + "&team=" + team_name).then(response => {
                    var liveresponse = response;
                    commit(mutation_type.LIVE_AGENTS, liveresponse);
                    resolve(response);
                }).catch(function(error) {
                    // commit(mutation_type.LIVE_AGENTS, error);
                    reject(error);
                });
            } catch (error) {
                commit(mutation_type.LIVE_AGENTS, error);
                reject(error);
            }
        })
    },
    userLogout: ({}, payload) => {
        var data = {
            "user": payload.user,
            "station": payload.station,
            "name": payload.name,
            "status": payload.status,
            "queue": payload.queue,
            "phone_num": payload.phone_num,
            "dur": payload.dur,
            "call_type": payload.call_type,
            "campaign": payload.campaign,
            "dur_in_sec": payload.dur_in_sec
        }
        api.post("/realtime/forceLogout", data).then(response => {
            return response;
        })
    },
    barge: ({}, payload) => {
        api.post("/realtime/barge", payload).then(response => {
            return response;
        })
    },
    queuelist: ({ commit }) => {
        api.get("/realtime/queueList").then(response => {
            var response1 = response;
            commit(mutation_type.QUEUE_LISTS, response1);
        })
    },
    extensionlist: ({ commit }) => {
        api.get("/realtime/extenList").then(response => {
            var response2 = response;
            commit(mutation_type.EXTENSION_LISTS, response2);
        })
    },
    Availextensionlist: ({ commit }) => {
        api.get("/realtime/availableExten").then(response => {
            var response4 = response;
            commit(mutation_type.AVAILEXTENSION_LISTS, response4);
        })
    },
    campaignList: ({ commit }) => {
        return new Promise((resolve, reject) => {
        api.get("/realtime/campaignList").then(response => {
            var response5 = response;
            commit(mutation_type.CAMPAIGN_LISTS, response5);
            resolve(response5)
        }).catch(function(error) {
            // commit(mutation_type.LIVE_AGENTS, error);
            reject(error);
        });
    })
    
    },
    GetPausecode: ({ commit }) => {
        return new Promise((resolve, reject) => {
          try {
            let take = {
              take: ''
            }
            api.get(`/pausecode?take=${take.take}`).then(response => {
              const pausecode = response.data;
              commit(mutation_type.PauseCode, pausecode);
              const pausecode_array = [];
              pausecode.data.forEach(item => {
                pausecode_array.push(item.pause_code);
              });
              // localStorage.setItem("pause_code", JSON.stringify(pausecode_array));
              resolve(response.data);
            });
          } catch (error) {
            reject(error);
          }
        });
      },
    AssignQueue: ({},payload) => {
        var data = {
            "agent": payload.agent,
            "queue": payload.queue,
        }
        return new Promise((resolve, reject) => {
        api.put("/changeQueue",data).then(response => {
            resolve(response)
        }).catch(function(error) {
            reject(error);
        });
    })
    },
    userlist: ({ commit }) => {
        api.get("/realtime/userList").then(response => {
            var response3 = response;
            commit(mutation_type.USER_LISTS, response3);
        })
    },
    checkingtoken: ({ commit }) => {
        commit(mutation_type.CHECKING_TOKEN);
    },
    LiveAgentMontData : ({ commit },payload) =>{
        commit(mutation_type.LIVE_AGENTS_MONITOR_DATA, payload)
    },
    LiveAgentMontType : ({ commit },payload) =>{
        commit(mutation_type.LIVE_AGENTS_MONITOR_TYPE, payload)
    },
    LiveAgentMontBool : ({ commit },payload) =>{
        commit(mutation_type.LIVE_AGENTS_MONITOR_BOOL, payload)
    },
    CallActionflag : ({ commit },payload) =>{
        commit(mutation_type.BARGE_CALL_FLAG, payload)
    } 
};
export default {
    state,
    getters,
    mutations,
    actions
};