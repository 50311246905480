let AppDrawerItems = [
  {
    icon: "dashboard",
    title: "Dash Board",
    name: "DashBoard",
    permission: ["dashboard:reportbuilder:view"],
    auth: true,
  },
 
  {
    icon: "fas fa-users-cog",
    title: "CRM",
    group: "crm",
    items: [
      {
        prependIcon: "",
        title: "Campaigns",
        name: "AsterCampaign",
        permission: ["leadmanagement:campaigns:view"],
        auth: true,
      },

      {
        prependIcon: "",
        title: "Lists",
        name: "AsterList",
        permission: ["leadmanagement:list:view"],
        auth: true,
      },
      {
        prependIcon: "",
        title: "Leads",
        name: "AsterLead",
        permission: ["leadmanagement:lead:view"],
        auth: true,
      },
      // {
      //   prependIcon: "",
      //   title: "Templates",
      //   name: "CustomFormList",
      //   permission: ["leadmanagement:custom-form:view"],
      //   auth: true,
      // },
      {
        prependIcon: "",
        title: "Contacts",
        name: "AsterContact",
        permission: ["leadmanagement:contacts:view"],
        auth: true,
      },
      {
        prependIcon: "",
        title: "Tickets",
        name: "SupportsList",
        permission: ["leadmanagement:tickets:view"],
        auth: true,
      },
      {
        prependIcon: "",
        title: "Meetings",
        name: "AsterMeetings",
        permission: ["leadmanagement:meetings:view"],
        auth: true,
      }
    ],
  },

  {
    icon: "people",
    title: "Users & Groups",
    group: "usergroup",
    items: [
      {
        prependIcon: "",
        title: "User Group",
        name: "GroupManagement",
        permission: ["configurations:usergroup:view"],
        auth: true,
      },
      {
        prependIcon: "",
        title: "Users",
        name: "UserManagement",
        permission: ["management:user:view"],
        auth: true,
      },
      { prependIcon: "", title: "Team", name: "TeamManagement",permission: ['management:team:view'], auth: true },
      { prependIcon: "", title: "Channel", name: "Channel",permission: ['management:channel:view'], auth: true },
      { prependIcon: "", title: "Source", name: "Source",permission: ['management:source:view'], auth: true },
      
    ],
  },
  // {
  //   icon: "fa-soild fa-hospital",
  //   title: "Appointment",
  //   group: "appointment",
  //   items: [
  //     { prependIcon: "", title: "Shift", name: "Shift", permission: ['configurations:pausecode:view'], auth: true },
  //     { prependIcon: "", title: "Category", name: "Category", permission: ['configurations:pausecode:view'], auth: true },
  //     { prependIcon: "", title: "User Shift", name: "ShiftUser", permission: ['configurations:pausecode:view'], auth: true },
  //     { prependIcon: "", title: "Calender", name: "Calender", permission: ['configurations:pausecode:view'], auth: true },
  //   ]
  // },
  {
    icon: "assignment_turned_in",
    title: "Reports",
    group: "report",
    items: [

      { prependIcon: "", title: "Call Log", name: "CallDetailReport", permission: ['reports:calldetail:view'], auth: true },
      { prependIcon: "", title: "Call Recordings", name: "RecordingReport", permission: ['reports:recording:view'], auth: true },
      { prependIcon: "", title: "Call Transfer", name: "CallTransferReport", permission: ['reports:calltransfer:view'], auth: false },
      { prependIcon: "", title: "Agent Performance", name: "PerformanceReport", permission: ['reports:agentperformance:view'], auth: true },
      { prependIcon: "", title: "Agent Activity", name: "AgentActivityReport", permission: ['reports:agentactivity:view'], auth: true },
      { prependIcon: "", title: "Agent Pause", name: "AgentPauseReport", permission: ['reports:agentpause:view'], auth: true },
      { prependIcon: "", title: "Agent Dispo", name: "AgentDispoReport", permission: ['reports:agentdispo:view'], auth: true },
      { prependIcon: "", title: "Dropped Calls", name: "DropedCallReport", permission: ['reports:dropedcall:view'], auth: true },
      { prependIcon: "", title: "Missed Calls", name: "MissedCallReport", permission: ['reports:missedcall:view'], auth: true },
      { prependIcon: "", title: "Queue Summary", name: "QueueSummary", permission: ['reports:queueperformance:view'], auth: true },
      { prependIcon: "", title: "Abandoned Summary", name: "AbandonedSummary", permission: ['reports:abandonedsummary:view'], auth: true },
      { prependIcon: "", title: "Effective Abandon", name: "EffectiveAbandonReport", permission: ['reports:effectiveabandon:view'], auth: true },
      { prependIcon: "", title: "System Call Summary", name: "SystemCallSummaryReport", permission: ['reports:callsummary:view'], auth: true },
      { prependIcon: "", title: "Ticket By Date", name: "TicketReport", permission: ['reports:ticket:view'], auth: false },
      { prependIcon: "", title: "Ticket By Agent", name: "TicketByAgent", permission: ['reports:ticketbyagent:view'], auth: false },
      { prependIcon: "", title: "Social Chat Log", name: "WaChatLogReport",permission: ['reports:whatsappchatlog:view'], auth: false },// permission: ['reports:whatsappchatlog:view'],
      
      // { prependIcon: "", title: "WhatsApp By Date", name: "WatsAppReport", permission: ['reports:whatsapp:view'], auth: true },
      // { prependIcon: "", title: "WhatsApp By Agent", name: "WatsAppByAgent", permission: ['reports:whatsappbyagent:view'], auth: true },
    


    ]
  },
  {

    icon: "alarm",
    title: "Real Time",
    group: "realtime",
    items: [

      { prependIcon: "", title: "Live Agents/Calls", name: "LiveAgents", permission: ['realtime:liveagents:view'], auth: true },
      // { prependIcon: "", title: "Live Calls", name: "LiveCalls", permission: ['realtime:livecalls:view'], auth: true },
      {
        prependIcon: "",
        title: "Live Status",
        name: "Wallboard",
        permission: ['realtime:livestatus:campaignview', 'realtime:livestatus:queueview'],
        auth: true
      },
    ]
  },
  {
    icon: "settings",
    title: "Configurations",
    group: "configurations",
    items: [

      { prependIcon: "", title: "Queues", name: "QueueManagement", permission: ['management:queue:view'], auth: true },
      { prependIcon: "", title: "Dispositions", name: "Disposition", permission: ['configurations:dispositions:view'], auth: true },
      { prependIcon: "", title: "Sub-Dispositions", name: "SubDisposition", permission: ['configurations:subdisposition:view'], auth: true },
      { prependIcon: "", title: "Lead Status", name: "leadStatus", permission: ['configurations:leadstatus:view'], auth: true },
      { prependIcon: "", title: "Ticket Status", name: "ticketStatus", permission: ['configurations:ticketstatus:view'], auth: true },
      { prependIcon: "", title: "Pause Codes", name: "PauseCode", permission: ['configurations:pausecode:view'], auth: true },
      { prependIcon: "", title: "Audio Store", name: "AudioStore", permission: ['configurations:audiostore:view'], auth: true },
      { prependIcon: "", title: "Block List", name: "BlockList", permission: ['configurations:blocklist:view'], auth: true },
      { prependIcon: "", title: "DNC", name: "DoNoCall", permission: ['configurations:dnc:view'], auth: true },
      { prependIcon: "", title: "DID Number", name: "DidNumber", permission: ['configurations:didnumber:view'], auth: true },
      { prependIcon: "", title: "Tags", name: "TagManagement", permission: ['management:tags:view'], auth: true },
      { prependIcon: "", title: "Announcements", name: "Announcement", permission: ['configurations:announcement:view'], auth: true },
      // { prependIcon: "", title: "Reminders", name: "Reminder", permission: ['configurations:reminder:view'], auth: true },
      { prependIcon: "", title: "Scripts", name: "Script", permission: ['configurations:script:view'], auth: true },
      // hide the modules not remove hided codes .start
      // { prependIcon: "", title: "SLA", name: "Sla", permission: ['configurations:sla:view'], auth: true },
      // END
      // { prependIcon: "", title: "Workflow", name: "Workflow", permission: ['configurations:sla:view'], auth: true },
      { prependIcon: "", title: "Rules", name: "Rules", permission: ['configurations:rules:view'], auth: true },
      // { prependIcon: "", title: "Scheduler", name: "Scheduler", permission: ['configurations:scheduler:view'], auth: true },
      { prependIcon: "", title: "Inbound Route", name: "InboundRoute", permission: ['configurations:inboundroute:view'], auth: true },
      { prependIcon: "", title: "Omnichannel Route", name: "OmnichannelRoute", permission: ['configurations:omnichannelroute:view'], auth: false},
      { prependIcon: "", title: "Timezone", name: "Timezone", permission: ['configurations:dialrule:view'], auth: true },
      // { prependIcon: "", title: "Notification", name: "Notification", permission: ['configurations:notification:view'], auth: true },
      { prependIcon: "", title: "System Settings", name: "SystemSettings", permission: ['configurations:announcement:view'], auth: true },
      { prependIcon: "", title: "Meeting Title", name: "MeetingTitle", permission: ['configurations:pausecode:view'], auth: true },  //Meeting Title
    ]
  },
  {

    icon: "fa fa-building",
    title: "Custom Builder",
    group: "builder",
    items: [
      {
        prependIcon: "",
        title: "Modules",
        name: "Formbuilder",
        permission: ['formbuilder:forms:view'],
        auth: true
      },

      {
        prependIcon: "",
        title: "Lead Templates",
        name: "CustomFormList",
        permission: ["leadmanagement:custom-form:view"],
        auth: true,
      },
      { prependIcon: "", title: "Custom Reports", name: "Widgets", permission: ["dashboard:widget:view"], auth: true },
      // { prependIcon: "", title: "Live Calls", name: "LiveCalls", permission: ['realtime:livecalls:view'], auth: true },
    
    ]
  },
  // {

  //   icon: "fa fa-building",
  //   title: "Report Builder",
  //   name: "Widgets",
  //   permission: ["dashboard:widget:view"],
  //   auth: true,
  // },
  // {
  //   icon: "dashboard",
  //   title: "Form Builder",
  //   name: "Formbuilder",
  //   permission:  ["formbuilder:forms:view"],
  //   auth: false,
  // },
  // {
  //   icon: "rss_feed",
  //   title: "Connections",
  //   group: "connections",
  //   items: [
  //     {
  //       prependIcon: "",
  //       title: "SMS",
  //       name: "AsterSms",
  //       permission: ["template:sms:view"],
  //       auth: true,
  //     },
  //     {
  //       prependIcon: "",
  //       title: "Email",
  //       name: "AsterMail",
  //       permission: ["template:mail:view"],
  //       auth: true,
  //     },
  //     {
  //       prependIcon: "",
  //       title: "Whatsapp",
  //       name: "AsterWhatsapp",
  //       permission: ["template:whatsapp:view"],
  //       auth: true,
  //     },
  //     {
  //       prependIcon: "",
  //       title: "Web Hooks",
  //       name: "WebHooks",
  //       permission: ["connection:webhooks:view"],
  //       auth: true,
  //     },
  //   ],
  // },
  {
    icon: "phone",
    title: "Call Flow",
    name: "CallFlow",
    permission: ["call:flow:view"],
    auth: true,
  },
  


  {
    icon: "fa-clipboard fa-2x",
    title: "Custom Module",
    group: "custom",
    component:"custom",
    items: [

      ]
  },

];

export default AppDrawerItems;
