<template>
    <div>
        <Expandpanel @save="save" :GetAsterLeadField="GetAsterLeadField" :ExpandpanelSchema="ExpandpanelSchema">
        </Expandpanel>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog persistent v-model="openpop" :scrollable="false" max-width="600px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Upload {{ uploadresponse.live ? "Completed" : "In Progress" }}</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>

                        <v-card-text>Total Data: {{ uploadresponse.totalcount }} </v-card-text>
                        <v-card-text>Total Upload Data: {{ uploadresponse.uploadcount }} </v-card-text>
                        <v-card-text>Total Skip Data: {{ uploadresponse.skipcount }} </v-card-text>
                        <v-card-text v-if="uploadresponse.skipcount > 0">Download Skip Data: <v-btn color="primary" flat
                                @click="skipdatadownload()">Click Here</v-btn> </v-card-text>
                        <v-card-text v-if="!uploadresponse.live">The process could take a while ,Please do not reload
                            this page </v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions v-if="uploadresponse.live">
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="close(true)">Close</v-btn>

                </v-card-actions>
            </v-card>

            <!-- -->
        </v-dialog>
    </div>
</template>

<script>
import Expandpanel from "./expandpanel.vue"
import api from "@/utils/axios-util";
// const { convertArrayToCSV } = require("convert-array-to-csv");
export default {
    components: {
        Expandpanel
    },
    mounted() {
        this.sidebar_enable_disable(true);
        if (!this.$route.query.hasOwnProperty('action') || !this.$route.query.hasOwnProperty('module') || Object.keys(this.$route.query).length !== 2 || this.$route.query.module !== "leads" || this.$route.query.action !== "create") {
            this.$router.push({
                name: "callback",
            });
        } else {
            this.getcampaign(this.$route.query.module)
            if (this.$route.query.module == "leads") {


                const titleData_local = JSON.parse(
                    localStorage.getItem("templetname")
                );
                let titleData = titleData_local.templetname;


                this.$store
                    .dispatch("GetAsterLeadField", { titleData, show_on_view: "true" })
                    .then((response) => {
                        var datas = [];
                        let duplicatecheckdatas = []
                        for (let index = 0; index < response.data[0].fields.length; index++) {
                            const element = response.data[0].fields[index];
                            if (element.model !== "lead_id" && element.model !== "list_id" && element.model !== "modified_by") {
                                datas.push({ model: element.model, disabled: false, model_type: element.type })
                                if (element.model === "phone_number") {

                                    duplicatecheckdatas.push({ model: element.model, model_type: element.type })
                                }

                            }

                        }
                        this.GetAsterLeadField = datas
                        for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
                            const schema = this.ExpandpanelSchema[i];
                            if (schema.value === "header") {
                                schema.fields.options = datas
                            }
                            if (schema.value === "duplicate_check") {
                                schema.childfields.options = duplicatecheckdatas
                            }

                        }
                    })
            }
        }
    },
    methods: {
        skipdatadownload() {
            let type = ""
            for (let i = 0; i < this.getsavedata.length; i++) {
                const element = this.getsavedata[i];
                if (element.value === "file_type") {
                    type = element.fields.value
                }
            }
            if (type === "csv") {
                // let scope = this; // Assuming 'this' context is correctly set

                let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
                csvContent += Object.keys(this.uploadresponse.skipdata[0]).join(",") + "\r\n";

                this.uploadresponse.skipdata.forEach(function (row) {
                    let data1 = Object.values(row).map(elm => {
                        if (typeof elm === 'string') {
                            return elm.replace(/[~`!@#$%^&*()_+\-=[\]{}\\|:;”’<,>.?/]/g, " ");
                        } else {
                            return elm;
                        }
                    });

                    let csvRow = data1.join(",");
                    csvContent += csvRow + "\r\n";
                });

                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "data.csv");
                document.body.appendChild(link);
                link.click();


            } else if (type === "txt") {
                let txtContent = "";
                for (const key in this.uploadresponse.skipdata[0]) {
                    let txt_tab = `${key} \t`;
                    txtContent += `${txt_tab}`;
                }
                this.uploadresponse.skipdata.forEach(function (rows) {
                    let data = Object.values(rows);
                    let row = data.join("\t");
                    txtContent += `\n ${row}` + "\r";
                });
                var encodedUri1 =
                    "data:text/plain;charset=utf-8,\uFEFF" +
                    encodeURIComponent(txtContent);

                var link1 = document.createElement("a");
                link1.setAttribute("href", encodedUri1);
                link1.setAttribute(
                    "download",
                    "skip_" + `data.txt`
                );
                document.body.appendChild(link1);
                link1.click();
            }

        },
        close(data) {
            this.uploadresponse = {
                "live": false,
                "totalcount": "In Progress",
                "uploadcount": "In Progress",
                "skipcount": "In Progress",

            }
            this.openpop = false
            this.getsavedata = []
            if (data) {
                this.goBack()
            }

        },
        showSnackBar(message) {//nouse
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        save(data) {
            this.getsavedata = data
            this.openpop = true
            let formData = new FormData();
            for (let i = 0; i < data.length; i++) {
                if (data[i].value === "duplicate_check") {
                    formData.append("duplicate_check", data[i].fields.value === "no" ? false : true);
                    formData.append("duplicate_check_rule", JSON.stringify(data[i].childfields.value));
                } if (data[i].value === "auto_assign") {
                    formData.append("automation", data[i].fields.value === "no" ? false : true);
                    formData.append("automation_details", JSON.stringify({ strategy: "roundrobin", values: data[i].childfields.value }));
                } if (data[i].value === "header") {
                    formData.append("header", JSON.stringify(data[i].fields.value));
                } if (data[i].value === "list_id") {
                    formData.append("additionalInfo", JSON.stringify([{ "list_id": data[i].fields.value }]));
                } if (data[i].value === "upload") {
                    formData.append("file", data[i].fields.value);
                }

            }
            formData.append("model", this.$route.query.module);
            formData.append("type", this.$route.query.action);
            api.defaults.headers["Content-Type"] = "multipart/form-data";
            api
                .post("/upload", formData)
                .then((response) => {
                    api.defaults.headers["Content-Type"] = "application/json";
                    this.uploadresponse = response.data
                    this.uploadresponse.live = true
                })
                .catch((error) => {
                    this.close()
                    this.showSnackBar("Upload Fail ==>" + error)


                });
        },
        goBack() {

            this.$router.go(-1); // Assuming you are using Vue Router
        },
        getcampaign() {
            let scope = this
            let payload_ = { template: JSON.parse(localStorage.templetname).templetname, skip: 0, take: 0, campaign_status: 'Y', All: true };
            return scope.$store
                .dispatch("GetAsterCampaigns", payload_)
                .then(async (response) => {
                    if (this.$route.query.module == "leads") {
                        this.get_list(response)
                    }
                    this.getteams(response)
                }).catch(function (error) {
                    scope.showSnackBar("error" + error);
                });
        },
        async getteams(response) {
            let teams_user = []
            if (response.statusCode == 200) {
                let campaign = await response.data

                for (let i = 0; i < campaign.length; i++) {
                    const element = campaign[i];
                    let stats = {
                        skip: 0,
                        campaign: element.campaign_name,
                        take: 100,
                        // template:JSON.parse(localStorage.templetname).templetname
                    };

                    await this.$store.dispatch("FilterTeam", stats).then(async (response) => {
                        if (response.status === 200) {

                            for (let j = 0; j < response.data.data.length; j++) {
                                const element_ = response.data.data[j];
                               let teamdata = JSON.parse(
                                    localStorage.getItem("agent_emails")
                                );
                                let agents=[]
                                for (let k = 0; k < teamdata.length; k++) {
                                    const elementteam = teamdata[k];
                                    for (let l = 0; l < element_.user.length; l++) {
                                        const elementval = element_.user[l];
                                        if(elementteam===elementval){
                                            agents.push(elementval)
                                        }
                                        
                                    }
                                }
                                
                                teams_user = teams_user.concat(agents)
                            }


                        }


                    })
                }


            }
            console.log(teams_user, "rrrrrrrrrrrrrrrrrrwerwer")
            if (teams_user.length === 0) {
                teams_user = JSON.parse(
                    localStorage.getItem("agent_emails")
                );
            }


            for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
                const schema = this.ExpandpanelSchema[i];
                if (schema.value === 'auto_assign') {
                    schema.childfields.options = teams_user
                }

            }
        },
        async get_list(response) {
            let scope = this
            let list = []
            if (response.statusCode == 200) {
                let campaign = await response.data

                for (let i = 0; i < campaign.length; i++) {
                    const element = campaign[i];
                    let stats = {
                        list_name: "",
                        list_campaign: element.campaign_name,
                        list_status: "Y",
                        // template:JSON.parse(localStorage.templetname).templetname
                    };

                    await scope.$store.dispatch("FilterAsterList", stats).then(async (response) => {

                        let data = await response.data;
                        if (data !== undefined) {
                            for (let j = 0; j < data.length; j++) {

                                const element_ = data[j];



                                list.push(element_.list_id)
                            }
                        }


                    })
                }


            } else {
                list = []
            }


            for (let i = 0; i < this.ExpandpanelSchema.length; i++) {
                const schema = this.ExpandpanelSchema[i];
                if (schema.value === "list_id") {
                    schema.fields.options = list
                }

            }


        },
        sidebar_enable_disable(data) {
            this.$root.$emit('sidebar_disable', data);
        },
    },
    data() {
        return {
            getsavedata: [],
            uploadresponse: {
                "live": false,
                "totalcount": "In Progress",
                "uploadcount": "In Progress",
                "skipcount": "In Progress",

            },
            openpop: false,
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            GetAsterLeadField: [],
            ExpandpanelSchema: [
                {
                    header: 'Select File Upload Type',
                    name: " File Type",
                    value: "file_type",
                    active: true,
                    child: false,
                    disabled: false,
                    fields: {
                        field: "select",
                        multiple: false,
                        value: "csv",
                        default: "csv",
                        placeholder: "Select Upload Type",
                        options: [{
                            lable: "Csv",
                            value: "csv",
                        },
                            // {
                            //     lable: "Txt",
                            //     value: "txt",
                            // }
                        ]
                    }


                },
                {
                    header: 'Import Your File',
                    name: "Upload file",
                    value: "upload",
                    child: false,
                    active: true,
                    disabled: false,
                    fields: {
                        field: "image",
                        placeholder: "Please Select file to upload",
                        value: {},

                    }

                },
                {
                    header: 'Map your file header',
                    name: "Header Mapping",
                    value: "header",
                    child: false,
                    active: true,
                    disabled: true,
                    fields: {
                        field: "select",
                        multiple: false,
                        default: "",
                        value: [],
                        options: [],
                        placeholder: "Map Header",
                    }
                },
                {
                    header: 'Select list id to upload lead',
                    name: "Select List Id",
                    value: "list_id",
                    child: false,
                    active: true,
                    disabled: true,
                    fields: {
                        field: "select",
                        default: "",
                        multiple: false,
                        value: "",
                        placeholder: "Select List Id",
                        options: []
                    }

                },
                {
                    header: 'Check data duplication',
                    name: "Duplicate Check",
                    value: "duplicate_check",
                    active: true,
                    disabled: true,
                    child: true,
                    childfields: {
                        condition: "yes",
                        field: "select",
                        multiple: true,
                        default: "",
                        placeholder: "Select Duplicate Check Field",
                        value: [],
                        options: []
                    },
                    fields: {
                        field: "select",
                        default: "no",
                        multiple: false,
                        placeholder: "Select Duplicate Check ",
                        value: "no",
                        options: [{
                            lable: "Yes",
                            value: "yes",
                        }, {
                            lable: "No",
                            value: "no",
                        }]
                    }

                },
                {
                    header: 'Auto assign Algorithm',
                    name: "Auto Assign",
                    value: "auto_assign",
                    active: true,
                    disabled: true,
                    child: true,
                    childfields: {
                        condition: "yes",
                        field: "select",
                        multiple: true,
                        placeholder: "Select User ",
                        default: "",
                        value: [],
                        options: []
                    },
                    fields: {
                        field: "select",
                        default: "no",
                        multiple: false,
                        placeholder: "Select Auto assign need or not",
                        value: "no",
                        options: [{
                            lable: "Yes",
                            value: "yes",
                        }, {
                            lable: "No",
                            value: "no",
                        }]
                    }

                },

            ],
            panel: [0, 1],
            disabled: false,
        }
    },
}
</script>
