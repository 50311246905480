<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
      @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>

    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="omnichannelData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.channel }}</td>
          <td class="checkEmpty p0">{{ props.item.did_number }}</td>
          <td class="checkEmpty p0">
            {{
              props.item.campaign.toString() !== "" &&
                props.item.campaign.toString() !== "0"
                ? props.item.campaign.toString()
                : "-"
            }}
          </td>
          <td class="checkEmpty p0">{{ props.item.queue }}</td>
          <td class="checkEmpty p0">
            <template v-if="props.item.active === 1">Active</template>
            <template v-else>Inactive</template>
          </td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.id)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog persistent v-model="DeleteRowdata" max-width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Omnichannel Route</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this Omnichannel route?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template>
      <v-dialog persistent :value="page.isAddOmnichannel" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Omni-Channel Route</span>
            </v-card-title>
          </v-toolbar>

          <v-card-text style="height: 500px">
            <v-form ref="forms" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select v-model="addOmnichannel.payload.channel" required label="Channel"
                      :rules="[(v) => !!v || 'Channel is required']" :items="channels"></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field v-model="addOmnichannel.payload.did_number" required label=" DID Routing" type="text"
                      :rules="[(v) => !!v || ' DID Routing is required']"></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-select v-model="addOmnichannel.payload.campaign" required label="Campaign"
                      :rules="[(v) => !!v || 'Campaign value is required']" :items="campaignValues"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="addOmnichannel.payload.queue" required label="Queue"
                      :rules="[(v) => !!v || 'Queue value is required']" :items="queueValues"></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-select v-model="addOmnichannel.payload.strategy" required label="Strategy"
                      :items="strategy"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch color="primary" v-model="addOmnichannel.payload.sticky" label="Sticky" :true-value="`1`"
                      :false-value="`0`"></v-switch>
                  </v-flex>

                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddOmnichannel()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAddOmnichannel()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>


    <template>
      <v-dialog persistent :value="page.iseditOmnichannel" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Omni-Channel Route</span>
            </v-card-title>
          </v-toolbar>

          <v-card-text style="height: 500px">
            <v-form ref="form" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select v-model="editOmnichannel.channel" required label="Channel"
                      :rules="[(v) => !!v || 'Channel is required']" :items="channels"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="editOmnichannel.did_number" readonly label="Routing ID"
                      type="text"></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-select v-model="editOmnichannel.campaign" required label="Campaign"
                      :rules="[(v) => !!v || 'Campaign value is required']" :items="campaignValues"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editOmnichannel.queue" required label="Queue"
                      :rules="[(v) => !!v || 'Queue value is required']" :items="queueValues"></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-select v-model="editOmnichannel.strategy" required label="Strategy"
                      :items="strategy"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch color="primary" v-model="editOmnichannel.sticky" label="Sticky" :true-value="`1`"
                      :false-value="`0`"></v-switch>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editOmnichannel.active" required label="Status" :items="active"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!updateValid" @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>


    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete v-model="filter.payload.campaign" :items="campaignValues"
              :search-input.sync="campaignSearch" label="Campaign"></v-autocomplete>

            <v-autocomplete v-model="filter.payload.queue" :items="queueValues" :search-input.sync="queueSearch"
              label="Queue"></v-autocomplete>
              <v-select v-model="filter.payload.active" label="Status" :items="active"></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click=" getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import loader from "@/components/Widgets/loader";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Omni-Channel Route",
        icon: "fa-file-alt fa-2x",
        loading: false,
        isAddOmnichannel: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        iseditOmnichannel: false,
        temp: { key: "addOmnichannel", label: "Add omnichannel" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Channel",
          align: "left",
          class: "p0",
          sortable: false,
          value: "text",
        },
        {
          text: "DID Routing",
          align: "left",
          class: "p0",
          sortable: false,
          value: "text",
        },

        {
          text: "Campaign",
          align: "left",
          class: "p0",
          sortable: false,
          value: "text",
        },
        {
          text: "Queue",
          align: "left",
          class: "p0",
          sortable: false,
          value: "text",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      
      ],

      campaignSearch: "",
      queueSearch: "",

      omnichannelData: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: {
        campaign: [],
        queue: []
      },
      filter: {
        payload: {
          campaign: "",
          queue: "",
          active: "",
        },
        options: {
          active: ["Active", "Inactive"],
        },
        isLoading: false,
      },
      isProcessing: false,
      editOmnichannel: {
        id: '',
        did_number: '',
        campaign: '',
        channel: '',
        queue: '',
        strategy: 'ping all',
        sticky: '',
        active:''
      },
       channels: ['WhatsApp'],
      updateValid:true,
      addOmnichannel: {
      valid: false,
        payload: {
          did_number: "",
          channel: "",
          campaign: "",
          queue: "",
          strategy: 'ping all',
          sticky: '1',
        },
      },
      campaignValues: [],
      queueValues: [],
      strategy: ['ping all'],
      active: ["Active", "Inactive"],

    };

  },

  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.pagination.page = 1;
      scope.page.loading = true;
      scope.filter.payload.campaign = "";
      scope.filter.payload.queue = "";
      scope.filter.payload.active = "";
      this.getDataFromApi();
    },

    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;

    },
    reset() {
      let scope = this
      scope.filter.payload.campaign = "";
      scope.filter.payload.queue = "";
      scope.filter.payload.active = "";
      this.$refs.forms.reset();
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }

      let query = {
        skip: skip,
        take: take,
        queue: scope.filter.payload.queue ? scope.filter.payload.queue : undefined,
        campaign: scope.filter.payload.campaign ? scope.filter.payload.campaign : '',
        active: scope.filter.payload.active ? scope.filter.payload.active : ''
      };
      this.skip = skip;
      console.log("createdatafromapi", query);
      this.$store
        .dispatch("GetOmnichannel", query)
        .then((response) => {
          this.pagecount = response.data.count;
          if (response.status === 204) {
            scope.omnichannelData = [];
            scope.tableLoading = false;
          } else {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data.data;
            if (datas !== "" && datas !== undefined && datas !== null) {
              //for only filterdata
              datas.forEach((element) => {
                if (element.hasOwnProperty("campaign")) {

                  scope.filtervalue.campaign.push(element["campaign"]);
                } else if (element.hasOwnProperty("queue")) {

                  scope.filtervalue.queue.push(element["queue"]);
                }
              });
            }
            //only omni data
            scope.omnichannelData = datas;
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
      scope.page.loading = false;
    },
    searchData(column, val) {
      let scope = this;
      val = val === null ? "" : val;
      let data = { model: "omnichannel", column: [column], search: val };
      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.isLoading = false;
          if (response) {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            if (column === "campaign") {
              scope.filtervalue.campaign = datas[0].campaign;
            } else if (column === "queue") {
              scope.filtervalue.queue = datas[0].queue;
            }
          } else {
            if (column === "campaign") {
              scope.filtervalue.campaign = [];
            } else if (column === "queue") {
              scope.filtervalue.queue = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
 
    AddNewRule() {
    this.page.isAddOmnichannel = true;
     this.valid = false;
  },
    closeEditRules: function () {
      this.page.iseditOmnichannel = false;
      this.updateValid= true;
   
    },
    resetFormValidation() {
    this.$refs.forms.resetValidation('did_number');
    this.$refs.forms.resetValidation('channel');
    this.$refs.forms.resetValidation('campaign');
    this.$refs.forms.resetValidation('queue');
  },
    closeAddOmnichannel() {
    this.page.isAddOmnichannel = false;
    this.addOmnichannel.payload.did_number = '';
    this.addOmnichannel.payload.campaign = '';
    this.addOmnichannel.payload.channel = '';
    this.addOmnichannel.payload.queue = '';
    this.addOmnichannel.payload.sticky= '1';
    this.resetFormValidation(); 
  },
    saveEditRules() {
      var scope = this;
      const payload = scope.editOmnichannel
      console.log("updatedata", payload);
      scope.$store
        .dispatch("updateOmnichannel", payload)
        .then(() => {
          scope.page.isAddOmnichannel = false;
          scope.showSnackBar("updated Successfully");
          scope.page.iseditOmnichannel = false;
          scope.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isAddOmnichannel = false;
          scope.page.iseditOmnichannel = false;
          scope.showSnackBar("update fail" + error);
        });

    
    },
    saveAddOmnichannel() {
      var scope = this;
      scope.$store
        .dispatch("insertOmnichannel", scope.addOmnichannel.payload)
        .then(() => {
          scope.page.isAddOmnichannel = false;
          this.getDataFromApi();
          scope.showSnackBar("Created Successfully");
          this.$refs.form.reset();
          this.closeAddOmnichannel()
        })
        .catch(function (error) {
          scope.page.isAddOmnichannel = false;
          scope.showSnackBar("error" + error);
          scope.addOmnichannel.payload.did_number = "";
          scope.addOmnichannel.payload.campaign = "";
          scope.addOmnichannel.payload.queue = "";
          scope.editOmnichannel.payload.channel = "";
          this.resetFormValidation(); 
        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      //let scope = this;
      console.log("items", item);
      this.page.iseditOmnichannel = true;
      this.editOmnichannel.did_number = item.did_number;
      this.editOmnichannel.id = item.id;
      this.editOmnichannel.campaign = item.campaign
      this.editOmnichannel.channel = item.channel
      this.editOmnichannel.queue = item.queue
      this.editOmnichannel.strategy = item.strategy
      this.editOmnichannel.sticky = item.sticky
      this.updateValid= true;
      if (item.active === 1) {
        this.editOmnichannel.active = "Active";
      } else {
        this.editOmnichannel.active = "Inactive";
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteOmnichannel", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("omnichannel Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },

  },
  mounted() {
    let scope = this;
    this.page.loading = false;
       
    if (
      scope.$store.getters.UserPermissions.indexOf(
        'configurations:omnichannelroute:create'
      )== -1
    ) {
      this.page.addnew = [{ label: "New Routing" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        'configurations:omnichannelroute:delete'
      )== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        'configurations:omnichannelroute:edit'
      )== -1
    ) {
      scope.page.canedit = true;
    }
   
    this.$store
      .dispatch("GetActiveCampaigns")
      .then((response) => {
        var datas = response;
        if (datas !== "" && datas !== undefined && datas !== null) {
          this.campaignValues = datas
        }
      })
      .catch(function (error) {
        scope.showSnackBar("error" + error);
      });

    const payload = {
      skip: 0,
      take: 10
    }

    this.$store
      .dispatch("queueManagement", payload)
      .then((response) => {
        var datas = response.data.data;
        if (datas !== "" && datas !== undefined && datas !== null) {
          datas.forEach((element) => {
            if (element.hasOwnProperty("active") && element.active === "Y") {
              scope.queueValues.push(element["name"]);
            } else {
              scope.queueValues.push(element["name"]);
            }
          });
        }

      })
      .catch(function (error) {
        scope.showSnackBar("error" + error);
      });

    this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },

    "campaignSearch"(val) {
      this.searchData("campaign", val);
    },
    "queueSearch"(val) {
      this.searchData("queue", val);
    },

  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>