<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :exportOptions="page.exportOptions"
      @refreshPage="refreshPage"
      @openFilter="openFilter"
      @exportData="exportReport"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="agentPauseReport"
        class="elevation-1"
        :pagination.sync="pagination"
        :total-items="totalCount"
        :rows-per-page-items="[5, 10, 25]"
      >
        <template v-slot:items="props">
          <td
            class="checkEmpty p0"
            v-for="(property, index) in Object.keys(props.item)"
            :key="index"
          >
            {{ props.item[property] }}
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-navigation-drawer
      fixed disable-resize-watcher
      right
      v-model="page.isFilterOpen"
      class="filterNavigation"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
      <v-container class="sidenavContainer">
        <AgentPauseFilter
          v-if="page.isFilterOpen"
          @getDataFrom="getDataFrom"
          :appliedfilter="appliedfilter"
        ></AgentPauseFilter>
      </v-container>
      <v-container row class="sidenavFooter">
        <v-btn flat @click="resetvalue()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getDataFromApi">Filter</v-btn>
      </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
import moment from "moment";
import AgentPauseFilter from "./Components/AgentPauseFilter";
export default {
  components: {
    loader,
    AgentPauseFilter,
  },
  computed: {
    ...mapGetters(["agentPauseReport"]),
  },
  mounted() {
    const scope = this;
    (scope.page.loading = false), (scope.page.loading = false);
    scope.getDataFromApi();
    scope.dateFormate();
    scope.refreshValues();
    setTimeout(
      function() {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:agentpause:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV","Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
      }.bind(scope),
      1
    );
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Agent Pause Report",
        icon: "fa-user fa-2x",
        loading: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
      },
      pagination: {
        rowsPerPage: 10,
      },
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        agent: "",
        detail: "agentactivityreport",
        descending: false,
        export: "exportcalldetails",
      },
      headers: [],
      totalCount: 0,
      total: 0,
      from_date: "",
      to_date: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      pdf_data: [],
      appliedfilter: {
        fromdate: "",
        todate: "",
        agent: "",
      },
      page_flag: false,
    };
  },
  methods: {
    getDataFromApi() {
      const scope = this;
      (scope.page.loading = false), (scope.page.loading = false);
      scope.payload.to_date = scope.to_date;
      scope.payload.from_date = scope.from_date;
      let { page, rowsPerPage } = scope.pagination;
      scope.payload.agent = JSON.parse(localStorage.getItem("agent_emails"));
      if (rowsPerPage == -1) {
        scope.payload.take = scope.total;
      } else {
        scope.payload.take = rowsPerPage;
      }
      if (page != "" && page != undefined && page != null) {
        scope.skip = page;
      } else {
        scope.skip = 1;
      }
      scope.payload.skip = (scope.skip - 1) * scope.payload.take;
      let { descending } = scope.pagination;
      scope.payload.descending = descending;
      scope.$store
        .dispatch("agentPauseReports", scope.payload)
        .then((Response) => {
          scope.page.loading = false;
          if (Response.status === 200) {
            if (scope.page_flag) {
              scope.pagination.page = 1;
              scope.page_flag = false;
            }
            if (Response.data.length) {
              scope.headers = [];
              Object.keys(Response.data[0]).forEach((element) => {
                scope.headers.push({
                  text: element,
                  align: "left",
                  sortable: false,
                  class: "p0",
                  value: element.toLowerCase,
                });
              });
            }
            scope.total = Response.total;
            scope.totalCount = scope.total;
          }
          if (Response.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
        scope.page.isFilterOpen =false
    },
    dateFormate() {
      const scope = this;
      scope.page.loading = false;
      scope.to_date = new Date();
      scope.to_date = moment(scope.to_date).format("YYYY-MM-DD");
      const from_date1 = new Date();
      const dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      scope.from_date = moment(from_date1).format("YYYY-MM-DD");
      scope.$store.commit("FILTERFROMDATE", scope.from_date);
      scope.$store.commit("FILTER_TODATE", scope.to_date);
    },
    refreshPage: function() {
      const scope = this;
      scope.page.loading = true;
      scope.dateFormate();
      scope.refreshValues();
      this.pagination.page=1
      scope.appliedfilter.campaign = "";
      scope.appliedfilter.agent = "";
      scope.getDataFromApi();
    },
    refreshValues() {
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.$store.commit("AGENT_CAMPAIGN", { campaign: "" });
    },
    exportReport: function(option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        this.pdf_flag = true;
      }
    },

    export(option) {
      const scope = this;
      scope.excel_data = [];
      scope.pdf_data = [];
      let add_details = {
        from_date: scope.payload.from_date,
        to_date: scope.payload.to_date,
        misseddetail: "AgentPauseReport",
      };
      scope.$store.dispatch("exportdatafetch", add_details).then((response) => {
        scope.exports = response;
        scope.exports.forEach((item) => {
          scope.excel_data.push(item);
        });
        if (option == "CSV") {
          let csvContent = '';
          csvContent += Object.keys(scope.exports[0]).join(",") + "\r\n";
          scope.excel_data.forEach(function(rows) {
            let data = Object.values(rows);
            let data1=[]
            data.forEach((elm)=>{
             
              if(typeof elm === 'string'){

                data1.push(elm.replace(/'|"|#|\n/g, " "))
              }
              else{
                data1.push(elm)
              }
            })
            for (let i = 0; i < data1.length; i++) {
              if (
                data1[i] != null &&
                data1[i] != undefined &&
                data1[i] != "" && typeof data1[i] == "string"
              ) {
                if (data1[i].includes(",")) {
                  data1[i] = data1[i].replaceAll(",", ";");
                }
              }
            }
            let row = data1.join(",");
            csvContent += row + "\r\n";
          });
          var date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "AgentPauseReport" + date + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
         if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          // let txt_key =
          //   ["Date Time","DID Number","Phone Number","Queue","Application","Duration"];
          // let txt_tab = txt_key.join("\t");
          // txtContent += `${txt_tab} \r\n`;
          txtContent += Object.keys(scope.exports[0]).join("\t") + "\r\n"
          // txtContent +=
          //   "Date Time,DID Number,Phone Number,Queue,Application,Duration\r\n";
          //  csvContent += "Date,Uniqueid,DID,Queue,Phone Number,Call type ,Queue Enter Time ,Answer Time ,Application ,Agent ,Station,Dispo Status,Status ,Duration ,Talk Time,Comments \r\n";
          
          scope.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
         var encodedUri1 ="data:text/plain;charset=utf-8,\uFEFF"+encodeURIComponent(txtContent);
        
          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "AgentPauseReport" + date2 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
      });
    },
    openFilter() {
      this.page.isFilterOpen = true;
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
    },
    resetvalue() {
      const scope = this;
      scope.skip = 0;
      var to_date = new Date();
      to_date = moment(to_date).format("YYYY-MM-DD");
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format("YYYY-MM-DD");
      scope.$store.commit("FILTERFROMDATE", from_date);
      scope.$store.commit("FILTER_TODATE", to_date);
      scope.appliedfilter.agent = "";
      scope.appliedfilter.flag = true;
      scope.appliedfilter.fromdate = "";
      scope.appliedfilter.todate = "";
      if (scope.appliedfilter.flag) {
        scope.appliedfilter.fromdate = from_date;
        scope.appliedfilter.todate = to_date;
        scope.appliedfilter.flag = false;
      }
      scope.refreshValues();
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    exportAgentActivityReport: function(option) {
      if (option == "CSV") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        this.pdf_flag = true;
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        if(this.pagination.page>1 || this.payload.skip>=10){
        this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
};
</script>
