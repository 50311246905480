<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
 
    <!-- data content -->
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="pausecodeData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.did_number }}</td>
          <td class="checkEmpty p0">{{ props.item.dest_name }}</td>
          <td class="checkEmpty p0">{{ props.item.status }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item.id)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
 
    <!-- delete model -->
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Inbound Route Info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this Inbound route? It will be removed from the associated module.
        </v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 
    <!-- create model -->
    <template>
      <v-dialog persistent :value="page.isAddSubDisposition" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Inbound Route</span>
            </v-card-title>
          </v-toolbar>
 
          <v-card-text style="height: 500px">
            <v-form ref="forms" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="addSubDisposition.payload.did_number" required label="DID Number" type="text"
                      :rules="[(v) => !!v || 'DID Number is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="addSubDisposition.payload.did_name" required label="DID Name"
                      type="text" :rules="[(v) => !!v || 'DID Name is required']"></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
  <v-select
  v-model="addSubDisposition.payload.campaign"
  required
  label="Campaign"
  :items="maindispo"
  ></v-select>
  </v-flex> -->
 
                  <v-flex xs12>
                    <v-select @change="selectDestination" v-model="addSubDisposition.payload.destination_name" required
                      label="Destination Name" :items="destname" :rules="[(v) => !!v || 'Destination Name is required']"></v-select>
                  </v-flex>
 
                  <v-flex xs12 v-if="addSubDisposition.payload.destination_name !==''&&addSubDisposition.payload.destination_name !=='voicemail' && addSubDisposition.payload.destination_name !=='hungup'">
                    <v-select v-if="showDropDown" v-model="addSubDisposition.payload.destination_value" required
                      label="Destination Value" :items="destValue" item-disabled="hungup" :rules="[(v) => !!v || 'Destination Value is required']"></v-select>
                    <v-text-field v-if="showInput" v-model="addSubDisposition.payload.destination_value"
                      label="Destination value" type="text" :rules="[(v) => !!v || 'Destination Value is required']"></v-text-field>
                  </v-flex>
 
                  <v-flex xs12>
                    <v-switch v-model="addSubDisposition.payload.blocklist" color="primary" flat label="Blocklist">
                    </v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAddPausecode()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- update model -->
    <template>
      <v-dialog persistent :value="page.isEditSubDisposition" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Inbound Route</span>
            </v-card-title>
          </v-toolbar>
 
          <v-card-text style="height: 500px">
            <v-form ref="form" v-model="validupdate">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field readonly v-model="editSubDisposition.did_name" required label="DID Name" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
  <v-select
  v-model="editSubDisposition.campaign"
  required
  label="Campaign"
  :items="maindispo"
  ></v-select>
  </v-flex> -->
 
                  <v-flex xs12>
                    <v-select @change="
                      selectDestination(editSubDisposition.destination_name)
                      " v-model="editSubDisposition.destination_name" required label="Destination name"
                      :items="destname"></v-select>
                  </v-flex>
 
                  <v-flex xs12 v-if="editSubDisposition.destination_name !==''&&editSubDisposition.destination_name !=='voicemail' && editSubDisposition.destination_name !=='hungup'" >
                    <v-select v-if="showDropDown" v-model="editSubDisposition.destination_value" required
                      label="Destination value" :items="destValue"
                      :value="editSubDisposition.destination_value"></v-select>
                    <v-text-field v-if="showInput" v-model="editSubDisposition.destination_value"
                      label="Destination value" type="text"></v-text-field>
                  </v-flex>
 
                  <v-flex xs12>
                    <v-text-field v-model="editSubDisposition.did_number" required label="DID Number"
                      type="text"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch v-model="editSubDisposition.blocklist" color="primary" flat label="Blocklist">
                    </v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!validupdate" @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- filter model -->
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <!-- <v-autocomplete
  v-model="filter.payload.campaign"
  :items="filtervalue"
  :loading="filter.isLoading"
  :search-input.sync="filter.search"
  hide-no-data
  hide-selected
  label="campaign"
  ></v-autocomplete> -->
            <v-autocomplete v-model="filter.payload.destination_name" :items="destname"
              label="Application"></v-autocomplete>
            <v-select v-model="filter.payload.status" label="Status" :items="status"></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
 
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },
 
  data() {
    return {
      valid: true,
      validupdate:true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Inbound Route",
        icon: "fa-file-alt fa-2x",
        loading: false,
        isAddSubDisposition: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditSubDisposition: false,
        temp: { key: "addpausecode", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "DID Number",
          align: "left",
          class: "p0",
          sortable: false,
          value: "dispo_id",
        },
 
        {
          text: "Application",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      pausecodeData: [],
      maindispo: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          status: "",
          campaign: "",
          destination_name: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      isProcessing: false,
      call_status: [],
      editSubDisposition: {
        id: "",
        did_number: "",
        did_name: "",
        campaign: "",
        status: "Active",
        blocklist: false,
        destination_name: "",
 
        destination_value: "",
      },
      addSubDisposition: {
        valid: false,
        payload: {
          did_number: "",
          did_name: "",
          campaign: "",
          status: "Active",
          blocklist: false,
          destination_name: "",
 
          destination_value: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      destname: [
        "queue",
        "user",
        "audiostore",
        "manualdial",
        "voicemail",
        "hungup",
      ],
      destValue: [],
      valueArr: {
        queue: [],
        user: [],
        // hungup: [''],
        // voicemail: [],
        audiostore: [],
        manualdial: [],
      },
      showDropDown: true,
      showInput: false,
      status: ["Active", "Inactive"],
    };
  },
 
  methods: {
    selectDestination(item) {
      console.log("pausecode data",this.pausecodeData)
      this.destValue = [];
      this.showDropDown = true;
      this.showInput = false;
 
      let selectedValue = this.addSubDisposition.payload.destination_name;
      if (selectedValue == "queue") {
        this.destValue = this.valueArr.queue;
      }
      if (selectedValue == "user") {
        this.destValue = this.valueArr.user;
      }
      if (selectedValue == "audiostore") {
        this.destValue = this.valueArr.audiostore;
      }
      if (selectedValue == "manualdial") {
        this.showDropDown = false;
        this.showInput = true;
        // this.addSubDisposition.payload.destValue = this.valueArr.audiostore
      }
      if (selectedValue == "hungup") {
        this.showDropDown = false;
      }
      if (selectedValue == "voicemail") {
        this.showDropDown = false;
      }
 
      // let selectedvalue = this.editSubDisposition.destination_name
      let selectedvalue = item;
 
      if (selectedvalue == "queue") {
        this.destValue = this.valueArr.queue;
      }
      if (selectedvalue == "user") {
        this.destValue = this.valueArr.user;
      }
      if (selectedvalue == "audiostore") {
        this.destValue = this.valueArr.audiostore;
      }
      if (selectedvalue == "manualdial") {
        this.showDropDown = false;
        this.showInput = true;
        this.editSubDisposition.destination_value = "";
        // this.addSubDisposition.payload.destValue = this.valueArr.audiostore
      }
      if (selectedvalue == "hungup") {
        this.showDropDown = false;
      }
      if (selectedvalue == "voicemail") {
        this.showDropDown = false;
      }
      this.addSubDisposition.payload.destination_value = "";
      this.editSubDisposition.destination_value = "";
    },
    refreshThisPage: function () {
      var scope = this;
      scope.pagination.page = 1;
      scope.page.loading = true;
      scope.filter.payload.status = "";
      scope.filter.payload.campaign = "";
      scope.filter.payload.destination_name = "";
      scope.getDataFromApi();
    },
    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
      let data = { model: "inbound", column: ["campaign"] };
 
      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.filtervalue = datas[0].campaign;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
    },
    reset() {
      let scope = this;
      scope.filter.payload.status = "";
      scope.filter.payload.campaign = "";
      scope.filter.payload.destination_name = "";
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    getDataFromApi() {
      // console.log("sssss",this.pagination.page);
      var scope = this;
 
      scope.tableLoading = true;
 
      this.page.isFilterOpen = false;
      scope.filtervalue = [];
 
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
 
      let query = {
        skip: skip,
        take: take,
        status: scope.filter.payload.status,
        campaign: scope.filter.payload.campaign,
        destination_name: scope.filter.payload.destination_name,
        // dest_name:scope.filter.payload.destination_name
 
        // descending:''
      };
      this.skip = skip;
      // var { descending } = scope.pagination;
      // this.query.descending = descending;
      this.$store
        .dispatch("InboundRoute", query)
        .then((response) => {
          console.log(response.data, "response");
          this.pagecount = response.data.count;
          if (response.status === 204) {
            scope.pausecodeData = [];
            scope.tableLoading = false;
          } else {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data.data;
            if (datas !== "" && datas !== undefined && datas !== null) {
              // for (let i = 0; i < datas.length; i++) {
              // if (datas[i].active == "Y") {
              // datas[i].active = "Active";
              // } else {
              // datas[i].active = "InActive";
              // }
              // }
 
              datas.forEach((element) => {
                scope.filtervalue.push(element["campaign"]);
              });
            }
            scope.pausecodeData = datas;
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
      scope.page.loading = false;
    },
    AddNewRule: function () {
      this.page.isAddSubDisposition = true;
    },
    closeEditRules: function () {
      this.page.isEditSubDisposition = false;
    },
    closeAddPausecode: function () {
      this.page.isAddSubDisposition = false;
      this.$refs.forms.reset();
    },
    saveEditRules() {
      var scope = this;
 
      if (scope.editSubDisposition.blocklist === true) {
        scope.editSubDisposition.blocklist = 1;
      }
      if (scope.editSubDisposition.blocklist === false) {
        scope.editSubDisposition.blocklist = 0;
      }
      delete scope.editSubDisposition.campaign;
      scope.$store
        .dispatch("updateInbound", scope.editSubDisposition)
        .then(() => {
          scope.page.isAddSubDisposition = false;
          scope.showSnackBar("Edited Successfully");
          this.page.isEditSubDisposition = false;
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isAddSubDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditSubDisposition = false;
        });
      this.getDataFromApi();
    },
    saveAddPausecode() {
      var scope = this;
 
      if (scope.addSubDisposition.payload.blocklist === true) {
        scope.addSubDisposition.payload.blocklist = 1;
      }
      if (scope.addSubDisposition.payload.blocklist === false) {
        scope.addSubDisposition.payload.blocklist = 0;
      }
      delete scope.addSubDisposition.payload.active;
      delete scope.addSubDisposition.payload.campaign;
      scope.$store
        .dispatch("insertInbound", scope.addSubDisposition.payload)
        .then(() => {
          scope.page.isAddSubDisposition = false;
          this.getDataFromApi();
          scope.showSnackBar("Created Successfully");
          this.$refs.form.reset();
          this.closeAddPausecode();
 
          scope.addSubDisposition.payload.blocklist = false;
        })
        .catch(function (error) {
         
          scope.showSnackBar(error);
         
        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      this.selectDestination(item.dest_name);
      // let scope = this;
      this.page.isEditSubDisposition = true;
      this.editSubDisposition.did_number = item.did_number;
      this.editSubDisposition.id = item.id;
      this.editSubDisposition.did_name = item.did_name;
      this.editSubDisposition.campaign = item.campaign;
      this.editSubDisposition.blocklist = item.blocklist;
      this.editSubDisposition.destination_name = item.dest_name;
      this.editSubDisposition.destination_value = item.dest_value;
 
      // if (item.active === "Y") {
      // scope.editSubDisposition.active = "Active";
      // }
      // if (item.active === "N") {
      // scope.editSubDisposition.active = "Inactive";
      // }
 
      // if (item.call_back === "Y") {
      // scope.editSubDisposition.call_back = true;
      // }
      // if (item.call_back === "N") {
      // scope.editSubDisposition.call_back = false;
      // }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteInbound", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Inbound Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  created() {
    let scope = this;
    let query = {
      skip: "",
      take: "",
    };
 
    this.$store
      .dispatch("queue", query)
      .then((response) => {
        // console.log("dataS",response);
        var datas = response;
        this.valueArr.queue = datas;
        // console.log(datas,"datas");
      })
      .catch(function (error) {
        // scope.page.isAddSubDisposition = false;
        scope.showSnackBar("error" + error);
        // this.page.isEditSubDisposition = false;
      });
      this.valueArr.user = JSON.parse(localStorage.getItem("agent_emails"));
    // this.$store
    //   .dispatch("userManagement", { skip: 0, take: 30 })
    //   .then(async (response) => {
    //     // console.log("dataS",response);
    //     let arr = [];
    //     var datas = response.data.data;
    //     await datas.forEach((res) => {
    //       arr.push(res.user_email);
    //     });
    //     this.valueArr.user = arr;
    //     // console.log(datas,"datas");
    //   })
    //   .catch(function (error) {
    //     // scope.page.isAddSubDisposition = false;
    //     scope.showSnackBar("error" + error);
    //     // this.page.isEditSubDisposition = false;
    //   });
    this.$store
      .dispatch("GetAudio", { skip: "", take: "" })
      .then(async (response) => {
        let arr = [];
        if (response.status == 200) {
          var datas = response.data.records;
          await datas.forEach((res) => {
            arr.push(res.file_name);
          });
        }
        this.valueArr.audiostore = arr;
      })
      .catch(function (error) {
        // scope.page.isAddSubDisposition = false;
        scope.showSnackBar("error" + error);
        // this.page.isEditSubDisposition = false;
      });
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
 
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:inboundroute:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "New" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:inboundroute:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:inboundroute:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
 
    this.$store
      .dispatch("Getcampaigns")
      .then((response) => {
        // console.log("dataS",response);
        var datas = response.data;
        if (datas !== "" && datas !== undefined && datas !== null) {
          datas.forEach((element) => {
            if (element.active === "Y") {
              scope.maindispo.push(element["campaign_name"]);
            }
          });
        }
      })
      .catch(function (error) {
        // scope.page.isAddSubDisposition = false;
        scope.showSnackBar("error" + error);
        // this.page.isEditSubDisposition = false;
      });
 
    this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;
 
        let data = {
          model: "inbound",
          column: ["campaign", "dest_name"],
        };
        data["search"] = val;
 
        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            console.log(datas, "data");
            (this.filtervalue = datas[0].campaign), datas[0].dest_name;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>