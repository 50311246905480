
import api from "../../utils/axios-util";
const actions = {
    insertOmnichannel: (context,payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/create/omnichannel`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    GetActiveCampaigns: () => {
        return new Promise((resolve, reject) => {
            try {
              
                api.get(`/aster/campaigns`).then(response => {
                    if (response && response.status=== 200) {
                       const datas= response.data.data
                        const campaignNames = [];
                        datas.forEach(item => {
                          if (item.campaign_name && item.active === "Y") {
                            campaignNames.push(item.campaign_name);
                          }
                        });
                        resolve(campaignNames);
                    } else {
                        reject("Error in API response");
                    }
                });
            } catch (error) {
                console.error("Error in API call:", error);
                reject(error);
            }
        });
    },
   
    GetOmnichannel: (context,params) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/omnichannel`,{params}).then((response) => {
                    resolve(response)
                }).catch(function(error) {
                    reject(error)
                });
            } catch (error) {
                reject(error)
            }
        })
    },
   
    deleteOmnichannel: (context, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/omnichannel/delete/${payload}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateOmnichannel: (context, payload) => {
        return new Promise((resolve, reject) => {
          try {
            const id = payload.id;
            delete payload.id;
      
            api.put(`/omnichannel/${id}`, payload)
              .then(response => {
               
                resolve(response);
              })
              .catch(function (error) {
       
                reject(error);
              });
          } catch (error) {
            reject(error);
          }
        });
      }
      
};
export default {
    actions
};