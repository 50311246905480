<template>
  <div>
    <section-definition
      :title="pagecontrol.title"
      :extraOptions="pagecontrol.options"
      :icon="pagecontrol.icon"
      :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions"
      :addNewData="pagecontrol.addnew"
      @openFilter="filterClick"
      @refreshPage="refreshClick"
     @addNew="addNew"
      :style="styleObject"
    ></section-definition>
    <loader v-if="pagecontrol.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <template>
        <v-dialog presistent v-model="pagecontrol.isAddUser" scrollable max-width="500px">
          <v-card>
            <v-toolbar dark color="primary" class="modal-header--sticky">
              <v-card-title>
                <span class="headline">Create User</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text style="height: 500px">
              <v-form ref="adduserform" v-model="valid">
                <v-container grid-list-md>
                  <div id="scrollset">
                    <div id="scrollbar">
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-text-field
                            v-model="newlyUser.user_id"
                            :rules="idrules"
                            required
                            label="User Id"
                          ></v-text-field>
                          <div>
                            <h4
                              v-if="error_user_id === 500"
                              class="alert-danger"
                            >
                              This UserId already exists
                            </h4>
                            <h4
                              v-else-if="error_user_id === 400"
                              class="alert-danger"
                            >
                              This UserId already exists
                            </h4>
                          </div>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="newlyUser.name"
                            :rules="namerules"
                            required
                            label="Name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            v-model="newlyUser.user_phone_number"
                            :rules="mobileRules"
                            required
                            label="Mobile Number"
                            onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                            type="text"
                          ></v-text-field>
                          <div>
                            <h4
                              v-if="error_user_id === 500"
                              class="alert-danger"
                            >
                              This mobile Mobile Number maybe already exists
                            </h4>
                            <h4
                              v-else-if="error_user_id === 400"
                              class="alert-danger"
                            >
                              This Mobile Number maybe exists
                            </h4>
                          </div>
                          <v-flex xs12>
                            <v-text-field
                              v-model="newlyUser.user_email"
                              :rules="emailRules"
                              required
                              label="Email Id"
                            ></v-text-field>
                            <div>
                              <h4
                                v-if="error_user_id === 500"
                                class="alert-danger"
                              >
                                This mail id maybe already exists
                              </h4>
                              <h4
                                v-else-if="error_user_id === 400"
                                class="alert-danger"
                              >
                                This mail id maybe exists
                              </h4>
                            </div>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              v-model="newlyUser.password"
                              :rules="passwordrules"
                              required
                              label="Password"
                              placeholder="sample@1234"
                            ></v-text-field>
                          </v-flex>
                        </v-flex>
                        <v-flex xs12  @click="getDID">
                          <v-select
                            v-model="newlyUser.didNumber"
                            :items="didNumber"
                            item-text="did_number"
                            item-value="id"
                            label="DID"
                            return-object
                          ></v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="newlyUser.roles"
                            :items="UserType"
                            :rules="[(v) => !!v || 'Item is required']"
                            label="Role"
                            item-text="name"
                            item-value="id"
                            return-object
                          ></v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            v-model="newlyUser.groups"
                            :items="groupTypes"
                            :rules="groupRules"
                            label="Group"
                            item-text="group_name"
                            item-value="group_id"
                            return-object
                            :multiple="newlyUser.roles"
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                  <!-- </v-form> -->
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions class="modal-footer--sticky">
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="CloseNewUser()">Close</v-btn>
              <v-btn :disabled="!valid" color="primary" @click="SaveNewUser()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      
      <v-dialog presistent v-model="dialog" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
          </v-toolbar>

          <v-container grid-list-md>
            <v-form ref="edituserform" v-model="valid">
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.user_id"
                    :rules="idrules"
                    readonly
                    label="User Id"
                  ></v-text-field>
                  <div>
                    <h4 v-if="error_edit === 500" class="alert-danger">
                      This UserId maybe already exists
                    </h4>
                    <h4 v-else-if="error_edit === 400" class="alert-danger">
                      This UserId maybe exists
                    </h4>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="namerules"
                    required
                    label="Name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.user_phone_number"
                    :rules="mobileRules"
                    required
                    onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                    label="Mobile Number"
                  ></v-text-field>
                  <div>
                    <h4 v-if="error_edit === 500" class="alert-danger">
                      This Number maybe already exists
                    </h4>
                    <h4 v-else-if="error_edit === 400" class="alert-danger">
                      This Number maybe exists
                    </h4>
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.user_email"
                    :rules="emailRules"
                    readonly
                    required
                    label="Email Id"
                  ></v-text-field>
                  <div>
                    <h4 v-if="error_edit === 500" class="alert-danger">
                      This EmailId maybe already exists
                    </h4>
                    <h4 v-else-if="error_edit === 400" class="alert-danger">
                      This EmailId maybe exists
                    </h4>
                  </div>
                </v-flex>
                <v-flex xs12 @click="getDID">
                  <v-select
                    v-model="editedItem.didNumber"
                    :items="didNumber"
                    label="DID"
                    item-text="did_number"
                    item-value="did_id"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.roles"
                    :items="UserType"
                    :rules="selectRules"
                    label="Role"
                    item-text="name"
                    item-value="id"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.groups"
                    :items="groupTypes"
                    :rules="groupRules"
                    label="Groups"
                    item-text="group_name"
                    item-value="group_id"
                    :multiple="true"
                    return-object
                    persistent-hint
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedItem.status"
                    :rules="selectRules"
                    :items="userstatus"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    return-object
                    persistent-hint
                    @change="editedItem.status = editedItem.status.value"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="CloseEditUser()">Close</v-btn>
            <v-btn color="primary" flat :disabled="!valid" @click="SaveEditUser()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="password" max-width="500px">
        <v-form ref="editpassform" v-model="valid">
          <v-card>
            <v-toolbar dark color="primary">
              <v-card-title>
                <span class="headline">Change Password</span>
              </v-card-title>
            </v-toolbar>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="change.Newpassword"
                    :rules="newRules"
                    required
                    label="New Password"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="change.ConfirmPassword"
                    :rules="confirmRules"
                    required
                    label="Confirm Password"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="light" flat @click="close()">Close</v-btn>
              <v-btn color="primary" :disabled="!valid" @click="save()"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog  presistent v-model="DeleteRow" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Delete User</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text
                >Are you sure to delete this User?</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Close</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="QueueAssignModel" max-width="650px">
        <v-form ref="refassign" v-model="valid">
          <v-card>
            <v-toolbar color="primary" dark class="modal-header--sticky">
              <v-toolbar-title>Assign Queue</v-toolbar-title>
            </v-toolbar>
            <v-container
              id="scroll-target"
              style="max-height: 350px"
              class="scroll-y"
            >
              <v-layout
                v-scroll:#scroll-target="onScroll"
                row
                v-for="(item, index) in QueueModelItems.queue"
                :key="index"
                style="height: 90 px"
                {{QueueModelItems.queue[index].name}}
              >
                <template>
                  <v-flex xs6 class="queue_user">
                    <v-select
                      @change="assignedval(QueueModelItems.queue[index].name)"
                      :disabled="QueueModelItems.queue[index].name !== ''"
                      v-model="QueueModelItems.queue[index].name"
                      :items="queueclone"
                      item-disabled="active"
                      label="Queue"
                      item-text="name"
                      item-value="name"
                      persistent-hint
                      :rules="[(v) => !!v || 'required']"
                    ></v-select>
                  </v-flex>
                  <v-flex xs5 style="padding-left: 10px !important">
                  
                    <v-select
                      @change="
                        assignedrank(QueueModelItems.queue[index].user_rank)
                      "
                      :disabled="false"
                      v-model="QueueModelItems.queue[index].user_rank"
                      :items="rank"
                      item-disabled="active"
                      label="User_rank"
                      item-text="name"
                      item-value="name"
                      persistent-hint
                      :rules="[(v) => !!v || 'required']"
                    ></v-select>
                    <!-- <v-select  @change="assignedrank(QueueModelItems.queue[index].user_rank)" v-model="QueueModelItems.queue[index].user_rank" :items="rank"
                    :disabled="QueueModelItems.queue[index].user_rank!==0"    :rules="[(v) => !!v || 'required']" label="User_rank" persistent-hint></v-select> -->
                  </v-flex>
                  <v-flex xs1>
                    <v-icon
                      v-if="QueueModelItems.queue.length > 0"
                      color="red"
                      class="mt-4 ml-0 remove-icon"
                      @click="removeItem(index, QueueModelItems.queue[index])"
                      >close</v-icon
                    >
                  </v-flex>
                </template>
              </v-layout>
            </v-container>
            <v-card-actions class="modal-footer--sticky">
              <v-icon
                class="icon"
                :disabled="QueueModelItems.queue.length >= QueueList.length"
                @click="QueueAssignExtra()"
                color="primary"
                >mdi-plus
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn
                v-if="QueueModelItems.queue.length == 0"
                color="light"
                flat
                @click="QueueAssignClose()"
                >Close</v-btn
              >
              <v-btn
                v-else
                color="light"
                type="reset"
                flat
                @click="QueueAssignClose()"
                >Close</v-btn
              >
              <v-btn
                color="primary"
                flat
                @click="QueueAssignSave()"
                :disabled="QueueModelItems.queue.length == 0"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-navigation-drawer
        fixed
        disable-resize-watcher
        right
        v-model="pagecontrol.isFilterOpen"
        class="filterNavigation"
      >
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            flat
            color="white"
            @click="pagecontrol.isFilterOpen = false"
          >
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div
          class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
        >
          <v-container class="sidenavContainer">
            <UserManagementFilter
              v-if="pagecontrol.isFilterOpen"
              @getDataFrom="getDataFrom"
              :appliedfilter="appliedfilter"
            ></UserManagementFilter>
          </v-container>

          <v-container row class="sidenavFooter">
            <v-btn flat @click="resetvalue()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getDataFrom()">Filter</v-btn>
          </v-container>
        </div>
      </v-navigation-drawer>
      <!-- <h1>{{usersdata}}</h1> -->
      <!-- <h1>{{headers}}</h1> -->
      <v-data-table
        :headers="headers"
        :items="usersdata"
        class="fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.user_id }}</td>
          <td class="checkEmpty p0">{{ props.item.user_name }}</td>
          <td class="checkEmpty p0">{{ props.item.user_phone_number }}</td>
          <td class="checkEmpty p0">{{ props.item.user_email }}</td>
          <td class="checkEmpty p0">{{ userstring(props.item.status) }}</td>
          <td class="checkEmpty p0" style="text-overflow: elipsis">
            {{ rolesString(props.item.roles) }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="pagecontrol.canedit"
              @click="editItem(props.item)"
              >editz</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              :disabled="props.item.user_email == userinfo.user_email"
              v-if="pagecontrol.candelete"
              @click="deleteItem(props.item)"
              >delete</v-icon
            >
            <v-icon
              color="blue"
              small
              class="mr-4"
              :disabled="rolesString(props.item.roles) === 'Administrator'"
              v-if="pagecontrol.canassignqueue"
              @click="getUserQueue(props.item)"
              >queue</v-icon
            >
            <v-icon
              color="violet"
              small
              class="mr-4"
              v-if="pagecontrol.updatepassword"
              @click="pass(props.item)"
              >fa-key</v-icon
            >
          </td>
        </template>
      </v-data-table>
      <v-dialog persistent :value="pagecontrol.isUploadUser" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Upload User </span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <p>
            <span style="font-weight: bold">Not sure what to upload? </span>
            Click here to download the sample file. Format:
            <a @click="downloadSample('csv')">CSV</a>,
            <a @click="downloadSample('txt')">Txt(Tab-Delimited)</a>. Save the
            file with .csv /.txt extension and upload the file.
          </p>
          <div
            class="file-dummy"
            @click="$refs.file.click()"
            :style="{
              background: uploadUser.color + '66',
              'border-color': uploadUser.color,
              color: uploadUser.color,
            }"
          >
            {{ uploadUser.label }}
          </div>
          <input
            type="file"
            id="file"
            ref="file"
            accept=".csv,.txt"
            v-on:change="handleFileUpload()"
          />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="CloseUploadUser(false)"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-snackbar
        v-model="snackbar.show"
        :bottom="true"
        :right="true"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
      <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import loader from "@/components/Widgets/loader";
import UserManagementFilter from "./Components/UserManagementFilter";
const { convertArrayToCSV } = require("convert-array-to-csv");
function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
// import Multiselect from 'vue-multiselect';
// import vSelect from 'vue-select'
export default {
  components: {
    loader,
    UserManagementFilter,
  },
  data() {
    return {
      fielddata:[],
      headers: [
        {
          text: "User Id",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "user_id",
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "user_name",
        },
        {
          text: "Mobile Number",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "user_phone_number",
          width: "10%",
        },
        {
          text: "Email Id",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "user_email",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "status",
          width: "10%",
        },
        {
          text: "Role",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "roles",
          width: "10%",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },

      listdata: [],
      queueclone: [],
      uploadUser:{},
      userstatus: [
        { name: "Active", value: "active" },
        { name: "Inactive", value: "inactive" },
      ],
      payload: {
        skip: 0,
        take: 0,
      },
      isProcessing: false,
      page: 1,
      pagecount: 0,
      delete_id: "",
      totalCount: "",
      error_user_id: "",
      error_edit: "",
      pagination: {
        page: 1,
        rowsPerPage: 10,
      },
      password: false,
      dialog: false,
      DeleteRow: false,
      QueueAssignModel: false,
      Role: null,
      valid: true,
      editedItem: {
        UserId: "",
        name: "",
        MobileNumber: "",
        EmailId: "",
        roles: "",
        didNumber:"",
        groups: [],
        Status: "",
      },
      tableLoading: false,
      defaultItem: {
        UserId: "",
        MobileNumber: "",
        EmailId: "",
        roles: "",
        groups: "",
        Status: "",
      },
      groupTypes: [],
      newlyUser: {
        UserId: "",
        name: "",
        MobileNumber: "",
        EmailId: "",
        roles: "",
        groups: "",
        Status: "",
        didNumber:""
      },
      string: "",
      QueueModelName: "",
      QueueModelItems: {
        name: "",
        interface: "",
        queue: [],
      },
      Assignbtn: false,
      listQueue: [],
      rank: [],
      AddUser: false,
      change: {
        id: "",
        user_email: "",
        Newpassword: "",
        ConfirmPassword: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^[a-z0-9_]+([.-]?[a-z0-9]+)*@[a-z0-9]+([.-]?[a-z0-9]+)*(\.[a-z]{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      groupRules: [
        (v) => !!v.length || "Please select at least one group",
      ],
      mobileRules: [
        (v) => !!v || "Mobile Number is required",
        // (v) => (v && (v.length >0) ) || "Number must be 3 or 10 digits",
      ],
      passwordrules: [
        (v) => !!v || "password is required",
        (v) =>
          /^(?=.*[0-9])(?=.*[!@#$%^&*]?)[a-zA-Z0-9!@#$%^&*]{7,}$/.test(v) ||
          "password is week",
      ],
      idrules: [(v) => !!v || "User Id is required"],
      namerules: [(v) => !!v || "Name is required"],

      selectRules: [
        (v) => !!v || "Item is required",
        (v) => !!v || "Must be select at lest one field",
      ],
      newRules: [
        (v) => !!v || "password is required",
        (v) => {
          if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/.test(v)) {
            return "Minimum 8 characters in length, Try a mix of letters, numbers and symbols.";
          }
          return true;
        },
      ],
      confirmRules: [
        (v) => !!v || "password is required",
        (v) => v == this.change.Newpassword || "password doesn't match",
      ],
      appliedfilter: {
        UserId: "",
        MobileNumber: "",
        EmailId: "",
        Role: "",
      },
      page_flag: false,
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      tempInterface: "",
      pagecontrol: {
        title: "User Management",
        icon: "fa-users fa-2x",
        loading: true,
        isFilterOpen: false,
        isAddUser: false,
        isUploadUser: false,
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        canedit: false,
        candelete: false,
        canassignqueue: false,
        updatepassword:false,
      },
      userinfo: {},
      didNumber:[],
      editCheck:false
    };
  },

  updated() {
    this.QueueModelItems.queue.forEach((element) => {
      this.listdata.push(element);
    });
  },
  mounted() {
    let scope = this;
    this.userinfo = JSON.parse(localStorage.apiInfo || "{}");
    this.pagecontrol.loading = false;
    this.$store.dispatch("UserType");
    // this.getDataFromApi(); 
    this.getGroupList();
    this.$store.dispatch("QueueList");
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:create"
          ) !== -1
        ) {
          scope.pagecontrol.addnew = [{ label: "Add User" }];
          // scope.pagecontrol.addnew = [{label:'Upload'},{ label: "Add User" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:edit"
          ) !== -1
        ) {
          scope.pagecontrol.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:delete"
          ) !== -1
        ) {
          scope.pagecontrol.candelete = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:assignqueue"
          ) !== -1
        ) {
          scope.pagecontrol.canassignqueue = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "management:user:updatepassoword"
          ) !== -1
        ) {
          scope.pagecontrol.updatepassword = true;
        }
      }.bind(scope)
    );
    this.uploadUser = getDefaultData();
  },
  methods: {
    getDID(){
      this.$store
        .dispatch("DidNumber")
        .then((response)=>{
          let data = response.data.data
          data.forEach((item)=>{
            delete item.active
            delete item.created_at
            delete item.status
            delete item.trunk
            this.didNumber.push(item)
          })
        })
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    queuecopy() {
      for (let i = 0; i < this.queueclone.length; i++) {
        if (this.queueclone[i].active === false) {
          return this.queueclone;
        }
      }
    },

    assignedval(val) {
      for (let i = 0; i < this.queueclone.length; i++) {
        if (val === "cancel") {
          this.queueclone[i].active = false;
        } else {
          if (this.queueclone[i].name === val) {
            this.queueclone[i].active = true;
          }
        }
      }
    },
    assignedrank(val) {
      for (let i = 0; i < this.rank.length; i++) {
        if (val === "cancel") {
          this.rank[i].active = false;
        } else {
          if (this.rank[i].name === val) {
            this.rank[i].active = false;
          }
        }
      }
    },
    queuearrange(val) {
      this.listdata.push(val);
    },
    refreshClick: function () {
      this.pagecontrol.loading = true;
      this.resetvalue(true);
      this.getDataFromApi();
    },
    selectedRoles(obj) {
      return obj.map((o) => {
        return o.id;
      });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    rolesString(obj) {
      let arr = obj.map((o) => {
        return o.name;
      });
      return arr.join(",");
    },
    getGroupList: function () {
      let scope = this;
      this.$store
        .dispatch("groupLists")
        .then((response) => {
          scope.groupTypes = response;
        })
        .catch(() => {
          scope.groupTypes = [];
        });
    },
    // modifyNewUserGroups: function () {
    // if (this.newlyUser.roles === "Administrator") {
    // this.newlyUser.groups = {};
    // } else {
    // this.newlyUser.groups = [];
    // }
    // },
    // modifyUpdateUserGroups: function () {
    // if (this.editedItem.roles === "Administrator") {
    // this.editedItem.groups = {};
    // } else {
    // this.editedItem.groups = [];
    // }
    // },
    userstring(val) {
      var k = this.userstatus.filter((statusvalue) => {
        return statusvalue.value == val;
      });
      if (k.length > 0) {
        return k[0].name;
      } else {
        return "";
      }
    },
    // edit
    downloadSample(type) {
      const scope = this;
      let UserFields = "";
      scope.$store.dispatch("GetAsterUsersFields").then((response) => {
        var datas = response.fields;
        UserFields = datas;
        const header = [];
       
          UserFields[0]
          for(const values in UserFields[0]){
           if(values=='user_phone_number'||
              values=='user_id'||
              values=='user_name'||
              values=='user_email'){
                 let key = {
             key:values,
             label: values,
           };
           scope.fielddata.push(key);
           header.push(values)
              }
              else if(values=='oauth_user'){
               let key = {
             key:'password',
             label:"password",
           };
           scope.fielddata.push(key);
           header.push('password')
            }
          }
          header.push('role')
          header.push('group')
        let csvContent = "";
        if (type === "csv") {
          csvContent += "data:text/csv;charset=utf-8,\uFEFF";
          csvContent += header.join(",");
        } else {
          csvContent += "data:text/txt;charset=utf-8,\uFEFF";
          csvContent += header.join("\t");
        }
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "samplefile" + "." + type);
        document.body.appendChild(link);
        link.click();
      });
    },

    handleFileUpload: function () {
      try {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      scope.uploadUser.color = "#A64452";
      scope.uploadUser.label = "Uploading";  
      let formData = new FormData();
      
      formData.append("file", scope.file);
      formData.append(
        "created_by",
        JSON.parse(localStorage.apiInfo).user_email
      );
      formData.append(
        "modified_by",
        JSON.parse(localStorage.apiInfo).user_email
      );
      scope.$store.dispatch("UploadBulkData",formData)
      .then((response)=>{
        if(response.inserted.length>0){
         scope.uploadUser.color = "#4BB543";
         scope.uploadUser.label = "Uploaded Successfully";
         scope.pagecontrol.isUploadUser=false
         scope.showSnackBar('Uploaded Succesfully')
        }else{
          scope.uploadUser.color = "F51B00";
          scope.uploadUser.label = "Upload Error";
          scope.pagecontrol.isUploadUser=false
        }
        if(response.skippedData.length>0){
          if (response.filetype === "csv") {
              let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
              let skipvalue = convertArrayToCSV(response.skippedData);
              csvContent += skipvalue;
              var encodedUri = encodeURI(csvContent);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute(
                "download",
                "skip_user-upload.csv"
              );
              document.body.appendChild(link);
              link.click();
            } else {
              let txtContent = "";
              for (const key in response.skippedData[0]) {
                let txt_tab = `${key} \t`;
                txtContent += `${txt_tab}`;
              }
              response.skippedData.forEach(function (rows) {
                let data = Object.values(rows);
                let row = data.join("\t");
                txtContent += `\n ${row}` + "\r";
              });
              var encodedUri1 =
                "data:text/plain;charset=utf-8,\uFEFF" +
                encodeURIComponent(txtContent);

              var link1 = document.createElement("a");
              link1.setAttribute("href", encodedUri1);
              link1.setAttribute(
                "download",
                "skip_" + `User_template.txt`
              );
              document.body.appendChild(link1);
              link1.click();
            }
        }
         scope.resetvalue();
         scope.getDataFromApi();
         scope.uploadUser.color = "#2196f3";
         scope.uploadUser.label = "please select some files";
         scope.$refs.file.value = null;
      }).catch((err)=>{
        console.log(err)
         scope.uploadUser.color = "#F51B00";
         scope.uploadUser.label = "Upload Error";
         return err
      })
       } catch (error) {
        console.log(error,'upload error checking')
         scope.uploadUser.color = "#F51B00";
         scope.uploadUser.label = "Upload Error";
         return error
      }
      
    },
    CloseUploadUser(value){
      this.pagecontrol.isUploadUser = value;
      this.uploadUser = getDefaultData();
      this.refreshClick()

    },
    NewUser: function () {
      this.pagecontrol.isAddUser = true;
    },
    BulkUser: function () {
      this.pagecontrol.isUploadUser = true;
    },
    addNew(event) {
       const buttonLabel = event.label;
       if (buttonLabel === 'Upload') {
         this.BulkUser();
       } else if (buttonLabel === 'Add User') {
         this.NewUser();
       }
    },
    // edit
    filterClick: function () {
      this.pagecontrol.isFilterOpen = true;
    },
   async editItem(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      this.editedItem.roles = this.editedItem.roles[0];
      this.editedItem.name = this.editedItem.user_name;
      this.editedItem.groups = this.groupTypes.filter((group) => {
        return this.editedItem.groups.indexOf(group.group_id) !== -1;
      });
    await this.getDID()
    const response = await this.$store.dispatch('userDidFetch',item.user_email)
    const plainArray = Array.from(this.didNumber).map(item => Object.assign({}, item));
    this.editedItem.didNumber = plainArray.find(item => Number(item.did_number) === response.data.data);
    console.log(this.editedItem.didNumber,'this is edited item')
    if(!this.editedItem.didNumber){
      this.editCheck=true
    }
    this.dialog = true;
    },
    pass(item) {
      const scope = this;
      this.editedIndex = 0;
      scope.change.id = item.id;
      scope.change.user_email = item.user_email;
      this.password = true;
      this.$refs.editpassform.reset();
    },
    close() {
      this.password = false;
    },
    save() {
      const scope = this;
      if (scope.change.Newpassword === scope.change.ConfirmPassword) {
        scope.isProcessing = true;
        scope.$store
          .dispatch("EditPassword", scope.change)
          .then((response) => {
            scope.isProcessing = false;
            if (response.statusCode == 200) {
              scope.change.id = "";
              scope.change.user_email = "";
              scope.change.Newpassword = "";
              scope.change.ConfirmPassword = "";
              scope.showSnackBar("Password Updated Successfully");
              this.getDataFromApi();
              this.close();
            } else {
              scope.isProcessing = false;
              scope.showSnackBar("Update Error- " + response.message);
            }
          })
          .catch((err) => {
            scope.isProcessing = false;
            scope.showSnackBar("Error: " + err.message);
          });
      } else {
        scope.showSnackBar(
          "Error: " + "password and confirm password didn't match"
        );
      }
    },
    CloseEditUser() {
      this.error_edit = "";
      this.$refs.edituserform.reset();
      this.dialog = false;
    },
    SaveEditUser() {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("editedItemuser", this.editedItem)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode == 200) {
            console.log(response,'this is response')
            console.log(this.editCheck,'this edit check')
            // if(this.editCheck&&this.editedItem.didNumber != undefined){
              this.editAndSave(this.editCheck)
            // }
            // else if(this.editedItem.didNumber != undefined){
              // this.$store.dispatch('userDidEdit',this.editedItem)
            // }
            let payload ={
              skip : 0,
              take :100
            }
            scope.$store
        .dispatch("userManagement", payload).then((response) => {
          const agentRoles = response.data.data.filter(item => item.roles.some(role => role.name === "Agent" ) && item.status === "active");
          const AdminRoles = response.data.data.filter(item => item.roles.some(role => role.name === "Administrator" ) && item.status === "active");
          let agentEmails = agentRoles.map(item => item.user_email);
          let AdminEmails = AdminRoles.map(item => item.user_email);
          localStorage.setItem("agent_emails", JSON.stringify(agentEmails));
          localStorage.setItem("admin_emails", JSON.stringify(AdminEmails));
        })
            scope.showSnackBar(" Updated Successfully");
            this.getDataFromApi();
            this.CloseEditUser();
          } else {
            scope.showSnackBar("Update Error- " + response.data.message);
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Update Error");
        });
    },
    CloseNewUser() {
      this.pagecontrol.isAddUser = false;
      this.newlyUser = Object.assign({}, this.defaultItem);
      this.error_user_id = "";
      this.$refs.adduserform.reset();
    },
    editAndSave(){
      console.log(this.editedItem.didNumber,'this is checking')
      try {
        if(this.editCheck&&this.editedItem.didNumber != ""){
        const userData = {
          agent:this.editedItem.user_email,
          station:this.editedItem.user_phone_number,
          did_number:this.editedItem.didNumber
        }
        console.log(userData,'this is edite user Data')
        this.$store.dispatch('did_user',userData)
        this.editCheck=false;
        return
      }
     else if(this.editedItem.didNumber !== "" && this.editedItem.didNumber !== undefined){
        this.$store.dispatch('userDidEdit',this.editedItem)
        return
      }
      const didData = {
          agent:this.newlyUser.user_email,
          station:this.newlyUser.user_phone_number,
          did_number:this.newlyUser.didNumber
        }
       this.$store.dispatch('did_user',didData)
      } catch (error) {
        console.log(error,'this is error')
}
      
      
    },
    SaveNewUser() {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("newlyUser", this.newlyUser)
        .then((response) => {
          scope.isProcessing = false;

          if (
            response.data.statusCode == 207 &&
            response.data.data[0].statusCode == 200
          ) {
            console.log(this.newlyUser.didNumber,'this is didNumber')
            if(this.newlyUser.didNumber!="" && this.newlyUser.didNumber != undefined){
            this.editAndSave()
            }
            scope.showSnackBar(" Created Successfully");
            this.CloseNewUser();
            this.getDataFromApi();
          } else {
            scope.showSnackBar(
              "Error Creating User- " + response.data.data[0].message
            );
          }
        })
        .catch(function (error) {
          scope.isProcessing = false;
          scope.error_user_id = error.data.statusCode;
          scope.showSnackBar("Error Creating User");
        });
    },
    deleteItem(item) {
      this.DeleteRow = true;
      this.delete_id = item;
    },

    QueueAssignExtra() {
      this.QueueModelItems.queue.push({ name: "", user_rank: 0 });
    },
    removeItem: function (index, value) {
      let scope = this;
      for (let i = 0; i < this.queueclone.length; i++) {
        if (this.queueclone[i].name === value.name) {
          this.queueclone[i].active = false;
        }
      }
      for (let i = 0; i < this.rank.length; i++) {
        if (this.rank[i].name === value.user_rank) {
          this.rank[i].active = false;
        }
      }
      if (value.id !== undefined) {
        scope.$store
          .dispatch("DeleteQueueUser", value.id)
          .then(() => {
            scope.showSnackBar("Success! User Removed Queues");
          })
          .catch(() => {
            scope.isProcessing = false;
            scope.QueueAssignModel = true;
            scope.showSnackBar("Error! User Assign Failed");
          });
      }

      this.QueueModelItems.queue.splice(index, 1);
    },

    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("DeleteRow", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "Success") {
            this.getDataFromApi();
           scope.showSnackBar("User Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete User");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete User");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRow = false;
    },
    checkstatus() {
      this.listQueue.push(this.checkstatus);
    },
    getUserQueue(item) {
      var scope = this;
      console.log(scope.QueueList,"scope.QueueList")
      for (let j = 0; j < scope.QueueList.length; j++) {
        scope.QueueList[j].active = false;

        scope.queueclone.push(scope.QueueList[j]);
        // scope.rank.push({ name: j + 1, active: false });
      }
scope.rank=[ { "name": 1, "active": false }, { "name": 2, "active": false }, { "name": 3, "active": false }, { "name": 4, "active": false }, { "name": 5, "active": false }]
      scope.tempInterface = item.user_phone_number;
      scope.$store
        .dispatch("getUserQueue", item.user_email)
        .then((response) => {
          scope.QueueModelItems = Object.assign({}, response);
          scope.QueueModelName = response.name;
          response.queue.forEach((elm) => scope.assignedval(elm.name));
          // this.QueueAssignExtra();
          scope.QueueAssignModel = true;
        });
    },
    QueueAssignClose() {
      let scope = this;
      scope.QueueModelItems = {
        name: "",
        interface: "",
        queue: [],
      };
      this.QueueAssignModel = false;
    },
    QueueAssignSave() {
      let scope = this;

      if (scope.QueueModelItems.queue.length > 0) {
        let count = 0;
        scope.QueueModelItems.queue.forEach((elm) => {
          if (elm.name == "" && elm.user_rank == 0) {
            count++;
          } else if (elm.name == "") {
            count++;
          } else if (elm.user_rank == 0) {
            count++;
          }
        });

        if (count == 0) {
          scope.QueueAssignModel = false;
          scope.isProcessing = true;
          let QueueModelItems = scope.QueueModelItems;
          QueueModelItems.interface = scope.tempInterface;
          this.$store
            .dispatch("createQueueUser", QueueModelItems)
            .then(() => {
              scope.QueueModelItems = {
                name: "",
                interface: "",
                queue: [],
              };
              scope.isProcessing = false;
              scope.showSnackBar("Success! User Assigned Successfully");
            })
            .catch((err) => {
              console.log(err, ":Err");
              scope.isProcessing = false;
              scope.QueueAssignModel = true;
              scope.showSnackBar("Error! User Assign Failed");
            });
        } else {
          this.$refs.refassign.validate();
          scope.QueueAssignModel = true;
        }
      }
    },
    isFilterCancel() {
      this.pagecontrol.isFilterOpen = false;
    },
    resetvalue(closefilter) {
      this.skip = 0;
      this.appliedfilter.UserId = "";
      this.$store.commit("filter_userId", { user_id: "" });
      this.appliedfilter.MobileNumber = "";
      this.$store.commit("filter_MobileNumber", { user_phone_number: "" });
      this.appliedfilter.EmailId = "";
      this.$store.commit("filter_EmailId", { user_email: "" });
      this.appliedfilter.Role = "";
      this.$store.commit("filter_Role", { role: "" });
      this.getDataFrom();
      if(!closefilter){
        this.pagecontrol.isFilterOpen= true;
      }
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      var { page, rowsPerPage } = scope.pagination;
      if (rowsPerPage == -1) {
        scope.payload.take = scope.totalCount;
      } else {
        scope.payload.take = rowsPerPage;
      }
      if (page != "" && page != undefined && page != null) {
        scope.skip = page;
      } else {
        scope.skip = 1;
      }
      scope.payload.skip = (scope.skip - 1) * scope.payload.take;
      return new Promise(() => {
        scope.$store
          .dispatch("userManagement", scope.payload)
          .then((response) => {
            scope.pagecontrol.loading = false;
            scope.tableLoading = false;
            if (scope.page_flag) {
              // scope.pagination.page = 1;
              scope.page_flag = false;
            }
            scope.totalCount = response.data.totalCount;
            scope.pagecount = scope.totalCount;
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              scope.$store.dispatch("checkingtoken");
            }
            scope.tableLoading = false;
          });
      });
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
      this.pagecontrol.isFilterOpen = false
    },
  },
  computed: {
    ...mapGetters([
      "usersdata",
      "UserType",
      "QueueList",
      "Createqueue",
      "AssignQueueList",
    ]),
    formTitle() {
      return this.editedIndex === 1 ? "Change password" : "Update User";
    },
  },
  watch: {
    dialog(val) {
      val || this.CloseEditUser();
    },
    pagination: {
      handler() {
        this.getDataFromApi().then((data) => {
          this.pagecount = data.totalCount;
        });
      },
      deep: true,
    },
  },

  refreshvalue() {
    this.$store.commit("filter_userId", { UserId: "" });
    this.$store.commit("filter_MobileNumber", { MobileNumber: "" });
    this.$store.commit("filter_EmailId", { EmailId: "" });
    this.$store.commit("filter_Role", { Role: "" });
  },
  beforeDestroy() {
    this.$store.commit("filter_userId", { user_id: "" });
    this.$store.commit("filter_MobileNumber", { user_phone_number: "" });
    this.$store.commit("filter_EmailId", { user_email: "" });
    this.$store.commit("filter_Role", { role: "" });
  },
};
</script>
<style>
.alert-danger {
  color: red;
}

.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 10;
}

.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 10;
}

.sidenavContainer {
  height: 200px;
}
</style>