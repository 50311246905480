export default {
  MaskingModules: {
    CallDetailReport: ["phone_number"],
    RecordingReport: ["Phone Number"],
    TransferCallReport: ["phone_number", "to1"],
    DropedCallReport: ["phone_number"],
    MissedCallReport: ["phone_number"],
    EffectiveAbandonReport: ["phone_number"],
    WaChatlogDetailsReport: [
      {
        name: "msg_from",
        condition: { bool:true , props_name: "msg_direction",props_value: "in",props_operate: "===" },
      },
      {
        name: "msg_to",
        condition: { bool:true , props_name: "msg_direction",props_value: "out",props_operate: "===" },
      },
    ],
    WaChatlogReport: ["msg_from"],
    AsterList: ["phone_number"],
    Leads: [],
    Contacts: [],
    Tickets: [],
    Meeting: ["leadNumber"],
    LiveAgent: ["phone_number"],
    LiveCalls: ["phone_number"],
  },
  MaskType: ["string", "number"],
};