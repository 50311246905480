import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/vuse";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { createI18n } from "./i18n/index";
import HighchartsVue from 'highcharts-vue';
import moment from 'moment';
import * as svgicon from 'vue-svgicon';
import VueRulerTool from "vue-ruler-tool";
import VueMultiselect from "vue-multiselect";
// import DatetimePicker from 'vuetify-datetime-picker';
// import 'vuetify-datetime-picker/src/stylus/main.styl';
// Vue.use(DatetimePicker);
Vue.use(svgicon, {
    tagName: 'svgicon'
});
import JsonCSV from 'vue-json-csv'
import Datetime from 'vue-datetime'
import Toasted from 'vue-toasted';
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import './assets/scss/main.scss'
import VueLodash from 'vue-lodash'
// Load the core build.
const _ = require('lodash/core');
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-vue/esm/index.js'
import 'bootstrap-vue/dist/bootstrap-vue.min.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/src/components/table/index.js';
import 'vue-datetime/dist/vue-datetime.css'
import { UploaderComponent, UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
    // import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { TablePlugin } from 'bootstrap-vue'
Vue.use(TablePlugin)


import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";



const faviconLink = document.createElement('link');
faviconLink.rel = 'icon';
let logoinfoobj=JSON.parse(localStorage.getItem("logodetails"))
let logoinfo=logoinfoobj===null?{"name":"","logo":""}:logoinfoobj
if(logoinfoobj!==null){
    faviconLink.href = logoinfo.logo;
    document.title =logoinfo.name+ ' Admin Portal';
}
    


document.head.appendChild(faviconLink);
Vue.component("multi-select", VueMultiselect);
Vue.component(UploaderPlugin.name, UploaderComponent)
Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.component("vue-ruler-tool", VueRulerTool);
Vue.component('downloadCsv', JsonCSV)
Vue.use(HighchartsVue);
Vue.use(Datetime);
Vue.use(Toasted);
Vue.use(Element, { locale });
Vue.use(VueLodash);
Vue.use(_);
// Vue.use(fp);
// Vue.use(array);
// Vue.use(object);
// Vue.use(at);
// Vue.use(curryN);
Vue.config.productionTip = false;

const i18n = createI18n();

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm a')
    }
});
Object.defineProperty(Array.prototype, 'cloneArray', {
    value: function() {
        return this.slice(0);
    }
});
Object.defineProperty(Array.prototype, 'chunk', {
    value: function(size, width, min) {
        var that = this;
        var chunkMax = Math.round(width / min);
        var chunkSize = (size > chunkMax) ? chunkMax : size;
        return Array(Math.ceil(that.length / chunkSize)).fill().map(function(_, i) {
            return that.slice(i * chunkSize, i * chunkSize + chunkSize);
        });
    }
});

store.dispatch("tokencheck").then(() => {});

let handleOutsideClick;
Vue.directive("closable", {
    bind(el, binding, vnode) {
        handleOutsideClick = e => {
            e.stopPropagation();
            let { handler, exclude } = binding.value;
            let clickedOnExcludedEl = false;
            exclude.forEach(refName => {
                if (!clickedOnExcludedEl) {
                    let excludedEl = vnode.context.$refs[refName];
                    if (excludedEl) {
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                }
            });
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        };
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("touchstart", handleOutsideClick);
    },
    unbind() {
        document.removeEventListener("click", handleOutsideClick);
        document.removeEventListener("touchstart", handleOutsideClick);
    }
});

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");