<template>
    <v-container fluid grid-list-xl class="pb-0">
        <v-toolbar flat extended class="transparent section-definition-toolbar">
            <v-avatar class="box-glow" :tile="tile">
                <i v-if="icon && !isMaterialIconType" :class="['fas', [icon]]"></i>
                <i v-else-if="icon && isMaterialIconType" class="material-icons">{{ icon }}</i>
                <span v-else>{{ title }}</span>
            </v-avatar>
            <v-toolbar-title class="primary--text">{{ title }}</v-toolbar-title>
            <v-toolbar-title class="toobar-extension" slot="extension">
                <v-breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" class="pl-0">
                    <v-icon slot="divider">chevron_right</v-icon>
                </v-breadcrumbs>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu offset-y v-if="addOptions.length > 0">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">
                        New
                        <v-icon right dark>arrow_drop_down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-tile v-for="(option, index) in addOptions" :key="index" @click="addClick(option)">
                        <v-list-tile-title>
                            {{ option }}
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list>
            </v-menu>


            <!-- Ticket -->
            <!-- <div v-if = "industrydata.length > 0 || title == 'Support'">
    
    <v-select :items="industrydata" v-model="select_industry" label="Select Industry"
    @change="supportchanges(select_industry)"></v-select>
    </div>
    <div v-if="supportdata.length > 0 || title == 'Support'">
    <v-select :items="supportdata" v-model="select_model" label="Template Name"
    @change="supportchange(select_model)"></v-select>
    </div>
   
    
    -->
            <v-btn icon color="primary" flat v-if="extraOptions.back" @click="back_page()">
                <i class="fa fa-arrow-left savefont" aria-hidden="true"></i>
            </v-btn>
            <div  v-if="extraOptions.select_box_report">
                <v-select style="padding-right: 27px; width: 250px;" v-model="reportTypeData" :items="report_type_data"
                    label="Report Type" @change="report_types(reportTypeData)" dense outlined color="primary">
                </v-select>
            </div>
            <v-btn-toggle :value="toggleButtonResComputed" mandatory v-if="extraOptions.toggleButton">
                <v-btn v-for="(item, index) in extraOptions.tab" :key="index" color="primary" small :value="`${index+1}`" flat @click="toggleButtonClick(`${index+1}`)">
                    {{item}}
                </v-btn>
            </v-btn-toggle>
            <v-btn-toggle :value="toggleButtonResliveagent" mandatory v-if="extraOptions.toggleButtons">
                <v-btn color="primary" small value="1" flat @click="toggleButtonClicks('1')">
                    Live Agent
                </v-btn>
                <v-btn color="primary" small value="2" flat @click="toggleButtonClicks('2')">
                    Live Call
                </v-btn>
            </v-btn-toggle>
            <v-btn-toggle :value="toggleButtonReslivecall" mandatory v-if="extraOptions.toggleButtonss">
                <v-btn color="primary" small value="1" flat @click="toggleButtonClicks('1')">
                    Live Agent
                </v-btn>
                <v-btn color="primary" small value="2" flat @click="toggleButtonClicks('2')">
                    Live Call
                </v-btn>
            </v-btn-toggle>
            <v-btn color="primary" @click="uploadNew()" v-for="uploadbtn in uploadbtn" :key="uploadbtn.label">
                {{ uploadbtn.label }}
            </v-btn>
            <v-menu offset-y v-if="exportOptions.length > 0">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">
                        Export
                        <v-icon right dark>arrow_drop_down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-tile v-for="(option, index) in exportOptions" :key="index" @click="exportClick(option)">
                        <v-list-tile-title>

                            {{ option }}
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list>
            </v-menu>
            <v-menu offset-y v-if="chatOptions.length > 0">
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">
                        ChatOption
                        <v-icon right dark>arrow_drop_down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-tile v-for="(option, index) in chatOptions" :key="index" @click="ChatOptionClick(option)">
                        <v-list-tile-title>

                            {{ option }}
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list>
            </v-menu>
            <div v-if="extraOptions.autoComplete">
                <v-text-field v-model="filterData" label="Phone Number" small></v-text-field>
            </div>

            <div v-if="extraOptions.autoComplet">
                <v-text-field v-model="didfilterData" label="DID Number" small></v-text-field>
            </div>

            <v-btn color="primary" @click="addNew(newData)" v-for="newData in addNewData" :key="newData.label">
                {{ newData.label }}
            </v-btn>
            <v-btn color="primary" @click="addNew(true)" v-if="canadd">
                {{ "New" }}
            </v-btn>
            <v-btn icon title="Settings" color="primary" flat @click="Settingclick" v-if="extraOptions.cog">
                <i v-if="icon" style="font-size:20px" :class="['fa', 'fa-cog ']"></i>
            </v-btn>

            <!-- fffffffffffffffff -->
            <!-- <v-btn icon title="Settings" color="primary" flat @click="globalclick" v-if="extraOptions.cog">
    <i v-if="icon" style="font-size:20px" :class="['fas', 'fa-globe']"></i>
    </v-btn> -->
            <!-- fffffffffffffffff -->
            <v-btn icon title="Filter" color="primary" flat @click="filterClick" v-if="extraOptions.filter">
                <i v-if="icon" :class="['fas', 'fa-filter']"></i>
            </v-btn>
            <v-btn icon color="primary" flat @click="uploadClick" v-if="extraOptions.uploadicon">
                <i v-if="icon" :class="['fas', 'fa-upload']"></i>
            </v-btn>
            <v-btn icon color="primary" flat @click="addactionClick" v-if="extraOptions.add">
                <v-icon color="primary" medium class="addWidget">add</v-icon>
            </v-btn>

            <v-btn icon color="primary" flat @click="addaction" v-if="extraOptions.addDid">
                <v-icon color="primary" medium class="addWidget">add</v-icon>
            </v-btn>
            <v-btn icon color="primary" flat @click="editClick" v-if="extraOptions.edit">
                <v-icon color="red" small class="addWidget">edit</v-icon>
            </v-btn>

            <v-btn icon color="primary" flat @click="editClick" v-if="extraOptions.edit">
                <v-icon color="red" small class="addWidget">edit</v-icon>
            </v-btn>


            <v-btn icon color="primary" flat @click="plusClick" v-if="extraOptions.plus">
                <i v-if="icon" :class="['fas', 'fa-plus']"></i>
            </v-btn>
            <v-btn icon color="primary" flat @click="editiconClick" v-if="extraOptions.editicon">
                <i v-if="icon" :class="['fas', 'fa-pencil-square-o']"></i>
            </v-btn>
            <v-btn icon color="primary" flat @click="downloadClick" v-if="extraOptions.downloadicon">
                <i v-if="icon" :class="['fas', 'fa-download']"></i>
            </v-btn>

            <v-btn icon title="Refresh" color="primary" flat @click="refreshClick" v-if="extraOptions.refresh">
                <i v-if="icon" :class="['fas', 'fa-refresh']"></i>
            </v-btn>

            <v-btn icon title="Bulk Delete" color="primary" flat @click="ContactBulkDelete"
                v-if="extraOptions.bulkdeleteContactIcon">
                <i v-if="icon" :class="['fas', 'fa-trash-alt']"></i>
            </v-btn>

            <v-btn icon color="primary" flat @click="saveClick" v-if="extraOptions.save">
                <v-icon color="green" medium class="addWidget">save</v-icon>
            </v-btn>
            <v-btn icon color="primary" flat @click="saveClick" v-if="extraOptions.save">
                <v-icon color="green" medium class="addWidget">save</v-icon>
            </v-btn>


            <!-- lead section definition -->


            <div v-if="extraOptions.select_box" class="select_box_cls">
                <v-select style="padding-right: 27px;width: 250px;" v-model="industry_value" :items="industry_data"
                    label="Select Industry" @change="select_industrys(industry_value)" dense outlined color="primary">
                </v-select>
                <v-select style="width: 250px;" v-model="model_value" :items="filtervalue" label="Select Template"
                    @change="select_templet(model_value)" dense outlined color="primary">
                </v-select>
            </div>

            <!-- <div v-if="extraOptions.autoCompletePC" class="select_box_cls">
    <v-text-field v-model="pauseCode.name" label="Phone Number" @change="getDncNumber(DNC_phone_number)" small></v-text-field>
    </div> -->
        </v-toolbar>
    </v-container>
</template>
   
<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            model_value: '',
            industry_value: "",
            select_industry: "",
            select_model: "",
            dynamicDataField: [],
            filterData: "",
            didfilterData: "",
            DNC_phone_number: "",
            pauseCode: {
                name: ""
            },
            reportTypeData:""
        }
    },
    beforeMount() {
        this.select_model = this.extraOptions.selectedcurrent
        this.industry_value = 'All';
        this.$emit("industry_value", this.industry_value);
        this.reportTypeData = "Detailed Report";
        this.$emit("report_type",this.reportTypeData)
        // this.select_industry = 'All';
        // this.$emit("select_industry", this.select_industry);
    },
    mounted() {
        // this.model_value=this.filtervalue[0]
        // this.select_templet(this.filtervalue[0])
        // localStorage.setItem('templetname', this.model_value);

    },
    watch: {
        filtervalue() {
            let temp_name_ = JSON.parse(localStorage.getItem('templetname'))

            if (temp_name_ === null || Object.keys(temp_name_).length === 0) {
                this.model_value = this.filtervalue[0]
                localStorage.setItem("templetname", JSON.stringify({ templetname: this.model_value }))
            }
            let temp_name = JSON.parse(localStorage.getItem('templetname'))
            // console.log("rrrrrrrrrrr", temp_name)
            let is_includes = this.filtervalue.includes(temp_name.templetname)

            if (is_includes) {
                this.model_value = temp_name.templetname
                this.select_templet(temp_name.templetname)
            }
        },
        dncFilter() {
            this.DNC_phone_number = this.dncFilter
        },
        didFilter() {
            this.DNC_phone_number = this.didFilter
        },
        blockednumber() {
            this.blocked_phone_number = this.blockednumber
        }, filterData() {
            this.$emit("filterData", this.filterData)
            // }
        }, didfilterData() {
            this.$emit("didfilterData", this.didfilterData)
        }
        // supportdata() {
        // let temp_namee_ = JSON.parse(localStorage.getItem('templetname'))

        // if (temp_namee_ === null || Object.keys(temp_namee_).length === 0) {
        // this.select_model = this.supportdata[0]
        // localStorage.setItem("templetname", JSON.stringify({ templetname: this.select_model }))
        // }
        // let tempp_name = JSON.parse(localStorage.getItem('templetname'))

        // let is_includes_ = this.supportdata.includes(tempp_name.templetname)

        // if (is_includes_) {
        // this.select_model = tempp_name.templetname
        // this.select_templet(tempp_name.templetname)


        // // } else {
        // // this.model_value = this.filtervalue[0]
        // // this.select_templet(this.filtervalue[0])
        // }
        // },

        // 'extraOptions.selectedcurrent': {
        // handler: function () {
        // this.select_model = this.extraOptions.selectedcurrent
        // this.dynamicField(this.select_model)
        // }
        // }
    },
    props: {
        canadd: {
            type: Boolean,
            default: false
        },
        title: String,
        breadcrumbs: Array,
        icon: String,
        section_tab_change: {
            type: String
        },
        tile: {
            type: Boolean,
            default: true
        },
        industrydata: {
            required: false,
            default: function () {
                return [];
            }
        },
        dncFilter: String,
        blockednumber: String,
        previewOut: {
            type: Boolean,
            default: false
        },
        isMaterialIconType: {
            required: false,
            default: false
        },
        exportOptions: {
            required: false,
            default: function () {
                return [];
            }
        },
        chatOptions: {
            required: false,
            default: function () {
                return [];
            }
        },
        industry_data: {
            type: Array,
            default: function () {
                return [];
            }
        },
        report_type_data: {
            type: Array,
            default: function () {
                return [];
            }
        },
        addOptions: {
            required: false,
            default: function () {
                return [];
            }
        },
        addNewData: {
            required: false,
            default: function () {
                return [];
            }
        },
        supportdata: {
            required: false,
            default: function () {
                return [];
            }
        },
        filtervalue: {
            required: false,
            default: function () {
                return [];
            }
        },
        uploadbtn: {
            required: false,
            default: function () {
                return [];
            }
        },
        downloaddbtn: {
            required: false,
            default: function () {
                return [];
            }
        },
        extraOptions: {
            required: false,
            default: function () {
                return ({
                    filter: false,
                    refresh: false,
                    cog: false,
                    toggleButton: false,
                    toggleButtons: false,
                    toggleButtonss: false,
                    add: false,
                    edit: false,
                    save: false,
                    select_box: false,
                    select_box_report:false,
                    selectedcurrent: '',
                    back: false
                });
            }
        },
        toggleButtonRes: {
            required: false,
            default: "1"
        },
        toggleButtonResliveagent: {
            required: false,
            default: "1"
        },
        toggleButtonReslivecall: {
            required: false,
            default: "2"
        },
        ticketfields: {
            required: false,
            default: function () {
                return [];
            }
        },
    },
    methods: {
        report_types(val){
            this.$emit('report_type',val)
        },
        select_industrys(val) {
            localStorage.setItem('industry_value', val);
            this.$emit("industry_value", val);
        },
        select_templet(val) {

            this.$emit("templet_value", val);
        },
        supportchanges(val) {
            localStorage.setItem('industrydata', val);
            this.$emit("industrydata", val)


        },
        getDncNumber(val) {
            this.$emit("get_dnc_number", val)
        },
        Settingclick: function () {
            this.$emit("opensettings");
        },
        globalclick: function () {
            this.$emit("global");
        },
        // Settingclicks: function () {
        // this.$emit("opensettings");
        // },
        filterClick: function () {
            this.$emit("openFilter");
        },
        refreshClick: function () {
            this.$emit("refreshPage");
        },
        plusClick: function () {
            this.$emit("plusPage");
        },
        editiconClick: function () {
            this.$emit("editiconPage");
        },
        ContactBulkDelete: function () {
            this.$emit("contactbulkdelete");
        },
        downloadClick: function () {
            this.$emit("downloadData")
        },
        uploadClick: function () {
            this.$emit("uploadPage");
        },
        addactionClick: function () {
            this.$emit("add");
        },

        addaction: function () {
            this.$emit("addDid");
        },
        editClick: function () {
            this.$emit("edit");
        },
        saveClick: function () {
            this.$emit("save");
        },
        ChatOptionClick: function (option) {
            this.$emit("ChatOptionData", option);
        },
        exportClick: function (option) {
            this.$emit("exportData", option);
        },
        addClick: function (option) {
            this.$emit("addData", option);
        },
        toggleButtonClick: function (option) {
            this.$emit("toggleClick", option);
        },
        toggleButtonClicks: function (option) {
            this.$emit("toggleClicks", option);
        },
        addNew: function (newData) {
            this.$emit("addNew", newData);
        },
        uploadNew: function () {
            this.$emit("uploadNew");
        },
        downloadNew: function () {
            this.$emit("downloadNew");
        },
        back_page: function () {
            this.$emit("backpage");
        }

        // supportchange(val) {

        // this.$emit("supportdata", val)
        // this.dynamicField(val)
        // },

        // dynamicField(val) {
        // let scope = this;
        // let checkboxes = [];
        // let radio = [];
        // let date_time = [];
        // let date = [];
        // let text = [];
        // let select = [];

        // this.$store
        // .dispatch("GetAsterSupportField", val)
        // .then((response) => {
        // this.dynamicDataFields = [];
        // var datas = response.data[0].fields;
        // let AsterLeadFields = datas;


        // for (let i = 0; AsterLeadFields.length > i; ++i) {

        // if (AsterLeadFields[i].filter === true) {

        // if (AsterLeadFields[i].input_type === "Checkbox") {
        // let filter1 = {
        // text: AsterLeadFields[i].label,
        // value: "",
        // model: AsterLeadFields[i].model,
        // input_type: AsterLeadFields[i].input_type,
        // };
        // checkboxes.push(filter1);
        // }

        // if (AsterLeadFields[i].input_type === "Radio") {
        // let opt = JSON.parse(AsterLeadFields[i].values);
        // let filter2 = {
        // text: AsterLeadFields[i].label,
        // value: "",
        // model: AsterLeadFields[i].model,
        // input_type: AsterLeadFields[i].input_type,
        // options: opt,
        // };
        // radio.push(filter2);
        // }

        // if (AsterLeadFields[i].input_type === "DateTime") {
        // let filter3 = {
        // text: AsterLeadFields[i].label,
        // value: "",
        // model: AsterLeadFields[i].model,
        // input_type: AsterLeadFields[i].input_type,
        // };
        // date_time.push(filter3);
        // }
        // if (AsterLeadFields[i].input_type === "Date") {
        // let filter4 = {
        // text: AsterLeadFields[i].label,
        // value: "",
        // model: AsterLeadFields[i].model,
        // input_type: AsterLeadFields[i].input_type,
        // is_active: true,
        // };
        // date.push(filter4);
        // }
        // if (AsterLeadFields[i].input_type === "text") {
        // let filter5 = {
        // text: AsterLeadFields[i].label,
        // value: "",
        // model: AsterLeadFields[i].model,
        // input_type: AsterLeadFields[i].input_type,
        // };

        // text.push(filter5);


        // }
        // if (AsterLeadFields[i].input_type === "Select") {
        // let options = JSON.parse(AsterLeadFields[i].values);
        // let filter6 = {
        // text: AsterLeadFields[i].label,
        // value: "",
        // model: AsterLeadFields[i].model,
        // isMultiSelect: AsterLeadFields[i].multiple,
        // input_type: AsterLeadFields[i].input_type,
        // options: options,
        // };

        // select.push(filter6);
        // }
        // }
        // }


        // this.dynamicDataFields.push(text);
        // this.dynamicDataFields.push(select);
        // this.dynamicDataFields.push(date);
        // this.dynamicDataFields.push(date_time);
        // this.dynamicDataFields.push(checkboxes);
        // this.dynamicDataFields.push(radio);
        // this.$emit("dynamicFilter", this.dynamicDataFields)

        // })
        // .catch(function (error) {
        // scope.showSnackBar("error" + error);
        // });
        // },
    },
    computed: {
        toggleButtonResComputed: function () {
            return this.toggleButtonRes;
        },
        ...mapGetters(["form"])
    }
};
</script>
   
<style lang="stylus" scoped>
   .toobar-extension {
    margin-left: 5px !important;
   }
   
   .breadcrumb-section {
    margin-left: -25px !important;
   }
   
   .action-btn {
    margin-left: 5px !important;
   }
   
   .addWidget {
   
    z-index: 4;
   }
   
   .v-text-field {
    padding-top: 12px;
    padding-left: 12px;
    margin-top: 4px;
   }
   
   .select_box_cls {
    min-width: 350px;
    padding: 15px 200px 0px 0px;
    display:flex;
   }
   </style>
   