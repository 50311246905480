import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.PauseCode;
var getters = {
   
};
const mutations = {
    
    [mutation_type.CommitNothing]: () => {}
};

const actions = {
    GetSystemSettings: ({commit},) => {
        return new Promise((resolve, reject) => {
            try {
                api.get(`/systemconfig`).then(response => {
                    commit(mutation_type.CommitNothing, {});
                    delete  response.data.data[0].lead_id
                    resolve(response.data);
                });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateSystemSettings: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
               
                const id = payload.id;
                delete payload.id;
                api.put(`/systemconfig/${id}`, payload)
                    .then(response => {
                        commit(mutation_type.CommitNothing, {});
                        resolve(response.data);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};