import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterCampaign;
var getters = {
  AsterCampaign: (state) => state.AsterCampaign.getdata,
};
const mutations = {
  [mutation_type.AsterCampaign]: (state, response) => {
    state.AsterCampaign.getdata = response;
  },
  [mutation_type.RefreshAsterCampaign]: (state, response) => {
    state.AsterCampaign.forms = response;
  },
  [mutation_type.CommitNothing]: () => { },
};
const actions = {
  GetAsterCampaigns: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {

        let skip = payload.skip;
        let take = payload.take;
        let filter = "";
        if (payload.campaign_name) {
          filter += `&campaign_name=${payload.campaign_name}`
        }
        if (payload.campaign_type) {
          filter += `&call_type=${payload.campaign_type}`
        }
        if (payload.campaign_status) {
          filter += `&active=${payload.campaign_status}`
        }
        if (payload.All) {
          filter += `&All=${payload.All}`
        }
        if (payload.template) {
          filter += `&template=${payload.template}`
        }
        if (payload.team) {
          filter += `&team=${payload.team}`
        }
        api.get(`/aster/campaigns?skip=${skip}&take=${take}` + filter).then((response) => {
          const campaign = response.data;
          commit(mutation_type.AsterCampaign, campaign);
          resolve(response.data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  FilterAsterCampaigns: ({ commit }, query) => {
    return new Promise((resolve, reject) => {
      try {


        api.get(
          `/aster/campaigns?campaign_name=${query.campaign_name}&call_type=${query.campaign_type}&active=${query.campaign_status}`
        )
          .then((response) => {
            const campaign = response.data;
            commit(mutation_type.AsterCampaign, campaign);
            resolve(response.data);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  insertAsterCampaign: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        if (payload.start_time == null) {
          payload.start_time = "00:00:00.000000";
        }
        if (payload.end_time == null) {
          payload.end_time = "00:00:00.000000";
        }
        if (payload.dnc_check) {
          payload.dnc_check = "Y";
        } else {
          payload.dnc_check = "N";
        }
        if (payload.call_recording) {
          payload.call_recording = "Y";
        } else {
          payload.call_recording = "N";
        }
        if (payload.masking) {
          payload.masking = "true";
        } else {
          payload.masking = "false";
        }
        if (payload.active.name) {
          payload.active = "Y";
        } else {
          payload.active = "N";
        }
        if (payload.call_type != "AUTO") {
          payload.dial_ratio = "0";
        }
        if (payload.call_type != "VB" && payload.call_type != "WAB") {
          payload.channel_count = 0;
        }

        api
          .post(`/aster/campaigns`, payload)
          .then((response) => {
            const campaign = response.data;
            commit(mutation_type.AsterCampaign, campaign);
            // commit(mutation_type.CommitNothing, {});
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  GetTemplate: () => {
    return new Promise((resolve, reject) => {
      try {
        api
          .get(`/aster/form/template`)
          .then((response) => {
            let data = response.data.data;
            let template = [data];
            for (let i = 0; i < data.length; i++) {
              template.push(data[i].template_name);
            }
            resolve(template);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  GetWaTemplate:({ commit },)=>{
    return new Promise((resolve, reject) => {
      api.get(`/template`).then(response => {
        commit(mutation_type.CommitNothing, {});
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    })},
  deleteAsterCampaign: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        api
          .delete(`/aster/campaigns/${payload}`)
          .then((response) => {
            if (response.status == 204) {
              resolve({
                data: []
              })
            }
            commit(mutation_type.CommitNothing, {});
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  Disposition: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        let take = {
          take: ''
        }
        api.get(`/disposition?take=${take.take}`).then(response => {
          const disposition = response.data.data;
          commit(mutation_type.Disposition, disposition);
          const dispo_array = [];
          disposition.forEach(dispo => {
            dispo_array.push(dispo.dispo_id);
          });
          localStorage.setItem("group_disposition", JSON.stringify(dispo_array));
          resolve(response);
        });
      } catch (error) {
        reject(error);
      }
    });
  },


  GetPausecode: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        let take = {
          take: ''
        }
        api.get(`/pausecode?take=${take.take}`).then(response => {
          const pausecode = response.data;
          commit(mutation_type.PauseCode, pausecode);
          const pausecode_array = [];
          pausecode.data.forEach(item => {
            pausecode_array.push(item.pause_code);
          });
          // localStorage.setItem("pause_code", JSON.stringify(pausecode_array));
          resolve(response.data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  GetInbound: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        let take = {
          take: ''
        }
        api.get(`/inboundroute?take=${take.take}`).then(response => {
          const disposition = response.data.data;
          console.log(disposition, "disposition");
          commit(mutation_type.Disposition, disposition);
          // const dispo_array = [];
          // disposition.forEach(dispo => {
          //   dispo_array.push(dispo.id);
          // });
          resolve(response);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateAsterCampaign: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        const id = payload.id;
        delete payload.id;
        if (payload.call_type != "VB" && payload.call_type != "WAB") {
          payload.channel_count = 0;
        }
        if (payload.call_type != "VB" && payload.call_type != "WAB") {
          payload.start_time = "00:00:00.000000";
          payload.end_time = "00:00:00.000000";
        }

        if (payload.dnc_check == true) {
          payload.dnc_check = "Y";
        } else {
          payload.dnc_check = "N";
        }
        if (payload.call_recording == true) {
          payload.call_recording = "Y";
        } else {
          payload.call_recording = "N";
        }
        if (payload.timezone == true || payload.timezone == "Y") {
          payload.timezone = "Y";
        } else {
          payload.timezone = "N";
        }
        if (payload.masking == true) {
          payload.masking = "true";
        } else {
          payload.masking = "false";
        }
        api
          .put(`/aster/campaigns/${id}`, payload)
          .then((response) => {
            commit(mutation_type.CommitNothing, {});
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  GetModuleNameList: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      try {
        payload["skip"] = 0
        payload["take"] = 10
        api.post(`/doocti/commonfilter`, payload).then(response => {
          if (response.status == 204) {
            resolve({
              data: []
            })
          }
          commit(mutation_type.CommitNothing, {});

          resolve(response.data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  GetDid: ({ commit }) => {
    return new Promise((resolve, reject) => {
      try {
        let take = {
          take: ''
        }
        api.get(`/didnumber/fetch?take=${take.take}`).then(response => {
          const disposition = response.data.data;
          commit(mutation_type.Disposition, disposition);
          const dispo_array = [];
          disposition.forEach(dispo => {
            dispo_array.push(dispo.id);
          });
          resolve(response);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  CampaignRemove: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api.post(`/aster/removecamp`,payload).then(response => {
        commit(mutation_type.CommitNothing, {});
        resolve(response.data);
      }).catch((error) => {
        reject(error)
      });
    });
  },
  CheckDataCampaign: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api.get(`/aster/campaigns?${payload}`).then(response => {
        commit(mutation_type.CommitNothing, {});
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    })
  },
  call_credits: ({ commit },query) => {
    return new Promise((resolve, reject) => {
        try {
            let query1=""
            
            if(query.tenant_code){
                query1 =`tenant_code=${query.tenant_code}`
            }
           
            api.get(`/credit/minutes?`+query1).then((response) => {
                // var credit_res = response;
                commit(mutation_type.CommitNothing, {});
                resolve(response);
            }).catch(function(error) {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    })
},
vbcall: ({ commit },payload) => {
  return new Promise((resolve, reject) => {
      try {          
         
          api.post(`/api/vv/call`,payload).then((response) => {
              // var credit_res = response;
              commit(mutation_type.CommitNothing, {});
              resolve(response);
          }).catch(function(error) {
              reject(error)
          });
      } catch (error) {
          reject(error)
      }
  })
},
  GetCampaignRouting: ({ commit }, params) =>{
    return new Promise((resolve, reject) => {
      try {
          api.get(`/campaign/routing`,{params}).then((response) => {
              // var credit_res = response;
              commit(mutation_type.CommitNothing, {});
              resolve(response);
          }).catch(function(error) {
              reject(error)
          });
      } catch (error) {
          reject(error)
      }
  })
  },
  CreateCampaignRouting: ({ commit }, payload) =>{
    return new Promise((resolve, reject) => {
      try {
          api.post(`/campaign/routing`,payload).then((response) => {
              // var credit_res = response;
              commit(mutation_type.CommitNothing, {});
              resolve(response);
          }).catch(function(error) {
              reject(error)
          });
      } catch (error) {
          reject(error)
      }
  })
  }
};
export default {
  state,
  getters,
  mutations,
  actions,
};
