<template>
    <div :style="previewOut ? 'position: relative' : 'position: static'">
        <section-definition v-if="previewOut === false" :title="page.title" :extraOptions="page.options" :icon="page.icon"
            :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
            @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewForm" :style="styleObject"
            :previewOut="previewOut">
        </section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page>
            <v-data-table v-if="previewOut === false" :headers="headers" :items="customformData"
                class="elevation-1 fixed-layout" :pagination.sync="pagination" :total-items="pagecount"
                :loading="tableLoading" :rows-per-page-items="[10]">
                <template v-slot:no-data style="text-align: center">
                    <div style="text-align: center">No Data Available</div>
                </template>
                <template v-slot:items="props">
                    <td class="checkEmpty text-xs-left p0">
                        {{ props.item.template_name }}
                    </td>
                    <td class="checkEmpty text-xs-left p0">{{ props.item.count }}</td>
                    <td class="checkEmpty text-xs-left p0">{{ props.item.lead_template }}
                    </td>
                    <td class="checkEmpty text-xs-left p0">{{ props.item.module }}
                    </td>
                    <td class="checkEmpty text-xs-left p0">{{ props.item.fetch_type }}
                    </td>
                    <td class="text-xs-left checkEmpty p0">

                        <!-- <i class="fas fa-eye mr-4" style="color:rgb(98 201 85)" @click="previewOutside(props.item)"
                            flat></i> -->

                        <v-icon color="blue" small class="mr-4" v-if="page.canedit"
                            @click="editItem(props.item)">edit</v-icon>
                        <v-icon color="red" small class="mr-4" v-if="page.candelete"
                            @click="deleteItem(props.item)">delete</v-icon>
                    </td>
                </template>
            </v-data-table>
            <div v-if="previewOut" class="insertflex1">
                <div class="profil_pics">
                    <div class="profil_txts">
                        <i class="fa fa-expand" aria-hidden="true"></i>
                    </div>
                    <div class="v-toolbar__title primary--text" style="margin-top:8px">{{ templet_name.toUpperCase() }}
                    </div>
                </div>
                <div @click="previewOut = false">
                    <i style="color:rgb(33, 150, 243)" class="fa fa-arrow-left icon_cls_custom_form" aria-hidden="true"></i>
                </div>
            </div>
            <preview v-if="previewOut" :value_cls="false"></preview>

        </v-container>

        <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Delete Template</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure to delete this Template?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="page.isAddCustomForm" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Select the Module</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-form ref="customformvalidation" v-model="valid" >
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-select style="text-decoration: none"  v-model="formModule"
                                    :items="domain1" label="Module" item-text="name" item-value="value"
                                    multiple="true" :rules="[(v) => !!v || 'Module is required']"></v-select>
                            </v-flex>
                            <v-flex xs12 v-if="formModule.includes('lead')">
                                <v-select style="text-decoration: none" v-model="formtemplate" :items="domain"
                                    label="Template" item-text="name" multiple="true" item-value="value" return-string required
                                    persistent-hint :rules="[(v) => !!v || 'Template is required']"></v-select>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closeAddCustomform()">Close</v-btn>
                    <v-btn color="primary"  :disabled="(formtemplate===''&&formModule.includes('lead')) || formModule===''" @click="saveAddCustomform()">Continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent :value="page.isEditCustomform" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Update Template</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field v-model="editCustomform.pause_code" required label="Pause Code" type="text"
                                :rules="[(v) => !!v || 'Pausecode is required']"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field v-model="editCustomform.description" required label="Description" type="text"
                                :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field v-model="editCustomform.status" required label="Status" type="text"
                                :rules="[(v) => !!v || 'Status is required']"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
                    <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
            <v-toolbar small color="secondary" style="color: white">
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon flat color="white" @click="page.isFilterOpen = false">
                    <i class="fas fa-close"></i>
                </v-btn>
            </v-toolbar>
            <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
                <v-container class="sidenavContainer">
                    <div class="autocompleteDiv">
                        <v-autocomplete v-model="filter.payload.template_name" :items="filtervalue"
                            :loading="filter.isLoading" :search-input.sync="filter.search" label="Template Name"
                            hide-no-data hide-selected></v-autocomplete>
                    </div>
                </v-container>
                <v-container row class="sidenavFooter">
                    <v-btn flat @click="getDataFromApi()">Reset</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="fetchFilter()">Filter</v-btn>
                </v-container>
            </div>
        </v-navigation-drawer>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
   
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters, mapActions } from "vuex";
import preview from "./custom-form/Preview.vue";
export default {
    components: {
        loader,
        preview
    },

    data() {
        return {
            templet_name: '',
            previewOut: false,
            previewval: {},
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            valid:true,
            page: {
                title: "Modules",
                icon: "fa-file fa-2x",
                loading: false,
                isAddCustomForm: false,
                isFilterOpen: false,
                playingRecord: false,
                breadcrumbs: [],
                exportOptions: [],
                options: { filter: true, refresh: true },
                addnew: [],
                isEditCustomform: false,
                temp: { key: "addcustomform", label: "Add Template" },
                candelete: false,
                canedit: false,
                canpreview: false,
            },
            headers: [
                {
                    text: "Template Name",
                    align: "left",
                    class: "p0",
                    sortable: true,
                    value: "template_name",
                },
                {
                    text: "Total Fields",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "description",
                },
                {
                    text: "Lead Template",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "status",
                },
                {
                    text: "Module",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "status",
                },
                {
                    text: "Data Fetch",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "fetchdata",
                },
                {
                    text: "Action",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "btn",
                    width: "20%",
                },
            ],
            customformData: [],
            pagecount: 0,
            delete_item: {},
            pagination: {
                page: 0,
                rowsPerPage: 10,
            },
            domain: [],
            domain1: [{ name: "Lead", value: "lead" }, { name: "Ticket", value: "ticket" }, { name: "Contact", value: "contact" }],
            formtemplate: "",
            formModule: "",
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            DeleteRowdata: false,
            showRowData: false,
            DataRow: false,
            tableLoading: false,
            crmdomain: "",
            filtervalue: [],
            filter: {
                payload: {
                    template_name: "",
                },
                options: {
                    status: ["Active", "Inactive"],
                },
                isLoading: false,
                search: "",
            },
            isProcessing: false,
            call_status: [],
            editCustomform: {
                id: "",
                pause_code: "",
                type: "",
                description: "",
                status: "",
            },
            checktem:false,
            addCustomform: {
                valid: false,
                payload: {
                    template_name: "",
                    type: "",
                    description: "",
                    status: "",
                },
                options: {
                    status: ["Active", "Inactive"],
                },
            },
            IndustryRules: [
                v => !!v || 'Module is required'
            ],

        };
    },
    methods: {
        ...mapActions(['GetformbuilderDefaultFields']),
        previewOutside(val) {
            this.templet_name = val.template_name;
            this.$store.dispatch("GetFormFields", val.template_name)
                .then(() => {

                    // this.$router.push({
                    // name: "crm/CustomForm",
                    // });
                    this.previewval = val;
                    this.previewOut = !this.previewOut
                })
        },
        refreshThisPage: function () {
            var scope = this;
            scope.page.loading = true;
            scope.getDataFromApi();
        },
        filterClick: function () {
            this.page.isFilterOpen = true;
        },
        fetchFilter: function () {
            var scope = this;
            scope.tableLoading = true;
            this.page.isFilterOpen = false;
            this.$store
                .dispatch("FilterFormBuilder", scope.filter.payload)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    scope.customformData = datas;
                })
                .catch(function (error) {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditCustomform = false;
                });
        },

        filterFieldData() {
            let scope = this;
            let data = { model: "formbuilder", column: ["template_name"] };

            this.$store
                .dispatch("GetModuleNameList", data)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    this.filtervalue = datas[0].template_name;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDataFromApi() {
            var scope = this;
            scope.tableLoading = true;
            scope.filter.payload.pause_code = "";
            scope.filtervalue = [];
            this.$store
                .dispatch("GetFormBuilder")
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    if (datas !== "" && datas !== undefined && datas !== null) {
                        datas.forEach((element) => {
                            scope.filtervalue.push(element["template_name"]);
                        });
                    }
                    scope.customformData = datas;
                })
                .catch(function (error) {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("error" + error);
                    scope.page.isEditCustomform = false;
                });
        },
        AddNewForm: function () {
            var scope = this;
            scope.page.isAddCustomForm = true;
        },
        closeEditRules: function () {
            this.page.isEditCustomform = false;
        },
        closeAddCustomform: function () {
            this.page.isAddCustomForm = false;
            this.formtemplate = "";
            this.formModule = ""
            this.$refs.customformvalidation.reset();
        },
        saveEditRules() {
            var scope = this;
            scope.$store
                .dispatch("UpdatereportbuilderFields", scope.editCustomform)
                .then(() => {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("Updated Successfully");
                    this.page.isEditCustomform = false;
                })
                .catch(function (error) {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditCustomform = false;
                });
            this.getDataFromApi();
        },
        saveAddCustomform() {
            var scope = this;
            let finaldata = {
                formtemplate: scope.formtemplate,
                formModule: scope.formModule
            }
          
            if (this.$refs.customformvalidation.validate()) {
                scope.GetformbuilderDefaultFields(finaldata)
                    .then(() => {
                        scope.$router.push({
                            name: "Formbuilderview",
                        });
                    })
                    .catch(function (error) {
                        scope.page.isAddCustomForm = false;
                        scope.showSnackBar("error" + error);
                    });
            } else {
                scope.page.isAddCustomForm = true;
            }

        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        deleteItem(item) {
            this.DeleteRowdata = true;
            this.delete_item = item;
        },
        editItem(item) {
            var scope = this;
            scope.$store
                .dispatch("GetFormbuilderFields", item.template_name)
                .then(() => {
                    scope.$router.push({
                        name: "Formbuilderview",
                    });
                })
                .catch(function (error) {
                    scope.showSnackBar("error" + error);
                });
        },
        savedelete() {
            let scope = this;
            scope.isProcessing = true;
            console.log(this.delete_item,"skjhakjhjkahdjkahjkas")
            this.$store
                .dispatch("deleteform", this.delete_item)
                .then((response) => {
                    scope.isProcessing = false;
                    if (response.message === "Success") {

                        let obj = {
                            template_name: this.delete_item.lead_template,
                            model: this.delete_item.template_name,
                            module: this.delete_item.module,
                            deleted: "true",
                        }
                        scope.$store
                            .dispatch("createworkflow", obj)
                            .then((response) => {
                                if (response.statusCode === 201) {
                                    scope.showSnackBar("Deleted Successfully");
                                    scope.getDataFromApi();
                                }
                            })
                            .catch((err) => {
                                scope.isProcessing = false;
                                scope.showSnackBar(err);
                            });
                    }
                })
                .catch(function (error) {
                    scope.isProcessing = false;
                    scope.showSnackBar(error);
                });
            this.closedelete();
        },
        closedelete() {
            this.DeleteRowdata = false;
        },
        closeShow() {
            this.showRowData = false;
        },
    },
    computed: {
        ...mapGetters(["CallFlow"]),
    },
    mounted() {
        let scope = this;
        this.page.loading = false;
        setTimeout(
            function () {
                
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "formbuilder:forms:create"
                    ) != -1
                ) {
                    scope.page.addnew = [{ label: "New Form" }];
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "formbuilder:forms:edit"
                    ) != -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "formbuilder:forms:delete"
                    ) != -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
        // this.page.addnew = [{ label: 'Add Custom Form' }]
        // scope.page.candelete = true
        // scope.page.canedit = true
        const project_config = JSON.parse(localStorage.projectConfig);
        this.crmdomain = project_config.projects[0].config[0].crm_domain;
        this.getDataFromApi();
        this.filterFieldData()




        this.$store
            .dispatch("GetCustomForm")
            .then((response) => {

                var datas = response.data;
                for (let i = 0; i < datas.length; i++) {
                    const element = datas[i];
                    if (element.active === "Y") {
                        this.domain.push({ name: element.template_name, value: element.template_name });
                    }

                }
                console.log(datas, "ncjmncjznc,zmncmznc")
            })
            .catch(function (error) {

                scope.showSnackBar("error" + error);
            });

    },
    "filter.search"(val) {
        if (this.page.isFilterOpen && val != null) {
            let scope = this;

            let data = { model: "custom_forms", column: ["template_name"], search: val };

            this.$store
                .dispatch("GetModuleNameList", data)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    this.filtervalue = datas[0].template_name;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
    watch: {
        "filter.search"(val) {
            if (this.page.isFilterOpen && val != null) {
                let scope = this;

                let data = { model: "custom_forms", column: ["template_name"], search: val };

                this.$store
                    .dispatch("GetModuleNameList", data)
                    .then((response) => {
                        scope.tableLoading = false;
                        scope.page.loading = false;
                        var datas = response.data;
                        this.filtervalue = datas[0].template_name;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        formModule(val){
            if(val[0]=='lead'){
                this.checktem=true
            }else{
                this.checktem=false
            }
        }
    }
};
</script>
<style>
.file-dummy {
    width: 100%;
    padding: 30px;
    border: 2px dashed;
    text-align: center;
    transition: #2196f333 0.3s ease-in-out;
    cursor: pointer;
    font-weight: bold;
}

.application a {
    text-decoration: none;
}

.dialog_cls {
    position: absolute;
}

.pop_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.insertflex1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 55px;
    padding: 2px 10px 0px 20px;
    margin-bottom: 10px;

}

.profil_pics {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.profil_txts {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: 'Roboto Slab', serif;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%), 0 13px 24px -11px rgb(33 150 243 / 60%);
}

.icon_cls_custom_form {
    margin-top: 10px;
    font-size: 20px;
    padding: 0px 20px 0px 20px;
}
</style>