<template>
    <div class="main-view-cls">
        <div class="main_container_grid">
            <div class="grid1">
                <div class="header_cls">
                    <div class="profil_pic">
                        <div v-if="icon === ''" class="profil_txt" style="font-size:20px;padding-top:8px">N/A</div>
                        <div v-else class="profil_txt">{{ icon }}</div>
                        <div style="display: flex;flex-direction: column;margin: 8px;height: 40px;">

                            <div style="font-weight: bold;font-size: 19px;" class="v-toolbar__title primary--text">{{
                                `${masking ? ChartoMaskingChar(ph_user) : ph_user } #${editlead_id}` }}
                            </div>
                            <div style="color: #cacaca;font-style: oblique;">{{ `Last Modified :
                                ${edit_datas}` }}</div>
                        </div>
                    </div>
                    <div class="icon_flex">
                        <div>
                            <v-icon v-show="page.candelete" color="red" class="mr-4" style="font-size:25px"
                                @click="deleteItem()">delete
                            </v-icon>
                        </div>
                        <div>
                            <v-icon v-show="page.userassign" color="green" @click="assignAgent()" style="font-size:25px"
                                class="mr-4">queue
                            </v-icon>
                        </div>
                        <div>
                            <v-icon color="blue" style="font-size:25px" @click="pushBack()"
                                class="fas fa-arrow-left mr-4"></v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid2">

                <div class="timeline">
                    <timeline :id="editlead_id" :module="`lead`" :template_name="temp" :ticket_info="apidata[0]" :Filename="Filename" :masking="masking" :ChartoMaskingChar="ChartoMaskingChar"></timeline>
                </div>
            </div>
            <div class="grid3">
                <div class="v-toolbar__title titlecls "
                    style="font-size: medium;color:#ffffff;padding:10px 10px 10px 10px;text-align:center;background-color: rgb(33, 150, 243);">
                    LEAD
                    INFORMATIONS</div>
                <div class="form_data">
                    <Preview :value_cls="true" :apidata="apidata" @update="update_fun($event)"
                        @formpopup="formpopup($event)" ref="childrefpreview" :Filename="Filename" :masking="masking" :ChartoMaskingChar="ChartoMaskingChar"></Preview>
                </div>
            </div>
        </div>
        <v-dialog v-model="isShowAgentAssign" max-width="400px">
            <v-form ref="refassign" v-model="valid">
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>Assign Lead</v-toolbar-title>
                    </v-toolbar>
                    <v-container id="scroll-target" style="max-height: 350px" class="scroll-y">
                        <template>
                            <v-select  v-model="user" :items="agents" label="Agent" required item-text="name"
                                    item-value="value"  multiple
                                >
                            </v-select>
                        </template>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light" flat @click="closeAssign()">Cancel</v-btn>
                        <v-btn color="primary" @click="submitAssign()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog persistent v-model="editleadinfo" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <!-- <v-card-title style="width: 100% ;padding:0px!important"> -->
                    <div style=" display: flex;justify-content: space-between; width: 100%!important;padding: 5px;">
                        <div class="headline" style="width:90%;font-size:15px;margin-left: 10px;">Save changes</div>

                        <div style="width:10%;margin: auto;" @click="closelead()">
                            <!-- <v-btn icon flat color="white" "> -->
                            <i class="fas fa-close" style="font-size:20px"></i>
                            <!-- </v-btn> -->
                        </div>
                    </div>

                    <!-- </v-card-title> -->
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Save changes to this lead before closing?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closewithoutlead()">Close without Saving</v-btn>
                    <v-btn color="primary" @click="savelead()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Delete Lead</v-toolbar-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure to delete this Lead?</v-card-text>
                    </v-layout>
                </v-container>

                <v-card-actions class="modal-footer--sticky">
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="errormsg.errorpopup" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Fields Required Error</v-toolbar-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text
                            v-if="errormsg.msg.errorpopup_common == true && errormsg.msg.errorpopup_datetime == true">
                            <p style="font-size: medium" v-for="(data, i) in errormsg.msg.datetimevalidation_data" :key="i">
                                {{ i + 1 + `. ${data} is required` }}
                            </p>
                            <p style="font-size: medium">
                                {{ `Fill required Fields` }}
                            </p>
                        </v-card-text>
                        <v-card-text
                            v-if="errormsg.msg.errorpopup_common == true && errormsg.msg.errorpopup_datetime == false">
                            <p style="font-size: medium">
                                {{ `Fill required Fields` }}
                            </p>

                        </v-card-text>
                        <v-card-text
                            v-if="errormsg.msg.errorpopup_common == false && errormsg.msg.errorpopup_datetime == true">
                            <p style="font-size: medium" v-for="(data, i) in errormsg.msg.datetimevalidation_data" :key="i">
                                {{ i + 1 + `. ${data} is required` }}
                            </p>

                        </v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions class="modal-footer--sticky">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="errormsg.errorpopup = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>
   
<script>
import timeline from "../../components/crm/timeLine.vue"
import Preview from "./custom-form/Preview.vue";
import { MaskCharacter } from "@/utils/helpers";
// import moment from "moment";


export default {
    components: {
        timeline,
        Preview
    },
    props: {
        editlead_id: {
            type: Number
        }
    },
    data() {
        return {
            page: {
                userassign: false,
                candelete: false
            },
            ph_user: '',
            editleadinfo: false,
            DeleteRowdata: false,
            icon: "",
            title: "",
            lead_id: "",
            isShowAgentAssign: false,
            valid: true,
            agents: [],
            assignedDetails: {},
            user: "",
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            count: 0,
            edit_datas: "",
            apidata: [],
            required: [],
            temp: JSON.parse(localStorage.getItem(`templetname`)).templetname,
            errormsg: { errorpopup: false, msg: {} },
            Filename:"Leads",
            masking:false
        }
    },
    created() {
        var scope = this;
        let campaign
        let payload = { skip: 0, take: 0, campaign_status: 'Y', All: true, template: JSON.parse(localStorage.templetname).templetname };
        scope.$store
            .dispatch("GetAsterCampaigns", payload)
            .then((response) => {
                if (response.statusCode == 200) {
                    campaign = response.data
                }
            })
            .catch(function (error) {
                scope.showSnackBar("error" + error);
            });
            console.log(campaign,"campaigncampaigncampaign",JSON.parse(localStorage.templetname).templetname )
        let stats = {
            list_name: "",
            list_campaign: "",
            list_status: "Y",
        };
        scope.$store.dispatch("FilterAsterList", stats).then(async (response) => {
            let data = response.data;
            let list = [];
            for (let i = 0; i < data.length; i++) {
                await list.push(data[i].list_id.toString());
            }
            scope.add_list = list;
        });
        let agentdata= JSON.parse(localStorage.getItem("agent_emails"))
           let arr=[]
           for (let i = 0; i < agentdata.length; i++) {
            const element = agentdata[i];
            arr.push({name:element,value:element})
            
           }
           this.agents=arr
        this.mount()
    },
    mounted() {
        let scope = this;
        let obj = JSON.parse(localStorage.templetname)
        let form = {
            "name": obj.templetname,
            "filterValues":[{
            "name": "lead_id",
            "value": this.editlead_id.toString()
            }],
            "skip": 0,
            "take": 1,
            "from_date": "",
            "to_date": ""
        };
        this.$store.dispatch("GetAsterLeadData", form).then(async (res) => {
            if(res.data[0].user==="" ||res.data[0].user===null||res.data[0].user==='UNASSIGN'){
                this.user = []
            }else{
                this.user = await res.data[0].user.split(',')
            }
            
            this.ph_user = await res.data[0].phone_number;
            this.apidata = res.data;
            let localform = JSON.parse(localStorage.getItem("forms"))
            for (let i = 0; i < localform[0].fields.length; i++) {
                const element = localform[0].fields[i];

                if (element.fieldType === "Checkbox") {

                    if (this.apidata[0][element.model] === "true") {
                        this.apidata[0][element.model] = true
                    } else {
                        this.apidata[0][element.model] = false
                    }
                }
            }
            // let obj_ = {
            // 'user': this.user,
            // 'lead_id': this.editlead_id,
            // 'icon': this.user.split('')[0].toUpperCase()
            // }
            this.icons()
        })

        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:lead:userassign"
                    ) !== -1
                ) {
                    scope.page.userassign = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:lead:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
                if (
                scope.$store.getters.UserPermissions.indexOf(
                    "configurations:masking:view"
                ) !== -1
                ) {
                    scope.masking = true;
                }
            }.bind(scope),
            1
        );

    },
    methods: {
        sidebar_enable_disable(data) {
            this.$root.$emit('sidebar_disable', data);
        },
        closelead() {
            this.editleadinfo = false;
        },
        closewithoutlead() {
            this.editleadinfo = false;
            this.sidebar_enable_disable(false);
            this.$emit("customform", false);
        },
        savelead() {
            let valid_status = this.$refs.childrefpreview.validatecheck()
            if (valid_status.errorpopup_common && valid_status.errorpopup_datetime) {
                this.editleadinfo = false;
                this.errormsg.msg = valid_status
                this.errormsg.errorpopup = true
            } else if (valid_status.errorpopup_datetime) {
                this.editleadinfo = false;
                this.errormsg.msg = valid_status
                this.errormsg.errorpopup = true
            } else if (valid_status.errorpopup_common) {
                this.editleadinfo = false;
                this.errormsg.msg = valid_status
                this.errormsg.errorpopup = true
            } else {
                if (this.final_data[0].checker) {
                    let values_ = this.final_data[0][this.final_data[0].checker].toString()
                    this.final_data[0][this.final_data[0].checker] = values_
                    delete this.final_data[0].checker
                    this.editFunction(this.final_data)
                } else {
                    this.editFunction(this.final_data)
                }
                this.editleadinfo = false;
                this.$emit("customform", false);
                this.errormsg.errorpopup = false;
                this.sidebar_enable_disable(false);
            }

        },
        mount() {

            let obj = JSON.parse(localStorage.templetname)
            this.templet_name = obj.templetname
            this.icon = this.templet_name.split('')[0].toUpperCase()
            this.$store.dispatch("GetFormFields", obj.templetname)
                .then(async () => {
                    let localform = JSON.parse(localStorage.getItem("forms"))
                    let check_bts = []
                    let longtxt = []
                    let others = []
                    let link_data = []
                    let link_longtext_data = []
                    let link_check = []

                    for (let i = 0; i < localform[0].fields.length; i++) {

                        const element = localform[0].fields[i];

                        if (element.required === true) {
                            await this.required.push(element.model)
                        }
                        let get_dataa = {}
                        let link_get_dataa = {}
                        if (element.model !== "lead_id" && element.model !== "user" && element.model !== "ticket_id" && element.model !== "order_id" && element.model !== "list_id" && element.model !== "hopper_status") {
                            if (element.fieldType === 'Checkbox') {

                                element.values.forEach(res => {
                                    res.checked = false;
                                })


                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;

                                if (element.is_link && element.link_type === 'Child') {
                                    link_check.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = false;
                                    this.link_check_arr.push(link_get_dataa)
                                } else {
                                    check_bts.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = false;
                                    this.check_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'RadioButton') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                check_bts.push(element)
                                get_dataa[`name`] = element.model;
                                get_dataa[`value`] = "";
                                this.check_arr.push(get_dataa)
                            } else if (element.fieldType === 'LongTextInput') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;
                                if (element.is_link && element.link_type === 'Child') {
                                    link_longtext_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = "";
                                    this.link_longtxt_arr.push(link_get_dataa)
                                } else {
                                    longtxt.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = "";
                                    this.longtxt_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'DatePicker' || element.fieldType === "TimePicker") {

                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['isActive'] = false;
                                element['visible'] = false;
                                if (element.is_link && element.link_type === 'Child') {
                                    link_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = "";
                                    this.link_others_arr.push(link_get_dataa)
                                } else {
                                    others.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = "";
                                    this.others_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'DatetimePicker') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;
                                if (element.is_link && element.link_type === 'Child') {
                                    link_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = '';
                                    this.link_others_arr.push(link_get_dataa)
                                } else {
                                    others.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = '';
                                    this.others_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'SelectList') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;
                                others.push(element)
                                get_dataa[`name`] = element.model;
                                get_dataa[`value`] = "";
                                this.others_arr.push(get_dataa)
                            } else {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }

                                element['visible'] = false;

                                if (element.is_link && element.link_type === 'Child') {
                                    link_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = "";
                                    this.link_others_arr.push(link_get_dataa)
                                } else {
                                    others.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = "";
                                    this.others_arr.push(get_dataa)
                                }


                            }
                        }
                        await this.apifield.push(others)
                        await this.apifield.push(check_bts)
                        await this.apifield.push(longtxt)
                        await this.apifield.push(link_data)
                        await this.apifield.push(link_longtext_data)
                        await this.apifield.push(link_check)

                    }

                })

        },
        update_fun(val) {
            this.final_data = []
            let obj = {}
            for (let i = 0; i < val.length; i++) {
                const element = val[i];
                for (let y = 0; y < element.length; y++) {
                    const element1 = element[y];
                    if (element1.model !== "created_at") {
                        obj[`${element1.model}`] = element1.value.value
                    }
                    if (element1.fieldType === "Checkbox") {
                        obj[`${element1.model}`] = element1.value.value.toString()
                        obj[`checker`] = element1.model
                    }
                    if (element1.model != "lead_id" && element1.model != "ticket_id" && element1.model !== "created_at") {
                        if (element1.model == "modified_date") {
                            var today = new Date();
                            var dd = String(today.getDate()).padStart(2, "0");
                            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                            var yyyy = today.getFullYear();
                            today = yyyy + "-" + mm + "-" + dd;
                            obj[`${element1.model}`] = today.toString();
                        }
                        if (element1.model == "modified_by") {
                            obj[`${element1.model}`] = JSON.parse(localStorage.apiInfo).user_email;
                        }

                    }
                    if (element1.model_type == "SmallNumber" || element1.model_type == "BigNumber" || element1.model_type == "Number") {
                        if (element1.value.value == "") {
                            obj[`${element1.model}`] = null
                        } else {
                            obj[`${element1.model}`] = Number(element1.value.value)
                        }

                    }

                }

            }
            this.final_data.push(obj)
        },
        edit_data(val) {

            if (val.check) {
                this.showSnackBar(`400 Bad Request: ${val.val.toString()} Is Required Field`);
            } else {
                this.count += 1
                let payload = {
                    id: this.editlead_id,
                    template_name: JSON.parse(localStorage.getItem("templetname")).templetname,
                    fields: val
                }
                this.$store.dispatch("updateAsterLead", payload).then((response) => {
                    if (response.status === 200 && this.count > 1) {
                        this.showSnackBar("Lead Updated successfully!");
                    }
                })
            }
        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        icons() {
            // this.user = val.user
            // this.title = val.user.toUpperCase()
            // this.lead_id = val.lead_id



            // let isoDate = this.apidata[0].modified_date.toString();

            // let newDate = moment.utc(isoDate).format('YY-MM-DD');
            // console.log('converted date', newDate);

            let date = new Date(this.apidata[0].modified_date.toString());
            // date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            let mm = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)
            let dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
            this.edit_datas = date.getFullYear() + '-' + mm + '-' + dd;
        },
        assignAgent() {
            this.isShowAgentAssign = true
           let agentdata= JSON.parse(localStorage.getItem("agent_emails"))
           
           let arr=[]
           for (let i = 0; i < agentdata.length; i++) {
            const element = agentdata[i];
            arr.push({name:element,value:element})
            
           }
           this.agents=arr
            this.assignedDetails.template_name = JSON.parse(localStorage.getItem("templetname")).templetname
            // this.$refs.refassign.resetValidation();

        },
        closeAssign() {
            this.isShowAgentAssign = false
            this.assignedDetails = {};
        },
        submitAssign() {
            const scope = this;
            const assignDetails = {
                lead_id: scope.editlead_id,
                user: scope.user.toString()===""?'UNASSIGN':scope.user.toString(),
                template_name: scope.assignedDetails.template_name,
                modified_by: JSON.parse(localStorage.apiInfo).user_email,
            };
            scope.$store
                .dispatch("AssignAgent", assignDetails)
                .then((response) => {
                    if (response.statusCode === 200) {
                        scope.showSnackBar("Lead assigned to agent successfully!");
                        scope.assignedDetails = {};
                        scope.isShowAgentAssign = false;
                        // let val = {
                        // icon: this.user.split('')[0].toUpperCase(),
                        // user: this.user,
                        // lead_id: this.editlead_id
                        // }
                        this.icons()
                    } else {
                        scope.showSnackBar("Lead assigned to agent failed!");
                    }
                })
                .catch((err) => {
                    console.log("ERR : ", err);
                    scope.showSnackBar("Lead assigned to agenttt failed!");
                });
        },
        pushBack() {
            this.editleadinfo = true;
        },
        deleteItem() {
            this.DeleteRowdata = true
        },
        closedelete() {
            this.DeleteRowdata = false
        },
        savedelete() {

            let payload = {
                template_name: JSON.parse(localStorage.getItem("templetname")).templetname,
                delete_id: this.editlead_id,
            };
            this.$store.dispatch("deleteAsterLead", payload).then((response) => {

                if (response.statusCode == 200) {
                    let scope = this;
                    scope.showSnackBar("Lead deleted Successfully");
                    setTimeout(() => {
                        this.closedelete();
                        this.$emit("customform", false);
                    }, 1000);
                } else {
                    this.closedelete();
                }
            });

        },
        editFunction(val) {


            let keys = Object.keys(val[0])
            let values = Object.values(val[0])
            let main_arr = []
            for (let i = 0; i < keys.length; i++) {

                if (keys[i] !== "lead_id" && keys[i] !== "user" && keys[i] !== "ticket_id" && keys[i] !== "order_id" && keys[i] !== "list_id" && keys[i] !== "hopper_status") {

                    let obj = {}

                    obj.name = keys[i];
                    obj.value = values[i];


                    main_arr.push(obj)
                }

            }
            let fields = main_arr
            for (let i = 0; i < fields.length; i++) {
                const element = fields[i];
                for (let j = 0; j < this.required.length; j++) {
                    const element1 = this.required[j];
                    if (element.name === element1 && element.value === "") {
                        this.count += 1

                        this.err_arr.push(element.name.replace('_', ' ').toUpperCase())
                    }

                }

            }
            if (this.count === 0) {
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd;


                main_arr.forEach(res => {
                    if (res.name == 'modified_date') {
                        res.value = today.toString()
                    }
                    if (res.name == 'modified_by') {
                        res.value = JSON.parse(localStorage.apiInfo).user_email
                    }
                    if (res.name == 'sms_notify') {
                        res.value = "0"
                    }
                    if (res.name == 'wa_notify') {
                        res.value = "0"
                    }
                    if (res.name == 'api_push') {
                        res.value = "0"
                    }
                    if (res.name == 'email_notify') {
                        res.value = "0"
                    }
                })

                this.edit_data(main_arr)
                this.err_arr = []

            } else {
                let obj = {
                    check: true,
                    val: this.err_arr
                }
                this.edit_data(obj)
                this.count = 0
                this.err_arr = []

            }




        },
        ChartoMaskingChar(data){
            let scope = this
            if(!scope.$store.getters.MaskType.includes(typeof(data))){
                return data
            }
            return MaskCharacter(data)
        },
    },
    watch: {
        'editleadinfo': function(newVal) {
            this.sidebar_enable_disable(newVal);
        },
        'isShowAgentAssign': function(newVal) {
            this.sidebar_enable_disable(newVal);
        },
        'DeleteRowdata': function(newVal) {
            this.sidebar_enable_disable(newVal);
        },
        'errormsg.errorpopup': function(newVal) {
            this.sidebar_enable_disable(newVal);   
        }     
    }
        
}
</script>
   
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap');

/* .v-toolbar__content {
    padding: 0px !important;
   } */

.grid1 {
    grid-area: header;
}

.grid2 {
    grid-area: menu;
}

.grid3 {
    grid-area: main;
}

.main_container_grid {
    display: grid;
    grid-template-areas:
        'header header header header header header'
        'menu main main main main main';
    gap: 10px;

    padding: 10px;
}

.grid1 {

    padding: 5px;
    border-radius: 5px;
}

.grid2 {
    /* height: 500px; */
    border: 1px solid #cacaca;
    border-radius: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 100px
}

.timeline {
    min-width: 240px;
    height: 500px;
    /* padding: 5px; */
    overflow: auto;
}

.grid3 {
    /* height: 500px; */

    overflow: auto;
    border: 1px solid #cacaca;
    border-radius: 5px;
    display: flex;
    overflow: hidden;

    flex-direction: column;

}

.form_data {
    min-width: 800px;
    overflow: auto;
}

.profil_pic {
    display: flex;
    flex-direction: row;

}

.profil_txt {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: 'Roboto Slab', serif;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%), 0 13px 24px -11px rgb(33 150 243 / 60%);
}

.header_cls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.icon_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 5px 5px 5px;
}

.titlecls:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rbga(255, 255, 255, 0.5) !important;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.titlecls:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
</style>