<template>
    <div>
      <section-definition
        :title="page.title"
        :extraOptions="page.options"
        :icon="page.icon"
        :breadcrumbs="page.breadcrumbs"
        :sortOption="page.sortOption"
        :exportOptions="page.exportOptions"
        @openFilter="openWatsappFilter"
        @refreshPage="refreshWatsappPage"
        :isMaterialIconType="true"
        @exportData="exportWatsappReport"
        :style="styleObject"
      ></section-definition>
      <loader v-if="page.loading"></loader>
      <v-container v-else fluid grid-list-xl page>
        <v-data-table
          :headers="headers"
          :items="watsAppReport"
          class="elevation-1"
          :pagination.sync="pagination"
          :total-items="watsappTotal"
          :rows-per-page-items="[5, 10, 25]"
        >
          <template v-slot:items="props">
            <td class="checkEmpty">{{ props.item.Date ? props.item.Date : '--' }}</td>
  
            <td class="checkEmpty">{{ props.item.agent ? props.item.agent : '--' }}</td>
            <td class="checkEmpty">{{ props.item.count.wa_count ? props.item.count.wa_count:0 }}</td>
          </template>
        </v-data-table>
      </v-container>
      <v-navigation-drawer
        fixed disable-resize-watcher
        right
        v-model="page.isFilterOpen"
        class="filterNavigation"
      >
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat color="white" @click="page.isFilterOpen = false">
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div
          class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
        >
        <v-container class="sidenavContainer">
          <watsAppByAgentFilter
            v-if="page.isFilterOpen"
            @getDataFrom="getDataFrom"
            :appliedfilter="appliedfilter"
          ></watsAppByAgentFilter>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="resetvalue()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
        </div>
      </v-navigation-drawer>
    </div>
  </template>
  <script>
  import loader from "@/components/Widgets/loader";
  //import watsAppFilter from "./Components/watsAppFilter";
   import watsAppByAgentFilter from "./Components/watsAppByAgentFilter";
  import { mapGetters } from "vuex";
  import moment from "moment";
  export default {
    components: {
      loader,
      //watsAppFilter,
      watsAppByAgentFilter
    },
    mounted() {
      let scope = this;
      this.page.loading = false;
      this.page.loading = false;
      this.dateformate();
      setTimeout(
        function () {
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "reports:missedcall:export"
            ) !== -1
          ) {
            scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
          } else {
            scope.page.exportOptions = [];
          }
        }.bind(scope),
        1
      );
    },
    data() {
      return {
        styleObject: {
          height: "80px",
          "margin-top": "-8px",
        },
        page: {
          title: "WhatsApp By Agent",
          icon: "fa fa-whatsapp fa-3x",
          loading: true,
          materialicon: true,
          isFilterOpen: false,
          playingRecord: false,
          breadcrumbs: [],
          exportOptions: [],
          sortOption:[],
          options: { filter: true, refresh: true },
        },
        dialog: false,
        pagination: {
          rowsPerPage: 10,
        },
        headers: [
          {
            text: "Date",
            align: "left",
            sortable: true,
            class: "p0",
            value: "date",
          },
          {
            text: "Agent",
            align: "left",
            sortable: false,
            class: "p0",
            value: "agent",
          },
          {
            text: "Count",
            align: "left",
            sortable: false,
            class: "p0",
            value: "count",
          },
        ],
        watsappTotal: 0,
        payload: {
          from_date: "",
          to_date: "",
          skip: 0,
          take: 0,
          detail: "watsAppDetail",
          descending: "",
          export: "exportwatsAppDetails",
        },
        total: "",
        call_date: "",
        report: "",
        exports: [],
        excel_data: [],
        txt_data: [],
        from_date: "",
        to_date: "",
        appliedfilter: {
          fromdate: "",
          todate: "",
          flag: "",
        },
        page_flag: false,
      };
    },
    watch: {
      pagination: {
        handler() {
          this.getDataFromApi();
        },
        deep: true,
      },
    },
    computed: {
      ...mapGetters(["watsAppReport"]),
    },
    methods: {
      closeModel: function () {
        this.dialog = false;
      },
      openWatsappFilter: function () {
        this.page.isFilterOpen = true;
      },
      refreshWatsappPage: function () {
        var scope = this;
        scope.page.loading = true;
        scope.dateformate();
        scope.getDataFromApi();
      },
      exportWatsappReport: function (option) {
        if (option == "CSV" || option == "Txt(Tab-Delimited)") {
          this.export(option);
        } else {
          this.dialog = true;
          this.export(option);
        }
      },
      dateformate() {
        this.page.loading = false;
        this.to_date = new Date();
        this.to_date = moment(this.to_date).format("YYYY-MM-DD HH:mm:ss");
        var from_date1 = new Date();
        var dateOffset = 24 * 60 * 60 * 1000 * 30;
        from_date1.setTime(from_date1.getTime() - dateOffset);
        this.from_date = moment(from_date1).format("YYYY-MM-DD HH:mm:ss");
        this.$store.commit("FILTERFROMDATE", this.from_date);
        this.$store.commit("FILTER_TODATE", this.to_date);
      },
      resetvalue() {
        var to_date = new Date();
        to_date = moment(to_date).format();
        var from_date = new Date();
        var dateOffset = 24 * 60 * 60 * 1000 * 30;
        from_date.setTime(from_date.getTime() - dateOffset);
        from_date = moment(from_date).format();
        this.$store.dispatch("fromdate", from_date);
        this.$store.dispatch("todate", to_date);
        this.appliedfilter.flag = true;
        this.appliedfilter.fromdate = "";
        this.appliedfilter.todate = "";
        if (this.appliedfilter.flag) {
          this.appliedfilter.fromdate = from_date;
          this.appliedfilter.todate = to_date;
          this.appliedfilter.flag = false;
        }
        this.getDataFromApi();
        this.page.isFilterOpen = true;
      },
      getDataFromApi() {
        var scope = this;
        this.payload.to_date = this.to_date;
        this.payload.from_date = this.from_date;
        var { page, rowsPerPage } = this.pagination;
        if (rowsPerPage == -1) {
          this.payload.take = this.total;
        } else {
          this.payload.take = rowsPerPage;
        }
        if (page != "" || page != undefined || page != null) {
          this.skip = page;
        } else {
          this.skip = 1;
        }
         if(this.appliedfilter.agent){
      this.payload.agent=this.appliedfilter.agent.toString();
        }
        this.payload.skip = (this.skip - 1) * this.payload.take;
        return new Promise((resolve) => {
          var { descending } = scope.pagination;
          this.payload.descending = descending;
          scope.$store
            .dispatch("watsAppbyAgent", this.payload)
            .then((response) => {
              this.page.isFilterOpen = false;
              if(response.response.status === 204) {
                this.$store.commit('WatsAppReport',[]);
              }
              else {
                scope.page.loading = false;
                if (scope.page_flag) {
                  this.pagination.page = 1;
                  scope.page_flag = false;
                }
                scope.total = response.total;
                scope.watsappTotal = scope.total;
                resolve(scope.total);
              }
              if (response.response.status === 401) {
                scope.$store.dispatch("checkingtoken");
              }
            });
        });
      },
      getDataFrom() {
        this.skip = 0;
        this.getDataFromApi();
        this.page_flag = true;
      },
  
      export(option) {
        this.excel_data = [];
        this.txt_data = [];
        var add_details = {
          from_date: this.payload.from_date,
          to_date: this.payload.to_date,
          watsAppAgentDetail: "watsAppAgentDetail",
        };
        this.$store.dispatch("exportdatafetch", add_details).then((response) => {
          this.exports = response;
          console.log(response)
          for (var i = 0; i < this.exports.length; i++) {
            this.excel_data.push({
              Date: response[i].Date,
              Agent: response[i].agent,
              open: response[i].count.wa_count ?response[i].count.wa_count:0,
  
            });
            this.txt_data.push({
              Date: response[i].Date,
              Agent: response[i].agent,
              open: response[i].count.wa_count ?response[i].count.wa_count:0,
            });
          }
          if (option == "Txt(Tab-Delimited)") {
            let txtContent = "";
            let txt_key = [
             "Date",
             "Agent",
              "Count"
            ];
            let txt_tab = txt_key.join("\t");
            txtContent += `${txt_tab} \r\n`;
            this.txt_data.forEach(function (rows) {
              let data = Object.values(rows);
              let row = data.join("\t");
              txtContent += row + "\r\n";
            });
            var date2 = new Date();
            date2 = moment(date2).format("YYYY-MM-DD hh-mm");
            var encodedUri1 =
              "data:text/plain;charset=utf-8,\uFEFF" +
              encodeURIComponent(txtContent);
            var link1 = document.createElement("a");
            link1.setAttribute("href", encodedUri1);
            link1.setAttribute("download", "watsappagentreport" + date2 + ".txt");
            document.body.appendChild(link1);
            link1.click();
          }
          if (option == "CSV") {
            let csvContent = "";
            csvContent +=
               "Date,Agent,Count\r\n";
            this.excel_data.forEach(function (rows) {
              let data = Object.values(rows);
              let data1 = [];
              data.forEach((elm) => {
                if (typeof elm === "string") {
                  data1.push(elm.replace(/'|"|#|\n/g, " "));
                } else {
                  data1.push(elm);
                }
              });
  
              for (let l = 0; l < data1.length; l++) {
                if (data1[l] != null && data1[l] != undefined && data1[l] != "" && typeof data1[l] == "string") {
                  if (data1[l].includes(",")) {
                    data1[l] = data1[l].replaceAll(",", ";");
                  }
                }
              }
              let row = data1.join(",");
              csvContent += row + "\r\n";
            });
            var date11 = new Date();
            date11 = moment(date11).format("YYYY-MM-DD hh-mm");
            const encodedUri =
              "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "watsappagentreport_" + date11 + ".csv");
            document.body.appendChild(link);
            link.click();
          }
        });
      },
    }
  };
  </script>