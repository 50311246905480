<template>
    <div class="main_containers_cls">
        <div class="insertflex1">
            <div class="profil_pics">
                <div v-if="page_===false" class="profil_txts">
                    {{ icon }}
                </div>
                <div v-if="page_===true" class="profil_txts">
                    {{ model.charAt(0) }}
                </div>
                <div v-if="page_===false" class="v-toolbar__title primary--text" style="margin-top:8px">{{
                    templet_name.toUpperCase() }}
                </div>


                <div v-if="page_===true" class="v-toolbar__title primary--text" style="margin-top:8px">{{ "NEW " + model
                    .toUpperCase() }}
                </div>
            </div>
            <div>
                <v-btn icon color="primary" flat @click="back_insert()">
                    <i class="fa fa-arrow-left savefont" aria-hidden="true"></i>
                </v-btn>
                <v-btn icon color="primary" flat @click="savepopups()">
                    <i class="fa fa-floppy-o savefont" aria-hidden="true"></i>
                </v-btn>
            </div>

        </div>
        <div class="insert_body">
            <div v-if="page_" class="v-toolbar__title  titlecls "
                style="border-radius:10px;margin-right: 10px;margin-left: 16px;font-size: medium;color:#ffffff;padding:10px 15px 10px 15px;text-align:center;background-color: rgb(33, 150, 243);">
                CREATE {{ model }}
            </div>
            <div v-else class="v-toolbar__title  titlecls "
                style="border-radius:10px;margin-right: 10px;margin-left: 16px;font-size: medium;color:#ffffff;padding:10px 15px 10px 15px;text-align:center;background-color: rgb(33, 150, 243);">
                CREATE LEAD
            </div>
            <div class="loading_style">
                <!-- {{savepopupin}}{{contactnumid}}{{contactalldata}} -->
                <Preview :requiredfield="required" :insertsavepopup="savepopupin" :model="model" :page_="page_"
                    :contactnumid="contactnumid" :contactalldata="contactalldata"
                    @insertleads="(msg) => child_msg = msg" @insertnotification="lead_notification($event)"
                    @Supportmsg="Supportmsg" @formpopup="formpopup($event)">
                </Preview>
            </div>
        </div>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog persistent v-model="save_popup" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title v-if="model===`LEAD`">Create Lead</v-toolbar-title>
                    <v-toolbar-title v-if="model===`TICKET`">Create Ticket</v-toolbar-title>
                    <v-toolbar-title v-if="model===`CONTACT`">Create Contact</v-toolbar-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text v-if="model===`LEAD`">Are you sure to create this Lead ?</v-card-text>
                        <v-card-text v-if="model===`TICKET`">Are you sure to create this Ticket ?</v-card-text>
                        <v-card-text v-if="model===`CONTACT`">Are you sure to create this Contact ?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="cancelEditRules()">Cancel</v-btn>
                    <v-btn color="primary" @click="saveEditRules()">Yes, Create !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="errormsg.errorpopup" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title v-if="model===`LEAD`">Fields Required Error</v-toolbar-title>
                    <v-toolbar-title v-if="model===`TICKET`">Ticket Required Error</v-toolbar-title>
                    <v-toolbar-title v-if="model===`CONTACT`">Contact Required Error</v-toolbar-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text
                            v-if="model===`LEAD` && errormsg.msg.errorpopup_common == true && errormsg.msg.errorpopup_datetime == true">
                            <p style="font-size: medium" v-for="(data,i) in errormsg.msg.datetimevalidation_data"
                                :key="i">
                                {{ i+1 + `. ${data} is required`}}
                            </p>
                            <p style="font-size: medium">
                                {{`Fill required Fields`}}
                            </p>
                        </v-card-text>
                        <v-card-text
                            v-if="model===`LEAD` && errormsg.msg.errorpopup_common == true && errormsg.msg.errorpopup_datetime == false">
                            <p style="font-size: medium">
                                {{`Fill required Fields`}}
                            </p>

                        </v-card-text>
                        <v-card-text
                            v-if="model===`LEAD` && errormsg.msg.errorpopup_common == false && errormsg.msg.errorpopup_datetime == true">
                            <p style="font-size: medium" v-for="(data,i) in errormsg.msg.datetimevalidation_data"
                                :key="i">
                                {{ i+1 + `. ${data} is required`}}
                            </p>

                        </v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="closeErrPopup">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent :value="confirmDialog" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Confirm Action</v-toolbar-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure you want to go back?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="cancelBack">Cancel</v-btn>
                    <v-btn color="primary" @click="confirmBack">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Preview from '../../views/CRM/custom-form/Preview.vue';
export default {
    props: {
        page_: {
            default: false,
            type: Boolean
        },
        model:{
            default:"LEAD"
        }
    },
    components: {
        Preview
    },
    data() {
        return {
            confirmDialog: false,
            savepopupin: false,
            child_msg: false,
            main_data: [],
            apifield: [],
            apidata: [],
            save_popup: false,
            time: null,
            menu2: false,
            modal2: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            items: [1, 2, 3],
            arr: [],
            value: '',
            templet_name: "",
            icon: "",
            check_arr: [],
            longtxt_arr: [],
            others_arr: [],
            link_check_arr: [],
            link_longtxt_arr: [],
            link_others_arr: [],
            demo: {},
            assign_mul_select: "",
            agents: [],
            add_list: [],
            required: [],
            count: 0,
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            err_arr: [],
            contactalldata: [],
            contactnumid: [],
            errormsg:{errorpopup:false,msg:{}}
        }
    },
    mounted() {
         // var scope = this;
        // let stats = {
        //     list_name: "",
        //     list_campaign: "",
        //     list_status: "Y",
        // };
        // scope.$store.dispatch("FilterAsterList", stats).then((response) => {
        //     let data = response.data;
        //     let list = [];
        //     if (data !== undefined) {
        //         for (let i = 0; i < data.length; i++) {
        //             list.push(data[i].list_id.toString());
        //         }
        //     }
        //     scope.add_list = list;
        // });
        // if (this.forms[0].type == "Support") {
        //     this.$store
        //         .dispatch("GetContactDetails")
        //         .then((res) => {
        //             let contactnumiddata = []
        //             this.contactalldata = res.data.map(elm => {
        //                 contactnumiddata.push({ id: elm.id, contactNumber: elm.contactNumber })
        //                 return elm.contactNumber
        //             })
        //             this.contactnumid = contactnumiddata
        //         })
        // }
        this.agents = JSON.parse(localStorage.getItem("agent_emails"));
        this.mount()
    },
    watch: {
        child_msg() {
            if (this.child_msg === true) {
                this.$emit("customform", false);
                this.savepopupin = true;
            }
        }
    },
    computed: {
        ...mapGetters(["forms"]),
    },
    methods: {
        lead_notification(val){
            this.$emit('insertleadssnack', val)
        
        },
        apifield_five() {
            return this.apifield_link5 = this.apifield[3].find(res => {
                return res.visible === true;
            })
        },
        getChildProperties(value) {
            if (value !== undefined) {
                const scope = this;

                for (let j = 0; j < scope.apifield.length; j++) {
                    const element = scope.apifield[j];
                    element.forEach(res => {
                        if (res.is_link === true && res.link_type === 'Parent') {


                            for (let i = 0; i < scope.apifield.length; i++) {
                                const element = scope.apifield[i];
                                element.find(elm => {
                                    if (res.model === elm.linked_to) {

                                        if (elm.fieldType === 'SelectList' || elm.fieldType === 'RadioButton') {

                                            let arr = [];
                                            let val = elm.link_property[value].split(',');

                                            val.forEach(res => {
                                                arr.push({ "name": res, "value": res })
                                            })

                                            elm.values = arr;
                                        } else {

                                            let val = elm.link_property;
                                            if (val === value) {
                                                elm.visible = true;
                                            } else {

                                                elm.visible = false;
                                            }
                                        }

                                    }
                                })
                            }

                        }
                    })

                }
            }

        },
        mount() {
            console.log("called_leadcreate")
            let obj = JSON.parse(localStorage.templetname)
            this.templet_name = obj.templetname
            this.icon = this.templet_name.split('')[0].toUpperCase()
          if(this.page_===false) { 
            // this.$store
            //     .dispatch("GetFormFields", obj.templetname)
            //     .then(() => {

                    let localform = JSON.parse(localStorage.getItem("forms"))
                    let check_bts = []
                    let longtxt = []
                    let others = []
                    let link_data = []
                    let link_longtext_data = []
                    let link_check = []
                    let catagory=[]
                    for (let i = 0; i < localform[0].fields.length; i++) {
                        const element = localform[0].fields[i];
                        catagory.push(element.catagory_value)
                        if (element.required === true) {
                            this.required.push(element.model)
                        }
                        let get_dataa = {}
                        let link_get_dataa = {}
                        if (element.model !== "lead_id" && element.model !== "user2" && element.model !== "ticket_id" && element.model !== "order_id" && element.model !== "list_id2" && element.model !== "hopper_status") {
                            if (element.fieldType === 'Checkbox') {

                                element.values.forEach(res => {
                                    res.checked = false;
                                })


                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;

                                if (element.is_link && element.link_type === 'Child') {
                                    link_check.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = false;
                                    this.link_check_arr.push(link_get_dataa)
                                } else {
                                    check_bts.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = false;
                                    this.check_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'RadioButton') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                check_bts.push(element)
                                get_dataa[`name`] = element.model;
                                get_dataa[`value`] = "";
                                this.check_arr.push(get_dataa)
                            } else if (element.fieldType === 'LongTextInput') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;
                                if (element.is_link && element.link_type === 'Child') {
                                    link_longtext_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = "";
                                    this.link_longtxt_arr.push(link_get_dataa)
                                } else {
                                    longtxt.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = "";
                                    this.longtxt_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'DatePicker' || element.fieldType === "TimePicker") {

                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['isActive'] = false;
                                element['visible'] = false;
                                if (element.is_link && element.link_type === 'Child') {
                                    link_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = "";
                                    this.link_others_arr.push(link_get_dataa)
                                } else {
                                    others.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = "";
                                    this.others_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'DatetimePicker') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;
                                if (element.is_link && element.link_type === 'Child') {
                                    link_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = '';
                                    this.link_others_arr.push(link_get_dataa)
                                } else {
                                    others.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = '';
                                    this.others_arr.push(get_dataa)
                                }

                            } else if (element.fieldType === 'SelectList') {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }
                                element['visible'] = false;
                                others.push(element)
                                get_dataa[`name`] = element.model;
                                get_dataa[`value`] = "";
                                this.others_arr.push(get_dataa)
                            } else {
                                if (element.isPlaceholderVisible === false) {
                                    element.placeholder = element.label
                                }

                                element['visible'] = false;

                                if (element.is_link && element.link_type === 'Child') {
                                    link_data.push(element)
                                    link_get_dataa[`name`] = element.model;
                                    link_get_dataa[`value`] = "";
                                    this.link_others_arr.push(link_get_dataa)
                                } else {
                                    others.push(element)
                                    get_dataa[`name`] = element.model;
                                    get_dataa[`value`] = "";
                                    this.others_arr.push(get_dataa)
                                }


                            }
                        }
                        this.apifield.push(others)
                        this.apifield.push(check_bts)
                        this.apifield.push(longtxt)
                        this.apifield.push(link_data)
                        this.apifield.push(link_longtext_data)
                        this.apifield.push(link_check)
                        
                    }

                // })
            }

        },
        radiofun(val, index) {
            let assign_radio = this.check_arr[index]
            assign_radio.value = val
            this.getChildProperties(val)

        },
        link_checkfun(val, index) {
            let assign_check = this.link_check_arr[index]
            assign_check.value = val
        },
        checkfun(val, index) {
            let assign_check = this.check_arr[index]
            assign_check.value = val
        },
        mul_select(i) {
            this.assign_mul_select = i;
        },
        savepopups() {
      this.save_popup = true;
      this.sidebar_enable_disable(true); // Disable the sidebar
    },
    saveEditRules() {
      this.savepopupin = true;
      this.save_popup = false;
      this.closePopupAndEnableNavbar('save_popup'); // Close popup and enable navbar
    },
    closeErrPopup() {
      this.errormsg.errorpopup = false;
      this.sidebar_enable_disable(false); // Enable the sidebar
    },
    cancelEditRules() {
      this.savepopupin = false;
      this.save_popup = false;
      this.closePopupAndEnableNavbar('save_popup'); // Close popup and enable navbar
    },
    back_insert() {
      this.confirmDialog = true;
      this.sidebar_enable_disable(true); // Disable the sidebar
    },
    cancelBack() {
      this.confirmDialog = false;
      this.closePopupAndEnableNavbar('confirmDialog'); // Close popup and enable navbar
    },
    confirmBack() {
      this.confirmDialog = false;
      this.$emit("customform", false);
      this.savepopupin = true;
      this.sidebar_enable_disable(false); // Enable the sidebar
    },
    formpopup(data) {
      this.errormsg.msg = data;
      this.errormsg.errorpopup = true;
      this.savepopupin = false;
      this.sidebar_enable_disable(true); // Disable the sidebar
    },
    closePopupAndEnableNavbar(popupName) {
      this[popupName] = false;
      this.sidebar_enable_disable(false); // Enable the sidebar
    },
    sidebar_enable_disable(data) {
      this.$root.$emit('sidebar_disable', data);
    }
  ,
    showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        Supportmsg(msg) {
            this.showSnackBar(msg)
            this.savepopupin = false;
        },
    },
};
</script>

<style>
.profil_pics {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.profil_txts {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: 'Roboto Slab', serif;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%), 0 13px 24px -11px rgb(33 150 243 / 60%);
}

/* .main_containers_cls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
} */

.container_flex {
    display: grid;
    grid-template-columns: auto auto auto;
}

.insertflex2 {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;

}

.main_form_insert {
    display: flex;
    flex-direction: column;
    border: 1px solid #cacaca;
    border-radius: 5px;
}

.insertflex1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 55px;
    padding: 2px 10px 0px 25px;
    margin-bottom: 10px;

}

.insertflex3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
}

.radiorow {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 26px;
    align-items: baseline;
}

.savefont {
    font-size: 20px;
}

.loading_style {
    /* min-height: 460px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>