import config from "@/config/index";
import api from "../../utils/axios-util";
import * as mutation_type from "../mutation-types";
const state = config.AsterList;
var getters = {
    AsterList: state => state.AsterList.getdata
};
const mutations = {
    [mutation_type.AsterList]: (state, response) => {
        state.AsterList.getdata = response;
    },
    [mutation_type.CommitNothing]: () => {
    }
};
const actions = {
    GetAsterList: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
            try {
                let filter = ""
                if (query.list_name) {
                    filter += `&name=${query.list_name}`
                }
                if (query.list_campaign) {
                    filter += `&campaign=${query.list_campaign}`
                }
                if (query.list_status) {
                    filter += `&active=${query.list_status}`
                }
                if (query.team) {
                    filter += `&team=${query.team}`
                }
                api.get(`/aster/list?skip=${query.skip}&take=${query.take}` + filter).then(response => {
                    const List = response.data;
                    commit(mutation_type.AsterList, List);
                    resolve(response.data);
                });
            } catch (error) {
                commit(mutation_type.CommitNothing, {});
                reject(error);
            }
        });
    },
    FilterAsterList: ({ commit }, query) => {
        return new Promise((resolve, reject) => {
             let take = 1000
           
            let url
            if (query.template) {
                url = `/aster/list?name=${query.list_name}&campaign=${query.list_campaign}&active=${query.list_status}&template=${query.template}`
            } else {
                url = `/aster/list?name=${query.list_name}&campaign=${query.list_campaign}&active=${query.list_status}&take=${take}`
            }
            try {
                api.get(url).then(response => {
                    const campaign = response.data;
                    commit(mutation_type.AsterList, campaign);
                    resolve(response.data);
                });



            } catch (error) {
                reject(error);
            }
        });
    },
    GetCampaigns: () => {
        return new Promise((resolve, reject) => {
            try {
                let take = {
                    take: ''
                }
                api.get(`/aster/campaigns?take=${take.take}`).then(response => {
                    let data = response.data.data
                    let campaign = []
                    for (let i = 0; i < data.length; i++) {
                        campaign.push(data[i].campaign_name);
                    }
                    resolve(campaign);
                });
            } catch (error) {
                reject(error);
            }
        });

    },
    insertAsterList: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                if (payload.active.name) {
                    payload.active = "Y";
                } else {
                    payload.active = "N";
                }
                api
                    .post(`/aster/list`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteAsterList: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/aster/list/${payload}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAsterList: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id;
                api
                    .put(`/aster/list/${id}`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateAsterListrecyclecount: ({ commit }, id) => {
        return new Promise((resolve, reject) => {
            try {
                // const id = payload.id;
                // delete payload.id;
                api
                    .put(`/aster/lists/recyclecount/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    downloadlistdata: ({ commit }, id) => {
        return new Promise((resolve, reject) => {
            try {
                // const id = payload.id;
                // delete payload.id;
                api
                    .get(`/aster/lists/download/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    listreset: ({ commit }, id) => {
        return new Promise((resolve, reject) => {
            try {
                // const id = payload.id;
                // delete payload.id;
                api
                    .put(`/aster/lists/listreset/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    bufferreset: ({ commit }, id) => {
        return new Promise((resolve, reject) => {
            try {
                // const id = payload.id;
                // delete payload.id;
                api
                    .put(`/aster/lists/bufferreset/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    token: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                api
                    .post(`/api/token`, payload)
                    .then(response => {
                        // console.log(response,"response")
                        resolve(response);
                    })
                    .catch(function (error) {
                        commit(mutation_type.CommitNothing, {});
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },


};
export default {
    state,
    getters,
    mutations,
    actions
};