<template>
  <div id="LiveAgents">
    <!-- <section-definition :title="page.title" :icon="page.icon" :extraOptions="page.options" :breadcrumbs="page.breadcrumbs" @openFilter="openLiveCallsFilter" @refreshPage="refreshLiveCallsPage"></section-definition>
            <loader v-if="page.loading"></loader>-->
    <section-definition :title="page.title" :icon="page.icon" @toggleClicks="toggleClicks" :extraOptions="page.options"
      :breadcrumbs="page.breadcrumbs" @openFilter="openLiveAgentsFilter" @refreshPage="refreshLiveAgentsPage"
      :style="styleObject"></section-definition>
    <loader v-if="livecallsLoaders"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="Live_calls_response" class="elevation-1" :pagination.sync="pagination"
        :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]" sort-desc="true">
        >
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.queue_enter_time }}</td>
          <td class="checkEmpty p0">{{ props.item.queue }}</td>
          <td class="checkEmpty p0">{{ masking ? ChartoMaskingChar(props.item.phone_number) : props.item.phone_number }}</td>
          <td class="checkEmpty p0">{{ props.item.call_type }}</td>
          <td class="checkEmpty p0">{{ props.item.agent }}</td>
          <td class="checkEmpty p0">{{ props.item.station }}</td>
          <td class="checkEmpty p0">{{ props.item.answer_time.slice(11) }}</td>
          <td class="checkEmpty p0">{{ props.item.status }}</td>
        </template>
      </v-data-table>
    </v-container> 
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color:white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-text-field v-model="payloadnumber" label="Phone Number" small></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.agent" :items="filteragents" label="Agent" persistent-hint small multiple
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.Queue" :items="Queue_dropdown" label="Queue" persistent-hint small multiple
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
          <!-- <div class="autocompleteDiv">
                        <v-autocomplete v-model="payload.Campaign" :items="campaign" label="Campaign" persistent-hint small multiple single-line :allow-overflow="false">
                        </v-autocomplete>
          </div>-->
          <div class="autocompleteDiv">
            <v-text-field v-model="payload.Extension" :items="Extension_dropdown" label="Station" small></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.Type" :items="Type" label="Call Type" persistent-hint small multiple
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.Status" :items="Status" label="Status" persistent-hint small multiple
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="restvalues()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="text_filter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
//import { authUser } from "@/data/dummyData";
import { mapGetters } from "vuex";
import loader from "@/components/Widgets/loader";
import { MaskCharacter } from "@/utils/helpers";
export default {
  components: {
    loader,
  },
  data() {
    return {
      styleObject: {
        height: "70px",
        "margin-top": "-10px",
      },
      page: {
        title: "Live Calls",
        icon: "fa-headset fa-2x",
        loading: true,
        isFilterOpen: false,
        bargeType: "",
        confirmDialog: false,
        loadingDialog: false,
        errorlogout: false,
        successLogout: false,
        extension: "",
        barge: { error: false, message: "", minimized: false },
        argument: "",
        payloadqueue: "",
        payloadextension: "",
        payloaduser: "",
        breadcrumbs: [],
        options: { filter: true, refresh: true, toggleButtonss: true },
      },
      pagecount: 0,
      tableLoading: false,
      payload: {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      },
      startup_payload: {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      },
      payloadnumber: "",
      model: "",
      livecallsLoaders: "",
      bargingAgent: {},
      forcelogoutItem: {},
      refreshvalues: "",
      headers: [
        {
          text: "Call Date",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue_enter_time",
          masking:false
        },
        {
          text: "Queue",
          align: "left",
          sortable: false,
          class: "p0",
          value: "queue",
          masking:false
        },
        {
          text: "Phone Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "phone_number",
          masking:false
        },
        {
          text: "Call Type",
          align: "left",
          sortable: false,
          class: "p0",
          value: "call_type",
          masking:false
        },
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "agent",
          masking:false
        },
        // { text: "Campaign", align: "left", sortable: false, value: "camapign" },
        {
          text: "Station",
          align: "left",
          sortable: false,
          class: "p0",
          value: "extension",
          masking:false
        },
        {
          text: "Answer Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "answer_time",
          masking:false
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          class: "p0",
          value: "status",
          masking:false
        },
      ],
      liveagents: [],
      filteragents: [],
      livecall: "",
      loader: "",
      t: "",
      livecalls_clear: true,
      pagination: {
        rowsPerPage: -1,
      },
      Filename:"LiveCalls",
      masking:false
    };
  },
  computed: {
    ...mapGetters([
      "Live_calls_response",
      "Queue_dropdown",
      "Extension_dropdown",
      "Type",
      "Status",
      "filters",
      "Queue",
      "campaign",
      "livecallsLoader",
    ]),
  },
  beforeDestroy() {
    var scope = this;
    scope.campaign_clear = false;
    scope.livecalls_clear = false;
    this.$store.commit("FILTERLIVEAGENT", { agent: "" });
    this.$store.commit("FILTERLIVEQUEUE", { Queue: "" });
    this.$store.commit("FILTERLIVECAMPAIGN", { Campaign: "" });
    this.$store.commit("FILTERLIVEEXTENSTENSION", { Extension: "" });
    this.$store.commit("FILTERLIVETYPE", { Type: "" });
    this.$store.commit("FILTERLIVESTATUS", { Status: "" });
    this.$store.commit("FILTERLIVENUMBERS", { number: "" });
  },
  methods: {
    toggleBargeSheet: function (event, item) {
      if (event == "close") {
        this.page.bargestatus = false;
        this.page.isPlaying = false;
        this.page.bargeType = "";
        this.page.extension = "";
        this.bargingAgent = item;
      } else {
        this.page.bargestatus = true;
        this.page.isPlaying = false;
        this.page.bargeType = event;
        this.page.extension = "";
        this.bargingAgent = item;
      }
    }, toggleClicks: function (option) {
      this.$emit("toggleSubDrawer", option);

    },
    text_filter() {
      var scope = this;
      this.$store.commit("FILTERLIVEAGENT", { agent: scope.payload.agent });
      this.$store.commit("FILTERLIVEQUEUE", { Queue: scope.payload.Queue });
      this.$store.commit("FILTERLIVECAMPAIGN", {
        Campaign: scope.payload.Campaign,
      });
      this.$store.commit("FILTERLIVEEXTENSTENSION", {
        Extension: scope.payload.Extension,
      });
      this.$store.commit("FILTERLIVETYPE", { Type: scope.payload.Type });
      this.$store.commit("FILTERLIVESTATUS", { Status: scope.payload.Status });
      if (this.payloadnumber != "") {
        scope.payload.number = scope.payloadnumber;
      } else {
        this.$store.commit("FILTERLIVENUMBERS", {
          number: scope.payload.number,
        });
      }
      this.$store.commit("FILTERLIVENUMBER", this.payload);
      var string_data = JSON.stringify(scope.payload);
      scope.startup_payload = JSON.parse(string_data);
      scope.livecalls();
    },
    livecalls() {
      var scope = this;
      //scope.$store.dispatch("reportuserlist").then((response) => {
      // let dataa = JSON.parse(localStorage.getItem("group_users"));
      // scope.payload.agent = dataa;
      this.$store
        .dispatch("livecalls", this.startup_payload)
        .then(() => {
          if (scope.refreshvalues == "1") {
            scope.livecallsLoaders = false;
          }
          if (scope.livecalls_clear) {
            this.timeout_campaign = setTimeout(function () {
              scope.livecalls();
            }, 5000);
          }
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
      //});
    },
    queuerecords() {
      this.$store.dispatch("queuerecords", this.payloadqueue);
    },
    extensionrecords() {
      this.$store.dispatch("extensionrecords", this.payloadextension);
    },
    // agent() {
    //   this.$store.dispatch("user", this.payloaduser);
    // },
    changeBargeType: function (event) {
      this.page.bargeType = event;
    },
    canBarge: function (item) {
      if (item.status != "Idle") {
        return false;
      } else {
        return true;
      }
    },
    forceLogout(item) {
      this.forcelogoutItem = item;
      this.page.confirmDialog = true;
    },
    restvalues() {
      this.payloadnumber = "";
      this.payload.number = "";
      this.payload.agent = "";
      this.payload.Queue = "";
      this.payload.Campaign = "";
      this.payload.Extension = "";
      this.payload.Type = "";
      this.payload.Status = "";
      this.startup_payload = {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      };
      this.$store.commit("FILTERLIVEAGENT", { agent: "" });
      this.$store.commit("FILTERLIVEQUEUE", { Queue: "" });
      this.$store.commit("FILTERLIVECAMPAIGN", { Campaign: "" });
      this.$store.commit("FILTERLIVEEXTENSTENSION", { Extension: "" });
      this.$store.commit("FILTERLIVETYPE", { Type: "" });
      this.$store.commit("FILTERLIVESTATUS", { Status: "" });
      this.$store.commit("FILTERLIVENUMBERS", { number: "" });
      this.livecalls();
    },
    okConfirm() {
      this.page.confirmDialog = false;
      this.page.loadingDialog = true;
      var scope = this;
      setTimeout(
        function () {
          scope.page.loadingDialog = false;
          scope.page.successLogout = true;
          setTimeout(
            function () {
              scope.page.successLogout = false;
            }.bind(scope),
            4000
          );
        }.bind(scope),
        2000
      );
    },
    cancelConfirm() {
      this.forcelogoutItem = {};
      this.page.confirmDialog = false;
    },
    startBargein: function () {
      var scope = this;
      if (this.page.extension != "") {
        this.page.isPlaying = true;
      } else {
        scope.page.barge.message = "Please Choose the Extension";
        scope.page.barge.error = true;
        setTimeout(
          function () {
            scope.page.barge = { error: false, message: "" };
          }.bind(scope),
          4000
        );
      }
    },
    openLiveAgentsFilter: function () {
      this.page.isFilterOpen = true;
    },
    refreshLiveAgentsPage: function () {
      var scope = this;
      scope.livecallsLoaders = true;
      this.restvalues();
      scope.refreshvalues = "1";
    },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    }
  },
  mounted() {
    let scope = this
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:view"
          ) !== -1
        ) {
          scope.masking = true;
        }
      }.bind(scope),
      1
    );
    this.livecallsLoaders = false;
    this.filteragents = JSON.parse(localStorage.getItem("agent_emails"));
    this.livecalls();
    this.queuerecords();
    this.extensionrecords();
    // this.agent();
  },
};
</script>

<style>
.icons {
  height: 80px;
}
</style>
