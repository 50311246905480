<template>
  <div id="LiveAgents">
    <section-definition :title="page.title" :icon="page.icon" :extraOptions="page.options" @toggleClicks="toggleClicks"
      :breadcrumbs="page.breadcrumbs" @openFilter="openLiveAgentsFilter" @refreshPage="refreshLiveAgentsPage"
      :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="Live_agents_response" :pagination.sync="pagination"
        :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]" class="elevation-1" sort-desc="true">
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.user }}</td>
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.team === null ?'-': props.item.team }}</td>
          <td class="checkEmpty p0">{{ props.item.queue }}</td>
          <td class="checkEmpty p0">{{ props.item.call_type }}</td>
          <td class="checkEmpty p0">{{ masking ? ChartoMaskingChar(props.item.phone_number) : props.item.phone_number  }}</td>
          <td class="checkEmpty p0">{{ props.item.station }}</td>
          <td class="checkEmpty p0">{{ props.item.campaign }}</td>
          <td class="  checkEmpty p0">
            <div :class="[props.item.dur>'00:05:00' &&  props.item.status==='READY' ?'waitingforcallmore5' :
            props.item.dur>'00:01:00' &&  props.item.status==='READY' ? 'waitingforcallmore1' : 
             props.item.status==='READY' ? 'waitingforcall' :

             props.item.dur>'00:05:00' && props.item.status==='CONNECT' ? 'oncallmore5' :
            props.item.dur>'00:01:00' &&  props.item.status==='CONNECT' ? 'oncallmore1':
            props.item.dur>'00:00:10' && props.item.status==='CONNECT' ? 'oncallmore10sec' :
            
            props.item.status==='DISPO' ? 'dispo' :
            props.item.status==='WA_CHAT' ? 'chat' :

            props.item.dur>'00:15:00' && props.item.status==='PAUSE' ? 'pausemore15' :
            props.item.dur>'00:10:00' &&  props.item.status==='PAUSE' ? 'pausemore10':
            props.item.dur>'00:05:00' && props.item.status==='PAUSE' ? 'pausemore5' :
            props.item.dur>'00:01:00' &&  props.item.status==='PAUSE' ? 'pausemore1':
            props.item.dur>'00:00:10' &&  props.item.status==='PAUSE' ? 'pausemore10sec' :  '']">

              {{ props.item.status }}


              <span v-if="props.item.reason != null && props.item.reason !== ''">
                ({{ props.item.reason }})
              </span>
              <span v-if="props.item.isBreach === true">
                ({{ "Breached" }})
              </span>
            </div>

          </td>
          <td class="checkEmpty p0">{{ props.item.dur }}</td>
          <td class="checkEmpty p0">{{ props.item.dialer_flag == null ? props.item.dialer_flag : props.item.dialer_flag == 0 ?  'READY ' : 'PAUSE'	 }}</td>
          <td class="text-xs-right checkEmpty p0">
            <v-btn small fab :color="canBarge(props.item) ? 'white' : 'success'" flat :disabled="canBarge(props.item) || Live_agent_monitor_bool"
              @click="toggleBargeSheet('listen', props.item)" v-if="checkBargeStatus(props.item.status, props.item.id) &&
                page.canBarge
                ">
              <i class="fas fa-eye"></i>
            </v-btn>
            <v-icon color="blue" small class="mr-4" @click="getUserQueue(props.item)">queue</v-icon>
            <v-btn small fab flat color="error" @click="forceLogout(props.item)" v-if="page.canLogout">
              <i class="fas fa-sign-out-alt"></i>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-bottom-sheet v-model="page.bargestatus" hide-overlay persistent lazy inset max-width="500" class="align-left"
      id="barging-div" transparent>
      <v-card tile class="player-div" v-if="barge_pop.status == 'CONNECT' && barge_pop.flag == '1'">
        <v-toolbar color="primary" dark small>
          <v-toolbar-title small>Monitoring Agent</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click="toggleBargeSheet('close', {})">close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <div class="row align-center justify-sb">
            <div class="row align-center justify-sb">
              <v-avatar slot="activator" size="40">
                <!-- <img :src="authUser.avatar" :alt="authUser.name" /> -->
              </v-avatar>
              <div class="ml-2">
                <div style="font-weight: bold;">{{ page.agent }}</div>
                <div class="bold">{{ forcelogoutItem.user }}</div>
                <div>{{ forcelogoutItem.station }}</div>
              </div>
            </div>
            <div>
              <v-chip :color="page.bargeType == 'listen' ? 'primary' : 'light'"  :class="{'clip-disable' : page.isPlaying && page.bargeType !== 'listen'}"
                :text-color="page.bargeType == 'listen' ? 'white' : 'black'" small @click="changeBargeType('listen')">
                Listen</v-chip>
              <v-chip :color="page.bargeType == 'barging' ? 'primary' : 'light'"   :class="{'clip-disable' : page.isPlaying && page.bargeType !== 'barging'}"
                :text-color="page.bargeType == 'barging' ? 'white' : 'black'" small @click="changeBargeType('barging')">
                Barge</v-chip>
              <v-chip :color="page.bargeType == 'w-barging' ? 'primary' : 'light'"  :class="{'clip-disable' : page.isPlaying && page.bargeType !== 'w-barging'}"
                :text-color="page.bargeType == 'w-barging' ? 'white' : 'black'" small
                @click="changeBargeType('w-barging')">Wisper</v-chip>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row align-center justify-sb">
            <div class="row align-center justify-sb">
              <v-chip color="success" text-color="white">
                <v-avatar>
                  <i class="fas fa-phone-alt"></i>
                </v-avatar>
                {{ page.bargingAgent }}
              </v-chip>
              <v-chip color="secondary" text-color="white" v-if="false">
                <v-avatar>
                  <i class="fas fa-stopwatch"></i>
                </v-avatar>
                {{ page.bargingDur }}
              </v-chip>
            </div>
            <div class="row align-center justify-sb">
              <v-text-field v-model="this.station" :readonly="page.isPlaying"
                return-masked-value mask="###############" label="Extension" small>
              </v-text-field>
              <div v-if="page.isPlaying == true">
                <v-btn icon outline small color="error" @click="stopbarge()">
                  <v-icon small>stop</v-icon>
                </v-btn>
              </div>
              <div v-if="page.isPlaying == false">
                <v-btn icon outline small color="error" @click="startBargein(page)">
                  <v-icon small>play_arrow</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-bottom-sheet>

    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color:white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-text-field v-model="payload.number" label="Phone Number" small></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.agent" :items="filteragents" label="Agent" persistent-hint small multiple
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.Queue" :items="queue" label="Queue" persistent-hint small multiple  item-text="name" item-value="name"
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-text-field v-model="payload.Extension" :items="extension_dropdown" label="Station" small></v-text-field>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.Type" :items="type" label="Call Type" persistent-hint small multiple
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="payload.Status" :items="status" label="Status" persistent-hint small multiple
              single-line :allow-overflow="false"></v-autocomplete>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="restvalues()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fetchFilter()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>

    <v-dialog persistent v-model="showqueue_pop" max-width="500">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Assign Queue</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div v-for="(item, index) in index_" :key="index">
            <div v-if="index_controler[index].key" class="row align-center justify-sb selectbox">

              <v-select @change="assignedval(get_queue_value[index].queue, 'queue', index)" item-text="name"
                item-value="name" class="selectboxfield" :items="queue" label="Queue"
                v-model="get_queue_value[index].queue" item-disabled="active"
                :disabled="index_controler[index].queue_bool"></v-select>

              <v-select @change="assignedval(get_queue_value[index].rank, 'rank', index)" item-text="name"
                item-value="name" v-model="get_queue_value[index].rank" class="selectboxfield" :items="index_"
                label="Rank" item-disabled="active" :disabled="index_controler[index].rank_bool"></v-select>
              <v-icon color="red" class="mt-4 ml-0 remove-icon"
                @click="removeItem(index, get_queue_value[index])">close</v-icon>
            </div>
          </div>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="dialog_footer">
            <div>
              <v-icon class="icon" @click="QueueAssignExtra()" color="primary">mdi-plus
              </v-icon>
            </div>
            <div>
              <v-btn flat="flat" @click="closegetUserQueue">Cancel</v-btn>
              <v-btn color="primary" @click="savegetUserQueue">Continue</v-btn>
            </div>
          </div>


        </v-card-actions>
      </v-card>
    </v-dialog>






    <v-dialog persistent v-model="page.confirmDialog" max-width="500">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Are You Sure To Logout The User?</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div class="row align-center justify-sb">
            <div class="row align-center justify-sb">
              <v-avatar slot="activator" size="40">
                <!-- <img :src="authUser.avatar" :alt="authUser.name" /> -->
              </v-avatar>
              <div class="ml-2">
                <div class="bold">{{ forcelogoutItem.user }}</div>
                <div>{{ forcelogoutItem.station }}</div>
              </div>
            </div>
            <div>
              <v-chip color="secondary" small text-color="white">
                <v-avatar>
                  <i class="fas fa-check-circle"></i>
                </v-avatar>
                {{ forcelogoutItem.status }}
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat="flat" @click="cancelConfirm">Cancel</v-btn>
          <v-btn color="primary" @click="okConfirm(forcelogoutItem)">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="page.loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <div class="alert error" v-if="page.errorlogout">
  <strong>Error!</strong> Error while Logging Out User. Please Try again.
  </div> -->
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <!-- <div class="alert success" v-if="page.successLogout">
  <strong>Success!</strong> User Logout Successfully.
  </div> -->
    <div class="alert error" v-if="page.barge.error">
      <strong>Error!</strong>
      {{ page.barge.message }}
    </div>
  </div>
</template>
<script>
import { authUser } from "@/data/dummyData";
import { mapGetters } from "vuex";
// import liveCalls from '../RealTime/LiveCalls.vue';
import loader from "@/components/Widgets/loader";
import { MaskCharacter } from "@/utils/helpers";
export default {
  components: {
    loader,
  },
  data() {
    return {
      isOnline: navigator.onLine,
      internetlostcount:0,
      user_queueassign: "",
      styleObject: {
        height: "70px",
        "margin-top": "-10px",
        view: true,
      },
      page: {

        title: "Active Agents",
        icon: "fa-user-tie fa-2x",
        loading: true,
        bargestatus: false,
        isPlaying: false,
        isFilterOpen: false,
        viewing: true,
        bargeType: "listen",
        confirmDialog: false,
        loadingDialog: false,
        errorlogout: false,
        successLogout: false,
        extension: "",
        barge: { error: false, message: "", minimized: false },
        breadcrumbs: [],
        options: { filter: true, refresh: true, toggleButtons: true },
        status: "",
        flag: "",
        agent: "",
        barge_flag: "",
        id: "",
        canBarge: false,
        canLogout: false,
      },
      pagecount: 0,
      tableLoading: false,
      extensions: ["101", "102", "103", "104"],
      model: "",
      payloadqueue: "",
      pauseCodeData: '',
      payloadextension: "",
      payloadAvailextension: "",
      payloadCampaign: "",
      payloaduser: "",
      refreshvlaue: "",
      bargingAgent: {},
      forcelogoutItem: {},
      barge_option: "-",
      Live_agents: [],
      headers: [
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "user",
          masking: false
        },
        {
          text: "Agent Name",
          align: "left",
          sortable: false,
          class: "p0",
          value: "user",
          masking: false
        }, {
          text: "Team",
          align: "left",
          sortable: false,
          class: "p0",
          value: "user",
          masking: false
        },
        {
          text: "Queue",
          align: "left",
          sortable: false,
          class: "checkEmpty p0",
          value: "queue",
          masking: false
        },
        {
          text: "Call Type",
          align: "left",
          sortable: false,
          class: "p0",
          value: "call_type",
          masking: false
        },
        {
          text: "Phone Number",
          align: "left",
          sortable: false,
          class: "p0",
          value: "phone_number",
          masking: false
        },
        {
          text: "Station",
          align: "left",
          sortable: true,
          class: "p0",
          value: "station",
          masking: false
        },
        {
          text: "Campaign",
          align: "left",
          sortable: true,
          class: "p0",
          value: "campaign",
          masking: false
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          class: "p0",
          value: "status",
          masking: false
        },
        {
          text: "Duration",
          align: "left",
          sortable: true,
          class: "p0",
          value: "dur",
          masking: false
        },
        {
          text: "Dialer Status",
          align: "left",
          sortable: true,
          class: "p0",
          value: "dialer_flag",
          masking: false
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          class: "p0",
          value: "action",
          masking: false
        },
      ],
      station:"",
      liveagents: [],
      agent_data: [],
      result_id: "",
      barge_pop: {
        status: "",
        flag: "",
        name: "",
      },
      payload: {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      },
      startup_payload: {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      },
      settimeoutstop: false,
      pagination: {
        rowsPerPage: -1,
        sortBy: "name",
        descending: true,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      filteragents: [],
      render: 0,
      queue: [],
      index_: [],
      index_controler: [],

      showqueue_pop: false,
      get_queue_value: [],
      Filename:"LiveAgent",
      masking:false
    };  
  },
  computed: {
    ...mapGetters([
      "Live_agents_response",
      "queue_dropdown",
      "extension_dropdown",
      "availextension_dropdown",
      "type",
      "status",
      "user",
      "queue",
      "campaign",
      "Live_agent_monitor_data",
      "Live_agent_monitor_type",
      "Live_agent_monitor_bool"
    ]),
    authUser() {
      return authUser;
    },
    isbarge: function () {
      var barge = this.page.id;
      if (barge != "" && barge != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "page.status"(newval) {
      this.barge_pop.status = newval;
    },
    "page.flag"(newval) {
      this.barge_pop.flag = newval;
    },
    "page.name"(newval) {
      this.barge_pop.name = newval;
    },
    Live_agents_response: {
      handler: function (val, oldVal) {
        console.log('val', val, oldVal);
        for (let i = 0; i < val.length; i++) {
          let element = val[i];
          console.log('element', element);
          let filter = this.pauseCodeData.filter(res => res.pause_code == element.data1)
          if (filter.length > 0) {
            let a = `${filter[0].timeValue}:00`.split(':')
            var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60
            let dateTime = new Date()
            let sample = dateTime.toString().split(' ')
            let daa = sample[4].split(':')
            var totalTime = (+daa[0]) * 60 * 60 + (+daa[1]) * 60 + (+daa[2]);
            console.log('seconds', seconds, 'totalTime', totalTime);
            if (totalTime > seconds) {
              console.log('true', element);
              element['isBreach'] = true
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    updateOnlineStatus() {
      let scope = this
      this.isOnline = navigator.onLine;
      if(!this.isOnline){
        this.internetlostcount++
        if(scope.page.id != ""){
            scope.page.flag = 0;
            scope.page.agent = "";
            scope.page.id = "";
            if(scope.Live_agent_monitor_bool){
                  scope.toggleBargeSheet('close', {})
            }
          }
      }
    },
    stopbarge(){
     this.page.isPlaying = false
     this.$store.dispatch("CallActionflag", false);
     this.$store.dispatch("LiveAgentMontBool", false);
     this.$store.state.session.terminate()
    },
    assignedval(value, type, index) {
      if (type === "queue") {
        this.index_controler[index].queue_bool = true
        for (let i = 0; i < this.queue.length; i++) {
          const element = this.queue[i];
          if (element.name === value) {
            element.active = true
          }
        }
      }
      if (type === "rank") {
        this.index_controler[index].rank_bool = true
        for (let i = 0; i < this.index_.length; i++) {
          const element = this.index_[i];
          if (element.name === value) {
            element.active = true
          }

        }
      }

    },

    removeItem(val, data) {
      // console.log(data, "jhsckjsdkjvsdkjdfkjfsfbcshbcfs")

      for (let index = 0; index < this.queue.length; index++) {
        const element = this.queue[index];

        if (element.name === data.queue) {
          console.log(data, "jhsckjsdkjvsdtttttttkjdfkjfsfbcshbcfs", element)
          element.active = false
          this.get_queue_value[val].queue = ""
        }

      }
      for (let index__ = 0; index__ < this.index_.length; index__++) {
        const element = this.index_[index__];
        if (element.name === data.rank) {
          element.active = false
          this.get_queue_value[val].rank = ""
        }

      }
      this.index_controler[val].rank_bool = false
      this.index_controler[val].queue_bool = false
      // if (val !== 0) {
      // this.index_controler[val].key = false
      // }

    },
    QueueAssignExtra() {
      let count = 0
      let indexval = ""
      for (let i = 0; i < this.index_controler.length; i++) {
        const element = this.index_controler[i];
        if (element.key === false) {
          count++
        }
        if (count === 1 && element.key === false) {

          indexval = i
        }
      }

      if (indexval !== "") {

        this.index_controler[indexval].key = true
      }

      console.log(count, "index_controler")
    },
    savegetUserQueue() {
      let queue_ = []
      for (let i = 0; i < this.get_queue_value.length; i++) {
        const element = this.get_queue_value[i];
        if (element.rank !== "" && element.queue == "") {
          this.showSnackBar(" Queue Not Assigned.");
        } else if (element.rank == "" && element.queue !== "") {
          this.showSnackBar(" Rank Not Assigned.");
        } else if (element.rank !== "" && element.queue !== "") {
          queue_.push(element.queue)
        }

      }
      let value = queue_.toString()
      if (value !== "") {
        this.$store
          .dispatch("AssignQueue", { agent: this.user_queueassign, queue: value })
          .then((res) => {

            if (res.data.statusCode === 200) {
              this.closegetUserQueue();
            }
          })
          .catch(function (err) {
            this.showSnackBar(err);
          });
      } else {
        this.showSnackBar(" No Queue Selected.");
      }

    },
    getUserQueue(item) {
      this.showqueue_pop = true
      this.user_queueassign = item.user
    },
    closegetUserQueue() {
      console.log("hdskjhdjkahdkjhasjkdajksdhjkashdjkadjk")
      this.showqueue_pop = false
      let arr = []

      for (let i = 0; i < this.get_queue_value.length; i++) {
        let obj = {
          queue: "",
          rank: ""
        }
        arr.push(obj)
      }
      for (let k = 0; k < this.queue.length; k++) {
        const element = this.queue[k];
        const element1 = this.index_[k];
        element.active = false
        element1.active = false

      }
      for (let j = 0; j < this.index_controler.length; j++) {
        const element = this.index_controler[j];
        element.rank_bool = false
        element.queue_bool = false
        if (j > 0) {
          element.key = false
        }
      }

      this.get_queue_value = arr
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    toggleBargeSheet: function (event, item) {
      if (event == "close") {
        this.$store.dispatch("LiveAgentMontData", {});
        this.$store.dispatch("LiveAgentMontType", '');
        this.$store.dispatch("CallActionflag", false);
        this.$store.dispatch("LiveAgentMontBool", false);
        this.page.bargestatus = false;
        this.page.isPlaying = false;
        this.page.bargeType = "listen";
        this.page.extension = "";
        this.page.bargingAgent = item.station;
        this.page.bargingDur = item.dur;
        this.page.id = "";
        this.page.flag = "";
        this.page.agent = "";
        this.stopbarge();
        // this.props.item.status = "connect";
      } else {
        console.log("LiveAgentMontType", event);
        this.$store.dispatch("LiveAgentMontType", event);
        this.$store.dispatch("LiveAgentMontData", item);
        this.page.bargestatus = true;
        this.page.isPlaying = false;
        this.page.bargeType = event;
        this.page.extension = "";
        this.page.bargingAgent = item.station;
        this.page.bargingDur = item.dur;
        this.page.status = item.status;
        this.page.flag = 1;
        this.page.id = item.id;
        this.page.agent = item.name;
      }
    },
    checkBargeStatus(name, id) {
      var scope = this;
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "realtime:liveagents:barge"
        ) !== -1
      ) {
        if (name == "CONNECT") {
          if (scope.isbarge) {
            if (id == this.page.id) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    fetchFilter() {
      const scope = this;
      scope.page.isFilterOpen = false
      var string_data = JSON.stringify(scope.payload);
      scope.startup_payload = JSON.parse(string_data);
      scope.livaagents();
    },
    livaagents() {
      const scope = this;
      //scope.$store.dispatch("reportuserlist").then((response) => {
      // let dataa = response.toString(",");
      // scope.payload.agent = JSON.parse(localStorage.getItem("group_users"));
      let teams =  JSON.parse(localStorage.getItem("Team"))
      if (teams.length > 0) {
        this.startup_payload.team_name = teams.map(elm => elm.name)
      }
      this.$store
        .dispatch("liveagents", this.startup_payload)
        .then(function (result) {
          var result_data = result.data.data;
          // for (let i = 0; i < result_data.length; i++) {
          // const element = result_data[i].data1;
          // console.log(element);
          // }

          let  count = 0
          scope.agent_data = [];
          scope.Live_agents = [];
          result_data.forEach(function (result) {
            if (result.id == scope.page.id) {
              count = 1
              if (result.status != "CONNECT") {
                scope.page.flag = 0;
                scope.page.agent = "";
                scope.page.id = "";
                if(scope.Live_agent_monitor_bool){
                  scope.toggleBargeSheet('close', {})
                }
              }
            }
          });
          if(result_data.length == 0 || (scope.page.id != "" && count == 0)){
            scope.page.flag = 0;
            scope.page.agent = "";
            scope.page.id = "";
            if(scope.Live_agent_monitor_bool){
                  scope.toggleBargeSheet('close', {})
            }
          }
          if (scope.refreshvlaue == "1") {
            scope.page.loading = false;
          }
          scope.settimeoutstop = true;
          scope.timeOutLiveAgent();
        })
        .catch(function (err) {
          scope.settimeoutstop = true;
          scope.timeOutLiveAgent();
          if (err.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
      //});
    },
    timeOutLiveAgent: function () {
      var scope = this;
      if (scope.settimeoutstop) {
        if (scope.render == 0) {
          this.liveAgentTime = setTimeout(function () {
            scope.livaagents();
          }, 1000);
          scope.render = 1
        } else {
          this.liveAgentTime = setTimeout(function () {
            scope.livaagents();
          }, 4000);
        }
        scope.settimeoutstop = false;
      }
    },
    resetTimeout: function () {
      clearTimeout(this.liveAgentTime);
      this.livaagents();
    },
    // queuelist() {
    // this.$store.dispatch("queuelist", this.payloadqueue);
    // },
    // extensionlist() {
    // this.$store.dispatch("extensionlist", this.payloadextension);
    // },
    toggleClicks: function (option) {
      this.$emit("toggleSubDrawer", option);

    },
    // Availextensionlist() {
    // this.$store.dispatch("Availextensionlist", this.payloadAvailextension);
    // },
    // campaignlist() {
    // this.$store.dispatch("campaignList", this.payloadCamapign);
    // },
    // agentlist() {
    // this.$store.dispatch("userlist", this.payloaduser);
    // },
    changeBargeType: function (event) {
      this.page.bargeType = event;
      this.$store.dispatch("LiveAgentMontType", event);
    },
    canBarge: function (item) {
      if (item.status != "Idle") {
        return false;
      } else {
        return true;
      }
    },
    forceLogout(item) {
      this.forcelogoutItem = item;
      this.page.confirmDialog = true;
    },
    restvalues() {
      this.startup_payload = {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      };
      this.payload = {
        number: "",
        agent: "",
        Queue: "",
        Campaign: "",
        Extension: "",
        Type: "",
        Status: "",
      };
      // this.startup_payload.number = "";
      // this.startup_payload.agent = "";
      // this.startup_payload.Queue = "";
      // this.startup_payload.Campaign = "";
      // this.startup_payload.Extension = "";
      // this.startup_payload.Type = "";
      // this.startup_payload.Status = "";
      this.livaagents();
    },
    okConfirm(forcelogoutItem) {
      this.$store.dispatch("userLogout", forcelogoutItem);
      this.livaagents();
      this.page.confirmDialog = false;
      this.page.loadingDialog = true;
      var scope = this;
      setTimeout(
        function () {
          scope.page.loadingDialog = false;
          scope.page.successLogout = true;
          scope.showSnackBar(" User Logout Successfully.");
          // setTimeout(
          // function () {
          // scope.page.successLogout = false;
          // }.bind(scope),
          // 4000
          // );
        }.bind(scope),
        2000
      );
    },
    cancelConfirm() {
      this.forcelogoutItem = {};
      this.page.confirmDialog = false;
    },
    startBargein: function () {
      var scope = this;
      this.page.extension=scope.station
      if (this.page.extension == "Other") {
        this.page.extension = this.page.other_exten;
      }
      if (this.page.bargeType == "") {
        alert("Please Fill Barge Type");
        this.page.isPlaying = false;
      }
      var barge = {
        bargeType: this.page.bargeType,
        bargeChannel: this.page.bargingAgent,
        bargeVia: this.page.extension,
      };
      if (this.page.extension != "") {
        if (this.page.bargeType != "") {
          this.$store.dispatch("barge", barge);
          this.page.isPlaying = true;
          this.$store.dispatch("LiveAgentMontBool", true);
          this.$store.dispatch("CallActionflag", true);
          // this.$parent.transferSocket(barge)
        } else {
          this.page.isPlaying = false;
        }
      } else {
        if (this.page.extension == "" && this.page.bargeType != "") {
          scope.page.barge.message = "Please Enter the Extension";
          this.page.isPlaying = false;
          this.showSnackBar(this.page.barge.message);
          // scope.page.barge.error = true;
          // setTimeout(
          // function () {
          // scope.page.barge = { error: false, message: "" };
          // }.bind(scope),
          // 4000
          // );
        }
      }
    },

    openLiveAgentsFilter: function () {
      this.page.isFilterOpen = true;
    },
    refreshLiveAgentsPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.restvalues();
      scope.refreshvlaue = "1";
    },
    ChartoMaskingChar(data){
            let scope = this
            if(!scope.$store.getters.MaskType.includes(typeof(data))){
                return data
            }
      return MaskCharacter(data)
    }
  },
  mounted() {
    
    let scope = this;
    scope.station = JSON.parse(localStorage.getItem('projectConfig')).projects[0].userInfo[0].user_phone_number
    if(scope.Live_agent_monitor_bool && scope.Live_agent_monitor_type && scope.Live_agent_monitor_data){
      this.toggleBargeSheet(scope.Live_agent_monitor_type, scope.Live_agent_monitor_data)
      this.page.isPlaying = true;
      this.page.extension=scope.station
    }
    let teams =  JSON.parse(localStorage.getItem("Team"))
    let team_agent_user =  JSON.parse(localStorage.getItem("Team_agent_user"))
    // let admin_emails = JSON.parse(localStorage.getItem("admin_emails"));
      if (teams.length > 0 && team_agent_user.length > 0) {
        this.filteragents = team_agent_user
    }else{
      this.filteragents = JSON.parse(localStorage.getItem("agent_emails"));
    }
    this.queue = JSON.parse(localStorage.getItem("group_queue"));
    this.$store.dispatch("queue").then((response) => {
      let data = response;
      let queue_ = [];
      for (let i = 0; i < data.length; i++) {


        queue_.push({ name: data[i], active: false });
        this.index_.push({ name: i + 1, active: false })
        this.get_queue_value.push({
          queue: "",
          rank: ""
        })

        if (i === 0) {
          this.index_controler.push({
            key: true,
            queue: "",
            queue_bool: false,
            rank_bool: false,
          })
        } else {
          this.index_controler.push({
            key: false,
            queue: "",
          })
        }

      }
      this.queue = queue_
    });
    console.log(localStorage.getItem("group_queue"), "kokokokpokop")

    scope.$store.dispatch("GetPausecode").then((response) => {
      this.pauseCodeData = response[0].data;


      console.log('Live_agents_response', this.Live_agents_response);

    });

    this.page.loading = false;
    this.livaagents();
    // this.queuelist();
    // this.extensionlist();
    // this.Availextensionlist();
    // this.campaignlist();
    // this.agentlist();
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "realtime:liveagents:barge"
          ) !== -1
        ) {
          scope.page.canBarge = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "realtime:liveagents:forcelogout"
          ) !== -1
        ) {
          scope.page.canLogout = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:view"
          ) !== -1
        ) {
          scope.masking = true;
        }
      }.bind(scope),
      1000
    );

  },
  beforeDestroy() {
    let scope = this
    this.payloadqueue = "";
    this.payloadextension = "";
    this.payloadAvailextension = "";
    this.payloadCamapign = "";
    this.payloaduser = "";
    // this.$store.dispatch("queuelist", "");
    // this.$store.dispatch("extensionlist", "");
    // this.$store.dispatch("Availextensionlist", "");
    // this.$store.dispatch("campaignList", "");
    // this.$store.dispatch("userlist", "");
    this.settimeoutstop = false;
    clearTimeout(this.liveAgentTime);
    if(!scope.Live_agent_monitor_bool){
        this.$store.dispatch("LiveAgentMontData", {});
        this.$store.dispatch("LiveAgentMontType", '');
        this.$store.dispatch("LiveAgentMontBool", false);
        this.$store.dispatch("CallActionflag", false);
    }
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  created() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  destroyed() {
    clearTimeout(this.liveAgentTime);
  }

};
</script>
<style>
.text-wrap {
  /* .test1 { 
  width: 11em; 
  background-color: lightblue; 
  border: 2px solid black; 
  padding: 10px; 
  word-wrap: break-word; 
  font-size: 20px; 
  } */
  max-width: 112px;
  white-space: normal;
  word-break: break-word;
}

.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.icons {
  height: 80px;
}

.element.style {
  height: 34px;
}

.selectbox {
  padding: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectboxfield {
  margin: 5px;
}

.dialog_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.waitingforcall {
  padding: 10px;
  color: white;
  background-color: rgb(159, 175, 185);
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.waitingforcallmore1 {
  padding: 10px;
  color: white;
  background-color: #664bea;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.waitingforcallmore5 {
  padding: 10px;
  color: white;
  background-color: #11054f;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.oncallmore1 {
  padding: 10px;
  color: white;
  background-color: #dd7bea;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.oncallmore5 {
  padding: 10px;
  color: white;
  background-color: #a50db9;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.oncallmore10sec {
  padding: 10px;
  color: white;
  background-color: #e7cdea;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dispo{
  padding: 10px;
  color: white;
  background-color: #0aabc7;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.chat{
  padding: 10px;
  color: white;
  background-color: #c70a0a;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pausemore1 {
  padding: 10px;
  color: white;
  background-color: #fbf725;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pausemore10sec {
  padding: 10px;
  color: white;
  background-color: #f3f2a3;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pausemore5 {
  padding: 10px;
  color: white;
  background-color: #86843a;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pausemore10 {
  padding: 10px;
  color: white;
  background-color: #555449;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pausemore15 {
  padding: 10px;
  color: white;
  background-color: #612e07;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.clip-disable {
  pointer-events:none;
  opacity: 0.5;
}
</style>