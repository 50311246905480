<template>
  <div>
    <section-definition v-if="(insert_form == false && edit_form == false)" :title="$route.query.module[0]"
      :extraOptions="pagecontrol.options" :icon="pagecontrol.icon" :breadcrumbs="pagecontrol.breadcrumbs"
      :exportOptions="pagecontrol.exportOptions" :addNewData="pagecontrol.addnew" @openFilter="filterClick"
      @refreshPage="refreshClick" @addNew="NewTag" :style="styleObject"></section-definition>
    <loader v-if="pagecontrol.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-dialog v-model="DeleteRows" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Delete {{ pagecontrol.title }} </span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you sure to delete this {{ pagecontrol.title }}? </v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer fixed disable-resize-watcher right v-model="pagecontrol.isFilterOpen">
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat color="white" @click="pagecontrol.isFilterOpen = false">
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
          <v-container class="sidenavContainer">
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[0]" :key="indexfield">
                <div class="autocompleteDiv">
                  <v-text-field v-model="field.value" :label="field.text" small></v-text-field>
                </div>
              </div>
            </div>

            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[1]" :key="indexfield">
                <div class="autocompleteDiv" v-if="!field.isMultiSelect">
                  <v-select :items="field.options" :label="field.text" v-model="field.value" item-text="name"
                    item-value="value"></v-select>
                </div>
              </div>
            </div>
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[1]" :key="indexfield">
                <div class="autocompleteDiv" v-if="field.isMultiSelect">
                  <v-select :items="field.options" :label="field.text" v-model="field.value" item-text="name"
                    item-value="value" multiple></v-select>
                </div>
              </div>
            </div>
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[2]" :key="indexfield">
                <div class="autocompleteDiv">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox v-model="field.value" :label="field.text" v-bind="attrs" v-on="on">
                      </v-combobox>
                    </template>
                    <v-date-picker v-model="field.value" color="primary" @input="menu = false" width="250" no-title
                      scrollable>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
            <br />
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[3]" :key="indexfield">
                <div class="autocompleteDiv">
                  <datetime class="theme-orange" color="primary" style="border-bottom: 1px solid rgb(145 145 145)"
                    type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="field.value" :placeholder="field.text">
                  </datetime>
                </div>
              </div>
            </div>
            <br />
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[4]" :key="indexfield">
                <div class="autocompleteDiv">
                  <v-checkbox v-model="field.value" :label="field.text" primary @change="newValue(props.item)"
                    hide-details color="primary"></v-checkbox>
                  <br /><br />
                </div>
              </div>
            </div>
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[5]" :key="indexfield">
                <div class="autocompleteDiv">
                  <p>{{ field.text }}</p>

                  <v-radio-group v-model="field.value">{{ indexn }}
                    <v-radio v-for="(n, indexn) in field.options" :key="indexn" :value="n" color="primary"
                      :label="`${n.value}`">
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>

          </v-container>
          <v-container row class="sidenavFooter">
            <v-btn flat @click="reset()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn :color="dynamicFilterField.length > 0 ? 'primary' : 'primary disclass'"
              @click="fetchFilter()">Filter</v-btn>
          </v-container>
        </div>
      </v-navigation-drawer>
      <v-data-table v-if="(insert_form == false && edit_form == false)" :headers="headers" :items="custommoduledata"
        class="elevation-1 fixed-layout" :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading"
        :rows-per-page-items="[10]">
        <template v-slot:items="props">
          <!-- <td class="text-xs-left">{{ props.item.name }}</td>
          <td class="text-xs-left">{{ props.item.description }}</td>
          <td class="text-xs-left">{{ userstring(props.item.status) }}</td> -->
          <td class="checkEmpty p0" v-for="(property, index) in headers" :key="index">
            <div>
              <div style="text-align: center;margin-bottom: 10px;" v-if="property.value === 'img'">
                <img v-if="props.item[`ImageUpload`] !==''" class="image_intable" :src="props.item[`ImageUpload`]" alt="My Image">
                <!-- <img v-else class="image_intable" src="'../../../public/static/default/user.svg'" alt="My"> -->
              </div>
              <p class="justify-center layout px-0" v-else-if="property.value === 'btn'">

                <v-icon  v-if="permission.edit" color="green" small class="mr-4"
                  @click="editItem(props.item)">edit</v-icon>
                <v-icon v-if="permission.delete" color="red" small class="mr-4"
                  @click="deleteItem(props.item)">delete</v-icon>
              </p>
              <p  v-else>
                {{ props.item[property.value] }}
              </p>
            </div>


          </td>
          <!-- <td class="justify-center layout px-0">

          </td> -->
        </template>
      </v-data-table>
      <forms v-if="(insert_form == true && edit_form == false)" @customform="customform_insert($event)"
        @insertleadssnack="hide_($event)">
      </forms>
      <LeadDetail v-if="(insert_form == false && edit_form == true)" :editlead_id="edit_id"
        @customform="customform_edit($event)"></LeadDetail>
      <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
      <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="isUploadDNC" hide-overlay persistent width="500">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Upload</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <p>
              <span style="font-weight: bold">Not sure what to upload?</span>
              Click here to download the sample file. Format:
              <a @click="samplefiledownload('csv')">CSV</a>,
              <a @click="samplefiledownload('txt')">Txt(Tab-Delimited)</a>.
              Open the file and enter the phone numbers at the first column of
              each row. Save the file with .csv /.txt extension and upload the
              file.
            </p>
            <div class="file-dummy" @click="$refs.file.click()" :style="{
              background: uploadLead.color + '66',
              'border-color': uploadLead.color,
              color: uploadLead.color,
            }">
              {{ uploadLead.label }}
            </div>
            <input type="file" id="file" ref="file" accept=".csv,.txt" v-on:change="handleFileUpload()" />
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="ColseFile()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>

  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import api from "@/utils/axios-util";
import forms from "./InsertCustomForm.vue"
import LeadDetail from "./LeadDetail1.vue";

export default {
  components: {
    loader, forms, LeadDetail
  },
  data() {
    return {

      uploadLead: {
        label: "Please select some files",
        color: "#2196f3",
      },
      insert_form: false,
      edit_form: false,
      edit_id: "",
      isfilterval: false,
      custommoduledata: [],
      headers: [

      ],
      tagstatus: [
        { name: "Active", value: "active" },
        { name: "Inactive", value: "inactive" },
      ],
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      delete_tag: "",
      Addtags: {
        name: "",
        id: "",
        status: "active",
        description: "",
      },
      pagination: {
        rowsPerPage: 10,
      },
      appliedfilter: {
        name: "",
        isLoading: false,
        search: "",
        filtervalue: [],
      },
      error_edit: "",
      editedItem: {
        name: "",
        id: "",
        Description: "",
        status: "",
      },
      isProcessing: false,
      DeleteRows: false,
      dialog: false,
      valid: true,
      tableLoading: false,
      pagecount: 0,
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      dynamicFilterField: [],
      fields_: [],
      permission: {
        create: false,
        edit: false, delete: false
      },
      isUploadDNC: false,
      pagecontrol: {
        title: "Tag Management",
        icon: "fa-random",
        loading: true,
        isFilterOpen: false,
        isAddTag: false,
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
      },
    };
  },

  methods: {
    mount() {
      let scope = this
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "custommodule:module:create"
        ) !== -1
      ) {
        scope.permission.create = true;
        this.pagecontrol.addnew = [{ label: "Add" },{ label: "Upload" }];
      }
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "custommodule:module:edit"
        ) !== -1
      ) {
        scope.permission.edit = true;
      }
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "custommodule:module:delete"
        ) !== -1
      ) {
        scope.permission.delete = true;
      }
      this.pagecontrol.loading = false;
      // this.pagecontrol.addnew = [{ label: "Add" }, { label: "Upload" }];
      this.getDataFromApi();
      this.$store
        .dispatch("GetFormbuilderFields", this.$route.query.module)
        .then((value) => {
          if (value.data.data[0].isfileupload === true) {
            this.headers.push({
              text: "Profile",
              align: "center",
              sortable: false,
              value: "img",
              width: "20%",
            },)
          }
          for (let i = 0; i < value.data.data[0].fields.length; i++) {
            const element = value.data.data[0].fields[i];
            if (element.model !== "id") {
              this.headers.push({
                text: element.model,
                align: "left",
                sortable: false,
                value: element.model,
                width: "20%",
              })
            }



          }

          this.headers.push({
            text: "Action",
            align: "center",
            sortable: false,
            value: "btn",
            width: "20%",
          },)
        })
        .catch(function (error) {
          this.showSnackBar("error" + error);
        });
    },
    hide_(val) {
      console.log(val, "jlzksjdfzkljfzlkjzlkjzlvzlvlkzjvzlkxjzlkjvlzjvlz")
      if (val) {
        this.insert_form = false
        this.showSnackBar("Lead Succesfully created")
      } else {
        this.showSnackBar("Error! lead cannot be created")
      }
    },
    getDefaultData() {
      return {
        label: "Please select some files",
        color: "#2196f3",
      };
    },
    ColseFile() {
      this.uploadLead = this.getDefaultData();
      this.isUploadDNC = false;
      this.csvheader = []

    },
    handleFileUpload: function () {
      var scope = this;
      scope.uploadLead.color = "#A64452";
      scope.uploadLead.label = "Uploading";
      let formData = new FormData();
      formData.append("file", scope.$refs.file.files[0]);
      formData.append("template_name", this.$route.query.module);

      console.log(formData, "ifhksfkjsdkjfnsdakdjfksddfkldslkkldskldsnk")
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      api
        .post("/formbuilder/aster/fileupload", formData)
        .then((response) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.getDataFromApi();
          if (response.data.dataCount > 0) {
            scope.uploadLead.color = "#4BB543";
            scope.uploadLead.label = "Uploaded Successfully";
            scope.ColseFile();
          } else {
            scope.uploadLead.color = "#F51B00";
            scope.uploadLead.label = "Invalid Field Format";
          }
          scope.$refs.file.value = "";
        

          return response;
        })
        .catch((error) => {
          console.log(error, "scope.$refs.file.clearAll")
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadLead.color = "#F51B00";
          scope.uploadLead.label = "Upload Error";
          scope.$refs.file.clearAll();
          return error;
        });
    },
    samplefiledownload(type) {
      const scope = this;
      let titleData = this.$route.query.module;
      let custommodulefields_ = "";
      scope.$store.dispatch("GetFormbuilderFields", this.$route.query.module).then((response) => {
        var datas = response.data.data[0].fields;
        custommodulefields_ = datas;
        const header = [];
        console.log(custommodulefields_)
        for (let i = 0; custommodulefields_.length > i; ++i) {
          if (
            custommodulefields_[i].model !== "id"
          ) {
            header.push(custommodulefields_[i].model);
          }

        }
        let csvContent = "";
        if (type === "csv") {
          csvContent += "data:text/csv;charset=utf-8,\uFEFF";
          csvContent += header.join(",");
        } else {
          csvContent += "data:text/txt;charset=utf-8,\uFEFF";
          csvContent += header.join("\t");
        }
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", titleData + "sample." + type);
        document.body.appendChild(link);
        link.click();
      });
    },
    customform_insert(val) {
      if (val === 201) {
        this.insert_form = false
        // this.getDataFromApi()
        this.showSnackBar("Lead Succesfully created")
        this.refreshClick()
      } else if (val === false) {
        this.insert_form = false
        this.refreshClick()
      }
      else {
        this.insert_form = true
      }
    },
    filterClick: function () {
      // let scope = this;
      this.pagecontrol.isFilterOpen = true;

    },
    refreshClick: function () {
      this.pagecontrol.loading = true;
      this.pagination.page = 1;
      this.isfilterval = false
      this.dynamicFilterField.forEach((fie) => {
        fie.forEach((sub) => {
          if (sub.value != "") {
            sub.value = ""
          }
        })
      })
      this.getDataFromApi();
    },
    resetvalue() {
      this.appliedfilter.name = "";
      this.pagecontrol.loading = true;
      this.$store.commit("filter_name", { name: "" });
      this.getDataFromApi();
      this.pagecontrol.isFilterOpen = true;
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    NewTag: function (data) {
      if (data.label === "Add") {

        this.insertDetails("create")
        this.insert_form = true
        this.edit_form = false
      } else {
        this.isUploadDNC = true
        console.log(data, "hghggvbv")
      }

    },
    customform_edit(val) {
      if (val === 201) {
        this.edit_form = false
        // this.getDataFromApi()
        this.refreshClick()
        this.showSnackBar("Lead Succesfully created")
      } else if (val === false) {
        this.edit_form = false
        // this.getDataFromApi()
        this.refreshClick()
      }
      else {
        this.edit_id = val
        this.edit_form = true;
        this.refreshClick()
      }
    },
    insertDetails(val, id) {
      var scope = this;
      if (val === "edit") {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "custommodule:module:edit"
          ) === -1
        ) {
          scope.$router.push({
            name: "blankPage",
          });
        }
      } if (val === "create2") {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "custommodule:module:create"
          ) === -1
        ) {
          scope.$router.push({
            name: "blankPage",
          });
        }
      }
      else {
        scope.$store
          .dispatch("GetFormbuilderFields", this.$route.query.module)
          .then(() => {

            (val == 'edit') ? this.$emit("customform_edit", id) : this.$emit("customform_insert")

          })
          .catch(function (error) {
            scope.showSnackBar("error" + error);
          });
      }


    },

    getDataFrom() {
      this.$store.commit("filter_name", { name: this.appliedfilter.name });
      this.getDataFromApi();
      this.pagecontrol.isFilterOpen = false;
    },
    reset() {
      this.isfilterval = false
      this.pagecontrol.isFilterOpen = false
      this.dynamicFilterField.forEach((fie) => {
        fie.forEach((sub) => {
          if (sub.value != "") {
            sub.value = ""
          }
        })
      })
      this.refreshClick()

    },
    SaveEdittag() {
      var scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("editeditem", this.editedItem)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.statusCode == 200) {
            this.getDataFromApi();
            scope.showSnackBar("Tag Updated Successfully");
          } else {
            scope.showSnackBar("Update error-" + response.data.message);
          }
        })
        .catch(function () {
          scope.isProcessing = false;
          // scope.error_user_id = error.data.statusCode;
          scope.showSnackBar("Error Updating Tag");
        });
    },
    editItem(item) {
      this.insert_form = false
      this.edit_form = true
      this.edit_id = item.id
      console.log("zjhcjzchjzbcjzbchjzbcjbzxmcbzxxmcbzxmxcb")
      this.insertDetails('edit', item.id)
    },

    userstring(val) {
      var k = this.tagstatus.filter((statusvalue) => {
        return statusvalue.value == val;
      });
      if (k.length > 0) {
        return k[0].name;
      } else {
        return "";
      }
    },
    getDataFromApi() {
      if (this.isfilterval === false) {
        var scope = this;
        scope.tableLoading = true;
        return new Promise(() => {
          let take = this.pagination.rowsPerPage;
          let skip;
          if (this.pagination.page === 1) {
            skip = 0;
          } else {
            skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
          }
          if (skip == -10) {
            skip = 0;
          }

          if (isNaN(skip)) {
            skip = 0;
          }

          let query = {
            skip: skip,
            take: take,
            name: this.$route.query.module,
            bodydata:{
              template_name: this.$route.query.module,
              fetch_type: 'internal',
            }
          };
          this.skip = skip;

          scope.$store
            .dispatch("GetAsterCustommoduleData", query)
            .then((response) => {
              console.log(response, "hhhhhhhhhhhhhhhhhhhhhhhhhhh")
              this.custommoduledata = response.data;
              this.pagecount = response.totalCount;
              scope.pagecontrol.loading = false;
              scope.tableLoading = false;
            })
            .catch(function (err) {
              if (err.response.status === 401) {
                scope.$store.dispatch("checkingtoken");
              }
              scope.tableLoading = false;
            });
        });
      }

    },
    deleteItem(item) {
      console.log(item, "xzzkjhckzjhckjzhc")
      this.DeleteRows = true;
      this.delete_tag = item;
    },
    closedelete() {
      this.DeleteRows = false;
    },

    savedelete() {
      var scope = this;
      scope.isProcessing = true;
      let obj = {
        delete_id: this.delete_tag.id,
        template_name: this.$route.query.module
      }
      this.$store
        .dispatch("deleteCustomModuledata", obj)
        .then((response) => {
          console.log(response, "sakjchakjshcjkascjasbccjasbccjasbcjasb")
          scope.isProcessing = false;
          if (response.statusCode == 200) {
            this.getDataFromApi();
            this.closedelete();
            scope.showSnackBar("Deleted  Successfully");
          } else {
            scope.showSnackBar("Delete error-" + response.message);
          }
        })
        .catch(function () {
          scope.isProcessing = false;
          // scope.error_user_id = error.data.statusCode;
          scope.showSnackBar("Error Deleting ");
        });
    },
    dynamicfilter_() {
      let checkboxes = [];
      let radio = [];
      let date_time = [];
      let date = [];
      let text = [];
      let select = [];
      let scope = this
      this.$store
        .dispatch("GetFormbuilderFields", this.$route.query.module)
        .then((response) => {

          var datas = response.data.data[0].fields;
          let ModuleCustomformField = datas;

          // if (scope.headers.length === 0) {
          this.dynamicFilterField = [];
          for (let i = 0; ModuleCustomformField.length > i; ++i) {
            if (ModuleCustomformField[i].fieldType == "DatetimePicker") {
              this.fieldtype.DatetimePicker.push(ModuleCustomformField[i].model)
            } else if (ModuleCustomformField[i].fieldType == "DatePicker") {
              this.fieldtype.DatePicker.push(ModuleCustomformField[i].model)
            }
            if (ModuleCustomformField[i].label === "Disposition") {
              let temp1 = JSON.parse(
                JSON.stringify(ModuleCustomformField[i].values)
              );
              let temp2 = JSON.parse(temp1);
              for (let j = 0; temp2.length > j; ++j) {
                scope.bulk_dispo.push(temp2[j].name);
              }
            }
            ModuleCustomformField[i].model = ModuleCustomformField[i].model.replace(
              " ",
              "_"
            );
            ModuleCustomformField[i].label = ModuleCustomformField[i].label.replace(
              "_",
              " "
            );
            if (ModuleCustomformField[i].show_on_view === true) {
              if (ModuleCustomformField[i].model === "lead_id" || ModuleCustomformField[i].model === "phone_number" || ModuleCustomformField[i].model === "user" || ModuleCustomformField[i].model === "disposition") {
                let key = {
                  text: ModuleCustomformField[i].label,
                  value: ModuleCustomformField[i].model,
                  align: "center",
                  sortable: false,
                };
                scope.headers.push(key);
              }
            }

            if (ModuleCustomformField[i].filter === true) {

              if (ModuleCustomformField[i].input_type === "Checkbox") {
                let filter1 = {
                  text: ModuleCustomformField[i].label,
                  value: "",
                  model: ModuleCustomformField[i].model,
                  input_type: ModuleCustomformField[i].input_type,
                };
                checkboxes.push(filter1);
              }

              if (ModuleCustomformField[i].input_type === "Radio") {
                let opt = JSON.parse(ModuleCustomformField[i].values);
                let filter2 = {
                  text: ModuleCustomformField[i].label,
                  value: "",
                  model: ModuleCustomformField[i].model,
                  input_type: ModuleCustomformField[i].input_type,
                  options: opt,
                };
                radio.push(filter2);
              }

              if (ModuleCustomformField[i].input_type === "DateTime") {
                let filter3 = {
                  text: ModuleCustomformField[i].label,
                  value: "",
                  model: ModuleCustomformField[i].model,
                  input_type: ModuleCustomformField[i].input_type,
                };
                date_time.push(filter3);
              }
              if (ModuleCustomformField[i].input_type === "Date") {
                let filter4 = {
                  text: ModuleCustomformField[i].label,
                  value: "",
                  model: ModuleCustomformField[i].model,
                  input_type: ModuleCustomformField[i].input_type,
                  is_active: true,
                };
                date.push(filter4);
              }
              if (ModuleCustomformField[i].input_type === "text") {
                let filter5 = {
                  text: ModuleCustomformField[i].label,
                  value: "",
                  model: ModuleCustomformField[i].model,
                  input_type: ModuleCustomformField[i].input_type,
                };

                text.push(filter5);


              }
              if (ModuleCustomformField[i].input_type === "Select") {
                let options = JSON.parse(ModuleCustomformField[i].values);
                let filter6 = {
                  text: ModuleCustomformField[i].label,
                  value: "",
                  model: ModuleCustomformField[i].model,
                  isMultiSelect: ModuleCustomformField[i].multiple,
                  input_type: ModuleCustomformField[i].input_type,
                  options: options,
                };

                select.push(filter6);
              }
            }
          }
          this.dynamicFilterField.push(text);
          this.dynamicFilterField.push(select);
          this.dynamicFilterField.push(date);
          this.dynamicFilterField.push(date_time);
          this.dynamicFilterField.push(checkboxes);
          this.dynamicFilterField.push(radio);

          // }
          // if (scope.GetAsterLead !== "") {
          //   const payload = {
          //     name: scope.GetAsterLead,
          //     skip: scope.perPage * (scope.currentPage - 1),
          //     take: scope.perPage,
          //   };

          //   scope.filtervalue = [];
          //   scope.$store
          //     .dispatch("GetAsterLeadData", payload)
          //     .then((response) => {
          //       this.downloadData = response.downloadData;
          //       let datas = response.data;
          //       if (datas !== "" && datas !== undefined && datas !== null) {
          //         datas.forEach((element) => {
          //           scope.filtervalue.push(element["list_id"]);
          //         });
          //       }
          //       let temparr = [];
          //       datas.forEach((element) => {
          //         let tempobj = {};
          //         scope.headers.forEach((elm) => {
          //           if (
          //             element[elm.value] === undefined &&
          //             elm.value !== "actions"
          //           ) {
          //             tempobj[elm.value] = "";
          //           }
          //           if (
          //             elm.value !== "actions" &&
          //             element[elm.value] !== undefined
          //           ) {
          //             tempobj[elm.value] = element[elm.value];
          //           }
          //         });
          //         tempobj.checker = false;
          //         temparr.push(tempobj);

          //       });
          //       scope.AsterLeadValue = temparr;

          //       scope.AsterLeadValueClone = datas

          //       if (response.totalCount !== undefined) {
          //         scope.pagecount = response.totalCount;
          //       } else {
          //         scope.pagecount = 0;
          //       }
          //       scope.endPage = scope.currentPage * scope.perPage;
          //       scope.endPage =
          //         scope.endPage < scope.pagecount ?
          //           scope.endPage :
          //           scope.pagecount;
          //     })
          //     .catch(function (error) {
          //       scope.showSnackBar("error" + error);
          //     });
          // }




        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    fetchFilter() {
      const scope = this;
      let filterData = [];
      console.log(this.dynamicFilterField, "kjsscjkscjksancj")
      scope.dynamicFilterField.forEach((input_type) => {
        input_type.forEach((field) => {
          if (field.input_type === "Radio" && field.value != "") {
            filterData.push({ name: field.model, value: field.value.name });
          }
          else if (field.value != "") {
            filterData.push({ name: field.model, value: field.value });

          }
        });
      });

      let filter = {
        template_name: this.$route.query.module[0],
        filterValues: filterData,
      };

      scope.$store
        .dispatch("DynamicFilter_Custommodule", filter)
        .then((response) => {
          let result = response.data;
          this.custommoduledata = result;
          scope.pagecount = 0;
          this.isfilterval = true
          this.pagecontrol.isFilterOpen = false
        })
    },
  },
  watch: {
    "Addtags.name"(newVal) {
      var scope = this;
      scope.error_edit = "";
      this.$store
        .dispatch("Tagmanagement", newVal)
        .then((response) => {
          response.data.data.map((res) => {
            if (res.name === newVal) {
              scope.error_edit = 409;
            } else {
              scope.error_edit = "";
            }
          });
        })
        .catch(function () {
          scope.isProcessing = false;
        });
    },
    $route() {
      console.log("hhhhhgrgegegewshhhhhhhhhhhhhhh")
      this.mount()
      this.headers = []
      this.insert_form = false
      this.edit_form = false
    },
    pagination: {
      handler() {
        this.demo += 1;
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "appliedfilter.search"(val) {
      if (this.pagecontrol.isFilterOpen) {
        let scope = this;

        let data = { model: "tags", column: ["name"], search: val };

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.pagecontrol.loading = false;
            var datas = response.data;
            this.appliedfilter.filtervalue = datas[0].name;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.mount()
    this.dynamicfilter_()
  },
  refreshvalue() {
    this.$store.commit("filter_name", { name: "" });
  },
  beforeDestroy() {
    this.$store.commit("filter_name", { name: "" });
  },
};
</script>
<style>
.alert-danger {
  color: red;
}

.sidenavContainer {
  height: 508px;
}

.disclass {
  pointer-events: none !important;
  opacity: 0.4 !important;
}

.image_intable {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  border-radius: 10px;
}
</style>