<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
      @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="meeting_data" 
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>

        <template v-slot:items="props">

          <td :class="['checkEmpty', 'p0', property.value === 'btn' ? 'text-xs-center' : '']" v-for="(property, index) in headers" :key="index">
            <template v-if="property.value === 'btn'">
              <v-icon color="blue" small class="mr-4" :disabled="page.canedit && props.item.status === 'completed'"

              @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4 " v-if="page.candelete"
              @click="deleteItem(props.item.meetingID)">delete
            </v-icon>
            </template>
            <template v-else>
              {{
                props.item[property.value] == "" ||
                props.item[property.value] == null ||
                props.item[property.value] == undefined
                ? "N/A"
                : headers[index].value == property.value &&  headers[index].masking ? ChartoMaskingChar(props.item[property.value]) :props.item[property.value] 
            }}
            </template>
        </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Meeting</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this Meeting?</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog persistent v-model="page.isaddmeetings" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Create Meetings</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="MeetingCreateref" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select v-model="addmeetings.payload.name" required label="Meeting title"
                      :items="this.meeting_title" :rules="[(v) => !!v || 'Meeting title is required']" @input="updateMeetingSubtitles($event,'add')"></v-select>
                  </v-flex>

                  <v-flex xs12 v-if="meeting_sub_title.length > 0">
                    <v-select v-model="addmeetings.payload.meeting_sub_title" label="Meeting Sub title"
                      :items="meeting_sub_title">
                    </v-select>
                  </v-flex>
                  <v-flex xs12 v-if="addmeetings.payload.name == 'others'">
                    <v-text-field  v-model="addmeetings.payload.manual_title"
                      required label="Title" type="text" :rules="[(v) => !!v || 'Title is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea v-model="addmeetings.payload.description" required label="Description" type="text"
                      :rules="validations.descriptionRules"></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-select :rules="[(v) => !!v || 'Schedule By is required']" :items="typeBase" required label="Schedule By"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.type" @change="changeField('add','type',$event)"
                      persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs12 v-if="addmeetings.payload.type == 'agent'">
                    <v-select :rules="[(v) => !!v || 'Agent is required']" :items="agents" required label="Agent"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.agent"
                      @change="changeField('add','agent')" persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs12 >
                      <!-- <label class="sheduleddate">Meeting Date</label> -->
                      <datetime :class="addmeetings.payload.type !== '' ? 'theme-orange' : 'text-field-disable'" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                        v-model="addmeetings.payload.sheduleddate" label="test" :min-datetime="currentDateTime" 
                        @input="changeField('add','date',$event)">
                        <label for="startDate" slot="before" class="sheduleddate">Meeting Date</label>
                      </datetime>
                    <div v-if="showTimeConflictMessage" class="error--text" style="font-size: 0.875rem;">
                      Your meeting time will be Before {{ DateTimeformateaftermin(meeting_conflict_time,false) }} (or) After {{ DateTimeformateaftermin(meeting_conflict_time,true) }}  Available
                    </div>
                  </v-flex>
                  <v-flex xs12 v-if="addmeetings.payload.type === 'time'">
                    <v-select :rules="[(v) => !!v || 'Agent is required']" :items="agents" required label="Agent"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.agent"
                      @input="changeField('add','agent')" persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs12>
                  <!-- placeholder="Enter Contact or Ticket Phone Number" -->
                  <v-text-field :disabled="addmeetings.payload.agent === ''"  v-model="addmeetings.payload.phone_number" required label="Phone Number" type="text"
                    :rules="validations.phoneNumberRules" @input="changeField('add','phone_number',$event)"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select :rules="[(v) => !!v || 'Module is required']" :items="module" required label="Module"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.module"
                      persistent-hint  @change="changeField('add','module',$event)"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select :rules="[(v) => !!v || 'Campaign is required']" :items="campaign" required label="Campaign"
                      item-text="name" item-value="value" return-string v-model="addmeetings.payload.campaign"
                      persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <div class="text-field-disable">
                      {{ selectModuleid }}
                    </div>
                  </v-flex>
                  <v-flex xs12 v-if="addmeetings.payload.module === 'lead'">
                    <v-select :rules="[(v) => !!v || 'Template is required']" :items="template" required
                      label="Template" item-text="name" item-value="value" return-string
                      v-model="addmeetings.payload.template" persistent-hint></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddMeetings()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid || showTimeConflictMessage" @click="saveMeetings()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog persistent v-model="page.isEditMeeting" height="500px" width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Update Meetings</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 v-if="this.editmeetings.name">
                    <v-select  v-model="editmeetings.name" required label="Meeting title"
                      :items="this.meeting_title" :rules="[(v) => !!v || 'Meeting title is required']" @input="updateMeetingSubtitles($event,'update')"></v-select>
                  </v-flex>
                  <v-flex xs12 v-if="meeting_sub_title.length > 0">
                    <v-select 
                      v-model="editmeetings.meeting_sub_title" label="Meeting Sub title" :items="meeting_sub_title">
                    </v-select>
                  </v-flex>
                  <v-flex xs12 v-if="editmeetings.name === 'others'">
                    <v-text-field  v-model="editmeetings.manual_title" required
                      label="Title" type="text" :rules="[(v) => !!v || 'Title is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea v-model="editmeetings.comments" required label="Description" type="text"
                      :rules="validations.descriptionRules"></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-select disabled :rules="[(v) => !!v || 'Agent is required']" :items="agents" required label="Agent"
                      item-text="name" item-value="value" return-string v-model="editmeetings.user"
                      @change="handleAgentSelection('edit','agent',editmeetings.id)" persistent-hint></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                      v-model="editmeetings.scheduleDate" label="test"
                      @input="changeField('edit','type',$event)" :min-datetime="currentDateTime">
                      <label for="startDate" slot="before" class="sheduleddate2">Meeting Date</label>
                    </datetime>
                    <div v-if="showTimeConflictMessage" class="error--text" style="font-size: 0.875rem;">
                      Your meeting time will be Before {{ DateTimeformateaftermin(meeting_conflict_time,false) }} (or) After {{ DateTimeformateaftermin(meeting_conflict_time,true) }} Available
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field  v-model="editmeetings.phone_number" required disabled
                    label="Phone Number" type="text" ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field  v-model="editmeetings.module" required disabled
                    label="Module" type="text" ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field  v-model="editmeetings.campaign" required disabled
                    label="Campaign" type="text" ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <div class="text-field-disable">
                      {{ selectModuleid }}
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid || showTimeConflictMessage"
              @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-select :items="agents" required label="Agent" item-text="name" item-value="value" return-string
              v-model="filter.payload.user" persistent-hint></v-select>
            <v-select v-model="filter.payload.module" label="Module" :items="filtermodule"></v-select>

          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
import { MaskCharacter } from "@/utils/helpers";
export default {
  components: {
    loader,
  },

  data() {
    return {
      showTimeConflictMessage: false,
      currentDateTime: null,
      intervalRef: null,
      timeoutRef:null,
      agentUpcomingMeetings: [],
      req_id: [],
      valid: true,
      template: JSON.parse(localStorage.getItem("group_template")),
      module: [],
      agents_global: JSON.parse(localStorage.getItem("agent_emails")),
      agents:[],
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Meetings",
        icon: "fa-clipboard fa-2x",
        loading: false,
        isaddmeetings: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditMeeting: false,
        temp: { key: "addpausecode", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },
      titledata: [],
      typeBase:[{name:'Time',value:'time'},{name:'Agent',value:'agent'}],
      pagecount: 0,
      headers: [
        {
          text: "Meeting Title",
          align: "left",
          class: "p0",
          sortable: false,
          value: "meeting_name",
          width: "13%",
          masking:false
        },
        {
          text: "Meeting Subtitle",
          align: "left",
          class: "p0",
          sortable: false,
          value: "meeting_sub_title",
          masking:false
        },
        {
          text: "Phone Number",
          align: "left",
          class: "p0",
          sortable: false,
          value: "leadNumber",
          masking:false
        },
        {
          text: "Agent",
          align: "left",
          class: "p0",
          sortable: false,
          value: "ownerID",
          width: "18%",
          masking:false
        },
        {
          text: "Module",
          align: "left",
          class: "p0",
          sortable: false,
          value: "model",
          masking:false
        },
        {
          text: "Campagin",
          align: "left",
          class: "p0",
          sortable: false,
          value: "campaign",
          masking:false
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
          masking:false
        }, {
          text: "Scheduled Time",
          align: "left",
          class: "p0",
          sortable: false,
          value: "scheduledTime",
          masking:false
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
          masking:false
        },
      ],
      meeting_data: [],
      meeting_title: [],
      meeting_sub_title: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          module: "", user: ""
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      isProcessing: false,
      call_status: [],
      validations: {
        phoneNumberRules: [
          (v) => !!v || 'Phone number is required',
          (v) => /^[0-9]+$/.test(v) || 'Phone number must contain only digits',
          (v) => (v && v.length >= 3 && v.length <= 15) || 'Phone number must be between 3 and 15 digits',
        ],
        descriptionRules: [
          (v) => (v && v.trim().length > 0) || 'Description is required',
        ],
      },
      editmeetings: {
        user: "",
        name: "",
        scheduleDate: "",
        manual_title: "",
        comments: "",
        id: "",
        meeting_sub_title: "",
        campaign:"",
        phone_number:"",
        module:""
      },
      addmeetings: {
        valid: false,
        menu2: false,
        menu1: false,
        payload: {
          name: "",
          phone_number: "",
          description: "",
          module: "",
          agent: "", 
          sheduleddate: "", 
          meeting_sub_title: "",
          type:"",
          campaign:""
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      filtermodule: ["lead", "contact", "ticket"],
      Filename:"Meeting",
      masking:false,
      meeting_after_min:15,
      meeting_conflict_time:"",
      campaign:[],
      selectModuleid:""
    };
  },
  methods: {
    updateheader(){
      let scope = this
      scope.headers.forEach((elm)=>{
        elm.masking = scope.masking && elm.value == scope.MaskingModules.MaskingModules[scope.Filename].find(tlm => tlm == elm.value)
      })
    },
    initializeScheduledDate() {
      this.Timeudpate(true)
      let currdatetime = new Date()
      let currsecond = 60 - currdatetime.getSeconds()  
      this.timeoutRef = null

      if (!this.intervalRef) {
          this.timeoutRef = setTimeout(() => {
            this.Timeudpate(true)
            this.intervalRef = setInterval(() => {
                this.Timeudpate(true)
            }, 60000);
          }, currsecond * 1000);
      }

    },
    Timeudpate(createbool){
      let currenttime = new Date()
      currenttime.setMinutes(currenttime.getMinutes() + this.meeting_after_min);

      if(this.addmeetings.payload.sheduleddate == "" || new Date(this.addmeetings.payload.sheduleddate) < currenttime && createbool){
        this.addmeetings.payload.sheduleddate = this.updateScheduledDate(this.meeting_after_min);
      }else if(this.editmeetings.scheduleDate == "" || new Date(this.editmeetings.scheduleDate) < currenttime && !createbool) {
        this.editmeetings.scheduleDate = this.updateScheduledDate(this.meeting_after_min);
      }

      this.currentDateTime = this.updateScheduledDate(this.meeting_after_min);
    },
    DateTimeformateaftermin(data,afterbool){
        let datetime = new Date(data)
        if (afterbool) {
          datetime.setMinutes(datetime.getMinutes() + this.meeting_after_min - 1);
        }else{
          datetime.setMinutes(datetime.getMinutes() - this.meeting_after_min - 1);
        }
        
        let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
        let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
        let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
        let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
        return datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`
    },
    DateTimeformate(data){
        let datetime = new Date(data)
        let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
        let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
        let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
        let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
        return datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`
    },
    updateScheduledDate(min) {
      const currentDateTime = new Date();
      currentDateTime.setMinutes(currentDateTime.getMinutes() + min);
      currentDateTime.setSeconds(0);
      return currentDateTime.toISOString();
    },
    changeField(mode, field, value){

      let scope= this
      if(mode == "add"){

        if(field == "type"){
          scope.addmeetings.payload.phone_number = "";
          scope.addmeetings.payload.agent = "";
          scope.addmeetings.payload.module = "";
          scope.addmeetings.payload.campaign = "";

          clearInterval(scope.intervalRef);
          clearTimeout(scope.timeoutRef);
          scope.intervalRef = null;
          scope.timeoutRef = null

          scope.initializeScheduledDate();

          if(value == "time"){
            scope.handleAgentSelection(mode, undefined, undefined, value)
          }else{
            scope.agentUpcomingMeetings = []
            scope.agentavaliableset()
          }

        }else if(field == 'date'){ 

          if(scope.addmeetings.payload.type == "time"){
            scope.addmeetings.payload.agent = "";
            scope.addmeetings.payload.phone_number = "";
            scope.addmeetings.payload.module = "";
            scope.addmeetings.payload.campaign = "";
          }
          scope.Timeudpate(true)
          if(value != ""){
            scope.handleAgentSelection(mode, field, undefined, scope.addmeetings.payload.type)
          }

        }else if(field == 'agent'){ 
          scope.addmeetings.payload.phone_number = "";
          scope.addmeetings.payload.module = "";
          scope.addmeetings.payload.campaign = "";
          scope.handleAgentSelection(mode, field, undefined, scope.addmeetings.payload.type)

        }else if(field == 'phone_number'){ 
          scope.addmeetings.payload.module = "";
          scope.addmeetings.payload.campaign = "";
          if(value != "" && typeof Number(value) === 'number' && Number(value)){
            scope.checkPhoneNumber(value, scope.addmeetings.payload.agent);
          }

        }else if(field == 'module'){ 
          let module = this.req_id.find((item) => item.module === value)
          scope.selectModuleid =  `#${module[module.module]}`
          scope.addmeetings.payload.campaign = "";

        }

        if(field !== 'module' && field !== 'date'){
          scope.module = [];
          scope.req_id = [];
          scope.selectModuleid = ""
        }

        scope.$refs.MeetingCreateref.resetValidation();
       
      }else if(mode == "edit"){
        if(field !== 'date'){
          scope.Timeudpate(false)
          scope.handleAgentSelection('edit','date',scope.editmeetings.id)
        }
      }

    },
    async handleAgentSelection(mode, type, id, basetype) {
      let meetingdatetime = ''
      let agent

      if(mode == 'add'){

        agent = this.addmeetings.payload.agent
        if(this.addmeetings.payload.agent != ""){
          agent = this.addmeetings.payload.agent
        }

        meetingdatetime =  this.addmeetings.payload.sheduleddate
      }else if(mode == 'edit' ){
        meetingdatetime =  this.editmeetings.scheduleDate
        agent = this.editmeetings.user
      }

      const query = { user: agent, meetingDate: this.DateTimeformate(meetingdatetime) , meeting_status : 0, base:basetype};

      const response = await this.$store.dispatch("meetingCheckcondition", query);
      if(response.data.statusCode == 204){
        this.agentUpcomingMeetings = []
        this.meeting_conflict_time = ""
        this.showTimeConflictMessage = false

        if(basetype == "time"){
            this.agentavaliableset()
        }

        return false

      }else if(response.data.statusCode == 200){

        if(mode == 'edit'){
          this.agentUpcomingMeetings = response.data.data;

          if(this.agentUpcomingMeetings[0].fwp_id != id){
              this.meeting_conflict_time = this.agentUpcomingMeetings[0].fwp_date
              this.showTimeConflictMessage = true
              return true

          }else{
            this.agentUpcomingMeetings = []
            this.meeting_conflict_time = ""
            this.showTimeConflictMessage = false
          }

        }else{
          this.agentUpcomingMeetings = response.data.data;

          if(basetype == "time"){
            this.agentavaliableset()
          }

          if(basetype == "agent" && this.addmeetings.payload.agent !== ""){
            this.meeting_conflict_time = this.agentUpcomingMeetings[0].fwp_date
            this.showTimeConflictMessage = true
            return true
          }else{
            this.meeting_conflict_time = []
            this.showTimeConflictMessage = false
            return false
          }

        }

      }
    },
    agentavaliableset(){
      let agents_set = JSON.parse(JSON.stringify(this.agents_global))
            this.agentUpcomingMeetings.forEach((elm)=>{
              let agentindex = agents_set.indexOf(elm.user)
              if(agentindex != -1){
                agents_set.splice(agentindex, 1);
              }
        })
        this.agents = agents_set
    },
    updateMeetingSubtitles(meetingTitle,mode) {
      this.meeting_sub_title = []
      let subdata = this.titledata.find((elm) => elm.title === meetingTitle &&  elm.sub_title != "");
      if(subdata != undefined){
        this.meeting_sub_title =  subdata.sub_title.split(",")
      }

      if(mode == "add"){
        this.addmeetings.payload.meeting_sub_title = ""
        this.addmeetings.payload.manual_title = ""
      }
      else if(mode == "update"){
        this.editmeetings.meeting_sub_title = ""
        this.editmeetings.manual_title = ""
      }
    },
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.filter.payload.module = ""
      scope.filter.payload.user = ""
      scope.editmeetings.scheduleDate = ""
      scope.getDataFromApi();
      scope.campaignSet()
      scope.showTimeConflictMessage= false
    },
    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
      scope.agentavaliableset();
    },
    reset() {
      var scope = this;
      scope.filter.payload.module = ""
      scope.filter.payload.user = ""
      scope.resetpopup("create");
      scope.resetpopup("update");
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      scope.filtervalue = [];
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      let query = {
        skip: skip,
        take: take,
        module: scope.filter.payload.module,
        userID: scope.filter.payload.user
      };
      this.skip = skip;

      this.$store
        .dispatch("GetMeetings", query)
        .then((response) => {
          this.pagecount = response.data.totalCount;
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          scope.meeting_data = datas;
          this.addmeetings.payload.sheduleddate = "";
        })
        .catch(function (error) {
          scope.page.isaddmeetings = false;
          scope.showSnackBar("error" + error);
          this.page.isEditMeeting = false;
        });
      let titleQuery = {
        skip: "",
        take: "",
        title: scope.addmeetings.payload.manual_title || '',
        is_active: 1,
        module: 'meeting'
      }
      this.$store
        .dispatch("FetchData", titleQuery)
        .then((response) => {
          var datas = response.data;
          scope.titledata = datas
          scope.meeting_title = datas.map((element) => element.title);
          scope.meeting_title.push("others")
        })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
    },
    AddNewRule: function () {
      this.valid = false;
      this.initializeScheduledDate();
      this.page.isaddmeetings = true;
    },
    closeEditRules: function () { 
      this.page.isEditMeeting = false;
      this.resetpopup("update")
    },
    closeAddMeetings: function () {
      this.page.isaddmeetings = false;
      this.resetpopup("create")
    },
    resetpopup(mode){
      if(mode == "create"){
        this.valid = false
        this.$refs.MeetingCreateref.reset();
        this.$refs.MeetingCreateref.resetValidation();
        this.addmeetings.payload.manual_title = "";
        this.addmeetings.payload.name = "";
        this.addmeetings.payload.meeting_sub_title = ""
        this.addmeetings.payload.sheduleddate = "";
        this.addmeetings.payload.phone_number = "";
        this.addmeetings.payload.type = "";
        this.addmeetings.payload.agent = "";
        this.addmeetings.payload.module = "";
        this.addmeetings.payload.description = "";
        this.addmeetings.payload.campaign = "";
        clearInterval(this.intervalRef);
        clearTimeout(this.timeoutRef);
        this.intervalRef = null;
        this.timeoutRef = null
        this.meeting_sub_title = ""
        this.module= [];
        this.req_id= [];
        this.selectModuleid = ""
      }else if(mode == "update"){
        this.valid = false;
        this.editmeetings.user = ""
        this.editmeetings.name = ""
        this.editmeetings.scheduleDate = ""
        this.editmeetings.manual_title = ""
        this.editmeetings.comments = ""
        this.editmeetings.id = ""
        this.editmeetings.campaign =  ""
        this.editmeetings.module =  ""
        this.editmeetings.phone_number =  ""

      }
      this.showTimeConflictMessage = false;
      this.meeting_sub_title = [] 
      this.agentUpcomingMeetings = []
      this.agentavaliableset()
    },
    async saveMeetings() {
      var scope = this;
      if (scope.addmeetings.payload.module === "lead") {
        if (scope.addmeetings.payload.template !== "") {
          scope.$store
            .dispatch("findlead", { phone_number: scope.addmeetings.payload.phone_number, template: scope.addmeetings.payload.template })
            .then((res) => {
              if (res.status === 204) {
                scope.showSnackBar("Please map the correct lead template.");
                scope.addmeetings.payload.module = "";
              } else {

                this.req_id.push({ "lead": res.data.data[0].others.lead_id, module: "lead" })
                scope.create()
              }

            }).catch(function (error) {
              scope.showSnackBar(error);
            });
        } else {
          scope.showSnackBar("Please select template");
        }
      } else {
        scope.isProcessing = true;
        let isagent = await scope.handleAgentSelection('add',undefined, undefined, 'agent')
        if(!isagent){
          scope.create()
        }
        scope.isProcessing = false;
      }

      // this.getDataFromApi();
    },
    async checkPhoneNumber(phoneNumber,agent) {
      try {
        const [contactResponse, ticketResponse] = await Promise.all([
          this.$store.dispatch("TicketPhoneSearch", { phone_number: Number(phoneNumber) }),
          this.$store.dispatch("Popup", { phone_number:phoneNumber, user:agent })
        ]);
        this.handleContactResponse(contactResponse);
        this.handleTicketResponse(ticketResponse);
      } catch (error) {
        this.module= [];
          this.req_id= [];
          this.addmeetings.payload.module = '';
        console.error("Error checking phone number");

      }
    },
    campaignSet(action, template) {
      var scope = this;
      let payload = {}
      if (action) {
        payload = { skip: 0, take: 0, campaign_status: 'Y', All: true, template: template.slice('custom_'.length) };
      } else {
        payload = { skip: 0, take: 0, campaign_status: 'Y', All: true };
      }

      scope.$store.dispatch("GetAsterCampaigns", payload)
        .then((response) => {
          if (response.statusCode == 200) {
            scope.campaign = []
            response.data.forEach((campaign) => {
              scope.campaign.push(campaign.campaign_name);
            });
          }
          else {
            scope.campaign = []
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    handleContactResponse(response) {
      if (response.data != undefined && response.data.length > 0) {
        console.log("coming to contact")
        this.addModule("contact", response.data[0].id);
      } else {
        console.log("names no contact")
        this.removeModule("contact");
          this.addmeetings.payload.module = '';
      }
    },
    handleTicketResponse(response) {
      if (response.data.ids.length > 0) {
              console.log("coming to ticket")
        this.addModule("ticket", response.data.ids[0].ticket_id);
      } else {
        console.log("names no ticket")
        this.removeModule("ticket");
        this.addmeetings.payload.module = '';
      }
    },
    addModule(moduleName, id) {
      if (!this.module.includes(moduleName)) {
        this.module.push(moduleName);
      }
      this.req_id = this.req_id.filter(item => item.module !== moduleName);
      this.req_id.push({ [moduleName]: id, module: moduleName });
    },
    removeModule(moduleName) {
      this.module = this.module.filter(item => item !== moduleName);
      this.req_id = this.req_id.filter(item => item.module !== moduleName);
    },
    create() {
      var scope = this;
      let id = this.req_id.find((item) => item.module === this.addmeetings.payload.module);
      if (!id) {
        this.showSnackBar("Error: No matching module found");
        this.closeAddMeetings();
        return;
      }
      id = id[`${scope.addmeetings.payload.module}`]
      if (scope.addmeetings.payload.name === "others") {
        scope.addmeetings.payload.name = scope.addmeetings.payload.manual_title
      }
      let data = {
        "meeting_sub_title": scope.addmeetings.payload.meeting_sub_title,
        "meetingName": scope.addmeetings.payload.name,
        "meetingTilte": scope.addmeetings.payload.description,
        "meetingTime": scope.addmeetings.payload.sheduleddate,
        "user_email": scope.addmeetings.payload.agent,
        "leadID": id.toString(),
        "phoneNumber": scope.addmeetings.payload.phone_number,
        "campaign": scope.addmeetings.payload.campaign,
        "module": scope.addmeetings.payload.module,
        "callerName": scope.addmeetings.payload.phone_number
      }
      scope.$store
        .dispatch("insertMeeting", data)
        .then((res) => {
          if(res.status===201){
            scope.page.isaddmeetings = false;
            scope.showSnackBar("Created Successfully");
            this.resetpopup("create")
            this.getDataFromApi();
          }else{
            scope.page.isaddmeetings = false;
            scope.showSnackBar("Meeting not created");
            this.resetpopup("create")
            this.getDataFromApi();
          }          
        })
        .catch(function (error) {
          scope.page.isaddmeetings = false;
          scope.showSnackBar("error" + error);
          scope.resetpopup("create")
        });
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deletemeeting", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("Meetings Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    async saveEditRules() {
      var scope = this;
      scope.isProcessing = true;
      let isagent = await scope.handleAgentSelection('edit', undefined, scope.editmeetings.id, 'agent')
      if(!isagent){
        scope.isProcessing = false;
        if (scope.editmeetings.name === "others") {
          scope.editmeetings.name = scope.editmeetings.manual_title
          scope.editmeetings.meeting_sub_title = ""
          delete scope.editmeetings.manual_title

        } else {
          delete scope.editmeetings.manual_title
        }

        let updatepayload = JSON.parse(JSON.stringify(scope.editmeetings))
        scope.$store
          .dispatch("updateMeeting", updatepayload)
          .then(() => {
            scope.page.isaddmeetings = false;
            scope.showSnackBar("Updated Successfully");
            this.page.isEditMeeting = false;
            this.resetpopup("update")
            this.refreshThisPage();
          })
          .catch(function (error) {
            scope.page.isaddmeetings = false;
            scope.showSnackBar("error" + error);
            this.page.isEditMeeting = false;
          });
        this.getDataFromApi();
      }

    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    async editItem(item) {
      let count = 0
      this.updateMeetingSubtitles(item.meeting_name)

      this.meeting_title.map((element) => {
        if (element === item.meeting_name) {
          count += 1
          this.editmeetings.name = element;
        }
      })

      if (count == 0) {
        this.editmeetings.name = "others"
        this.editmeetings.manual_title = item.meeting_name;
      }

      this.Timeudpate(true)
      this.agentavaliableset()
      this.editmeetings.comments = item.subject;
      this.editmeetings.id = item.meetingID;
      this.editmeetings.user = item.ownerID
      this.editmeetings.meeting_sub_title = item.meeting_sub_title;
      this.editmeetings.scheduleDate = new Date(item.scheduledTime).toISOString();
      this.editmeetings.campaign = item.campaign
      this.editmeetings.module = item.model
      this.editmeetings.phone_number = item.phoneNumber
      this.selectModuleid = `#${item.leadID}`
      this.page.isEditMeeting = true;
      
      if(item.status == "missed"){
        this.meeting_conflict_time = this.DateTimeformateaftermin(new Date(),true)
        this.showTimeConflictMessage = true
      }

    },
    sidebar_enable_disable(data) {
      this.$root.$emit('sidebar_disable', data);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    }
  },
  computed: {
    ...mapGetters(["CallFlow","MaskingModules"])
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.sidebar_enable_disable(false)
    // if (!this.addmeetings.payload.sheduleddate) {
    //   this.initializeScheduledDate();
    // }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "leadmanagement:meetings:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "Add Meetings" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "leadmanagement:meetings:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "leadmanagement:meetings:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    let data = { model: "meeting", column: ["phone_number"] };

    scope.$store
      .dispatch("GetModuleNameList", data)
      .then((response) => {
        scope.tableLoading = false;
        scope.page.loading = false;
        var datas = response.data;
        scope.filtervalue = datas[0].phone_number;
      })
      .catch((err) => {
        console.log(err);
      });
    this.campaignSet()
    this.getDataFromApi();
  },
  watch: {
    'DeleteRowdata': function (newVal) {
      this.sidebar_enable_disable(newVal);
    },
    'page.isaddmeetings': function (newVal) {
      this.sidebar_enable_disable(newVal);
    },
    'page.isEditMeeting': function (newVal) {
      this.sidebar_enable_disable(newVal);
    },
    // 'addmeetings.payload.phone_number': {
    //   handler(newPhoneNumber) {
    //     if (!newPhoneNumber) {
    //       this.module= [];
    //       this.req_id= [];
    //       this.addmeetings.payload.module = '';
    //       return;
    //     }

    //     this.checkPhoneNumber(newPhoneNumber,this.addmeetings.payload.agent);
    //   },
    //   deep: true,
    // },
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;

        let data = { model: "meeting", column: ["phone_number"] };

        data["search"] = val;

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            this.filtervalue = datas[0].phone_number;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created(){
    let scope = this
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:masking:view"
      ) !== -1
    ) {
      scope.masking = true;
      scope.updateheader()
    }
  }
};
</script>

<style>
.vdatetime-input {
  width: 100%;
}

.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

.sheduleddate {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.84rem;
  font-weight: 550;
}

.sheduleddate2 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  font-weight: 550;
}

.error-message-validation {
  color: #d50000;
  caret-color: #d50000;
  font-size: 12px;
}
.date-time-wrapper {
  cursor: pointer;
}

.text-field-disable {
  pointer-events: none;
  opacity:0.5;
}
</style>