<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @openFilter="filterClick"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    >
    </section-definition>
    <loader v-if="page.loading"></loader>

    <!-- data content -->
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="pausecodeData"
        class="elevation-1 fixed-layout"
        :pagination.sync="pagination"
        :total-items="pagecount"
        :loading="tableLoading"
        :rows-per-page-items="[10]"
      >
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.dispo_id }}</td>
          <td class="checkEmpty p0">{{ props.item.subdispo_id }}</td>
          <td class="checkEmpty p0">{{ props.item.description }}</td>
          <td class="checkEmpty p0">{{ props.item.active }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon
              color="blue"
              small
              class="mr-4"
              v-if="page.canedit"
              @click="editItem(props.item)"
              >edit</v-icon
            >
            <v-icon
              color="red"
              small
              class="mr-4"
              v-if="page.candelete"
              @click="deleteItem(props.item.id)"
              >delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>

    <!-- delete model -->
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Sub-Disposition</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text
              >Are your sure to delete this Sub-Disposition?</v-card-text
            >
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- create model -->
    <template>
      <v-dialog
        persistent
        :value="page.isAddSubDisposition"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Sub-Disposition</span>
            </v-card-title>
          </v-toolbar>

          <v-card-text style="height: 300px">
            <v-form ref="form" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="addSubDisposition.payload.dispo_id"
                      required
                      label="Disposition"
                      :items="maindispo"
                      :rules="[(v) => !!v || 'Maindispo is required']"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="addSubDisposition.payload.subdispo_id"
                      required
                      label="Sub-Disposition"
                      type="text"
                      :rules="[(v) => !!v || 'subdispo id is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="addSubDisposition.payload.description"
                      required
                      label="Description"
                      type="text"
                      :rules="[(v) => !!v || 'Description is required']"
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-switch
                      v-model="addSubDisposition.payload.call_back"
                      color="primary"
                      flat
                      label="Call back"
                    >
                    </v-switch>
                  </v-flex> -->
                  <!-- <v-flex xs12>
                    <v-select v-model="addSubDisposition.payload.active" required label="Status" :items="status"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>
                  </v-flex> -->
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
            <v-btn
              color="primary"
              :disabled="!valid"
              @click="saveAddPausecode()"
              >Create</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- update model -->
    <template>
      <v-dialog
        persistent
        :value="page.isEditSubDisposition"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Sub-Disposition</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text style="height: 380px">
            <v-form ref="form1" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="editSubDisposition.dispo_id"
                      required
                      label="Disposition"
                      :items="maindispo"
                      :rules="[(v) => !!v || 'Maindispo is required']"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editSubDisposition.subdispo_id"
                      required
                      label="SubDisposition"
                      type="text"
                      readonly
                      :rules="[(v) => !!v || 'Sub-Disposition is required']"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editSubDisposition.description"
                      required
                      label="Description"
                      type="text"
                      :rules="[(v) => !!v || 'Description is required']"
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-switch
                      v-model="editSubDisposition.call_back"
                      flat
                      color="primary"
                      label="Call back"
                    ></v-switch>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-select
                      v-model="editSubDisposition.active"
                      required
                      label="Status"
                      :items="status"
                      :rules="[(v) => !!v || 'Status is required']"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveEditRules()"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <!-- filter model -->
    <v-navigation-drawer
      fixed
      disable-resize-watcher
      right
      v-model="page.isFilterOpen"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete
              v-model="filter.payload.subdispo_id"
              :items="filtervalue"
              :loading="filter.isLoading"
              :search-input.sync="filter.search"
              hide-no-data
              hide-selected
              label="Sub Disposition"
            ></v-autocomplete>
            <v-select
              v-model="filter.payload.active"
              label="Status"
              :items="status"
            ></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";

import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Sub-Disposition",
        icon: "fa-file-alt fa-2x",
        loading: false,
        isAddSubDisposition: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
        addnew: [],
        isEditSubDisposition: false,
        temp: { key: "addpausecode", label: "Add PauseCode" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Disposition",
          align: "left",
          class: "p0",
          sortable: false,
          value: "dispo_id",
        },
        {
          text: "Sub-Disposition",
          align: "left",
          class: "p0",
          sortable: false,
          value: "subdispo_id",
        },
        {
          text: "Description",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "20%",
        },
      ],
      pausecodeData: [],
      maindispo: [],
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          active: "",
          subdispo_id: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      isProcessing: false,
      call_status: [],
      editSubDisposition: {
        id: "",
        subdispo_id: "",
        dispo_id: "",
        description: "",
        active: "",
        call_back: "",
      },
      addSubDisposition: {
        valid: false,
        payload: {
          dispo_id: "",
          subdispo_id: "",
          description: "",
          active: "Active",
          call_back: false,
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      status: ["Active", "Inactive"],
    };
  },

  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.filter.payload.active = "";
      scope.filter.payload.subdispo_id = "";
      scope.pagination.page = 1;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    filterClick: function () {
      let scope = this;
      scope.page.isFilterOpen = true;
      let data = { model: "sub_disposition", column: ["subdispo_id"] };

      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.filtervalue = datas[0].subdispo_id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
     
      var data = {
        subdispo_status: scope.filter.payload.active,
        subdispo_id: scope.filter.payload.subdispo_id,
      };
      this.$store
        .dispatch("FiltersubDisposition", data)
        .then((response) => {
          if (response.statusCode === 200) {
            scope.filter.payload.active = "";
            scope.filter.payload.subdispo_id = "";
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;

            for (let i = 0; i < datas.length; i++) {
              if (datas[i].active == "Y") {
                datas[i].active = "Active";
              } else {
                datas[i].active = "InActive";
              }
            }

            scope.pausecodeData = datas;
          } else {
            scope.showSnackBar("No matching records found");
          }
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },reset(){
      let scope= this
      scope.filter.payload.active = "";
      scope.filter.payload.subdispo_id = "";
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      scope.filtervalue = [];
    
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      if (scope.filter.payload.active === "Active") {
        scope.filter.payload.active = "Y";
      }
      if (scope.filter.payload.active === "Inactive") {
        scope.filter.payload.active = "N";
      }
      let query = {
        skip: skip,
        take: take,
        subdispo_status: scope.filter.payload.active,
        subdispo_id: scope.filter.payload.subdispo_id,
        // descending:''
      };
      this.skip = skip;
      // var { descending } = scope.pagination;
      // this.query.descending = descending;
      this.$store
        .dispatch("GetSubDisposition", query)
        .then((response) => {
          this.pagecount = response.data.count;
          if (response.status === 204) {
            scope.pausecodeData = [];
            scope.tableLoading = false;
          } else {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data.data;
            if (datas !== "" && datas !== undefined && datas !== null) {
              for (let i = 0; i < datas.length; i++) {
                if (datas[i].active == "Y") {
                  datas[i].active = "Active";
                } else {
                  datas[i].active = "Inactive";
                }
              }

              datas.forEach((element) => {
                if(element.dispo_id==''){
                  element.dispo_id='-'
                }
                scope.filtervalue.push(element["subdispo_id"]);
              });
            }
            scope.pausecodeData = datas;
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
      scope.page.loading = false;
    },
    AddNewRule: function () {
      this.page.isAddSubDisposition = true;
    },
    closeEditRules: function () {
      this.page.isEditSubDisposition = false;
      
    },
    closeAddPausecode: function () {
      this.page.isAddSubDisposition = false;
      this.$refs.form.reset();
    },
    saveEditRules() {
      var scope = this;

      if (scope.editSubDisposition.active === "Active") {
        scope.editSubDisposition.active = "Y";
      }
      if (scope.editSubDisposition.active === "Inactive") {
        scope.editSubDisposition.active = "N";
      }

      if (scope.editSubDisposition.call_back === true) {
        scope.editSubDisposition.call_back = "Y";
      }
      if (scope.editSubDisposition.call_back === false) {
        scope.editSubDisposition.call_back = "N";
      }

      scope.$store
        .dispatch("updatesubDisposition", scope.editSubDisposition)
        .then(() => {
          scope.page.isAddSubDisposition = false;
          scope.showSnackBar("Edited Successfully");
          this.page.isEditSubDisposition = false;
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isAddSubDisposition = false;
          scope.showSnackBar("error" + error);
          this.page.isEditSubDisposition = false;
        });
     
    },
    saveAddPausecode() {
      var scope = this;
      if (scope.addSubDisposition.payload.active === "Active") {
        scope.addSubDisposition.payload.active = "Y";
      }
      if (scope.addSubDisposition.payload.active === "Inactive") {
        scope.addSubDisposition.payload.active = "N";
      }

      if (scope.addSubDisposition.payload.call_back === true) {
        scope.addSubDisposition.payload.call_back = "Y";
      }
      if (scope.addSubDisposition.payload.call_back === false) {
        scope.addSubDisposition.payload.call_back = "N";
      }
      scope.$store
        .dispatch("insertsubDisposition", scope.addSubDisposition.payload)
        .then(() => {
          scope.page.isAddSubDisposition = false;
          scope.showSnackBar("Created Successfully");
          this.$refs.form.reset();
          scope.addSubDisposition.payload.call_back = false;
          this.reset()
        })
        .catch(function (error) {
          // console.log(error);
          scope.page.isAddSubDisposition = false;
          scope.showSnackBar("error" + error);
          scope.addSubDisposition.payload.dispo_id = "";
          scope.addSubDisposition.payload.description = "";
          scope.addSubDisposition.payload.active = "";
          scope.addSubDisposition.payload.call_back = false;
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
       let scope = this;
      this.page.isEditSubDisposition = true;
      this.editSubDisposition.id = item.id;
      this.editSubDisposition.subdispo_id = item.subdispo_id;
      this.editSubDisposition.dispo_id = item.dispo_id !== "-" ? item.dispo_id : '';
      this.editSubDisposition.description = item.description;

      if (item.active === "Y" || item.active === "Active" ) {
        scope.editSubDisposition.active = "Active";
      }
      if (item.active === "N" || item.active === "Inactive" ) {
        scope.editSubDisposition.active = "Inactive";
      }

      if (item.call_back === "Y") {
        scope.editSubDisposition.call_back = true;
      }
      if (item.call_back === "N") {
        scope.editSubDisposition.call_back = false;
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deletesubDisposition", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("SubDisposition Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:subdisposition:create"
      ) !== -1
    ) {
      this.page.addnew = [{ label: "Add Sub-Dispo" }];
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:subdisposition:delete"
      ) !== -1
    ) {
      scope.page.candelete = true;
    }
    if (
      scope.$store.getters.UserPermissions.indexOf(
        "configurations:subdisposition:edit"
      ) !== -1
    ) {
      scope.page.canedit = true;
    }
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;

    let query1 = {
        skip: 0,
        take: 0,
        // descending:''
      };
    this.$store
        .dispatch("GetDisposition", query1)
        .then((response) => {
          // console.log("dataS",response);
          var datas = response.data.data;

          if (datas !== "" && datas !== undefined && datas !== null) {
            datas.forEach((element) => {
              if (element.active === "Y") {
                scope.maindispo.push(element["dispo_id"]);
              }
            });
          }
        })
        .catch(function (error) {
          // scope.page.isAddSubDisposition = false;
          scope.showSnackBar("error" + error);
          // this.page.isEditSubDisposition = false;
        });


    this.getDataFromApi();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;

        let data = {
          model: "sub_disposition",
          column: ["subdispo_id"],
          search: val,
        };

        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            this.filtervalue = datas[0].subdispo_id;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>