<template>
  <v-img :src="backgroundImg" class="page-vimg">
    <v-container fill-height pa-0>
      <v-layout align-center>
        <v-flex xs12>
          <v-card class="text-xs-center margin-auto border-radius6 box-white-500-glow elevation-10 auth-box">
            <v-layout align-center justify-center fill-height wrap>
              <v-flex xs12 class="text-xs-center mt-3">
                <img style="width: 160px; height: 60px" :src="logodata.logo" alt="Vuse" class="text-xs-center"
                  height="100" />


                <h3>{{ logodata.name }} Admin Login</h3>
                <v-form @submit.prevent="$v.$invalid ? invalid() : submit(form)" ref="form"
                  :class="{ 'form-disabled': formDisabled }">
                  <v-layout wrap pa-4>
                    <v-flex xs12 pa-0>
                      <v-text-field label="UserName" v-model="form.email" required :placeholder="'Enter User Name'"
                        :error-messages="fieldErrors('form.email')" :disabled="formDisabled"></v-text-field>
                      <v-text-field label="Password" v-model="form.password" :type="passwordFieldType" required
                        :placeholder="'Enter User password'" :error-messages="fieldErrors('form.password')"
                        append-icon="mdi-eye" @click:append="togglePasswordVisibility"
                        :disabled="formDisabled"></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap text-xs-center>
                        <v-flex xs12 class="no-mrpd">
                          <v-btn color="act" type="submit" block
                            :disabled="form.password.length === 0 || form.email.length === 0 || formDisabled"
                            :loading="isLoading">
                            {{ isLoading ? 'Login' : 'Login' }}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-snackbar v-model="snackbar.show" :right="true" :bottom="true" :color="'red'" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="black" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </v-img>
</template>
<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";
import { encoding } from '../utils/EncodeDecode'
const defaultForm = {
  email: "",
  password: "",
  remeberme: false,
};
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  validationMessages: {
    form: {
      email: {
        required: "Please enter email",
        email: "Email must be valid",
      },
      password: {
        required: "Please enter password",
        minLength: "Password must be of at least 8 characters",
      },
    },
  },
  data() {
    return {

      form: Object.assign({}, defaultForm),
      backgroundImg: "/static/doc-images/bgimage.jpg",
      passwordFieldType: "password",
      snackbar: {
        show: false,
        timeout: 5000,
        text: "",
      },
      isLoading: false,
      formDisabled: false,
      logodata: { "name": "", "logo": "" }
    };
  },
  components: {},
  mounted() {
   
    this.domain_name()
  },
  methods: {
    invalid: function () {
      this.$v.$touch();
      console.log("this is invalid");
    },
    showSnackBar(message) {
      this.snackbar.text = message;
      this.snackbar.show = true;
      setTimeout(() => {
        this.snackbar.text = "";
        this.snackbar.show = false;
      }, this.snackbar.timeout);
    },

    resetForm() {
      this.form = Object.assign({}, defaultForm);
      this.$refs.form.reset();
      this.formDisabled = false;
      this.isLoading = false;
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      console.log("inside togglePasswordVisibility", this.passwordFieldType);
    },
    handleVersionRedirection(userVersion, loginData) {
      const baseVersion = process.env.VUE_APP_BASE_URL || "/";
      const normalizeVersion = (version) => {
        console.log("Normalizing version:", version);
        if (!version || version === '/') {
          return '/';
        }
        return version.startsWith('/') ? version : `/${version}`;
      };

      const normalizedBaseVersion = normalizeVersion(baseVersion);
      const normalizedUserVersion = normalizeVersion(userVersion);

      console.log(`Version details. Base: ${normalizedBaseVersion}, User: ${normalizedUserVersion}`);

      // Check conditions for direct routing
      const shouldDirectRoute =
        normalizedUserVersion === normalizedBaseVersion ||
        !normalizedUserVersion ||
        console.log("Should direct route:", shouldDirectRoute);

      if (shouldDirectRoute) {
        console.log("Performing direct routing to LiveAgents");
        localStorage.setItem("socketConnectCount", 0);
        sessionStorage.setItem('isLoggedIn', 'true');
        // Direct routing to LiveAgents
        this.$router.push({
          name: "realtime/LiveAgents",
        });
      } else {
        // Handle dynamic user redirection
        console.log(`Version mismatch. Base: ${normalizedBaseVersion}, User: ${normalizedUserVersion}`);
        const domainName = window.location.hostname;
        const port = window.location.port;
        let redirectUrl;
        // Remove leading and trailing slashes from subVersion
        const cleanSubVersion = normalizedUserVersion.replace(/^\/|\/$/g, '');
        if (domainName === "localhost") {
          const redirectPort = port === "8080" ? "8081" : "8080";
          redirectUrl = `http://localhost:${redirectPort}${cleanSubVersion ? `/${cleanSubVersion}` : ''}/`;
          console.log(`Redirecting to: ${redirectUrl}`);
        } else {
          redirectUrl = `https://${domainName}${cleanSubVersion ? `/${cleanSubVersion}` : ''}/`;
        }

        const encodedLoginData = encoding(JSON.stringify(loginData));
        redirectUrl += `?token=${encodedLoginData}`;

        console.log(`Final Redirect URL: ${redirectUrl}`);
        window.location.href = redirectUrl;
      }
    }
    ,
    submit(form) {
      this.formDisabled = true;
      this.isLoading = true;
      var scope = this;
      delete form.remeberme;

      scope.$store
        .dispatch("auth0Login", form)
        .then(function (res) {
          console.log(res, "devtrial_agent002@doocti.com");
          return scope.$store.dispatch("auth0Parse", res);
        })
        .then(function () {
          // const mockversion=['/v2.1.106','/v2.1.107','/v2.1.108','/v2.1.105','','/','231/']
          //  const subVersion = '/'
          const apiInfo = JSON.parse(localStorage.getItem("apiInfo")) || {};
          const subVersion = apiInfo.sub_version;
          scope.showSnackBar("Login successful!");
          scope.resetForm();
          scope.$v.$reset();
          console.log('0000 Login successful, handling version redirection');
          scope.handleVersionRedirection(subVersion, form);
          localStorage.setItem("socketConnectCount", 0);
        })
        .catch((error) => {
          console.error("Login error:", error);
          scope.showSnackBar("Password or Email is incorrect!");
          this.formDisabled = false;
          this.isLoading = false;
        })
        .finally(() => {
          scope.formDisabled = false;
          scope.isLoading = false;
        });
    },

    domain_name() {
      var parts = location.hostname.split(".");
      let domain
      if (location.hostname === "localhost") {
        domain = "localhost"
      } else {
        domain = parts[parts.length - 2];
      }
      this.$store
        .dispatch("domaininfo", domain)
        .then( (res) =>{

        
         
          localStorage.setItem("logodetails", JSON.stringify(res.data[0]))
        
          this.logodata = res.data[0]
          
        })

        .catch((error) => {
          console.error( error);

        })

    },
  },
};
</script>

<style scoped>
.form-disabled {
  opacity: 0.6;
  pointer-events: none;
}
</style>