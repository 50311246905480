export const TOGGLE_CLIPPED = "TOGGLE_CLIPPED";
export const Announcement = "Announcement";
export const TOOLBAR_LEFT_CLIPPED = "TOOLBAR_LEFT_CLIPPED";
export const TOGGLE_DRAWER_VISIBILITY = "TOGGLE_DRAWER_VISIBILITY";
export const TOGGLE_MINI_VARIANT = "TOGGLE_MINI_VARIANT";
export const TOGGLE_FIXED_FOOTER = "TOGGLE_FIXED_FOOTER";
export const SIDENAV_DRAWER_POSITION = "SIDENAV_DRAWER_POSITION";
export const TOGGLE_FIXED_TOOLBAR = "TOGGLE_FIXED_TOOLBAR";
export const TOGGLE_TOOLBAR = "TOGGLE_TOOLBAR";
export const TOGGLE_FOOTER = "TOGGLE_FOOTER";
export const NAV_DRAWER_ALPHA_MOD = "NAV_DRAWER_ALPHA_MOD";
export const TOGGLE_MINIVARIENT_MODE = "TOGGLE_MINIVARIENT_MODE";
export const SET_DRAWER_ALPHA_IMG = "SET_DRAWER_ALPHA_IMG";
export const NAVIGATION_DRAWER_SCHEME = "NAVIGATION_DRAWER_SCHEME";
export const NAVIGATION_TOOLBAR_SCHEME = "NAVIGATION_TOOLBAR_SCHEME";
export const FOOTER_SCHEME = "FOOTER_SCHEME";
export const APP_COLOR_SCHEME = "APP_COLOR_SCHEME";
export const NAV_DRAWER_VARIANT_MOD = "NAV_DRAWER_VARIANT_MOD";
export const LIVE_CALLS = "LIVE_CALLS";
export const EXTENSION_RECORDS = "EXTENSION_RECORDS";
export const USER = "USER";
export const QUEUE_RECORDS = "QUEUE_RECORDS";
export const FILTERLIVECALLS = "FILTERLIVECALLS";
export const LIVE_AGENTS = "LIVE_AGENTS";
export const QUEUE_LISTS = "QUEUE_LISTS";
export const EXTENSION_LISTS = "EXTENSION_LISTS";
export const AVAILEXTENSION_LISTS = "AVAILEXTENSION_LISTS";
export const USER_LISTS = "USER_LISTS";
export const QUEUESTATUS = "QUEUESTATUS";
export const CAMPAIGN_LISTS = "CAMPAIGN_LISTS";
export const CAMPAIGN = "CAMPAIGN";
export const FILTERLIVENUMBER = "FILTERLIVENUMBER";
export const FILTERLIVENUMBERS = "FILTERLIVENUMBERS";
export const FILTERLIVEAGENT = "FILTERLIVEAGENT";
export const FILTERLIVEQUEUE = "FILTERLIVEQUEUE";
export const FILTERLIVECAMPAIGN = "FILTERLIVECAMPAIGN";
export const FILTERLIVEEXTENSTENSION = "FILTERLIVEEXTENSTENSION";
export const FILTERLIVETYPE = "FILTERLIVETYPE";
export const FILTERLIVESTATUS = "FILTERLIVESTATUS";
export const NULLVALUE = "NULLVALUE";
export const NULLVALUES = "NULLVALUES";
export const CALLDETAILS = "CALLDETAILS";
export const FILTER_TODATE = "FILTER_TODATE";
export const FILTERFROMDATE = "FILTERFROMDATE";
export const FILTER_AGENT = "FILTER_AGENT";
export const FILTER_TYPE = "FILTER_TYPE";
export const FILTER_PHONE = "FILTER_PHONE";
export const FILTER_LISTID = "FILTER_LISTID";
export const FILTER_CAMPAIGN = "FILTER_CAMPAIGN";
export const FILTER_EXTENSION = "FILTER_EXTENSION";
export const FILTER_GROUP = "FILTER_GROUP";
export const FILTER_DID = "FILTER_DID";
export const FILTER_QUEUE = "FILTER_QUEUE";
export const FILTER_DISPO = "FILTER_DISPO";
export const FILTER_STATUS = "FILTER_STATUS";
// export const FILTER_F/ leadStatus,LAG = "FILTER_FLAG";
export const FILTER_STATION = "FILTER_STATION";

export const SYSTEMCALLSUMMARY = "systemcallsummary";

export const RECORDINGDETAILS = "RECORDINGDETAILS";
export const ACTION_FROMDATE = "ACTION_FROMDATE";
export const ACTION_TODATE = "ACTION_TODATE";
export const DATE = "DATE";
export const AUTH0LOGINSUCCESS = "AUTH0LOGINSUCCESS";
export const AUTH0LOGOUTSUCCESS = "AUTH0LOGOUTSUCCESS";
export const RESETTOKEN = "RESETTOKEN";
export const AGENT_LISTS = "AGENT_LISTS";
export const MISSEDCALLDETAILS = "MISSEDCALLDETAILS";
export const DROPEDCALLDETAILS = "DROPEDCALLDETAILS";
export const FILTER_LAST_APP = "FILTER_LAST_APP";
export const FILTER_Event = "FILTER_Event";
export const AGENT_CAMPAIGN = "AGENT_CAMPAIGN";
export const AGENT_ACTIVITY_REPORTS = "AGENT_ACTIVITY_REPORTS";
export const EFFECTIVEABANDON = "effectiveabandon";
export const Performance_report = "Performance_report";
export const queueSummaryReport = "queueSummaryReport";
export const FILTER_UNIQUEID = "FILTER_UNIQUEID";
export const FILTER_TEMPLATE = "FILTER_TEMPLATE";
export const userManagement = "userManagement";
export const queueManagement = "queueManagement";
export const AudioStore = "AudioStore";
export const UserType = "UserType";
export const QueueList = "QueueList";
export const Createqueue = "Createqueue";
export const AssignQueueList = "AssignQueueList";
export const WebHookManagement = "WebHookManagement";
export const TimeCondition = "TimeCondition";
export const DoNotCall = "DoNotCall";
export const TagManagement = "TagManagement";
export const PauseCode = "PauseCode";
export const Reminder = "Reminder";
export const Disposition = "Disposition";
export const SubDisposition = "SubDisposition";
export const Contact = "Contact";
export const Rules = "Rules";
export const CallStatus = "CallStatus";
export const AsterCampaign = "AsterCampaign";
export const AsterContact = "AsterContact";
export const AsterList = "AsterList";
export const AsterSms = "AsterSms";
export const AsterMail = "AsterMail";
export const AsterWhatsapp = "AsterWhatsapp";
export const AsterLead = "AsterLead";
export const AsterLeadInsert = "AsterLeadInsert";
export const AsterLeadDelete = "AsterLeadDelete";
export const CHECKING_TOKEN = "CHECKING_TOKEN";
export const CustomForm = "CustomForm";
export const LINK_PROPERTY = "LINK_PROPERTY";
export const RefreshCustomForm = "RefreshCustomForm";
export const UpdateFormType = "UpdateFormType";
export const PageMethode = "PageMethode";
export const RefreshAsterCampaign = "RefreshAsterCampaign";
export const Blocklist = "Blocklist";
export const Script = "Script";
export const SLA = "SLA";
export const CommitNothing = "CommitNothing";
export const AGENT_PAUSE_REPORT = "AGENT_PAUSE_REPORT";
export const AGENT_DISPO_REPORT = "AGENT_DISPO_REPORT";
export const CallFlow = "CallFlow";
export const updateJSON = "updateJSON";
export const Abandonedsummary = "Abandonedsummary";
export const leadStatus = "leadStatus";
export const ticketStatus="ticketStatus";
export const didNumber = "didNumber";
export const WatsAppReport = "WatsAppReport";
export const TicketReport = "TicketReport";
export const DISPOSITION_LIST = "DISPOSITION_LIST";
export const SUBDISPOSITION_LIST = "SUBDISPOSITION_LIST";
export const SET_WEBSOCKET_STATUS="SET_WEBSOCKET_STATUS";
export const SET_SESSION='SET_SESSION';
export const ONCALL='ON_CALL';
export const CALL_RINGTONE = 'CALL_RINGTONE';
export const FETCHCALLACTION='FETCHCALLACTION';
export const ACTIVE_CONFERENCE='ACTIVE_CONFERENCE';
export const INITIATE_EVENT='INITIATE_EVENT';
export const LIVE_AGENTS_MONITOR_DATA='LIVE_AGENTS_MONITOR_DATA';
export const LIVE_AGENTS_MONITOR_TYPE='LIVE_AGENTS_MONITOR_TYPE';
export const LIVE_AGENTS_MONITOR_BOOL='LIVE_AGENTS_MONITOR_BOOL';
export const BARGE_CALL_FLAG = 'BARGE_CALL_FLAG'
export const SET_AUTO_LOGIN_LOADER = "SET_AUTO_LOGIN_LOADER";
export const MASKING = "MASKING"
