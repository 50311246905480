<template>
    <div :style="previewOut ? 'position: relative' : 'position: static'">
        <section-definition v-if="previewOut === false" :title="page.title" :extraOptions="page.options" :icon="page.icon"
            :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
            @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewForm" :style="styleObject"
            :previewOut="previewOut">
        </section-definition>
        <loader v-if="page.loading"></loader>
        <v-container v-else fluid grid-list-xl page>
            <v-data-table v-if="previewOut === false" :headers="headers" :items="customformData"
                class="elevation-1 fixed-layout" :pagination.sync="pagination" :total-items="pagecount"
                :loading="tableLoading" :rows-per-page-items="[10]">
                <template v-slot:no-data style="text-align: center">
                    <div style="text-align: center">No Data Available</div>
                </template>
                <template v-slot:items="props">
                    <td class="checkEmpty text-xs-left p0">
                        {{ props.item.template_name }}
                    </td>
                    <td class="checkEmpty text-xs-left p0">{{ props.item.count }}</td>
                    <td class="checkEmpty text-xs-left p0">{{ props.item.type }}</td>
                    <td class="checkEmpty text-xs-left p0">{{ props.item.industry }}</td>
                    <td class="text-xs-left checkEmpty p0">
                        <i class="fas fa-eye mr-4" style="color: rgb(98 201 85)" @click="previewOutside(props.item)"
                            flat></i>

                        <v-icon color="blue" small class="mr-4" v-if="page.canedit"
                            @click="editItem(props.item)">edit</v-icon>
                        <v-icon color="red" small class="mr-4" v-if="page.candelete"
                            @click="deleteItem(props.item)">delete</v-icon>
                    </td>
                </template>
            </v-data-table>
            <div v-if="previewOut" class="insertflex1">
                <div class="profil_pics">
                    <div class="profil_txts">
                        <i class="fa fa-expand" aria-hidden="true"></i>
                    </div>
                    <div class="v-toolbar__title primary--text" style="margin-top: 8px">
                        {{ templet_name.toUpperCase() }}
                    </div>
                </div>
                <div @click="previewOut = false">
                    <i style="color: rgb(33, 150, 243)" class="fa fa-arrow-left icon_cls_custom_form"
                        aria-hidden="true"></i>
                </div>
            </div>
            <preview v-if="previewOut" :value_cls="false"></preview>
        </v-container>

        <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Delete Lead Template</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Are you sure to delete this Lead Template?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
                    <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent :value="page.isAddCustomForm" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Select the Domain</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-form ref="customformvalidation" lazy-validation>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-select style="text-decoration: none" :rules="IndustryRules" v-model="formindusties"
                                    :items="domain1" label="Industry" item-text="name" item-value="value"></v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-select style="text-decoration: none" :rules="DomainRules" v-model="formDomain"
                                    :items="domain" label="Domain" item-text="name" item-value="value" return-string
                                    persistent-hint></v-select>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closeAddCustomform()">Close</v-btn>
                    <v-btn color="primary" @click="saveAddCustomform()">Continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
         
        <v-dialog v-model="delete_popup" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Deletion Restricted</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Restricted: Template is mapped to a campaign.</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="closedeleteRestricted()">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent :value="page.isEditCustomform" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Update Templates</span>
                    </v-card-title>
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-text-field v-model="editCustomform.pause_code" required label="Pause Code" type="text"
                                :rules="[(v) => !!v || 'Pausecode is required']"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field v-model="editCustomform.description" required label="Description" type="text"
                                :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field v-model="editCustomform.status" required label="Status" type="text"
                                :rules="[(v) => !!v || 'Status is required']"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
                    <v-btn color="primary" flat @click="saveEditRules()">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
            <v-toolbar small color="secondary" style="color: white">
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon flat color="white" @click="page.isFilterOpen = false">
                    <i class="fas fa-close"></i>
                </v-btn>
            </v-toolbar>
            <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
                <v-container class="sidenavContainer">
                    <div class="autocompleteDiv">
                        <v-autocomplete v-model="filter.payload.template_name" :items="filtervalue"
                            :loading="filter.isLoading" :search-input.sync="filter.search" label="Template Name"
                            hide-no-data hide-selected></v-autocomplete>
                    </div>
                </v-container>
                <v-container row class="sidenavFooter">
                    <v-btn flat @click="getDataFromApi()">Reset</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="fetchFilter()">Filter</v-btn>
                </v-container>
            </div>
        </v-navigation-drawer>
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Please stand by
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
import { mapGetters, mapActions } from "vuex";
import preview from "./custom-form/Preview.vue";
export default {
    components: {
        loader,
        preview,
    },

    data() {
        return {
            templet_name: "",
            previewOut: false,
            previewval: {},
            styleObject: {
                height: "80px",
                "margin-top": "-8px",
            },
            page: {
                title: "Lead Templates",
                icon: "fa-file fa-2x",
                loading: false,
                isAddCustomForm: false,
                isFilterOpen: false,
                playingRecord: false,
                breadcrumbs: [],
                exportOptions: [],
                options: { filter: true, refresh: true },
                addnew: [],
                isEditCustomform: false,
                temp: { key: "addcustomform", label: "Add Template" },
                candelete: false,
                canedit: false,
                canpreview: false,
            },
            headers: [
                {
                    text: "Template Name",
                    align: "left",
                    class: "p0",
                    sortable: true,
                    value: "template_name",
                },
                {
                    text: "Total Fields",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "description",
                },
                {
                    text: "Type",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "status",
                },
                {
                    text: "Industry",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "industry",
                },
                {
                    text: "Action",
                    align: "left",
                    class: "p0",
                    sortable: false,
                    value: "btn",
                    width: "20%",
                },
            ],
            customformData: [],
            pagecount: 0,
            delete_item: {},
            pagination: {
                page: 0,
                rowsPerPage: 10,
            },
            domain: [],
            domain1: [],
            formDomain: "",
            formindusties: "",
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            DeleteRowdata: false,
            delete_popup: false,
            showRowData: false,
            DataRow: false,
            tableLoading: false,
            crmdomain: "",
            filtervalue: [],
            filter: {
                payload: {
                    template_name: "",
                },
                options: {
                    status: ["Active", "Inactive"],
                },
                isLoading: false,
                search: "",
            },
            isProcessing: false,
            call_status: [],
            editCustomform: {
                id: "",
                pause_code: "",
                type: "",
                description: "",
                status: "",
            },
            addCustomform: {
                valid: false,
                payload: {
                    template_name: "",
                    type: "",
                    description: "",
                    status: "",
                },
                options: {
                    status: ["Active", "Inactive"],
                },
            },
            IndustryRules: [(v) => !!v || "Industry is required"],
            DomainRules: [(v) => !!v || "Domain is required"],
        };
    },
    methods: {
        ...mapActions(["GetDefaultFields"]),
        previewOutside(val) {
            this.templet_name = val.template_name;
            this.$store.dispatch("GetFormFields", val.template_name).then(() => {
                // this.$router.push({
                // name: "crm/CustomForm",
                // });
                this.previewval = val;
                this.previewOut = !this.previewOut;
            });
        },
        refreshThisPage: function () {
            var scope = this;
            scope.page.loading = true;
            scope.getDataFromApi();
        },
        filterClick: function () {
            this.page.isFilterOpen = true;
        },
        fetchFilter: function () {
            var scope = this;
            scope.tableLoading = true;
            this.page.isFilterOpen = false;
            this.$store
                .dispatch("FilterCustomForm", scope.filter.payload)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    scope.customformData = datas;
                })
                .catch(function (error) {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditCustomform = false;
                });
        },
        getIndustrydetails() {
            this.$store
                .dispatch("getIndustrydetail")
                .then((response) => {
                    let datas1 = response.data;
                    for (let k = 0; k < datas1.length; k++) {
                        this.domain1.push({
                            name: datas1[k].name,
                            value: datas1[k].name,
                            values: datas1[k].id_country,
                        });
                    }
                })
                .catch(function (error) {
                    this.page.isAddPausecode = false;
                    this.showSnackBar("error" + error);
                });
        },
        getDomaindetails() {
            this.$store
                .dispatch("getDomaindetail")
                .then((response) => {
                    let datas1 = response.data;
                    for (let k = 0; k < datas1.length; k++) {
                        this.domain.push({ name: datas1[k].type, value: datas1[k].name });
                    }
                })
                .catch(function (error) {
                    this.page.isAddPausecode = false;
                    this.showSnackBar("error" + error);
                });
        },
        filterFieldData() {
            let scope = this;
            let data = { model: "custom_forms", column: ["template_name"] };

            this.$store
                .dispatch("GetModuleNameList", data)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    this.filtervalue = datas[0].template_name;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDataFromApi() {
            var scope = this;
            scope.tableLoading = true;
            scope.filter.payload.template_name = "";
            this.$store
                .dispatch("GetCustomForm")
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    if (datas !== "" && datas !== undefined && datas !== null) {
                        scope.filtervalue = [];
                        datas.forEach((element) => {
                            scope.filtervalue.push(element["template_name"]);
                        });
                    }
                    scope.customformData = datas;
                })
                .catch(function (error) {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("error" + error);
                    scope.page.isEditCustomform = false;
                });
        },
        AddNewForm: function () {
            var scope = this;
            scope.page.isAddCustomForm = true;
        },
        closeEditRules: function () {
            this.page.isEditCustomform = false;
        },
        closeAddCustomform: function () {
            this.page.isAddCustomForm = false;
            this.formDomain = "";
            this.formindusties = "";
        },
        saveEditRules() {
            var scope = this;
            scope.$store
                .dispatch("UpdateFields", scope.editCustomform)
                .then(() => {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("Updated Successfully");
                    this.page.isEditCustomform = false;
                })
                .catch(function (error) {
                    scope.page.isAddCustomForm = false;
                    scope.showSnackBar("error" + error);
                    this.page.isEditCustomform = false;
                });
            this.getDataFromApi();
        },
        saveAddCustomform() {
            var scope = this;
            let finaldata = {
                formDomain: scope.formDomain,
                formindusties: scope.formindusties,
            };

            if (this.$refs.customformvalidation.validate()) {
                scope
                    .GetDefaultFields(finaldata)
                    .then(() => {
                        scope.$router.push({
                            name: "crm/CustomForm",
                        });
                    })
                    .catch(function (error) {
                        scope.page.isAddCustomForm = false;
                        scope.showSnackBar("error" + error);
                    });
            } else {
                scope.page.isAddCustomForm = true;
            }
        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        deleteItem(item) {
            this.DeleteRowdata = true;
            this.delete_item = item;
        },
        editItem(item) {
            var scope = this;
            scope.$store
                .dispatch("GetFormFields", item.template_name)
                .then(() => {
                    scope.$router.push({
                        name: "crm/CustomForm",
                    });
                })
                .catch(function (error) {
                    scope.showSnackBar("error" + error);
                });
        },
   
        savedelete() {
            let scope = this;
            scope.isProcessing = true;
            this.$store
                .dispatch("deleteCustomform", this.delete_item)
                .then((response) => {
                    scope.isProcessing = false;
                    if (response.statusCode === "200") {
                        scope.showSnackBar("Deleted Successfully");
                        scope.getDataFromApi();
                    } else if (response.statusCode === "403") {
                        //scope.showSnackBar("Restricted: Template is mapped to campaign");
                        scope.delete_popup = true;
                    }
                })
                .catch(function (error) {
                    scope.isProcessing = false;
                    scope.showSnackBar(error);
                });
            this.closedelete();
        },
        closedeleteRestricted() {
            this.delete_popup = false;
        },
        closedelete() {
            this.DeleteRowdata = false;
        },
        closeShow() {
            this.showRowData = false;
        },
    },
    computed: {
        ...mapGetters(["CallFlow"]),
    },
    mounted() {
        let scope = this;
        this.page.loading = false;
        this.getIndustrydetails();
        this.getDomaindetails();
        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:custom-form:create"
                    ) !== -1
                ) {
                    scope.page.addnew = [{ label: "Add Template" }];
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:custom-form:edit"
                    ) !== -1
                ) {
                    scope.page.canedit = true;
                }
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:custom-form:delete"
                    ) !== -1
                ) {
                    scope.page.candelete = true;
                }
            }.bind(scope),
            1
        );
        // this.page.addnew = [{ label: 'Add Custom Form' }]
        // scope.page.candelete = true
        // scope.page.canedit = true
        const project_config = JSON.parse(localStorage.projectConfig);
        this.crmdomain = project_config.projects[0].config[0].crm_domain;
        this.getDataFromApi();
        this.filterFieldData();
    },
    "filter.search"(val) {
        if (this.page.isFilterOpen && val != null) {
            let scope = this;

            let data = {
                model: "custom_forms",
                column: ["template_name"],
                search: val,
            };

            this.$store
                .dispatch("GetModuleNameList", data)
                .then((response) => {
                    scope.tableLoading = false;
                    scope.page.loading = false;
                    var datas = response.data;
                    this.filtervalue = datas[0].template_name;
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
    watch: {
        "filter.search"(val) {
            if (this.page.isFilterOpen && val != null) {
                let scope = this;

                let data = {
                    model: "custom_forms",
                    column: ["template_name"],
                    search: val,
                };

                this.$store
                    .dispatch("GetModuleNameList", data)
                    .then((response) => {
                        scope.tableLoading = false;
                        scope.page.loading = false;
                        var datas = response.data;
                        this.filtervalue = datas[0].template_name;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>
<style>
.file-dummy {
    width: 100%;
    padding: 30px;
    border: 2px dashed;
    text-align: center;
    transition: #2196f333 0.3s ease-in-out;
    cursor: pointer;
    font-weight: bold;
}

.application a {
    text-decoration: none;
}

.dialog_cls {
    position: absolute;
}

.pop_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.insertflex1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 55px;
    padding: 2px 10px 0px 20px;
    margin-bottom: 10px;
}

.profil_pics {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.profil_txts {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%),
        0 13px 24px -11px rgb(33 150 243 / 60%);
}

.icon_cls_custom_form {
    margin-top: 10px;
    font-size: 20px;
    padding: 0px 20px 0px 20px;
}
</style>
