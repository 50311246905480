<template>
  <div id="watsAppFilter">
    <div class="datetimefilter">
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>From</label>
        <div>
          <datetime
            class="theme-orange"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            v-model="filter_fromdate"
          ></datetime>
        </div>
      </div>
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>To</label>
        <div>
          <datetime
            class="theme-orange"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            v-model="filter_todate"
          >
          </datetime>
        </div>
      </div>
    
    </div>
      <v-autocomplete
        v-model="appliedfilter.agent"
        :items="filteragents"
        label="Agent"
        persistent-hint
        small
        multiple
        single-line
        :allow-overflow="false"
      >
      </v-autocomplete>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["appliedfilter"],
  data() {
    return {
      page: {
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      filter_fromdate: "",
      filter_todate: "",
      filteragents: [],
    };
  },
  watch: {
    appliedfilter: {
      handler: function () {
        if (
          this.appliedfilter.fromdate == this.filters.fromdate &&
          this.appliedfilter.todate == this.filters.todate
        ) {
          this.date_flag = false;
          this.filter_fromdate = this.appliedfilter.fromdate;
          this.filter_todate = this.appliedfilter.todate;
        }
      },
      deep: true,
    },
    filter_fromdate(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      // this.$emit("getDataFrom");
    },
    filter_todate(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      //    this.$emit("getDataFrom");
    },
   "appliedfilter.agent"(newVal) {
      this.$store.commit("FILTER_AGENT", { agent: newVal });
    },
  },
  computed: {
    ...mapGetters(["filters", "filter"]),
  },
  mounted() {
    this.filteragents = JSON.parse(localStorage.getItem("agent_emails"));
    this.filter_fromdate = moment(this.filters.fromdate).format();
    this.filter_todate = moment(this.filters.todate).format();
  },
};
</script>

<style>
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #2196f3;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #2196f3;
}
</style>