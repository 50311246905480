<template>
  <div>
    <section-definition
      :title="page.title"
      :extraOptions="page.options"
      :icon="page.icon"
      :breadcrumbs="page.breadcrumbs"
      :exportOptions="page.exportOptions"
      @openFilter="openAgentActivityFilter"
      @refreshPage="refreshAgentActivityPage"
      @exportData="exportAgentActivityReport"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table
        :headers="headers"
        :items="agentActivity"
        class="elevation-1" 
        :pagination.sync="pagination"
        :total-items="agentactivitytotal"
        :rows-per-page-items="[5, 10, 25]"
      >
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.date_time }}</td>
          <td class="checkEmpty p0">{{ props.item.agent }}</td>
          <td class="checkEmpty p0">{{ props.item.team }}</td>
          <td class="checkEmpty p0">{{ props.item.agent_name }}</td>
          <td class="checkEmpty p0">{{ props.item.event }}</td>
          <td class="checkEmpty p0" v-if="props.item.event_data != 'null'">
            {{ props.item.event_data }}
          </td>
          <td class="checkEmpty p0" v-else></td>
          <td class="checkEmpty p0">{{ props.item.event_start_time }}</td>
          <td class="checkEmpty p0">{{ props.item.event_end_time }}</td>
          <td class="checkEmpty p0">{{ props.item.duration }}</td>
        </template>
      </v-data-table>
    </v-container>
    <div>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="1000">
          <pdfexport
            v-if="dialog"
            :header="pdf.header"
            :pdfdata="pdf_data"
            @closeModel="closeModel"
            title="Agent Activity Report"
          ></pdfexport>
        </v-dialog>
      </v-layout>
    </div>
    <v-navigation-drawer
      fixed disable-resize-watcher
      right
      v-model="page.isFilterOpen"
      class="filterNavigation"
    >
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
      <v-container class="sidenavContainer">
        <AgentActivityFilter
          v-if="page.isFilterOpen"
          @getDataFrom="getDataFrom"
          :appliedfilter="appliedfilter"
        ></AgentActivityFilter>
      </v-container>
      <v-container row class="sidenavFooter">
        <v-btn flat @click="resetvalue()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getDataFromApi">Filter</v-btn>
      </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import AgentActivityFilter from "./Components/AgentActivityFilter";
import { mapGetters } from "vuex";
import pdfexport from "./Components/pdfexport";
import moment from "moment";
export default {
  components: {
    loader,
    AgentActivityFilter,
    pdfexport,
  },
  computed: {
    ...mapGetters(["agentActivity"]),
  },
  mounted() {
    let scope = this;
    (this.page.loading = false), (this.page.loading = false);
    // this.$store.dispatch("reportuserlist").then(() => {
    //   this.$store.dispatch("ReportcampaignList");
    //   // this.getDataFromApi();
    //   this.dateformate();
    // });
    this.$store.dispatch("ReportcampaignList");
    this.dateformate();
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:agentactivity:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
      }.bind(scope),
      1
    );
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Agent Activity Report",
        icon: "fa-user fa-2x",
        loading: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true },
      },
      pagination: {
        rowsPerPage: 10,
      },
      headers: [
        {
          text: "Date",
          align: "left",
          sortable: true,
          class: "p0",
          value: "date_time",
        },
        {
          text: "Agent",
          align: "left",
          sortable: false,
          class: "p0",
          value: "agent",
        },
        {
          text: "Agent Name",
          align: "left",
          sortable: false,
          class: "p0",
          value: "agent_name",
        },
        {
          text: "Team",
          align: "left",
          sortable: false,
          class: "p0",
          value: "team",
        },
        {
          text: "Event",
          align: "left",
          sortable: false,
          class: "p0",
          value: "event",
        },
        {
          text: "Reason",
          align: "left",
          sortable: false,
          class: "p0",
          value: "event_data",
        },
        {
          text: "Start Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "event_start_time",
        },
        {
          text: "End Time",
          align: "left",
          sortable: false,
          class: "p0",
          value: "event_end_time",
        },
        {
          text: "Duration",
          align: "left",
          sortable: false,
          class: "p0",
          value: "duration",
        },
      ],
      agentactivityreport: [],
      agentactivitytotal: 0,
      pdf: {
        header: [
          { text: "Date", align: "left", sortable: true, value: "date" },
          { text: "Agent", align: "left", sortable: false, value: "agent" },
          {
          text: "Team",
          align: "left",
          sortable: false,
          class: "p0",
          value: "team",
        },
          { text: "Event", align: "left", sortable: false, value: "event" },
          {
            text: "Reason",
            align: "left",
            sortable: false,
            value: "event_data",
          },
          {
            text: "Start Time",
            align: "left",
            sortable: false,
            value: "event_start_time",
          },
          {
            text: "End Time",
            align: "left",
            sortable: false,
            value: "event_end_time",
          },
          {
            text: "Duration",
            align: "left",
            sortable: false,
            value: "duration",
          },
        ],
      },
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        agent: "",
        detail: "agentactivityreport",
        descending: false,
        export: "exportcalldetails",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      excel_data: [],
      txt_data: [],
      pdf_data: [],
      from_date: "",
      to_date: "",
      appliedfilter: {
        phonenumber: "",
        did: "",
        queue: "",
        fromdate: "",
        todate: "",
        flag: "",
        last_app: "",
        campaign: "",
        agent: "",
        event_data:[],
      },
      page_flag: false,
      dialog: false,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    openAgentActivityFilter: function () {
      this.payload.agent = JSON.parse(localStorage.getItem("agent_emails"));
      this.page.isFilterOpen = true;
    },
    closeModel: function () {
      this.dialog = false;
    },
    refreshAgentActivityPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.dateformate();
      scope.refreshvalue();
      scope.getDataFromApi();
      scope.appliedfilter.last_app = "";
      scope.appliedfilter.campaign = "";
      scope.appliedfilter.agent = "";
    },
    resetvalue() {
      this.refreshvalue()
      this.skip = 0;
      var to_date = new Date();
      to_date = moment(to_date).format("YYYY-MM-DD");
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format("YYYY-MM-DD");
      this.$store.commit("FILTERFROMDATE", from_date);
      this.$store.commit("FILTER_TODATE", to_date);
      this.appliedfilter = {
        phonenumber: "",
        did: "",
        queue: "",
        fromdate: from_date,
        todate: to_date,
        flag: false,
        last_app: "",
        campaign: "",
        agent: "",
        event_data: [],
      };
      this.payload = {
        from_date: from_date,
        to_date: to_date,
        skip: 0,
        take: this.pagination.rowsPerPage,
        agent: "",
        detail: "agentactivityreport",
        descending: false,
        export: "exportcalldetails",
        event_data: [],
        event: ""
      };
      this.getDataFromApi(true);
      this.page.isFilterOpen = true;
    },
    exportAgentActivityReport: function (option) {
      if (option == "CSV" || option == "Txt(Tab-Delimited)") {
        this.export(option);
      } else {
        this.dialog = true;
        this.export(option);
        this.pdf_flag = true;
      }
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD");
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    getDataFromApi(isReset = false) {
      var scope = this;
      if (!isReset) {
        this.payload.to_date = this.to_date;
        this.payload.from_date = this.from_date;
        this.payload.agent = this.appliedfilter.agent;
        this.payload.event_data = this.appliedfilter.event_data;
        this.payload.event = this.appliedfilter.event;
      }
      var { page, rowsPerPage, descending } = this.pagination;

      if (rowsPerPage == -1) {
        this.payload.take = this.total;
      } else {
        this.payload.take = rowsPerPage;
      }

      if (page != "" && page != undefined && page != null) {
        this.skip = page;
      } else {
        this.skip = 1;
      }
      this.payload.skip = (this.skip - 1) * this.payload.take;
      this.payload.descending = descending;
      scope.$store
        .dispatch("agentactivityreports", this.payload)
        .then((response) => {
          scope.page.loading = false;
          if (scope.page_flag) {
            this.pagination.page = 1;
            scope.page_flag = false;
          }
          scope.total = response.total;
          this.agentactivitytotal = scope.total;
          // resolve(scope.total);
          if (response.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
      // });
      // } catch (err) {
      //   alert(err);
      // }
      // })
      // .catch(function (err) {
      //     if (err.response.status === 401) {
      //     scope.$store.dispatch("checkingtoken");
      //   }
      // });
      scope.page.isFilterOpen=false
    },
    getDataFrom(data) {
      this.appliedfilter.event_data= data;
    },

    export(option) {
      this.excel_data = [];
      this.pdf_data = [];
      var add_details = {
        from_date: this.payload.from_date,
        to_date: this.payload.to_date,
        misseddetail: "AgentActivityReport",
      };
      this.$store.dispatch("exportdatafetch", add_details).then((response) => {
        this.exports = response;
        for (var i = 0; i < this.exports.length; i++) {
          var start = new Date(response[i].event_start_time);
          var end = new Date(response[i].event_end_time);
          var options = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          };
          var startString = start.toLocaleString("en-US", options);
          var endString = end.toLocaleString("en-US", options);
          this.call_date = response[i].date_time;
          var StartTime =
            response[i].event_start_time.substr(0, 11) + startString;
          var EndTime = response[i].event_end_time.substr(0, 11) + endString;
          response[i].agent = response[i].agent == null ? "" : response[i].agent
          response[i].agent_name = response[i].agent_name == null ? "" : response[i].agent_name
          response[i].team = response[i].team == null ? "" : response[i].team
          response[i].event = response[i].event == null ? "" : response[i].event
          response[i].event_data = response[i].event_data == null ? "" : response[i].event_data
          response[i].duration = response[i].duration == null ? "" : response[i].duration
          this.excel_data.push({
            Date: this.call_date,
            Agent: response[i].agent.replace(/'|"|#|\n/g, " "),
            agentName:response[i].agent_name,
            team:response[i].team,
            event: response[i].event.replace(/'|"|#|\n/g, " "),
            reason: response[i].event_data.replace(/'|"|#|\n/g, " "),
            StartTime: StartTime.replace(/'|"|#|\n/g, " "),
            EndTime: EndTime.replace(/'|"|#|\n/g, " "),
            duration: response[i].duration.replace(/'|"|#|\n/g, " "),
          });
         
          this.txt_data.push({
            Date: this.call_date,
            Agent: response[i].agent,
            agentName:response[i].agent_name,
            team:response[i].team,
            event: response[i].event,
            reason: response[i].event_data,
            StartTime: StartTime,
            EndTime: EndTime,
            duration: response[i].duration,
          });
          this.pdf_data.push([
            this.call_date,
            response[i].agent,
            response[i].event,
            response[i].event_data,
            StartTime,
            EndTime,
            response[i].duration,
          ]);
        }
        if (option == "CSV") {
          let csvContent = '';
          csvContent +=
            "Date,Agent,Agent Name,Team,Event,Reason,StartTime,EndTime,Duration\r\n";
          this.excel_data.forEach(function (rows) {
            let data = Object.values(rows);
             for (let i = 0; i < data.length; i++) {
              if (
                data[i] != null &&
                data[i] != undefined &&
                data[i] != "" && 
                typeof data[i] == "string"
              ) {
                if (data[i].includes(",")) {
                  data[i] = data[i].replaceAll(",", ";");
                }
              }
            }
            let row = data.join(",");
            csvContent += row + "\r\n";
          });
            var date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "AgentAvtivityReport" + date + ".csv");
          document.body.appendChild(link); // Required for FF
          link.click();
        }
        if (option == "Txt(Tab-Delimited)") {
          let txtContent = "";
          let txt_key = ["Date","Agent","Agent Name","Team","Event","Reason","StartTime","EndTime","Duration"]
          let txt_tab = txt_key.join("\t");
          txtContent += `${txt_tab} \r\n`;
          this.txt_data.forEach(function (rows) {
            let data = Object.values(rows);
            let row = data.join("\t");
            txtContent += row + "\r\n";
          });
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);

          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute("download", "AgentAvtivityReport" + date2 + ".txt");
          document.body.appendChild(link1);
          link1.click();
        }
      });
    },
    refreshvalue() {
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.$store.commit("AGENT_CAMPAIGN", { campaign: "" });
      this.$store.commit("FILTER_Event", { event: "" });
    },
  },
  beforeDestroy() {
    this.$store.commit("FILTER_AGENT", { agent: "" });
    this.$store.commit("AGENT_CAMPAIGN", { campaign: "" });
    this.$store.commit("FILTER_Event", { event: "" });
  },
};
</script>
