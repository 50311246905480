<template>
  <div id="custom_table">
    <div>
      <div class="toolbar_operations">
        <v-icon :title="hasFilteredData ? 'Filter active' : 'Filter'" class="fa fa-filter12 filter-icon"
          :class="{ 'filter-active': hasFilteredData }" small @click="isFilter()" aria-hidden="true"></v-icon>
        <v-icon title="Refresh" small @click="refreshClick()" class="fa fa-refresh fa-refresh1"></v-icon>
        <v-icon title="Upload Leads" small v-show="pagecontrol.fileupload" @click="UploadFile()" class="fas fa-upload">
        </v-icon>
        <v-icon title="download Leads" small v-show="pagecontrol.export" @click="exportpopup()"
          class="fas fa-download1">
        </v-icon>
        <v-icon title="Create Leads" small v-show="pagecontrol.cancreate" @click="insertDetails()" class="fas fa-plus1">
        </v-icon>
        <v-icon v-if="(hasFilteredData || bulk_edit_arr.length > 0) && AsterLeadValue.length > 0" title="Bulk Edit" small @click="editClick()"
          class="fa fa-pencil-square-o"></v-icon>
        <div v-else></div>
        <v-icon v-if="bulk_edit_arr.length > 0" title="Bulk Delete" small @click="deleteClick()"
          class="fas fa-trash-alt"></v-icon>
        <div v-else></div>
      </div>
      <div class="v-toolbar__title titletxt " v-if="bulk_edit_arr.length">Selected Data Count:{{ bulk_edit_arr.length }}</div>
      <div>
        <v-data-table :no-data-text="nodata_msg" :headers="headers" :items="AsterLeadValue" class="elevation-1"
          hide-default-header :headers-length="10" hide-actions>
          <template v-slot:headers="props" id="table-scroll">
            <tr style="height: 30px">
              <th style="height: 32px; width: 50px">
               
                <v-checkbox v-if=" AsterLeadValue.length===0" @change="select_all()" style="padding-top: 12px; padding-left: 2px; height: 50px"
                  v-model="select_check_stat" color="primary">
                </v-checkbox>
                <v-checkbox v-else @change="select_all()" style="padding-top: 12px; padding-left: 2px; height: 50px"
                  v-model="select_check " color="primary">
                </v-checkbox>
              </th>
              <th style="min-width: 150px; text-align: center" v-for="(head, i) in props.headers" :key="i">
                {{ head.text }}
              </th>
              <th style="text-align: center">
                Action
                <v-icon small class="icon-padding" @click="openPopupHeader">mdi-settings</v-icon>
              </th>
            </tr>
          </template>
          <template v-slot:items="props">
            <tr v-if="AsterLeadValue.length > 0">
              <td>
                <v-checkbox v-model="props.item.checker" primary @change="newValue(props.item)" hide-details
                  color="primary"></v-checkbox>
              </td>

              <td style="min-width: 150px; text-align: center" class="checkEmpty"
                v-for="(property, index) in Object.keys(props.item)" :key="index">
                <p style="
                margin-top: 14px
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                " v-if="property !== 'checker'">
                  {{
          (props.item[property] == "" ||
            props.item[property] == null ||
            props.item[property] == undefined)
            ? "UNASSIGN"
            : headers[index].value == property &&  headers[index].masking ? ChartoMaskingChar(props.item[property]) :props.item[property] 
        }}
                </p>
                <div class="icon_section">
                  <template v-if="property == 'checker'">
                    <v-icon small class="mr-4" v-show="pagecontrol.canedit"
                      @click="insertDetails('edit', props.item.lead_id)">
                      info</v-icon>
                    <v-icon color="blue" v-show="pagecontrol.userassign" @click="assignAgent(props.item)"
                      title="Agent Assign" small class="mr-4">
                      queue</v-icon>
                    <v-icon color="red" small class="mr-4" title="Delete" v-show="pagecontrol.candelete"
                      @click="deleteDetails(props.item)">delete
                    </v-icon>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="pagination-div theme--light" style="height: 50px">
        <div flex id="row-selector">
          <div id="page-control">
            <span>{{ (currentPage - 1) * perPage + 1 }}-{{ endPage }} of
              {{ totalRows }}</span>
            <span class="action-icon">
              <v-icon medium class="move-page" @click="moveNextPage(false)">chevron_left</v-icon>
              <v-icon medium class="move-page" @click="moveNextPage(true)">chevron_right</v-icon>
            </span>
          </div>
        </div>
      </div>

      <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Delete Lead</v-toolbar-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you sure to delete this Lead?</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
            <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="process.isloading" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">{{ process.message }}</span>
            </v-card-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>The process could take a while ,Please do not reload
                this page </v-card-text>
            </v-layout>
          </v-container>
          <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="process.isloading=false">Close</v-btn>
 
                </v-card-actions> -->
        </v-card>
      </v-dialog>
      <v-dialog v-model="isShowAgentAssign" scrollable max-width="400px">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Assign Lead</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="refassign" v-model="valid">
              <v-container id="scroll-target" style="max-height: 350px" class="scroll-y">
                <template>
                  <v-select  v-model="assignedDetails.user" :items="agents" label="Agent" required item-text="name"
                  item-value="value"  multiple>
                  </v-select>
                </template>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAssign()">Cancel</v-btn>
            <v-btn color="primary" @click="submitAssign()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer fixed disable-resize-watcher right v-model="pagecontrol.isFilterOpen">
        <v-toolbar small color="secondary" style="color: white">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon flat color="white" @click="pagecontrol.isFilterOpen = false">
            <i class="fas fa-close"></i>
          </v-btn>
        </v-toolbar>
        <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
          <v-container class="sidenavContainer">
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[0]" :key="indexfield">
                <div class="autocompleteDiv">
                  <v-text-field v-if="field.text !== 'deleted' && field.text !== 'isclosed'" v-model="field.value"
                    :label="field.text" small></v-text-field>
                </div>
              </div>
            </div>

            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[1]" :key="indexfield">
                <div class="autocompleteDiv" v-if="!field.isMultiSelect">
                  <v-select :items="field.options" :label="field.text" v-model="field.value" item-text="name"
                    item-value="value"></v-select>
                </div>
              </div>
            </div>
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[1]" :key="indexfield">
                <div class="autocompleteDiv" v-if="field.isMultiSelect">
                  <v-select :items="field.options" :label="field.text" v-model="field.value" item-text="name"
                    item-value="value" multiple></v-select>
                </div>
              </div>
            </div>
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[2]" :key="indexfield">
                <div class="autocompleteDiv">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox v-model="field.value" :label="field.text" v-bind="attrs" v-on="on">
                      </v-combobox>
                    </template>
                    <v-date-picker v-model="field.value" color="primary" @input="menu = false" width="250" no-title
                      scrollable>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
            <br />
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[3]" :key="indexfield">
                <label>{{ field.text }}</label>
                <div class="datetimefilter">
                  <v-container class="pa-1">
                    <v-menu v-model="pagecontrol.menu.fromdate" :close-on-content-click="false" label="Created At"
                      full-width max-width="290" small>
                      <template v-slot:activator="{ on }">
                        <label>From</label>
                        <datetime class="theme-orange" color="primary" style="border-bottom: 1px solid rgb(145 145 145)"
                          type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.fromdate"
                          :value="appliedfilter.fromdate" label="From Date" readonly v-on="on"
                          @change="pagecontrol.menu.fromdate = false"></datetime>
                      </template>
                    </v-menu>
                  </v-container>

                  <v-container class="pa-1">
                    <v-menu v-model="pagecontrol.menu.todate" :close-on-content-click="false" full-width max-width="290"
                      small>
                      <template v-slot:activator="{ on }">
                        <label>To</label>
                        <datetime class="theme-orange" color="primary" style="border-bottom: 1px solid rgb(145 145 145)"
                          type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="appliedfilter.todate"
                          :value="appliedfilter.todate" label="To Date" readonly v-on="on"
                          @change="pagecontrol.menu.fromdate = false"></datetime>
                      </template>
                    </v-menu>
                  </v-container>
                </div>
              </div>
            </div>
            <br />
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[4]" :key="indexfield">
                <div class="autocompleteDiv">
                  <v-checkbox v-model="field.value" :label="field.text" primary @change="newValue(props.item)"
                    hide-details color="primary"></v-checkbox>
                  <br /><br />
                </div>
              </div>
            </div>
            <div>
              <div v-for="(field, indexfield) in dynamicFilterField[5]" :key="indexfield">
                <div class="autocompleteDiv">
                  <p>{{ field.text}}</p>

                  <v-radio-group v-model="field.value">
                    <v-radio v-for="(n, indexn) in field.options" :key="indexn" :value="n" color="primary"
                      :label="`${n.value}`">
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
          </v-container>
          <v-container row class="sidenavFooter">
            <v-btn flat @click="reset()">Reset</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="fetchFilter(true)" :disabled="!isFilterApplied" >Filter</v-btn>
          </v-container>
        </div>
      </v-navigation-drawer>
      <template>
        <v-dialog persistent v-model="pagecontrol.isUploadFile" scrollable max-width="500px">
          <v-card>
            <!-- <v-toolbar dark color="primary"> -->
            <v-toolbar dark color="primary">
              <div class="pop_title">
                <div>
                  <div style="font-size: large; font-weight: 600">
                    Upload Leads
                  </div>
                </div>
                <div style="padding-top: 3px">
                  <v-icon color="light" flat style="font-size: 20px" @click="ColseFile()">
                    fa-times</v-icon>
                </div>
              </div>
            </v-toolbar>
            <!-- <v-card-title>
    <span class="headline">Upload AsterLead</span>
    </v-card-title>
    </v-toolbar> -->
            <v-card-text>
              <v-form v-if="mapper === false" ref="createform">
                <v-container grid-list-md>
                  <div class="autocompleteDiv">
                    <v-autocomplete v-model="selected_list" :items="add_list" label="List" persistent-hint small
                      required single-line :menu-props="{ allowOverflow: false }"
                      :rules="[(v) => !!v || 'list is required']"></v-autocomplete>
                    <div v-if="csvheader.length === 0">
                      <p class="pgraph">Allow duplicate numbers ?</p>
                      <label class="labelgraph">
                        <input type="radio" name="allow_access" v-model="duplicate" value="true" checked> YES
                      </label>
                      <label class="labelgraph">
                        <input type="radio" name="allow_access" v-model="duplicate" value="false"> NO
                      </label>
                    </div>
                  </div>
                  <p v-if="csvheader.length === 0">
                    <span style="font-weight: bold">Not sure what to upload?</span>
                    Click here to download the sample file. Format:
                    <a @click="samplefiledownload('csv')">CSV</a>,
                    <a @click="samplefiledownload('txt')">Txt(Tab-Delimited)</a>. Open the file and enter the phone
                    numbers at the first
                    column of each row. Save the file with .csv /.txt extension
                    and upload the file.
                  </p>
                  <div v-if="csvheader.length === 0" class="file-dummy" @click="$refs.file.click()" :style="{
                    background: uploadLead.color + '66',
                    'border-color': uploadLead.color,
                    color: uploadLead.color,
                  }">
                    {{ uploadLead.label }}
                  </div>
                  <input v-if="csvheader.length === 0" v-on:change="filenext__()" :hidden="true" type="file" id="file"
                    ref="file" accept=".csv,.txt" />
                </v-container>
              </v-form>
              <div v-if="mapper === true && csvheader.length > 0">
                <div class="map_value" v-for="(data, index_) in csvheader" :key="index_">
                  <div class="map_value_lft">
                    {{ index_ + 1 }}) {{ data.key }}
                  </div>
                  <v-autocomplete :disabled="data.value !== ''" @change="assignedval(data)" v-model="data.value"
                    :items="fields_model" item-text="name" item-value="value" item-disabled="active"></v-autocomplete>
                  <v-flex xs1>
                    <v-icon color="red" class="mt-4 ml-0 remove-icon" @click="removeItem(data, index_)">close</v-icon>
                  </v-flex>
                </div>
              </div>

              <div v-if="mapper === false &&
                selected_list !== '' &&
                csvheader.length > 0
              " class="nextbutton">
                <div @click="next__()" class="nextbutton-innerdiv">Next</div>
              </div>
              <div v-if="mapper" class="nextbutton">
                <button @click="handleFileUpload()" class="upload-button" :disabled="uploading">
                  <!-- buttonstyle     -->
                  <span v-if="!uploading">Upload</span>
                  <span v-else>
                    Please wait for Few minutes Lead Uploading...
                    <div class="progress-bar-container">
                      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
                    </div>
                  </span>
                </button>
                <!-- <div v-show="showProgress">
                <progress-bar :options="options" :value="progress" />
                </div> -->
                <!-- <br>{{ progress }} {{ "%" }}
                <ProgressBar :value="progress" max="100"> </ProgressBar> -->
              </div>
            </v-card-text>
            <!-- <v-card-actions class="model-footer--sticky">
    <v-spacer></v-spacer>
    <v-btn color="light" flat @click="ColseFile()">Cancel</v-btn>
    </v-card-actions> -->
          </v-card>
        </v-dialog>
      </template>
      <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </div>
    <tablefielddragable :headerEdit="headerEdit" :items="items" :headersfield="headersfield"
      @closePopupHeader="closePopupHeader" @savePopupHeader="savePopupHeader" />
    <exportfieldlist ref="export" :exportPopup="exportPopup" :items="headersfield" @export="exportfield" />

    <!-- Bulkedit -->
    <v-dialog persistent v-model="showEditPopup" scrollable max-width="450px" max-height="500px">
      <v-form ref="refassign" v-model="bulkeditvalid">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Leads</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select v-model="bulk_edit.user" :items="agents" label="User" required persistent-hint
                    :menu-props="{ allowOverflow: false }"></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select label="Disposition" :items="filter_Dis" return-string v-model="bulk_edit.disposition"
                    persistent-hint></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select label="List ID" :items="add_list" v-model="bulk_edit.list_id" persistent-hint>
                  </v-select>
                </v-flex>
                <!-- hint here -->
                <!-- <v-flex xs12 v-if="!filtersApplied">
                  <v-card class="mt-2" color="grey lighten-4" dense>
                    <v-card-text class="pa-1 d-flex align-center" style="font-size: 14px; color: grey;">
                      <span>Apply filters to unlock advanced bulk update features!</span>
                    </v-card-text>
                  </v-card>
                </v-flex> -->
                <v-flex xs12 v-if="filtersApplied">
                  <v-radio-group v-model="editMode" @change="resetEditLimitedCount">
                    <v-radio label="Edit selected Leads" value="selected" color="primary"
                      :disabled="hasSelectedLeads()"></v-radio>
                    <v-radio label="Advance settings" value="advanced" color="primary"></v-radio>
                  </v-radio-group>
                </v-flex>
                <template v-if="editMode === 'advanced'">
                  <v-flex xs12 v-if="filtersApplied">
                    <v-radio-group v-model="editModeAdvanced" @change="resetEditLimitedCount">
                      <v-radio :label="`Edit all ${this.totalRows} leads`" :value="'all'" color="primary"></v-radio>
                      <v-radio label="Edit Limited Leads" value="limited" color="primary"></v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex xs12 v-if="editModeAdvanced === 'limited'">
                    <!-- <v-text-field v-model="editLimitedCount" label="Edited lead count" type="number"
                      :rules="editLimitedCountRules" counter="1000" max="1000"></v-text-field> -->
                    <v-flex xs12 v-if="editModeAdvanced === 'limited'">
                      <v-text-field v-model.number="editLimitedCount" label="Edited lead count" type="number"
                        :rules="editLimitedCountRules" min="1" :max="Math.min(1000, totalRows)"
                        @input="preventLeadingZeros"></v-text-field>
                    </v-flex>
                  </v-flex>
                </template>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-btn color="primary" @click="closeEditPopup()" text>Close</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="proceedWithUpdate()"
              :disabled="canProceedWithUpdate() || disabling() || !bulkeditvalid">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog persistent v-model="delete_popup" max-width="400px">
      <v-form ref="refassign">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Delete Bulk leads</v-toolbar-title>
          </v-toolbar>

          <v-container id="scroll-target" style="max-height: 350px; font-size: 16px;" class="scroll-y">
            <p style="font-size: 16px;">Do you want to delete bulk leads?</p>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="delete_popup = false">Cancel</v-btn>
            <v-btn color="primary" @click="bulkDelete(true)">All {{ totalRows }} Leads</v-btn>
            <v-btn color="primary" @click="bulkDelete(false)">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog persistent v-model="export_popup" scrollable max-width="470px">
      <v-card>
        <v-toolbar dark color="primary">
          <div class="pop_title">
            <div>
              <div style="font-size: large; font-weight: 600">
                Download Leads
              </div>
            </div>
            <div style="padding-top: 3px">
              <v-icon color="light" flat style="font-size: 20px" @click="export_popup = false">fa-times</v-icon>
            </div>
          </div>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md>
            <div>
              <div class="exportflex">
                <div class="export" @click="exportAction('csv')">
                  <v-icon class="hovericon" size="60">fa fa-file-code-o
                  </v-icon>
                  <div style="
                      font-size: 20px;
                      color: #2c3e50;
                      font-weight: 800;
                      padding: 5px;
                    ">
                    CSV
                  </div>
                </div>
                <div class="export" @click="exportAction('txt')">
                  <v-icon class="hovericon" size="60">fa-file-text </v-icon>
                  <div style="
                      font-size: 20px;
                      color: #2c3e50;
                      font-weight: 800;
                      padding: 5px;
                    ">
                    TXT
                  </div>
                </div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="model-footer--sticky">
          <v-spacer></v-spacer>
          <!-- <v-icon color="light" flat size="35" @click="closedownload">fa-window-close-o</v-icon> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tablefielddragable from "@/components/tablefielddragable";
import exportfieldlist from "@/components/exportfieldlist";
import moment from "moment";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import { mapGetters } from "vuex";
import api from "@/utils/axios-util";

// import moment from "moment";
const { convertArrayToCSV } = require("convert-array-to-csv");
import { MaskCharacter } from "@/utils/helpers";
function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
export default {
  name: "App",
  components: {
    tablefielddragable,
    exportfieldlist,
  },
  data() {
    return {
      showEditPopup: false,
      editMode: 'selected',
      editModeAdvanced: 'all',
      editLimitedCount: null,
      editLimitedCountRules: [
        v => v <= this.totalRows || `Edited lead count must be less than or equal to ${this.totalRows}`,
        v => v <= 1000 || 'Maximum of only 1000 records can be edited at a time',
        v => v > 0 || 'Edited lead count must be greater than 0',
      ],
      filtersApplied: false,
      countdown: 5,
      countdownInterval: null,
      exportPopup: {
        title: "Export Columns",
      },
      filter_Dis: [],
      process: {
        isloading: false,
        message: ''
      },
      duplicate: true,
      filter_dynamic: {},
      export_popup: false,
      currentsupport: "Lead",
      headersfield: [],
      headerEdit: false,
      mapper: false,
      showProgress: false,
      progress: 0,
      uploading: false,
      headers: [],
      menu: false,
      selected_list: "",
      add_list: [],
      perPage: 8,
      currentPage: 1,
      totalRows: 0,
      endPage: 0,
      template_name: "",
      pagecontrol: {
        title: "User Management",
        icon: "fa-user",
        loading: true,
        isFilterOpen: false,
        isAddUser: false,
        isUploadFile: false,
        exportOptions: [],
        options: {
          filter: false,
          refresh: true,
        },
        addnew: [],
        canedit: false,
        candelete: false,
        fileupload: false,
        export: false,
        cancreate: false,
        userassign: false,
        table: false,
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      AsterLeadValue: [],
      asterLeadIds: [],
      AsterLeadValueClone: [],
      bulk_edit_arr: [],
      bulk_edit: {
        user: "",
        disposition: "",
        list_id: "",
      },
      appliedfilter: {
        fromdate: "",
        todate: "",
      },
      hasFilteredData: false,
      user_email: JSON.parse(localStorage.apiInfo).user_email,
      select_label: "Select All",
      select_check_stat: false,
      select_check: false,
      delete_popup: false,
      lead_history: [],
      newData: [],
      fields: [],
      fielddata: [],
      DownloadFile111: false,
      DeleteRowdata: false,
      uploadLead: {},
      excel_data: [],
      read_data: "",
      from_date: "",
      to_date: "",
      isShowAgentAssign: false,
      assignedDetails: {},
      agents: [],
      valid: true,
      bulkeditvalid: true,
      filter: {
        phone_number: "",
        list_id: "",
      },
      filtervalue: [],
      pagination: {},
      downloadData: [],
      dynamicFilterField: [],
      fieldtype: {
        DatetimePicker: [],
        DatePicker: [],
      },
      another_filter: [],
      filter_count: '',
      csvheader: [],
      fields_model: [],
      filter_data_temp: [],
      filter_payload: {},
      Filename:"Leads",
      masking:false
    };
  },
  props: {
    titleDatas: {
      type: String,
      default: "",
    },
  },
  watch: {
    titleDatas() {
      this.headers = [];
      this.getDatafieldApi();
    },
    editMode(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetSkipTakeValues();
        this.toggleButtonDisabled();
      }
    },
    "appliedfilter.fromdate"(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      this.skip = 0;

      // this.getDataFromApi();
    },
    "appliedfilter.todate"(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      this.skip = 0;
      // this.getDataFromApi();
    },
    "DeleteRowdata"(data) {
      this.sidebar_enable_disable(data);
    },
    "isShowAgentAssign"(data) {
      this.sidebar_enable_disable(data);
    },
    "showEditPopup"(data) {
      this.sidebar_enable_disable(data);
    },
    "delete_popup"(data) {
      this.sidebar_enable_disable(data);
    },
    "export_popup"(data) {
      this.sidebar_enable_disable(data);
    },
    "headerEdit"(data) {
      this.sidebar_enable_disable(data);
    }
  },
  computed: {
    ...mapGetters(["GetAsterLead","MaskingModules"]),
    canEditLimited() {
      return this.editMode === 'limited' && this.skipValue !== null && this.takeValue !== null;
    },
    canEditAll() {
      return this.editMode === 'all';
    },

    sortOptions() {
      return this.newData
        .filter((f) => f.sortable)
        .map((f) => {
          return {
            text: f.label,
            value: f.key,
          };
        });
    },
    nodata_msg() {

      return "No data available";

    },
    isFilterApplied() {
      const hasDynamicFilter = this.dynamicFilterField.some(group =>
        group.some(field => {
          if (Array.isArray(field.value)) {
            return field.value.length > 0;
          }
          if (typeof field.value === 'number') {
            return field.value !== null && field.value !== undefined;
          }
          return field.value && field.value.trim && field.value.trim().length > 0;
        })
      );
      const hasBothDates = this.appliedfilter.fromdate && this.appliedfilter.todate;
      const hasAnyDate = this.appliedfilter.fromdate || this.appliedfilter.todate;
      if (hasAnyDate && !hasBothDates) {
      return false;
      }
     return hasDynamicFilter || hasBothDates;
    }
  },
  methods: {
    disabling() {
      console.log("filtersApplied", this.filtersApplied);
      const scope = this;
      if (scope.bulk_edit.list_id || scope.bulk_edit.disposition || scope.bulk_edit.user) {
        return false;
      }
      return true;
    },
    hasSelectedLeads() {
      if (this.bulk_edit_arr.length <= 0) {
        this.editMode = 'advanced';
        return true;
      }
    },
    resetEditLimitedCount() {
      this.editLimitedCount = null;
    },
    proceedWithUpdate() {
      if (this.editMode === 'advanced') {
        if (this.editModeAdvanced === 'all') {
          this.bulkEdit('all');
        } else if (this.editModeAdvanced === 'limited') {
          this.bulkEdit('limited', this.editLimitedCount);
        }
      } else {
        this.bulkEdit('selected');
      }
    },
    canProceedWithUpdate() {
      if (this.editMode === 'selected') {
        return false;
      } else if (this.editMode === 'advanced') {
        if (this.editModeAdvanced === 'all') {
          return !(this.filtersApplied);
        } else if (this.editModeAdvanced === 'limited') {
          return !(this.filtersApplied && this.editLimitedCount > 0 && this.editLimitedCount <= 1000);
        }
      }
      return false;
    },
    closeEditPopup() {
      const scope = this
      this.$refs.refassign.resetValidation();
      this.process.isloading = false;
      this.bulkeditvalid = true
      this.showEditPopup = false;
      this.editMode = 'selected';
      this.editModeAdvanced = 'all';
      this.editLimitedCount = null;
      scope.bulk_edit.list_id = ''
      scope.bulk_edit.disposition = ''
      scope.bulk_edit.user = ''
    },
    exportfield({ field, type }) {
      const scope = this;
      scope.filedownload(type, field);
    },

    exportAction(type) {
      const scope = this;
      scope.export_popup = false;
      scope.$refs.export.OpenPopup(type);
    },
    exportpopup() {
      this.export_popup = true;
    },
    openPopupHeader() {
      let scope = this;
      scope.headerEdit = true;
    },
    closePopupHeader() {
      let scope = this;
      scope.headerEdit = false;
    },
    preventLeadingZeros(value) {
      if (value && value.startsWith('0') && value !== '0') {
        this.editLimitedCount = parseInt(value, 10).toString();
      }
    },
    savePopupHeader(data) {
      try {
        let scope = this;
        let clone_data = [...data];
        let payload = {
          fields: clone_data.map((elm) => {
            delete elm.lable;
            delete elm.model;
            delete elm.item;
            return elm;
          }),
        };
        scope.closePopupHeader();
        this.$parent.isProcessing = true;
        scope.pagecontrol.loading = true;
        scope.$store
          .dispatch("UpdateLeadsTableHeader", payload)
          .then((response) => {
            if (response.status === 200) {
              scope.headersfield = [];
              scope.showSnackBar("Lead Header Updated successfully");
              scope.closePopupHeader();
              this.$parent.isProcessing = false;
              scope.getDatafieldApi(this.currentsupport);
            } else {
              scope.showSnackBar("Lead Header Updated to failed!");
            }
          })
          .catch((error) => {
            scope.showSnackBar(
              error + "Ticket Header Updated to agent failed!"
            );
          });
      } catch (error) {
        console.log(error);
      }
    },

    assignedval(data) {
      for (let i = 0; i < this.fields_model.length; i++) {
        const element = this.fields_model[i];
        if (data.value === element.name) {
          element.active = true;
        }
      }
    },
    removeItem(data, index) {
      for (let i = 0; i < this.fields_model.length; i++) {
        const element = this.fields_model[i];
        if (data.value === element.name) {
          element.active = false;
        }
      }
      this.csvheader[index].value = "";
    },
    datetimeformate(data) {
      let datetime = new Date(data);
      let dd =
        datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
      let mm =
        datetime.getMonth() + 1 < 10
          ? `0${datetime.getMonth() + 1}`
          : datetime.getMonth() + 1;
      let hr =
        datetime.getHours() < 10
          ? `0${datetime.getHours()}`
          : datetime.getHours();
      let min =
        datetime.getMinutes() < 10
          ? `0${datetime.getMinutes()}`
          : datetime.getMinutes();
      return datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`;
    },
    dateformate(data) {
      let datetime = new Date(data);
      let dd =
        datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
      let mm =
        datetime.getMonth() + 1 < 10
          ? `0${datetime.getMonth() + 1}`
          : datetime.getMonth() + 1;
      return datetime.getFullYear() + `-${mm}-${dd}`;
    },

    filedownload(value, field) {
      try {
        let scope = this;
        const payload = {
          status: "",
          skip: scope.perPage * (scope.currentPage - 1),
          take: scope.perPage,
          download: true,
          name: this.$parent.titleData,
          from_date:
            this.appliedfilter.fromdate != ""
              ? this.datetimeformate(this.appliedfilter.fromdate)
              : "",
          to_date:
            this.appliedfilter.todate != ""
              ? this.datetimeformate(this.appliedfilter.todate)
              : "",
        };
        if (scope.$parent.isfilterval == true) {
          payload.filterValues = scope.filter_data_temp

        }
        scope.$store
          .dispatch("GetAsterLeadData", payload)
          .then((response) => {
            if(response.totalCount > 100000){
              scope.showSnackBar(response.message)
              return
            }
            let datas = response.data;
            console.log("datas ", datas);
            if (value == "csv") {
              let csv = "";
              for (let row = 0; row < datas.length; row++) {
                if(scope.masking){
                  scope.MaskingModules.MaskingModules[this.Filename].forEach((elm)=>{
                  datas[row][elm] =  scope.ChartoMaskingChar(datas[row][elm])
                  })
                }
                let keysAmount = field.length;
                let keysCounter = 0;

                if (row === 0) {
                  for (let key in field) {
                    key = field[key];
                    csv += key + (keysCounter + 1 < keysAmount ? "," : "\r\n");

                    keysCounter++;
                  }
                  keysCounter = 0;
                  for (let key in field) {
                    key = field[key];
                    if(typeof(datas[row][key]) === "string" && datas[row][key].includes(",")){
                      datas[row][key] = `"${datas[row][key]}"`
                    }
                    csv +=
                      datas[row][key] +
                      (keysCounter + 1 < keysAmount ? "," : "\r\n");
                    keysCounter++;
                  }
                } else {
                  for (let key in field) {
                    key = field[key];
                    if(typeof(datas[row][key]) === "string" && datas[row][key].includes(",")){
                      datas[row][key] = `"${datas[row][key]}"`
                    }
                    csv +=
                      datas[row][key] +
                      (keysCounter + 1 < keysAmount ? "," : "\r\n");
                    keysCounter++;
                  }
                }

                keysCounter = 0;
              }

              let link = document.createElement("a");
              link.id = "download-csv";
              link.setAttribute(
                "href",
                "data:text/csv;charset=utf-8,%EF%BB%BF" +
                encodeURIComponent(csv)
              );
              link.setAttribute("download", `${this.currentsupport}.${value}`);
              document.body.appendChild(link);
              link.click();
              this.export_popup = false;
            } else if (value == "txt") {
              let textContent = "";
              for (let row = 0; row < datas.length; row++) {
                if(scope.masking){
                  scope.MaskingModules.MaskingModules[this.Filename].forEach((elm)=>{
                    datas[row][elm] =  scope.ChartoMaskingChar(datas[row][elm])
                  })
                }
                let keysAmount = field.length;
                let keysCounter = 0;

                if (row === 0) {
                  for (let key in field) {
                    key = field[key];
                    textContent += key + (keysCounter + 1 < keysAmount ? "\t" : "\r\n");
                    keysCounter++;
                  }
                  keysCounter = 0;
                  for (let key in field) {
                    key = field[key];
                    textContent +=
                      datas[row][key] +
                      (keysCounter + 1 < keysAmount ? "\t" : "\r\n");
                    keysCounter++;
                  }
                } else {
                  for (let key in field) {
                    key = field[key];
                    textContent +=
                      datas[row][key] +
                      (keysCounter + 1 < keysAmount ? "\t" : "\r\n");
                    keysCounter++;
                  }
                }

                keysCounter = 0;
              }
              let link = document.createElement("a");
              link.id = "download-txt";
              link.setAttribute(
                "href",
                "data:text/plain;charset=utf-8,%EF%BB%BF" +
                encodeURIComponent(textContent)
              );
              link.setAttribute("download", `${this.currentsupport}.txt`);
              document.body.appendChild(link);
              link.click();
              this.export_popup = false;
            }
          })
          .catch(function (error) {
            console.log(error,"error")
            scope.showSnackBar("error1" + error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    download_() {
      this.DownloadFile111 = true;
    },
    closedownload() {
      this.DownloadFile111 = false;
    },
    insertDetails(val, id) {
      var scope = this;
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "leadmanagement:lead:create"
        ) === -1
      ) {
        scope.$router.push({
          name: "blankPage",
        });
      } else {
        scope.$store
          .dispatch("GetFormFields", this.GetAsterLead)
          .then(() => {
            val == "edit"
              ? this.$emit("customform_edit", id)
              : this.$emit("customform_insert");
          })
          .catch(function (error) {
            scope.showSnackBar("error" + error);
          });
      }
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    newValue(row) {
      var scope = this;
      let id = row["lead_id"];
      if (!scope.bulk_edit_arr.includes(id)) {
        scope.bulk_edit_arr.push(id);
      } else {
        const index = scope.bulk_edit_arr.indexOf(id);
        if (index > -1) {
          scope.bulk_edit_arr.splice(index, 1);
        }
      }
      let temparr_id=[]
      for (let i = 0; i < this.AsterLeadValue.length; i++) {
        const element = this.AsterLeadValue[i];
       
        temparr_id.push(element.lead_id)
      }
      let counter = temparr_id.every(item2 => this.bulk_edit_arr.some(item1 => item1 === item2))
      if (counter) {
        scope.select_label = "Unselect All";
        scope.select_check = true;
      } else {
        scope.select_label = "Select All";
        scope.select_check = false;
      }
    },
    editClick() {
      this.editMode = 'selected'
      this.bulkeditvalid = true
      this.showEditPopup = true;
    },
    deleteClick() {
      this.delete_popup = true;
    },
    async bulkEdit(mode, editedLeadCount = null) {
      const scope = this;
      let payload = {};
      if (mode === 'selected') {
        payload = {
          bulk_id: scope.bulk_edit_arr,
          disposition: scope.bulk_edit.disposition,
          user: scope.bulk_edit.user,
          list_id: scope.bulk_edit.list_id,
          template_name: this.GetAsterLead,
          modified_by: JSON.parse(localStorage.apiInfo).user_email,
          editall: false
        };
      } else {
        const filterPayload = {
          name: scope.titleDatas,
          filterValues: scope.filter_data_temp, //      filterValues: scope.filter_data_temp,
          skip: 0,
          take: mode === 'limited' ? Number(editedLeadCount) : scope.totalRows,
          from_date: this.appliedfilter.fromdate !== "" ? this.datetimeformate(this.appliedfilter.fromdate) : "",
          to_date: this.appliedfilter.todate !== "" ? this.datetimeformate(this.appliedfilter.todate) : ""
        };

        if (scope.filter_payload.filterValues.length > 0 ||(scope.filter_payload.to_date &&scope.filter_payload.from_date)) {
          // Filters are applied
          payload = {
            bulk_id: [],
            disposition: scope.bulk_edit.disposition,
            user: scope.bulk_edit.user,
            list_id: scope.bulk_edit.list_id,
            template_name: this.GetAsterLead,
            modified_by: JSON.parse(localStorage.apiInfo).user_email,
            filter: filterPayload,
            totalRows: scope.totalRows,
            editall: mode === 'all'
          };
        } else {
          // No filters applied, display an error message or handle accordingly
          scope.showSnackBar("Please apply at least one filter to proceed with 'Advance settings'.");
          return;
        }
      }
      console.log("format:", payload)
      scope.closeEditPopup();
      scope.process.isloading = true;
      scope.process.message = 'Updating...';
      scope.$store.dispatch("BulkEditSave", payload)
        .then(() => {
          scope.select_check = false;
          scope.select_all();
          scope.select_label = "select All";
          scope.closeEditPopup();
          scope.showSnackBar("Lead Updated Successfully");
          scope.getDatafieldApi();
        })
        .catch(function (error) {
          scope.closeEditPopup();
          scope.showSnackBar("Error: " + error);
        });

    },
    select_all() {
      var scope = this;
      let id = "";
      if (scope.select_check) {
        scope.select_label = "Unselect All";
        // scope.bulk_edit_arr = [];
        for (let i = 0; i < scope.AsterLeadValue.length; i++) {
          scope.AsterLeadValue[i].checker = true;
          id = scope.AsterLeadValue[i]["lead_id"];
          scope.bulk_edit_arr.push(id);
        }
        scope.bulk_edit_arr= [...new Set(scope.bulk_edit_arr)]
      } else {
        let arr2=[]
        for (let j = 0; j < scope.AsterLeadValue.length; j++) {
          scope.AsterLeadValue[j].checker = false;
          id = scope.AsterLeadValue[j]["lead_id"];
          arr2.push(id)
        }
        // scope.bulk_edit_arr = [];
        scope.bulk_edit_arr=scope.bulk_edit_arr.filter(item => !arr2.includes(item));
        scope.select_label = "Select All";
      }
    },
    bulkDelete(value) {
      var scope = this;
      var delete_data = {
        bulk_id: scope.bulk_edit_arr,
        template_name: this.GetAsterLead,
        modified_by: JSON.parse(localStorage.apiInfo).user_email,
        filter: scope.filter_payload,
        totalRows: scope.totalRows,
        deleteall: value
      };
      scope.delete_popup = false;
      scope.process.isloading = true
      scope.process.message = 'Deleting...'
      scope.$store
        .dispatch("BulkDeleteSave", delete_data)
        .then((res) => {
          if (res.statusCode == 200) {
            scope.delete_popup = false;
            scope.bulk_edit_arr = [];
            scope.select_label = "Select All";
            scope.select_check = false;
            scope.showSnackBar("Lead Deleted Successfully");
            scope.getDatafieldApi();
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    get_list() {
      let scope = this
      let payload_ = { template: JSON.parse(localStorage.templetname).templetname, skip: 0, take: 0, campaign_status: 'Y', All: true };
      return scope.$store
        .dispatch("GetAsterCampaigns", payload_)
        .then(async (response) => {
          let list = []
          let defaultlist_ = []
          if (response.statusCode == 200) {
            let campaign = await response.data

            for (let i = 0; i < campaign.length; i++) {
              const element = campaign[i];
              let stats = {
                list_name: "",
                list_campaign: element.campaign_name,
                list_status: "Y",

              };

              console.log("stats:", stats)
              await scope.$store.dispatch("FilterAsterList", stats).then(async (response) => {
                let data = await response.data;
                console.log("data:", data)
                //let template=JSON.parse(localStorage.templetname).templetname
                if (data !== undefined) {
                  for (let j = 0; j < data.length; j++) {

                    const element_ = data[j];

                    if (element.primary_list == element_.list_id) {
                      defaultlist_.push(element_.list_id)
                    }


                    list.push(element_.list_id)
                  }
                }


              })
            }


          } else {
            list = []
          }

          scope.add_list = list;
          return list

        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    refresh_fun() {
      this.getDatafieldApi();
      var scope = this;
      this.get_list()
      scope.agents = JSON.parse(localStorage.getItem("agent_emails"));
      setTimeout(
        function () {
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "leadmanagement:lead:fileupload"
            ) !== -1
          ) {
            scope.pagecontrol.fileupload = true;
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "leadmanagement:lead:userassign"
            ) !== -1
          ) {
            scope.pagecontrol.userassign = true;
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "leadmanagement:lead:edit"
            ) !== -1
          ) {
            scope.pagecontrol.canedit = true;
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "leadmanagement:lead:create"
            ) !== -1
          ) {
            scope.pagecontrol.cancreate = true;
          }
          if (
            scope.$store.getters.UserPermissions.indexOf(
              "leadmanagement:lead:delete"
            ) !== -1
          ) {
            scope.pagecontrol.candelete = true;
          }
        }.bind(scope),
        1
      );

      this.uploadLead = getDefaultData();
    },

    editDetails(row) {
      row = this.AsterLeadValueClone.find((elm) => elm.lead_id == row.lead_id);
      var scope = this;
      if (
        scope.$store.getters.UserPermissions.indexOf(
          "leadmanagement:lead:edit"
        ) === -1
      ) {
        scope.$router.push({
          name: "blankPage",
        });
      } else {
        scope.$store.dispatch("Editdata", row);
        scope.$store.dispatch("GetFormFields", this.GetAsterLead).then(() => {
          scope.$router.push({
            name: "crm/EditAsterLead",
          });
        });
      }
    },
    deleteDetails(row) {
      row = this.AsterLeadValueClone.find((elm) => elm.lead_id == row.lead_id);
      var scope = this;
      if (scope.pagecontrol.candelete) {
        this.DeleteRowdata = true;
      }
      let id_value = "";
      for (const [key, value] of Object.entries(row)) {
        if (
          `${key}` === "order id" ||
          `${key}` === "ticket id" ||
          `${key}` === "lead_id"
        ) {
          id_value = `${value}`;
        }
      }
      this.delete_id = id_value;
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      let payload = {
        template_name: this.GetAsterLead,
        delete_id: this.delete_id,
        modified_by: JSON.parse(localStorage.apiInfo).user_email,
      };
      this.$store.dispatch("deleteAsterLead", payload).then((response) => {
        scope.isProcessing = false;
        if (response.statusCode == 200) {
          scope.showSnackBar("Lead Deleted Successfully");
          scope.getDatafieldApi();
        } else {
          scope.showSnackBar("Error! Unable Delete Lead");
        }
      });
      this.closedelete();
      this.getDatafieldApi();
    },
    userDatas() {
      let scope = this
      let payload = {
        skip: 0,
        take: 100,
        status:'active'
      }
      scope.$store
        .dispatch("userManagement", payload).then((response) => {
          const agentRoles = response.data.data.filter(item => item.roles.some(role => role.name === "Agent"));
          const AdminRoles = response.data.data.filter(item => item.roles.some(role => role.name === "Administrator"));
          this.agentEmails = agentRoles.map(item => item.user_email);
          let AdminEmails = AdminRoles.map(item => item.user_email);
          localStorage.setItem("agent_emails", JSON.stringify(this.agentEmails));
          localStorage.setItem("admin_emails", JSON.stringify(AdminEmails));
        })
    },
    reset() {
      this.$parent.isfilterval = false;
      this.filter_payload = {}
      this.filter_dynamic = {}
      this.filter_data_temp = [];
      this.filtersApplied = false
      this.hasFilteredData = false
      this.dynamicFilterField.forEach((fie) => {
        fie.forEach((sub) => {
          if (sub.value != "") {
            sub.value = "";
          } else {
            this.appliedfilter.fromdate = "";
            this.appliedfilter.todate = "";
          }
        });
      });
      if (this.$parent.isfilterval === false) {
        this.getDatafieldApi('close');
      }
      //this.$parent.isfilterval = true;
    },
    getallAsterleads(payload) {
      var scope = this;
      return new Promise((resolve, reject) => {
        scope.$store.dispatch("GetAsterLeadData", payload)
          .then((response) => {
            console.log("inside oooo")
            console.log("oooo asterleadtabledata:", scope.AsterLeadValue)
            let datas = response.data;
            if (datas && datas.length > 0) {
              let leadIds = datas.map((element) => element.lead_id);
              console.log("ooo Lead IDs:", leadIds);

              resolve(leadIds);
            } else {
              console.log('no data');
              resolve([]);
            }
          })
          .catch((error) => {
            console.error("Error fetching AsterLeadData:", error);
            reject(error);
          });
      })
    },
    getDatafieldApi(data, ispagination) {
      try {

        var scope = this;
        scope.tableLoading = true;
        if (data === undefined) {
          scope.pagecontrol.isFilterOpen = false;
        }
        scope.filter.list_id = "";
        scope.filter.phone_number = "";
        scope.template_name = JSON.parse(
          localStorage.getItem("templetname")
        );
        const titleData_local = JSON.parse(
          localStorage.getItem("templetname")
        );
        let titleData = titleData_local.templetname;
        let AsterLeadFields = "";
        scope.bulk_edit.user = "";
        scope.bulk_edit.list_id = "";
        scope.bulk_edit.disposition = "";
        if (ispagination !== 'pagination') {
          scope.bulk_edit_arr = [];
        }

        scope.select_label = "Select All";
        // scope.select_check = false;
        let checkboxes = [];
        let radio = [];
        let date_time = [];
        let date = [];
        let text = [];
        let select = [];
        this.$store
          .dispatch("GetAsterLeadField", { titleData })
          .then((response) => {
            // changed
            var datas = response.data[0].fields;
            AsterLeadFields = datas;
            scope.headers = [];
            let maskingvalues = {
              name:scope.Filename,
              value:[]
            }
            if (scope.headers.length === 0) {
              if(!this.$parent.isfilterval){
                this.dynamicFilterField = [];
              }
              for (let i = 0; AsterLeadFields.length > i; ++i) {
                if (
                  AsterLeadFields[i].model !== "ticket_id" &&
                  AsterLeadFields[i].model !== "lead_id" &&
                  AsterLeadFields[i].model !== "order_id" &&
                  AsterLeadFields[i].model !== "list_id" &&
                  AsterLeadFields[i].model !== "disposition" &&
                  AsterLeadFields[i].model !== "hopper_status" &&
                  AsterLeadFields[i].model !== "subdisposition" &&
                  AsterLeadFields[i].model !== "deleted" &&
                  AsterLeadFields[i].model !== "modified_by" &&
                  AsterLeadFields[i].model !== "created_by" &&
                  AsterLeadFields[i].model !== "created_at" &&
                  AsterLeadFields[i].model !== "modified_date"
                ) {
                  this.fields_model.push({
                    name: AsterLeadFields[i].model,
                    value: AsterLeadFields[i].model,
                    active: false,
                  });
                }
                this.headersfield = []
                response.data[0].fields.forEach((elm) => {
                  if (elm.model !== 'lead_id' && elm.model !== 'deleted' && elm.model !== 'isclosed') {
                    this.headersfield.push({
                      id: elm.id,
                      lable: elm.label,
                      model: elm.model,
                      table_position: elm.table_position,
                      table_show: elm.table_show,
                      item: false,
                    });
                  }

                });

                AsterLeadFields[i].model = AsterLeadFields[i].model.replace(
                  " ",
                  "_"
                );
                AsterLeadFields[i].label = AsterLeadFields[i].label.replace(
                  "_",
                  " "
                );
                // if (AsterLeadFields[i].show_on_view === true) {
                //   if (AsterLeadFields[i].model === "status") {
                //     scope.HeadersFieldProperty.push(AsterLeadFields[i]);
                //   }
                // }
                if (AsterLeadFields[i].model === 'disposition') {
                  console.log("oooo yesssss")
                  let key = {
                    text: AsterLeadFields[i].model === "user" ? "User" : AsterLeadFields[i].label,
                    value: AsterLeadFields[i].model === "user" ? "user" : AsterLeadFields[i].model,
                    align: "center",
                    sortable: false,
                    position: AsterLeadFields[i].table_position,
                  };
                  scope.headers.push(key);
                }
                if (
                  AsterLeadFields[i].table_show === "true" &&
                  AsterLeadFields[i].show_on_view === true && AsterLeadFields[i].model !== 'lead_id'
                ) {
                  let key = {
                    text:
                      AsterLeadFields[i].model == "user"
                        ? "User"
                        : AsterLeadFields[i].label,
                    value:
                      AsterLeadFields[i].model == "user"
                        ? "user"
                        : AsterLeadFields[i].model,
                    align: "center",
                    sortable: false,
                    position: AsterLeadFields[i].table_position,
                    masking: scope.masking && AsterLeadFields[i].type === "phone_number" 
                  };
                  scope.headers.push(key);
                }
                if (
                  AsterLeadFields[i].model === 'lead_id'
                ) {
                  let key = {
                    text:
                      AsterLeadFields[i].model == "user"
                        ? "User"
                        : AsterLeadFields[i].label,
                    value:
                      AsterLeadFields[i].model == "user"
                        ? "user"
                        : AsterLeadFields[i].model,
                    align: "center",
                    sortable: false,
                    position: AsterLeadFields[i].table_position,
                    masking: scope.masking && AsterLeadFields[i].type === "phone_number"
                  };
                  scope.headers.push(key);
                }
                if(scope.masking && AsterLeadFields[i].type === "phone_number"){
                  maskingvalues.value.push(AsterLeadFields[i].model)
                }
                if (AsterLeadFields[i].filter === true) {
                  if (AsterLeadFields[i].input_type === "Checkbox") {
                    let filter1 = {
                      text: AsterLeadFields[i].label,
                      value: "",
                      model: AsterLeadFields[i].model,
                      input_type: AsterLeadFields[i].input_type,
                    };
                    checkboxes.push(filter1);
                  }

                  if (AsterLeadFields[i].input_type === "Radio") {
                    let opt = JSON.parse(AsterLeadFields[i].values);
                    let filter2 = {
                      text: AsterLeadFields[i].label,
                      value: "",
                      model: AsterLeadFields[i].model,
                      input_type: AsterLeadFields[i].input_type,
                      options: opt,
                    };
                    radio.push(filter2);
                  }

                  if (AsterLeadFields[i].input_type === "DateTime") {
                    let filter3 = {
                      text: AsterLeadFields[i].label,
                      value: "",
                      model: AsterLeadFields[i].model,
                      input_type: AsterLeadFields[i].input_type,
                    };
                    date_time.push(filter3);
                  }
                  if (AsterLeadFields[i].input_type === "Date") {
                    let filter4 = {
                      text: AsterLeadFields[i].label,
                      value: "",
                      model: AsterLeadFields[i].model,
                      input_type: AsterLeadFields[i].input_type,
                      is_active: true,
                    };
                    date.push(filter4);
                  }
                  if (
                    AsterLeadFields[i].input_type === "text" &&
                    AsterLeadFields[i].model != "user" &&
                    AsterLeadFields[i].model != "created_by" &&
                    AsterLeadFields[i].model != "modified_by" &&
                    AsterLeadFields[i].model != "list_id" &&
                    AsterLeadFields[i].model != "subdisposition" &&
                    AsterLeadFields[i].model != "source" &&
                    AsterLeadFields[i].model != "lead_status"
                  ) {
                    let filter5 = {
                      text: AsterLeadFields[i].label,
                      value: "",
                      model: AsterLeadFields[i].model,
                      input_type: AsterLeadFields[i].input_type,
                    };

                    text.push(filter5);
                  }
                  scope.agents = JSON.parse(
                    localStorage.getItem("agent_emails")
                  );
                  // this.agents.unshift("UNASSIGN");
                  let agentdata = JSON.parse(
                    localStorage.getItem("agent_emails")
                  )
                  agentdata.unshift("UNASSIGN");
                  if (AsterLeadFields[i].model === "user") {
                    let filter6 = {
                      text: "User",
                      value: "",
                      model: "user",
                      isMultiSelect: false,
                      input_type: "Select",
                      options: agentdata,
                    };
                    select.push(filter6);
                  }

                  if (AsterLeadFields[i].model === "created_by") {
                    //this.agents.shift("UNASSIGN");
                    let createdby = {
                      text: "Created by",
                      value: "",
                      model: "created_by",
                      isMultiSelect: false,
                      input_type: "Select",
                      options: scope.group_users,
                    };
                    select.push(createdby);
                  }
                  if (AsterLeadFields[i].model === "modified_by") {
                    //this.agents.shift("UNASSIGN");

                    let modifiedby = {
                      text: "Modified by",
                      value: "",
                      model: "modified_by",
                      isMultiSelect: false,
                      input_type: "Select",
                      options: scope.group_users,
                    };
                    select.push(modifiedby);
                  }
                  if (AsterLeadFields[i].model === "list_id") {
                    scope.filter_listid = [];

                    scope.get_list().then((response) => {
                      scope.filter_listid = response;
                      let listid = {
                        text: "List Id",
                        value: "",
                        model: "list_id",
                        isMultiSelect: false,
                        input_type: "Select",
                        options: response,
                      };
                      select.push(listid);
                    })
                    // scope.$store;
                    // api.get("/aster/list").then((response) => {
                    //   let datas = response.data.data;
                    //   datas.forEach((element) => {
                    //     let val = element["list_id"];
                    //     if (
                    //       val !== "" &&
                    //       val !== undefined &&
                    //       val !== null
                    //     ) {
                    //       scope.filter_listid.push(element["list_id"]);
                    //     }
                    //   });
                    //   console.log(scope.filter_listid,"scope.filter_listid");
                    //   let listid = {
                    //     text: "List Id",
                    //     value: "",
                    //     model: "list_id",
                    //     isMultiSelect: false,
                    //     input_type: "Select",
                    //     options: scope.filter_listid,
                    //   };
                    //   select.push(listid);
                    // });
                  }

                  if (AsterLeadFields[i].model === "subdisposition") {
                    scope.filter_SubDis = [];
                    scope.$store;
                    api
                      .get("/subdisposition?active=Y&&skip=0&take=1000")
                      .then((response) => {
                        let datas = response.data.data;
                        datas.forEach((element) => {
                          let val = element["subdispo_id"];
                          if (
                            val !== "" &&
                            val !== undefined &&
                            val !== null
                          ) {
                            scope.filter_SubDis.push(
                              element["subdispo_id"]
                            );
                          }
                        });

                        let subdisop_filter = {
                          text: "Sub Disposition",
                          value: "",
                          model: "subdisposition",
                          isMultiSelect: false,
                          input_type: "Select",
                          options: scope.filter_SubDis,
                        };
                        select.push(subdisop_filter);
                      });
                  }

                  if (AsterLeadFields[i].model === "disposition") {
                    scope.filter_Dis = [];
                    let template = JSON.parse(localStorage.getItem('templetname')).templetname;
                    scope.$store;
                    api
                      .get(`/disposition?active=Y&skip=0&take=1000&template=${template}`)
                      .then((response) => {
                        let datas = response.data.data;
                        datas.forEach((element) => {
                          let val = element["dispo_id"];
                          if (
                            val !== "" &&
                            val !== undefined &&
                            val !== null
                          ) {
                            scope.filter_Dis.push(element["dispo_id"]);
                          }
                        });
                        let dispo_filter = {
                          text: "Disposition",
                          value: "",
                          model: "disposition",
                          isMultiSelect: false,
                          input_type: "Select",
                          options: scope.filter_Dis,
                        };
                        select.push(dispo_filter);
                      });
                  }

                  if (AsterLeadFields[i].model === "source") {
                    scope.filter_source = [];
                    scope.$store;
                    api
                      .get("/source/fetch?skip=0&take=1000")
                      .then((response) => {
                        let datas = response.data.data;
                        datas.forEach((element) => {
                          let val = element["name"];
                          if (
                            val !== "" &&
                            val !== undefined &&
                            val !== null
                          ) {
                            scope.filter_source.push(element["name"]);
                          }
                        });
                        let source_filter = {
                          text: "Source",
                          value: "",
                          model: "source",
                          isMultiSelect: false,
                          input_type: "Select",
                          options: scope.filter_source,
                        };
                        select.push(source_filter);
                      });
                  }

                  if (AsterLeadFields[i].model === "lead_status") {
                    scope.filter_status = [];
                    scope.$store;
                    api
                      .get("/fetch/lead?skip=0&take=1000")
                      .then((response) => {
                        if (response.status == 200) {
                          let datas = response.data.data;
                          datas.forEach((element) => {
                            let val = element["name"];
                            if (
                              val !== "" &&
                              val !== undefined &&
                              val !== null
                            ) {
                              scope.filter_status.push(element["name"]);
                            }
                          });
                          let lead_status_filter = {
                            text: "Lead status",
                            value: "",
                            model: "lead_status",
                            isMultiSelect: false,
                            input_type: "Select",
                            options: scope.filter_status,
                          };
                          select.push(lead_status_filter);
                        } else {
                          let lead_status_filter = {
                            text: "Lead status",
                            value: "",
                            model: "lead_status",
                            isMultiSelect: false,
                            input_type: "Select",
                            options: [],
                          };
                          select.push(lead_status_filter);
                        }

                      });
                  }
                }
              }
              this.$store.dispatch("MaskingModuleDataPush", maskingvalues)
            if(!this.$parent.isfilterval){
              this.dynamicFilterField.push(text);
              this.dynamicFilterField.push(select);
              this.dynamicFilterField.push(date);
              this.dynamicFilterField.push(date_time);
              this.dynamicFilterField.push(checkboxes);
              this.dynamicFilterField.push(radio);
             }
            }
            console.log(this.dynamicFilterField,'this is checking dynamic filter field')
            if (this.$parent.isfilterval) {
             this.fetchFilter(false,ispagination);
            } else if (scope.GetAsterLead !== "") {
              const payload = {
                name: scope.GetAsterLead,
                skip: scope.perPage * (scope.currentPage - 1),
                take: scope.perPage,
                download: false,
              };

              scope.filtervalue = [];
              scope.$store
                .dispatch("GetAsterLeadData", payload)
                .then((response) => {
                  this.downloadData = response.downloadData;
                  let datas = response.data;
                  if (
                    datas !== "" &&
                    datas !== undefined &&
                    datas !== null
                  ) {
                    datas.forEach((element) => {
                      scope.filtervalue.push(element["list_id"]);
                    });
                  }
                  if (response.statusCode === 204) {
                    scope.AsterLeadValue = []
                    //scope.showSnackBar("Data is not available");
                  } else {
                    let temparr = [];
                    let temparr_id=[]
                    datas.forEach((element) => {
                      let tempobj = {};
                      scope.headers.forEach((elm) => {
                        if (
                          element[elm.value] === undefined &&
                          elm.value !== "actions"
                        ) {
                          tempobj[elm.value] = "";
                        }
                        if (
                          elm.value !== "actions" &&
                          element[elm.value] !== undefined
                        ) {
                          tempobj[elm.value] = element[elm.value];
                        }
                      });
                      tempobj.checker = false;
                      temparr.push(tempobj);
                temparr_id.push(tempobj.lead_id)
              });
               let counter = temparr_id.every(item2 => this.bulk_edit_arr.some(item1 => item1 === item2))

                    for (let i = 0; i < this.bulk_edit_arr.length; i++) {
                      const element = this.bulk_edit_arr[i];
                      for (let j = 0; j < temparr.length; j++) {
                        const element_ = temparr[j];
                        if (element_.lead_id === element) {
                          element_.checker = true
                         
                        }

                      }

                    }
                    this.bulk_edit_arr.every(item2 => temparr.some(item1 => item1.lead_id === item2))
                    scope.AsterLeadValue = temparr;

                    scope.AsterLeadValueClone = datas;

                    if (response.totalCount !== undefined) {
                      scope.totalRows = response.totalCount;
                    } else {
                      scope.totalRows = 0;
                    }
                    scope.endPage = scope.currentPage * scope.perPage;
                    scope.endPage =
                      scope.endPage < scope.totalRows
                        ? scope.endPage
                        : scope.totalRows;
                      if ((ispagination === "pagination" && counter)||counter) {
                     
                      this.select_check = true
                   } else {
                      this.select_check = false
                     }
                  }
                  scope.process.isloading = false
                })

                .catch(function (error) {
                  scope.process.isloading = false
                  scope.process.message = ''

                  scope.showSnackBar("error" + error);
                });
            }
          })
          .catch(function (error) {
            console.log(error);
            scope.showSnackBar("error" + error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    moveNextPage(next) {
      const scope = this;
      if (next && scope.totalRows >= scope.currentPage * scope.perPage + 1) {
        scope.currentPage = scope.currentPage + 1;
        scope.getDatafieldApi(undefined, 'pagination');
      } else if (!next && scope.currentPage !== 1) {
        scope.currentPage = scope.currentPage - 1;
        scope.getDatafieldApi(undefined, 'pagination');
      }
    },
    CloseItem() {
      let scope = this;
      scope.$router.push({
        name: "crm/AsterLead",
      });
    },
    UploadFile() {
      let scope = this;
      scope.$router.push({
        name: "Upload",
        query: {
          module: "leads",
          action: "create",
        }
      });
    },
    ColseFile() {
      this.countdown = 5;
      this.countdownInterval = null;
      this.pagecontrol.isUploadFile = false;
      this.selected_list = "";
      this.progress = "";
      this.uploading = false;
      this.uploadLead = getDefaultData();
      this.mapper = false;
      this.csvheader = [];
      for (let i = 0; i < this.fields_model.length; i++) {
        const element = this.fields_model[i];
        element.active = false;
      }
      // this.$refs.createform.resetValidation();
    },
    isFilter() {
      this.pagecontrol.isFilterOpen = true;
    },
    next__: function () {
      if (this.csvheader.length > 0) {
        this.mapper = true;
      }
    },

    filenext__: async function () {
      var files = await document.querySelector("#file").files;
      this.file = files[0];
      if (files.length > 0) {
        this.$store
          .dispatch("Getfiledata", files[0])
          .then((res) => {
            for (let i = 0; i < res.length; i++) {
              let element = res[i];
              if (res.length - 1 === i) {
                element = element.replace(/\s/g, '')
              }
              this.csvheader.push({
                key: element,
                value: "",
              });
            }
          })
          .catch(() => { });
      }
      // else {
      //   alert("Please select a file.");
      // }
    },
    fetchFilter(check, ispagination) {
      const scope = this;
      
     
      // scope.select_all();
      if (ispagination !== 'pagination') {
        scope.bulk_edit_arr = [];
        this.perPage = 8
      this.currentPage = 1
      this.totalRows= 0
      this.endPage= 0
      }
      // scope.select_check = false
      if (check || ispagination === 'ispagination' ) {
        scope.filter_data_temp = [];
      }
      if (scope.filter_data_temp.length <= 0) {
        scope.dynamicFilterField.forEach((input_type) => {
          input_type.forEach((field) => {
            if (field.value != "") {
              scope.filter_data_temp.push({
                name: field.model,
                value: field.value,
              });
            }
          });
        });
      }
      if (scope.GetAsterLead !== "") {
        scope.filter_payload = {
          name: scope.titleDatas,
          filterValues: scope.filter_data_temp,
          skip: scope.perPage * (scope.currentPage - 1),
          take: scope.perPage,
          from_date:
            this.appliedfilter.fromdate != ""
              ? this.datetimeformate(this.appliedfilter.fromdate)
              : "",
          to_date:
            this.appliedfilter.todate != ""
              ? this.datetimeformate(this.appliedfilter.todate)
              : "",
        };

        scope.$store
          .dispatch("GetAsterLeadData", scope.filter_payload)
          .then((response) => {
            let datas = response.data;

            if (datas !== "" && datas !== undefined && datas !== null) {
              this.filtersApplied = true
              datas.forEach((element) => {
                scope.filtervalue.push(element["list_id"]);
              });
            }
            if (datas !== "" && datas !== undefined && datas !== null) {
              let temparr = [];
              let temparr_id=[]
              datas.forEach((element) => {
                let tempobj = {};
                scope.headers.forEach((elm) => {
                  if (
                    element[elm.value] === undefined &&
                    elm.value !== "actions"
                  ) {
                    tempobj[elm.value] = "";
                  }
                  if (
                    elm.value !== "actions" &&
                    element[elm.value] !== undefined
                  ) {
                    tempobj[elm.value] = element[elm.value];
                  }
                });
                tempobj.checker = false;
                temparr.push(tempobj);
                temparr_id.push(tempobj.lead_id)
              });
              let counter = temparr_id.every(item2 => this.bulk_edit_arr.some(item1 => item1 === item2))


              for (let i = 0; i < this.bulk_edit_arr.length; i++) {
                const element = this.bulk_edit_arr[i];
                for (let j = 0; j < temparr.length; j++) {
                  const element_ = temparr[j];
                  if (element_.lead_id === element) {
                    element_.checker = true

                  }

                      }

                    }

              scope.AsterLeadValue = temparr;

              scope.AsterLeadValueClone = datas;

              scope.totalRows = response.totalCount;
              scope.hasFilteredData = true;
              scope.endPage = scope.currentPage * scope.perPage;
              scope.endPage =
                scope.endPage < scope.totalRows
                  ? scope.endPage
                  : scope.totalRows;
               if (ispagination === "pagination" && counter||counter) {
                this.select_check = false
               } else {
                this.select_check = false
              }
            } else {
              scope.AsterLeadValue = []
              scope.showSnackBar("Data is not available");
            }
            scope.pagecontrol.isFilterOpen = false;
            scope.$parent.isfilterval = true;
            scope.process.isloading = false
          })
          .catch((err) => {
            scope.process.isloading = false
            console.log(err, "this is final error");
          });
      }

      // .catch(function (error) {
      // scope.showSnackBar("error" + error);
      // });
    },

    handleFileUpload: function () {
      var scope = this;

      scope.uploadLead.color = "#A64452";
      scope.uploadLead.label = "Uploading";
      let formData = new FormData();
      formData.append("file", scope.file);
      formData.append("template_name", scope.GetAsterLead);
      formData.append("list_id", scope.selected_list);
      formData.append("duplicate", scope.duplicate);
      // formData.append("user", JSON.parse(localStorage.apiInfo).user_email);
      // formData.append("hopper_status", "0");
      formData.append(
        "created_by",
        JSON.parse(localStorage.apiInfo).user_email
      );
      formData.append(
        "modified_by",
        JSON.parse(localStorage.apiInfo).user_email
      );
      formData.append("headerdata", JSON.stringify(scope.csvheader));
      formData.append("disposition", "new");
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      api
        .post("/aster/fileupload", formData, {
          onUploadProgress: function (uploadEvent) {
            scope.progress = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            );
            scope.uploading = true;
          },
        })
        .then((response) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.getDatafieldApi();
          if (response.data.status == 200) {
            //scope.showProgress = false;
            scope.uploading = true;
            scope.uploadLead.color = "#4BB543";
            scope.uploadLead.label = "Uploaded Successfully";
            this.startCountdown();
          } else {
            scope.uploading = false;

            scope.uploadLead.color = "#F51B00";
            scope.uploadLead.label = "Invalid Field Format";
          }
          // scope.$refs.file.value = "";
          if (response.data.skippedData.length > 0) {
            if (response.data.filetype === "csv") {
              let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
              let skipvalue = convertArrayToCSV(response.data.skippedData);
              csvContent += skipvalue;
              var encodedUri = encodeURI(csvContent);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute(
                "download",
                "skip_" + scope.GetAsterLead + ".csv"
              );
              document.body.appendChild(link);
              link.click();
            } else {
              let txtContent = "";
              for (const key in response.data.skippedData[0]) {
                let txt_tab = `${key} \t`;
                txtContent += `${txt_tab}`;
              }
              response.data.skippedData.forEach(function (rows) {
                let data = Object.values(rows);
                let row = data.join("\t");
                txtContent += `\n ${row}` + "\r";
              });
              var encodedUri1 =
                "data:text/plain;charset=utf-8,\uFEFF" +
                encodeURIComponent(txtContent);

              var link1 = document.createElement("a");
              link1.setAttribute("href", encodedUri1);
              link1.setAttribute(
                "download",
                "skip_" + `${this.template_name.templetname}.txt`
              );
              document.body.appendChild(link1);
              link1.click();
            }
            if (response.data.dataCount === 0) {
              scope.ColseFile();
            }
          }

          return response;
        })
        .catch((error) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadLead.color = "#F51B00";
          scope.uploadLead.label = "Upload Error";
          // scope.$refs.file.clearAll();
          return error;
        })
        .finally(() => {
          setTimeout(
            function () {
              this.showProgress = false;
            }.bind(this),
            1000
          );
        });
    },
    assignAgent(leadData) {
      leadData = this.AsterLeadValueClone.find(
        (elm) => elm.lead_id == leadData.lead_id
      );
      const scope = this;
      scope.assignedDetails = JSON.parse(JSON.stringify(leadData));
      scope.assignedDetails.user = scope.assignedDetails.user.split(',')
      scope.assignedDetails.template_name = scope.GetAsterLead;
      scope.isShowAgentAssign = true;
      scope.$refs.refassign.resetValidation();
    },
    closeAssign() {
      const scope = this;
      scope.assignedDetails = {};
      scope.isShowAgentAssign = false;
      scope.$refs.refassign.resetValidation();
    },
    submitAssign() {
      const scope = this;
      if (scope.assignedDetails["user"][0] === 'UNASSIGN' || scope.assignedDetails["user"][0] === '') {
        scope.assignedDetails["user"].shift()
      }
      const assignDetails = {
        lead_id: scope.assignedDetails["lead_id"],
        user: scope.assignedDetails["user"].toString() === "" ? 'UNASSIGN' : scope.assignedDetails["user"].toString(),
        template_name: scope.assignedDetails.template_name,
        modified_by: JSON.parse(localStorage.apiInfo).user_email,
      };
      scope.$store
        .dispatch("AssignAgent", assignDetails)
        .then((response) => {
          if (response.statusCode === 200) {
            scope.showSnackBar("Lead assigned to agent successfully!");
            scope.assignedDetails = {};
            scope.isShowAgentAssign = false;
            scope.$refs.refassign.resetValidation();
            scope.getDatafieldApi();
          } else {
            scope.showSnackBar("Lead assigned to agent failed!");
          }
        })
        .catch(() => {
          scope.showSnackBar("Lead assigned to agent failed!");
        });
    },
    samplefiledownload(type) {
      const scope = this;
      let titleData = scope.GetAsterLead;
      let AsterLeadFields = "";
      scope.$store.dispatch("GetAsterLeadField", { titleData }).then((response) => {
        var datas = response.data[0].fields;
        AsterLeadFields = datas;
        const header = [];
        for (let i = 0; AsterLeadFields.length > i; ++i) {
          let key = {
            key: AsterLeadFields[i].model,
            label: AsterLeadFields[i].label,
          };

          if (
            AsterLeadFields[i].model !== "ticket_id" &&
            AsterLeadFields[i].model !== "lead_id" &&
            AsterLeadFields[i].model !== "order_id" &&
            AsterLeadFields[i].model !== "list_id" &&
            AsterLeadFields[i].model !== "disposition" &&
            AsterLeadFields[i].model !== "hopper_status" &&
            AsterLeadFields[i].model !== "subdisposition" &&
            AsterLeadFields[i].model !== "deleted" &&
            AsterLeadFields[i].model !== "modified_by" &&
            AsterLeadFields[i].model !== "created_by" &&
            AsterLeadFields[i].model !== "created_at" &&
            AsterLeadFields[i].model !== "modified_date" &&
            AsterLeadFields[i].model !== "isclosed"
          ) {
            header.push(AsterLeadFields[i].model);
          }
          scope.fielddata.push(key);
        }
        let csvContent = "";
        if (type === "csv") {
          csvContent += "data:text/csv;charset=utf-8,\uFEFF";
          csvContent += header.join(",");
        } else {
          csvContent += "data:text/txt;charset=utf-8,\uFEFF";
          csvContent += header.join("\t");
        }
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", titleData + "." + type);
        document.body.appendChild(link);
        link.click();
      });
    },
    refreshClick() {
      this.$parent.isfilterval = false
      this.filter_payload = {}
      this.filter_dynamic = {}
      this.filter_data_temp = [];
      this.filter_payload = {}
      this.filtersApplied = false
      this.hasFilteredData = false
      this.perPage = 8
      this.currentPage = 1
      let scope = this;
      scope.getDatafieldApi();
    },
    pushBack() {
      this.$router.push({
        name: "crm/AsterLead",
      });
    },
    lead_info(val) {
      let obj = JSON.parse(localStorage.templetname);
      obj.lead_id = val;
      localStorage.setItem("editfields", JSON.stringify(true));
      localStorage.setItem("templetname", JSON.stringify(obj));

      const titleData_local = JSON.parse(localStorage.getItem("templetname"));
      let titleData = titleData_local.templetname;
      this.$store.dispatch("GetAsterLeadField", { titleData }).then((res) => {
        this.$store.dispatch("Editfields", res.data[0]);
      });
      this.$router.push({
        name: "crm/leadDetail",
      });
    },
    startCountdown() {
      // Start the countdown
      this.countdownInterval = setInterval(() => {
        this.countdown -= 1;

        if (this.countdown === 0) {
          clearInterval(this.countdownInterval);
          this.ColseFile(); // Call your function after countdown completes
        }
      }, 1000); // Update every second
    },
    sidebar_enable_disable(data){
      this.$root.$emit('sidebar_disable',data);
    },
    ChartoMaskingChar(data){
            let scope = this
            if(!scope.$store.getters.MaskType.includes(typeof(data))){
                return data
            }
      return MaskCharacter(data)
    }
  },
  mounted() {
    this.userDatas()
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:fileupload"
          ) !== -1
        ) {
          scope.pagecontrol.fileupload = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:userassign"
          ) !== -1
        ) {
          scope.pagecontrol.userassign = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:edit"
          ) !== -1
        ) {
          scope.pagecontrol.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:create"
          ) !== -1
        ) {
          scope.pagecontrol.cancreate = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:delete"
          ) !== -1
        ) {
          scope.pagecontrol.candelete = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:download"
          ) !== -1
        ) {
          scope.pagecontrol.export = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:view"
          ) !== -1
        ) {
          scope.masking = true;
        }

      }.bind(scope),
      1
    );
    if (this.titleDatas !== "") {
      this.getDatafieldApi();
    }
    var scope = this;
    this.get_list()
    scope.agents = JSON.parse(localStorage.getItem("agent_emails"));
    scope.group_users = JSON.parse(localStorage.getItem("group_users"));

    this.uploadLead = getDefaultData();
   
  },
};
</script>

<style>
.filter-icon {
  cursor: pointer;
  color: #333;
  /* Default color */
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.filter-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: rgba(52, 152, 219, 0.2);
  border-radius: 50%;
  z-index: -1;
  transition: all 0.3s ease;
}

.filter-icon:hover,
.filter-active {
  color: #3498db;
}

.filter-icon:hover::after,
.filter-active::after {
  width: 100%;
  height: 100%;
}

/* .filter-active {
  animation: subtle-pulse 2s infinite ease-in-out;
}

@keyframes subtle-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */

.btn-light {
  margin-left: 900px;
}

.edit-icon {
  margin: 0px 10px !important;
  color: blue;
}

.delete-icon {
  margin: 0px 10px !important;
  color: red;
}

.mr-3 {
  padding: 10px !important;
  margin: 10px !important;
  margin-top: -7px !important;
  color: #2196f3 !important;
  position: absolute;
  top: 87px;
  right: 45px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: "Lato", sans-serif;
}

.mr-6 {
  padding: 10px !important;
  margin: 50px !important;
  margin-top: -7px !important;
  color: #2196f3 !important;
  position: absolute;
  top: 87px;
  right: 90px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: "Lato", sans-serif;
}

/* .csvBtn{
    height: 50px;
    width: 230px;
    background-color: green;
    }
    .textBtn{
    height: 50px;
    width: 230px;
    background-color: palevioletred;
    } */
.title {
  padding: 10px !important;
  margin: 10px;
  color: #2196f3 !important;
}

.b-table-sticky-header {
  overflow-y: auto !important;
  overflow-x: auto !important;
  max-height: 390px !important;
}

.tabledata {
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
}

.tabledata tr th {
  background: #fff;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.54);
  font-family: "Lato", sans-serif;
  padding: 15px 10px !important;
}

.tabledata tr td {
  background: #fff;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Lato", sans-serif;
  padding: 15px !important;
}

.b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 0rem !important;
}

.showing_history {
  padding: 5px;
  padding-top: 10px;
}

.showing_history .icon_history {
  font-size: 40px;
  padding-bottom: 0px;
  padding-top: 20px;
  margin-bottom: -16px;
  padding: 10px;
  height: 20px;
}

.pagination-div {
  background: #fff;
  height: 60px;
  margin-top: 5px;
  width: 100%;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2) !important;
  border-bottom-color: #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.pagination-div #row-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px !important;
  color: rgba(53, 18, 18, 0.54) !important;
  font-family: "Lato", sans-serif !important;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.pagination-div #row-selector select {
  margin-left: 10px;
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: "Lato", sans-serif !important;
}

.pagination-div #row-selector #page-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px !important;
  color: rgba(53, 18, 18, 0.54) !important;
  font-family: "Lato", sans-serif !important;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.pagination-div #row-selector #page-control .action-icon {
  color: rgba(53, 18, 18, 0.64) !important;
  margin-left: 20px;
}

.pagination-div #row-selector #page-control .action-icon .move-page {
  margin: auto 20px;
  cursor: pointer !important;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.toolbar_operations {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 170px;
  top: 41px;
  right: 55px;
}

.toolbar_operations .v-icon {
  color: #2196f3 !important;
}

.icon_section {
  align-content: center;
  padding: 0px 0px 0px 70px;
}

.exportflex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px;
}

.export {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 40%;
  height: 130px;
  text-align: center;
  padding: 20px;
  background-color: #cacaca;
  color: #ffffff;
  border-radius: 10px;
}

.hovericon {
  color: #2c3e50;
  cursor: pointer;
}

.pop_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.fa-filter12:before {
  /* border: 1px solid; */
  width: 33px;
  height: 33px;
  padding: 9px;
  border-radius: 50px;
  content: "\f0b0";
}

.fa-filter12:hover {
  /* border: 1px solid; */
  border-radius: 50px;
  color: #fff;
  background-color: #b3d4fc;
  opacity: 0.12px;
}

.fa-refresh1:before {
  width: 33px;
  height: 33px;
  padding: 9px;
  border-radius: 50px;
}

.fa-refresh1:hover {
  border-radius: 50px;
  color: #fff;
  background-color: #b3d4fc;
  opacity: 1px;
}

.fa-upload:before {
  width: 33px;
  height: 33px;
  padding: 9px;
  border-radius: 50px;
  content: "\f093";
}

.fa-upload:hover {
  /* border: 1px solid; */
  border-radius: 50px;
  color: #fff;
  background-color: #b3d4fc;
  opacity: 1px;
}

.fa-download1:before {
  content: "\f019";
  width: 33px;
  height: 33px;
  padding: 9px;
  border-radius: 50px;
}

.fa-download1:hover {
  /* border: 1px solid; */
  border-radius: 50px;
  color: #fff;
  background-color: #b3d4fc;
  opacity: 1px;
}

.fa-plus1:before {
  content: "\f067";
  width: 33px;
  height: 33px;
  padding: 9px;
  border-radius: 50px;
}

.fa-plus1:hover {
  /* border: 1px solid; */
  border-radius: 50px;
  color: #fff;
  background-color: #b3d4fc;
}

.fa-pencil-square-o:hover {
  border-radius: 50px;
  color: #fff;
  background-color: #b3d4fc;
}

.fa-pencil-square-o:before {
  width: 33px;
  height: 33px;
  padding: 9px;
  border-radius: 50px;
}
.fa-trash-alt:before{
  width: 33px;
  height: 33px;
  padding: 9px;
  border-radius: 50px;
}
.fa-trash-alt:hover {
  border-radius: 50px;
  color: #fff;
  background-color: #b3d4fc;
}
.upload-button {
  position: relative;
  padding: 10px;
  background-color: rgb(33, 150, 243);
  color: #fff;
  border: none;
  cursor: pointer;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #c4dbf5;
  color: black;
  transition: width 0.3s;
}

.progress-bar-container {
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ddd;
}

/* Animation keyframes */
@keyframes progressAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.nextbutton {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.nextbutton-innerdiv {
  width: 60px;
  font-weight: 700;
  padding: 7px;
  text-align: center;
  background-color: #2296f3;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.map_value {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.map_value_lft {
  width: 150px;
  margin: auto;
  margin-left: 5px;
  font-weight: 700;
}

.map_value_rht {
  width: calc(100% - 150px);
}

.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected>span>span,
.theme-orange .vdatetime-calendar__month__day--selected:hover>span>span {
  background: #2196f3;
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #2196f3;
}

.datetimefilter {
  padding: 5px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
}

.pgraph {
  margin-bottom: 10px;
}

.labelgraph {
  display: block;
  margin-bottom: 5px;
}

.titletxt {
  font-size: 15px !important;
  margin: 10px;
}
</style>