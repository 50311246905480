<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :dncFilter="filter.payload.number"
      :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions" @filterData="dnc_phone_number"
      @openFilter="openDNCFilter" @refreshPage="refreshDNCPage" @addNew="NewDNC"
      :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="table.headers" :items="table.data" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">

          <div style="text-align: center">No Data Available</div>

        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.entry_date }}</td>
          <td class="checkEmpty p0">{{ props.item.phone_number }}</td>
          <td class="text-xs-center checkEmpty p0">
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item)">delete</v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete DNC</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this DNC.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer fixed disable-resize-watcher right :value="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-text-field v-model="filter.payload.number" label="Phone Number" small></v-text-field>
          </div>
          <!-- <div class="autocompleteDiv">
            <v-autocomplete
              v-model="filter.payload.status"
              :items="filter.options.status"
              label="Status"
              persistent-hint
              small
              multiple
              single-line
              :allow-overflow="false"
            ></v-autocomplete>
          </div> -->
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="restvalues()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-dialog persistent :value="page.isAddDNC" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">New DNC</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-form ref="adduserform" v-model="addDNC.valid">
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field v-model="addDNC.payload.number" required label="Phone Number"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-select v-model="addDNC.payload.status" :items="addDNC.options.status" label="Status"></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="CloseAddDNC(false)">Cancel</v-btn>
          <v-btn color="primary" :disabled="!addDNC.valid" @click="CloseAddDNC(true)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent :value="page.isUploadDNC" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Upload DNC</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <p>
            <span style="font-weight: bold">Not sure what to upload? </span><a @click="downloadSample">Click here</a>
            and download the sample
            CSV file. Open the file and enter the phone numbers at the first
            column of each row.In Second Column <b>A-</b>Add New Data,<b>D-</b>Delete Existing Data. Save the file with
            .csv extension and upload the
            file.
          </p>
          <div class="file-dummy" @click="$refs.file.click()"
            :style="{ background: uploadDNC.color + '66', 'border-color': uploadDNC.color, color: uploadDNC.color, }">
            {{ uploadDNC.label }}
          </div>
          <input type="file" id="file" ref="file" accept=".csv" v-on:change="handleFileUpload()" />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="CloseUploadDNC(false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import api from "@/utils/axios-util";
const { convertArrayToCSV } = require("convert-array-to-csv");
import { mapGetters } from "vuex";
// import moment from "moment";
function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
export default {
  components: {
    loader,
  },
  computed: {
    ...mapGetters(["DoNot"]),
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.uploadDNC = getDefaultData();

    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:dnc:upload"
          ) !== -1
        ) {
          scope.page.addnew = [{ key: "upload", label: "Upload" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:dnc:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
  },
  created() { },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "DNC List",
        icon: "fa-phone fa-2x",
        loading: true,
        isAddDNC: false,
        isUploadDNC: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: false, refresh: true,autoComplete:true },
        addnew: [],
        temp: { key: "adddnc", label: "Add DNC" },
        candelete: false,
      },
      pagecount: 0,
      table: {

        headers: [
          {
            text: "Date",
            align: "left",
            class: "p0",
            sortable: false,
            value: "entry_date",
          },
          {
            text: "Phone Number",
            align: "left",
            class: "p0",
            sortable: false,
            value: "phone_number",
          },
          {
            text: "Action",
            align: "center",
            class: "p0",
            sortable: false,
            value: "action",
          },
        ],
        data: [],
      },

      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      tableLoading: false,
      filter: {
        payload: {
          name: "",
          number: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      isProcessing: false,
      addDNC: {
        valid: false,
        payload: {
          name: "",
          number: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      uploadDNC: {},
    };
  },
  methods: {
    refreshDNCPage: function () {
      var scope = this;
      scope.page.loading = true;
        scope.filter.payload.number = "";
         scope.page.isFilterOpen = false;
      scope.getDataFromApi();
    },
    dnc_phone_number(val){
      let scope=this
        scope.filter.payload.number =val;
          scope.getDataFromApi();
    },
    getDataFromApi() {
      var scope = this;
      this.tableLoading = true;
    
      scope.page.isFilterOpen = false;

      let take = this.pagination.rowsPerPage
      let skip
      if (this.pagination.page === 1) {
        skip = 0
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1)
      }
      let query = {
        skip: skip,
        take: take,
        phone_number: scope.filter.payload.number
      }
      this.skip=skip
      this.$store
        .dispatch("DoNotCall", query)
        .then((response) => {

          this.pagecount = response.data.count
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          this.table.data = datas
          // .map((ele) => {
          //   ele.entry_date = moment(ele.entry_date).format("YYYY-MM-DD HH:mm:ss");
          //   return ele
          // });

          // scope.pagecontrol.loading = false;

        })
        .catch(function (error) {
          scope.page.isUploadDNC = false;
          scope.showSnackBar("error" + error);

        });
    },
  filterDnc() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage
      let skip
      // if (this.pagination.page === 1) {
        skip = 0
      // } else {
      //   skip = this.pagination.rowsPerPage * (this.pagination.page - 1)
      // }
      let query = {
        skip: skip,
        take: take,
        phone_number:scope.filter.payload.number
      }
      this.skip=skip
      this.$store
        .dispatch("DoNotCall",query)
        .then((response) => {
          // scope.tableLoading = false;
          // scope.page.loading = false;
          // var datas = response.data;
          // scope.table.data = datas;
          this.pagecount = response.data.count
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data.data;
          this.table.data = datas
      
        })
        .catch(function (error) {
          scope.page.isUploadDNC = false;
          scope.showSnackBar("error" + error);

        });
    },
    openDNCFilter: function () {
      this.page.isFilterOpen = true;
    },
    NewDNC: function (dnc) {
      if (dnc.key === "upload") {
        this.page.isUploadDNC = true;
      } else if (dnc.key === "adddnc") {
        this.page.isAddDNC = true;
      }
    },
    restvalues() {
      this.filter.payload.number = "";
      this.page.isFilterOpen = false;
      this.getDataFromApi()
    },
    CloseAddDNC: function () {
      this.page.isAddDNC = false;
    },
    CloseUploadDNC: function () {
      this.page.isUploadDNC = false;
      this.getDataFromApi();
      this.uploadDNC = getDefaultData();
    },
    onFileRemove: function (args) {
      args.postRawFile = false;
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(item) {
      this.DeleteRowdata = true;
      this.delete_id = item;
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("DeleteRowdata", this.delete_id.id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "deleted") {
            scope.getDataFromApi();
            scope.showSnackBar("Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    downloadSample: function () {
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += "phone_number,status\n\r1000000000,A\n\r1000000000,D";
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "sample_dnc.csv");
      document.body.appendChild(link);
      link.click();
    },
    handleFileUpload: function () {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      scope.uploadDNC.color = "#A64452";
      scope.uploadDNC.label = "Uploading";
      let formData = new FormData();
      formData.append("file", scope.file);
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      api
        .post("/dncUpload", formData)
        .then((response) => {
          if(response.data.skipData.length > 0){
              let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
              let skipvalue = convertArrayToCSV(response.data.skipData);
              csvContent += skipvalue;
              var encodedUri = encodeURI(csvContent);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute(
                "download",
                "skip_DNC" + ".csv"
              );
              document.body.appendChild(link);
              link.click();
          }
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadDNC.color = "#4BB543";
          scope.uploadDNC.label = "Uploaded Successfully";
          scope.$refs.file.value = "";
          return response;
        })
        .catch((error) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadDNC.color = "#F51B00";
          scope.uploadDNC.label = "Upload Error";
          scope.$refs.file.clearAll();
          return error;
        });
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  }
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>