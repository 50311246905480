// App Theme settings
/**
 * This setting is imported from src/data/json/AppColorScheme.json
 * Here we have choosen one of the theme among the list
 * Setting it's titled as Color Scheme
 * Ref: store/modules/swatch.js
 */
import schemes from "./schemes";

// Theme Base componet Config
/**
 * This is based config for theme UI.
 * Basically refers to different mod/visibility of 3 based navigation of an app.
 * Ref: store/modules/navigation.js
 */
import navigation from "./navigation";
import swatch from "./swatch";
import liveAgents from "./liveAgents";
import campaign_status from "./campaign_status";
import queuestate from "./queuestate";
import report from "./report";
import auth0 from "./auth0";
import overall_report_state from "./overall_report_state";
import userManagement from "./userManagement";
import groupManagement from "./groupManagement";
import queueManagement from "./queueManagement";
import AudioStore from "./AudioStore";
import WebHookManagement from "./WebHookManagement";
import TimeCondition from "./TimeCondition";
import DoNotCall from "./DoNotCall";
import TagManagement from "./TagManagement";
import PauseCode from "./Pausecode";
import Reminder from "./Reminder";
import Script from "./Script";
import AsterCampaign from "./AsterCampaign";
import AsterContact from "./AsterContact";
import AsterMail from "./mail";
import AsterWhatsapp from "./whatsapp";
import AsterSms from "./sms";
import AsterList from "./AsterList";
import AsterLead from "./AsterLead";
import AsterLeadInsert from "./AsterLeadInsert";
import AsterLeadDelete from "./AsterLeadDelete";
import CustomForm from "./CustomForm";
import Widget from "./widget";
import CallFlow from "./CallFlow";
import LeadStatus from "./leadStatus";
import DidNumber from "./didNumber";
import MaskingModules from "./MaskingModules"

export const configSchemes = schemes;
export const configNavigation = navigation;
export const configSwatch = swatch;
export const configLiveAgents = liveAgents;
export var conficampaign = campaign_status;
export var confiqueuestatus = queuestate;
export const configReport = report;
export const auth0Login = auth0;
export const configoverallreport = overall_report_state;
export default {
  schemes,
  navigation,
  liveAgents,
  swatch,
  campaign_status,
  queuestate,
  report,
  auth0,
  overall_report_state,
  userManagement,
  queueManagement,
  AudioStore,
  groupManagement,
  WebHookManagement,
  TimeCondition,
  DoNotCall,
  TagManagement,
  PauseCode,
  Reminder,
  Script,
  AsterCampaign,
  AsterContact,
  AsterList,
  AsterMail,
  AsterSms,
  AsterWhatsapp,
  AsterLead,
  AsterLeadInsert,
  AsterLeadDelete,
  CustomForm,
  CallFlow,
  Widget,
  LeadStatus,
  DidNumber,
  MaskingModules
};
