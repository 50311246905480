<template>
  <div>
    <v-layout wrap row ma-0>
      <v-flex xs12>
        <v-autocomplete
          v-model="appliedfilter.EmailId"
          :items="filteragentsAndAdmin"
          label="EmailId"
          persistent-hint
          small
          multiple
          single-line
          :allow-overflow="false"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="appliedfilter.UserId"
          label="UserId"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="appliedfilter.MobileNumber"
          onkeypress="return/\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
          label="MobileNumber"
        ></v-text-field>
      </v-flex>
      <v-flex xs12>
        <v-select
          v-model="appliedfilter.Role"
          label="Role"
          :items="UserType"
          multiple
          persistent-hint
        ></v-select>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["appliedfilter"],
  data() {
    return {
      page: {
        menu: {
          UserId: false,
          MobileNumber: false,
          EmailId: false,
          Role: false,
        },
      },
      filter_UserId: "",
      filter_MobileNumber: "",
      filter_EmailId: "",
      filter_Role: "",
      filteragentsAndAdmin: [],
      UserType: ["Admin", "Agent"],
      // Users: [],
    };
  },
  methods: {
    CloseFilter() {
      this.pagecontrol.isFilterOpen = false;
    },
  },
  watch: {
    "appliedfilter.UserId"(newVal) {
      if(Array.isArray(newVal) && newVal.length == 0){
        newVal = ""
      } 
      this.$store.commit("filter_userId", { user_id: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.MobileNumber"(newVal) {
      this.$store.commit("filter_MobileNumber", { user_phone_number: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.EmailId"(newVal) {
      this.$store.commit("filter_EmailId", { user_email: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.Role"(newVal) {
      if(Array.isArray(newVal) && newVal.length == 0){
        newVal = ""
      } 
      this.$store.commit("filter_Role", { role: newVal });
      // this.$emit("getDataFrom");
    },
  },
  computed: { ...mapGetters(["usersdata", "userManagementFilter", "filters"]) },
  mounted() {
    this.filteragentsAndAdmin = JSON.parse(localStorage.getItem("group_users"));
    // this.$store.dispatch("filteruserManagement");
    // let data = localStorage.getItem("groupId");
    // this.$store
    //   .dispatch("getGroupUserLists", data)
    //   .then((response) => {
    //     this.Users = response;
    //   })
    //   .catch(() => {
    //     this.isProcessing = false;
    //   });
  },
};
</script>
