<template>
  <div>
    <section-definition
      v-if="insert_form == false && edit_form == false"
      :title="page.title"
      :filtervalue="filtervalue"
      :extraOptions="page.options"
      :icon="page.icon"
      :industry_data="industry_datas"
      :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew"
      :exportOptions="page.exportOptions"
      @industry_value="industry_values($event)"
      @openFilter="filterClick"
      @templet_value="viewItem($event)"
      @refreshPage="refreshThisPage"
      @addNew="AddNewRule"
      :style="styleObject"
    ></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page style="padding-top: 10px">
      <tables
        v-if="insert_form == false && edit_form == false"
        @customform_edit="customform_edit"
        @customform_insert="customform_insert"
        :titleDatas="titleData"
        ref="tableRef" 
      ></tables>
      <forms
        v-if="insert_form == true && edit_form == false"
        @insertleadssnack="demo($event)"
        @customform="customform_insert($event)"
      ></forms>
      <LeadDetail
        v-if="insert_form == false && edit_form == true"
        :editlead_id="edit_id"
        @customform="customform_edit($event)"
      ></LeadDetail>
    </v-container>
    <v-snackbar
      v-model="snackbar.show"
      :bottom="true"
      :right="true"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import tables from "./Table.vue";
import forms from "./InsertAsterLead.vue";
import { mapGetters } from "vuex";
import LeadDetail from "./LeadDetail.vue";
export default {
  components: {
    loader,
    tables,
    forms,
    LeadDetail,
  },

  data() {
    return {
      insert_form: false,
      industry_datas: [],
      edit_form: false,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Leads",
        icon: "fa-database fa-2x",
        loading: false,
        isAddAsterlead: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { select_box: true },
        addnew: [],
        isEditAsterlead: false,
        temp: { key: "addasterlead", label: "Add AsterLead" },
        candelete: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Templates",
          align: "left",
          class: "p0",
          sortable: true,
          value: "template_name",
        },
        {
          text: "Type",
          align: "left",
          class: "p0",
          sortable: true,
          value: "type",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: true,
          value: "status",
        },
        {
          text: "View Leads",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
      ],
      headers1: [],
      asterleadData: [],
      AsterLeadFields: [],
      AsterLeadValue: [],
      titleData: "",
      pagination: {
        page: 0,
        rowsPerPage: 5,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          active: "",
          template_name: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      isProcessing: false,
      call_status: [],
      AsterFieldData: [],
      editAsterlead: {
        id: "",
        pause_code: "",
        type: "",
        description: "",
        status: "",
      },
      isfilterval: false,
      addAsterlead: {
        valid: false,
        payload: {
          pause_code: "",
          type: "",
          description: "",
          status: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      dynamicFilter: [],
      industry_val: "",
    };
  },
  methods: {
    demo(val) {
      if (val) {
        this.showSnackBar("Lead Succesfully created");
      } else {
        this.showSnackBar("Error! lead cannot be created");
      }
    },
    industry_values(val) {
      console.log("calledddd");
      this.filtervalue = [];
      this.industry_val = val;
      this.getDataFromApi();
    },
    customform_insert(val) {
      if (val === 201) {
        this.insert_form = false;
        // this.getDataFromApi()
        this.showSnackBar("Lead Succesfully created");
      } else if (val === false) {
        this.insert_form = false;
        // this.getDataFromApi()
      } else {
        this.insert_form = true;
      }
    },
    customform_edit(val) {
      if (val === 201) {
        this.edit_form = false;
        // this.getDataFromApi()
        this.showSnackBar("Lead Succesfully created");
      } else if (val === false) {
        this.edit_form = false;
        // this.getDataFromApi()
      } else {
        this.edit_id = val;
        this.edit_form = true;
      }
    },
    templet_value() {},
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.getDataFromApi();
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },

    fetchFilter: function () {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      this.$store
        .dispatch("FilterAsterLeadData", scope.filter.payload.template_name)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.asterleadData = datas;
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.$store
        .dispatch("GetAsterLead")
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          scope.filter.payload.template_name = "";
          var datas = response.data;
          if (datas !== "" && datas !== undefined && datas !== null) {
            let arr = datas.map((res) => res.industry);
            this.industry_datas = [...new Set(arr)];
            this.industry_datas.unshift("All");
            datas.forEach((element) => {
              if (this.industry_val == "All") {
                scope.filtervalue.push(element["template_name"]);
              } else {
                if (element.industry == this.industry_val)
                  scope.filtervalue.push(element["template_name"]);
              }
            });
          }
          scope.asterleadData = datas;
        })
        .catch(function (error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditAsterlead = false;
        });
    },
    // getDatafieldApi() {
    //   var scope = this;
    //   scope.tableLoading = true;
    //   this.$store
    //     .dispatch("GetAsterLeadField", scope.titleData)
    //     .then((response) => {
    //       scope.tableLoading = false;
    //       scope.page.loading = false;

    //       var datas = response.data[0].fields;
    //       scope.AsterLeadFields = datas;
    //       var newData = [];
    //       scope.headers1 = [];
    //       if (scope.AsterLeadFields !== undefined) {
    //         for (let i = 0; scope.AsterLeadFields.length > i; ++i) {
    //           newData.pop(scope.AsterLeadFields[i].model);
    //           const head = {
    //             text: scope.AsterLeadFields[i].model,
    //             align: "left",
    //             class: "p0",
    //             sortable: true,
    //             value: "model",
    //           };
    //           scope.headers1.push(head);
    //         }
    //       }
    //       scope.headers1[0].header = newData;
    //       scope.AsterFieldData = [];
    //       if (scope.AsterLeadFields !== undefined) {
    //         for (let j = 0; scope.AsterLeadFields.length > j; ++j) {
    //           const head = scope.AsterLeadFields[j].model;
    //           scope.AsterFieldData.push(head);
    //         }
    //         this.getDataValueApi();
    //       }
    //     })
    //     .catch(function (error) {
    //       scope.page.isAddAsterlead = false;
    //       scope.showSnackBar("error" + error);
    //       this.page.isEditAsterlead = false;
    //     });
    // },
    // getDataValueApi() {
    //   var scope = this;
    //   scope.tableLoading = true;
    //   this.$store
    //     .dispatch("GetAsterLeadData", scope.titleData)
    //     .then((response) => {
    //       scope.tableLoading = false;
    //       scope.page.loading = false;
    //       var datas = response.data;
    //       scope.AsterLeadValue = [];
    //       scope.AsterLeadValue = datas;
    //     })
    //     .catch(function (error) {
    //       scope.page.isAddAsterlead = false;
    //       scope.showSnackBar("error" + error);
    //       this.page.isEditAsterlead = false;
    //     });
    // },
    AddNewRule: function () {
      var scope = this;
      scope.page.isAddAsterlead = true;
    },
    closeEditRules: function () {
      this.page.isEditAsterlead = false;
    },
    closeAddPausecode: function () {
      this.page.isAddAsterlead = false;
    },
    saveEditRules() {
      var scope = this;
      scope.$store
        .dispatch("UpdateFields", scope.editAsterlead)
        .then(() => {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlead = false;
        })
        .catch(function (error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlead = false;
        });
      this.getDataFromApi();
    },
    saveAddPausecode() {
      var scope = this;
      scope.$store
        .dispatch("insertPausecode", scope.addAsterlead.payload)
        .then(() => {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("Created Successfully");
        })
        .catch(function (error) {
          scope.page.isAddAsterlead = false;
          scope.showSnackBar("error" + error);
        });
      this.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(id) {
      this.DeleteRowdata = true;
      this.delete_id = id;
    },
    editItem(item) {
      var scope = this;
      scope.$store;
      console
        .log("Edit,edit")
        .dispatch("GetFormFields", item.template_name)
        .then(() => {
          scope.$router.push({
            name: "crm/EditAsterLead",
          });
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    viewItem(item) {
      var scope = this;
      scope.titleData = item;
      scope.$store.dispatch("Viewdata", item);
      scope.page.isEditAsterlead = true;
      scope.isfilterval=false
      this.$refs.tableRef.reset();
      // let checkboxes = [];
      // let radio = [];
      // let date_time = [];
      // let date = [];
      // let text = [];
      // let select = [];
      // const titleData_local = JSON.parse(localStorage.getItem("templetname"));

      // let titleData = titleData_local.templetname;

      // let AsterLeadFields = ""
      // this.dynamicFilter = [];
      // this.$store
      //   .dispatch("GetAsterLeadField", titleData)
      //   .then((response) => {
      //     console.log(response, "sdsdsd");
      //     var datas = response.data[0].fields;
      //     AsterLeadFields = datas;
      //     this.data = AsterLeadFields;

      //     if (AsterLeadFields != undefined) {
      //       for (let i = 0; AsterLeadFields.length > i; ++i) {

      // if (AsterLeadFields[i].filter === true) {

      //   if (AsterLeadFields[i].input_type === "Checkbox") {
      //     let filter1 = {
      //       text: AsterLeadFields[i].label,
      //       value: "",
      //       model: AsterLeadFields[i].model,
      //       input_type: AsterLeadFields[i].input_type,
      //     };
      //     checkboxes.push(filter1);
      //   }

      //   if (AsterLeadFields[i].input_type === "Radio") {
      //     let opt = JSON.parse(AsterLeadFields[i].values);
      //     let filter2 = {
      //       text: AsterLeadFields[i].label,
      //       value: "",
      //       model: AsterLeadFields[i].model,
      //       input_type: AsterLeadFields[i].input_type,
      //       options: opt,
      //     };
      //     radio.push(filter2);
      //   }

      //   if (AsterLeadFields[i].input_type === "DateTime") {
      //     let filter3 = {
      //       text: AsterLeadFields[i].label,
      //       value: "",
      //       model: AsterLeadFields[i].model,
      //       input_type: AsterLeadFields[i].input_type,
      //     };
      //     date_time.push(filter3);
      //   }
      //   if (AsterLeadFields[i].input_type === "Date") {
      //     let filter4 = {
      //       text: AsterLeadFields[i].label,
      //       value: "",
      //       model: AsterLeadFields[i].model,
      //       input_type: AsterLeadFields[i].input_type,
      //       is_active: true,
      //     };
      //     date.push(filter4);
      //   }
      //   if (AsterLeadFields[i].input_type === "text") {
      //     let filter5 = {
      //       text: AsterLeadFields[i].label,
      //       value: "",
      //       model: AsterLeadFields[i].model,
      //       input_type: AsterLeadFields[i].input_type,
      //     };

      //     text.push(filter5);

      //   }
      //   if (AsterLeadFields[i].input_type === "Select") {
      //     let options = JSON.parse(AsterLeadFields[i].values);
      //     let filter6 = {
      //       text: AsterLeadFields[i].label,
      //       value: "",
      //       model: AsterLeadFields[i].model,
      //       isMultiSelect: AsterLeadFields[i].multiple,
      //       input_type: AsterLeadFields[i].input_type,
      //       options: options,
      //     };

      //     select.push(filter6);
      //   }
      // }
      //   }
      // }

      // this.dynamicFilter.push(text);
      // this.dynamicFilter.push(select);
      // this.dynamicFilter.push(date);
      // this.dynamicFilter.push(date_time);
      // this.dynamicFilter.push(checkboxes);
      // this.dynamicFilter.push(radio);

      // })
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      let payload = {
        template_name: "New_form",
        delete_id: 7,
        modified_by: JSON.parse(localStorage.apiInfo).user_email,
      };
      this.$store
        .dispatch("deleteAsterLead", payload)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("AsterLead Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete AsterLead");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete AsterLead");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    sidebar_enable_disable(data){
            this.$root.$emit('sidebar_disable',data);
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
  },
  mounted() {
    let scope = this;
    scope.sidebar_enable_disable(false);
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:lead:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    // this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100% !important;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

.table {
  border: 1px;
}
</style>