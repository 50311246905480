import config from "@/config/index";
import api from '../../utils/axios-util';
import {
    LIVE_CALLS,
    QUEUE_RECORDS,
    EXTENSION_RECORDS,
    // USER,
    FILTERLIVENUMBER,
    FILTERLIVEAGENT,
    FILTERLIVEQUEUE,
    FILTERLIVECAMPAIGN,
    FILTERLIVEEXTENSTENSION,
    FILTERLIVETYPE,
    FILTERLIVESTATUS,
    FILTERLIVENUMBERS,
} from "../mutation-types";
const state = config.report;
var extension_payload = [];
// var user_payload = [];
var queue = [];
var getters = {
    Live_calls_response: state => state.livecalls.response,
    Queue_dropdown: state => state.filter.queue,
    Extension_dropdown: state => state.filter.extension,
    Type: state => state.filter.type,
    Status: state => state.filter.status,
    // User: state => state.filter.user,
    Queue: state => state.filter.queue,
    Campaign: state => state.filter.campaign,
    livecallsLoader: state => state.livecalls.page.loading
}
const mutations = {
    [LIVE_CALLS]: (state, payload) => {
        state.livecalls.response = payload.data.data;
    },
    [QUEUE_RECORDS]: (state, payload) => {
        for (var i = 0; i < payload.data.count; i++) {
            queue.push(payload.data.data[i].name);
        }
        state.filter.queue = queue;
    },
    [EXTENSION_RECORDS]: (state, payload) => {

        for (var i = 0; i < payload.data.count; i++) {
            extension_payload.push(payload.data.data[i].name);
        }
        state.filter.extension = extension_payload;
    },
    // [USER]: (state, payload) => {
    //   for (var i = 0; i < payload.data.count; i++) {
    //     user_payload.push(payload.data.data[i].name);
    //   }
    //   state.filter.user = user_payload;
    // },
    [FILTERLIVENUMBER]: (state, payload) => {
        if (payload.number == undefined) { payload.number = '' }
        state.livecalls.page.loading = false;
        state.livecalls.userFilter.number = payload.number;
        state.livecalls.userFilter.agent = payload.agent;
        state.livecalls.userFilter.Queue = payload.Queue;
        state.livecalls.userFilter.Campaign = payload.Campaign;
        state.livecalls.userFilter.Extension = payload.Extension;
        state.livecalls.userFilter.Type = payload.Type;
        state.livecalls.userFilter.Status = payload.Status;
    },
    [FILTERLIVENUMBERS]: (state, payload) => {
        if (payload.number == undefined) { payload.number = '' }
        state.livecalls.userFilter.number = payload.number;
    },
    [FILTERLIVEAGENT]: (state, payload) => {
        if (payload.agent == undefined) { payload.agent = '' }
        state.livecalls.userFilter.agent = payload.agent;
    },
    [FILTERLIVEQUEUE]: (state, payload) => {
        if (payload.Queue == undefined) { payload.Queue = '' }
        state.livecalls.userFilter.Queue = payload.Queue;
    },
    [FILTERLIVECAMPAIGN]: (state, payload) => {
        if (payload.Campaign == undefined) { payload.Campaign = '' }
        state.livecalls.userFilter.Campaign = payload.Campaign;
    },
    [FILTERLIVEEXTENSTENSION]: (state, payload) => {
        if (payload.Extension == undefined) { payload.Extension = '' }
        state.livecalls.userFilter.Extension = payload.Extension;
    },
    [FILTERLIVETYPE]: (state, payload) => {
        if (payload.Type == undefined) { payload.Type = '' }
        state.livecalls.userFilter.Type = payload.Type;
    },
    [FILTERLIVESTATUS]: (state, payload) => {
        if (payload.Status == undefined) {
            payload.Status = '';
        }
        state.livecalls.userFilter.Status = payload.Status;
    }
};
const actions = {
    livecalls: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {
                let agent = '';
                let team_user = '';
                if (payload.agent === '' || payload.agent.length === 0) {
                    team_user = JSON.parse(localStorage.getItem("group_users"));
                } else {
                    agent = state.livecalls.userFilter.agent
                }
                api.get("/realtime/liveCalls?phone_number=" + state.livecalls.userFilter.number + "&queue=" + state.livecalls.userFilter.Queue + "&agent=" + agent + "&team_user=" + team_user + "&campaign=" + state.livecalls.userFilter.Campaign + "&call_type=" + state.livecalls.userFilter.Type + "&status=" + state.livecalls.userFilter.Status + "&station=" + state.livecalls.userFilter.Extension + "&skip=&take=").then(response => {
                    var response0 = response;
                    commit(LIVE_CALLS, response0);
                    resolve(response);
                }).catch(function (error) {
                    reject(error);
                });
            } catch (error) {
                commit(LIVE_CALLS, error);
                reject(error);
            }
        })
    },
    queuerecords: ({ commit }) => {
        api.get("/realtime/queueList").then(response => {
            var response1 = response;
            commit(QUEUE_RECORDS, response1);
        })
    },
    extensionrecords: ({ commit }) => {
        api.get("/realtime/extenList").then(response => {
            var response2 = response;
            commit(EXTENSION_RECORDS, response2);
        })
    },
    GetsocialchatLog: (context , filterQuery) => {
        
        const apiUrl = `/chatlogfetch?${new URLSearchParams(filterQuery).toString()}`;
         console.log("apiUrlapiUrl",apiUrl)
        return new Promise((resolve, reject) => {
            try {
                api.get(apiUrl)
                    .then(response => {
                        if (response.data.status === 200) {
                            resolve(response);
                        } else {

                            resolve({ data: { data: [], total_count: [{ count: [] }] } });
                        }
                        resolve(response);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },

    //chatdetailview

    Getchatdetail: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            try {

                api.get(`/whatsapp/chatdetailview?session_id=${payload.session_id}`)
                    .then(response => {
                        console.log("commitcommit", commit);

                        const chatlog = response.data.data;
                        console.log("chatlogchatlog", chatlog);
                        resolve(response);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
};
export default {
    state,
    getters,
    mutations,
    actions
};