<template>
  <div id="callLogReportFilter">
    <div class="datetimefilter">
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>From</label>
        <div>
          <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="filter_fromdate">
          </datetime>
        </div>
      </div>
      <div class="row pa-1 justify-sb datediv" hint="Date">
        <label>To</label>
        <div>
          <datetime class="theme-orange" type="datetime" format="yyyy-MM-dd HH:mm:ss" v-model="filter_todate">
          </datetime>
        </div>
      </div>
    </div>
    <div class="row"></div>
    <v-text-field v-model="appliedfilter.phonenumber" :items="filters.phonenumber" label="Phoner Number"
      :allow-overflow="false">
    </v-text-field>
    <v-text-field v-model="appliedfilter.list_id" :items="filters.list_id" label="List Id">
    </v-text-field>
    <v-autocomplete v-model="appliedfilter.type" :items="filters.type" label="Call Type" persistent-hint small multiple
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-text-field v-model="appliedfilter.station" :items="filters.station" label="Station" :allow-overflow="false">
    </v-text-field>
    <v-autocomplete v-model="appliedfilter.agent" :items="filteragents" label="Agent" persistent-hint small multiple
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.campaign" :items="filters.campaign" label="Campaign" persistent-hint small
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-text-field v-model="appliedfilter.uniqueid" :items="filters.uniqueid" label="Uniqueid" :allow-overflow="false">
    </v-text-field>
    <v-text-field v-model="appliedfilter.did" :items="filters.did" label="DID" :allow-overflow="false">
    </v-text-field>
    <v-autocomplete v-model="appliedfilter.queue" :items="filterqueue" label="Queue" persistent-hint small multiple
      single-line :allow-overflow="false">
    </v-autocomplete>

    <v-autocomplete v-model="appliedfilter.dispo" :items="dispodata" label="Dispo Status" persistent-hint small multiple
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.status" :items="filters.status" label="Status" persistent-hint small multiple
      single-line :allow-overflow="false">
      {{ appliedfilter.template }}
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.template" :items="filtertemplate" label="Template" persistent-hint small
      single-line :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.answerStatus" :items="answerStatusOptions" label="Answer Status"
      persistent-hint small single-line :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete v-model="appliedfilter.Durationtype" :items="Durationtype" label="Duration Type" persistent-hint
      small single-line :allow-overflow="false">
    </v-autocomplete>
    <v-autocomplete :disabled="appliedfilter.Durationtype === ''" v-model="appliedfilter.operator" :items="operator"
      label="Condition" persistent-hint small single-line :allow-overflow="false">
    </v-autocomplete>
    <v-text-field :disabled="appliedfilter.operator === '' || appliedfilter.Durationtype === ''"
      v-model="appliedfilter.value" :items="value" label="Values in Seconds" :allow-overflow="false">
    </v-text-field>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: {
    appliedfilter: Object,
    teamvalues:{
      type:Array,
      default: function () {
        return []
      }
    },
    teambool:{
      type:Boolean,
      default:  function () {
        return false
      }
    }
  },
  data() {
    return {
      operator: ['less than', 'equal to', 'greater than'],
      Durationtype: ['Hold Time', 'Talk Time', 'Acw Time'],
      value: ['10 sec', '20 sec', '30 sec', '40 sec', '50 sec', '100 sec', '150 sec', '200 sec', '250 sec', '300 sec'],
      answerStatusOptions: [
      { text: 'Answered', value: 'Answered' },
      { text: 'Unanswered', value: 'Unanswered' }
    ],
      page: {
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      submit: "",
      filter_fromdate: "",
      filter_todate: "",
      filterqueue: [],
      filtertemplate: [],
      filteragents: [],
      dispodata: []
    };
  },
  watch: {
    appliedfilter: {
      handler: function () {
        if (
          this.appliedfilter.fromdate == this.filters.fromdate &&
          this.appliedfilter.todate == this.filters.todate
        ) {
          this.date_flag = false;
          this.filter_fromdate = this.appliedfilter.fromdate;
          this.filter_todate = this.appliedfilter.todate;
        }
      },
      deep: true,
    },
    filter_fromdate(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      // this.$emit("getDataFrom");
    },
    filter_todate(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD HH:mm:ss");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      // this.$emit("getDataFrom");
    },
    "appliedfilter.agent"(newVal) {
      this.$store.commit("FILTER_AGENT", { agent: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.template"(newVal) {
      this.$store.commit("FILTER_TEMPLATE", { template: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.uniqueid"(newVal) {
      this.$store.commit("FILTER_UNIQUEID", { uniqueid: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.list_id"(newVal) {
      this.$store.commit("FILTER_LISTID", { list_id: newVal });
     
    },
    "appliedfilter.type"(newVal) {

      // var scope = this;
      this.$store.dispatch("type", newVal);
      // scope.$emit("getDataFrom");
    },
   
    "appliedfilter.phonenumber"(newVal) {
      this.$store.commit("FILTER_PHONE", { phone: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.campaign"(newVal) {
      this.$store.commit("FILTER_CAMPAIGN", { campaign: newVal });
    },
    "appliedfilter.group"(newVal) {
      this.$store.commit("FILTER_GROUP", { group: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.station"(newVal) {
      this.$store.commit("FILTER_STATION", { station: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.did"(newVal) {
      this.$store.commit("FILTER_DID", { did: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.queue"(newVal) {
      this.$store.commit("FILTER_QUEUE", { queue: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.dispo"(newVal) {
      this.$store.commit("FILTER_DISPO", { dispo: newVal });
      // this.$emit("getDataFrom");
    },
    "appliedfilter.status"(newVal) {
      this.$store.commit("FILTER_STATUS", { status: newVal });
      // this.$emit("getDataFrom");
    },
  },

  computed: {
    ...mapGetters(["filters", "filter", "queue"]),
  },
  mounted() {
    // this.$store.dispatch("reportuserlist");
    // this.$store.dispatch("GetDisposition");
    // this.$store.dispatch("reportqueuelist");
    // this.$store.dispatch("reporttemplatelist");
    if(this.teambool){
      this.filteragents = this.teamvalues
    }else{
      this.filteragents = JSON.parse(localStorage.getItem("agent_emails"));
    }
    this.filterqueue = JSON.parse(localStorage.getItem("group_queue"));
    // this.filterdispo = JSON.parse(localStorage.getItem("group_disposition"));
    this.filtertemplate = JSON.parse(localStorage.getItem("group_template"));
    this.filter_fromdate = moment(this.filters.fromdate).format();
    this.filter_todate = moment(this.filters.todate).format();
    this.$store.dispatch("getcalldiso").then(res => {
      // let dispo_data=res.data.data
      const disposition = res.data.data;
      disposition.forEach(dispo => {
        this.dispodata.push(dispo.dispo_id);
      });

    })
      .catch(err => {
        console.log("err", err)
      })

  },
};
</script>

<style>
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected>span>span,
.theme-orange .vdatetime-calendar__month__day--selected:hover>span>span {
  background: #2196f3;
}

.theme-orange .vdatetime-month-picker__item--selected,
.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: #2196f3;
}
</style>
