<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon" :breadcrumbs="page.breadcrumbs"
      :addNewData="page.addnew" :exportOptions="page.exportOptions" @openFilter="filterClick"
      @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject">
    </section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="pausecodeData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.pause_code }}</td>
          <td class="checkEmpty p0">{{ props.item.description }}</td>
          <td class="checkEmpty p0">
            <template v-if="props.item.status === 'Ac'">Active</template>
            <template v-else>Inactive</template>
          </td>
          <td class="checkEmpty p0">{{ props.item.timeValue }}:00</td>



          <td class="text-xs-center checkEmpty p0">
            <v-icon color="blue" small class="mr-4" v-if="page.canedit" @click="editItem(props.item)">edit</v-icon>
            <v-icon color="red" small class="mr-4" v-if="page.candelete" @click="deleteItem(props.item)">delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete Pause Code Info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>{{ deletemsg }}</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <template>
      <v-dialog persistent :value="page.isAddPausecode" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Create Pause Code</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="pauseecode" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="addPausecode.payload.pause_code" required label="Pause Code" type="text"
                      :rules="[(v) => !!v || 'Pause code is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="addPausecode.payload.description" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12>
  <v-select v-model="addPausecode.payload.status" required label="Status" :items="status"
  :rules="[(v) => !!v || 'Status is required']"></v-select>
  </v-flex> -->

                  <v-flex xs12>
                    <v-text-field  label="Time" required v-model="addPausecode.payload.timeValue" 
                      @click="page.timeModal = true" :rules="validationRules" ></v-text-field>
                  </v-flex>
                  <v-dialog persistent ref="dialog" v-model="page.timeModal" :return-value.sync="addPausecode.payload.timeValue"
                    width="290px" height="50px" >


                    <v-time-picker v-if="page.timeModal" v-model="addPausecode.payload.timeValue" format="24hr" color="primary">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="closeDatepopup()">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="closeDatepopup(addPausecode.payload.timeValue)">
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>


                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid || !addPausecode.payload.pause_code || !addPausecode.payload.description || !addPausecode.payload.timeValue" @click="saveAddPausecode()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog persistent :value="page.isEditPausecode" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Update Pause Code</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="asdfg" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="editPausecode.pause_code" required label="Pause Code" type="text" readonly
                      :rules="[(v) => !!v || 'Pausecode is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="editPausecode.description" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editPausecode.status" required label="Status" :items="status"
                      :rules="[(v) => !!v || 'Status is required']"></v-select>

                    <v-flex xs12>
                      <v-text-field readonly label="Time" required v-model="editPausecode.timeValue"
                        @click="page.timeModal1 = true">
                      </v-text-field>
                    </v-flex>
                    <v-dialog persistent ref="dialog1" v-model="page.timeModal1" :return-value.sync="editPausecode.timeValue"
                      width="290px" height="50px">


                      <v-time-picker format="24hr" v-if="page.timeModal1" v-model="editPausecode.timeValue" color="primary"
                        header-color="primary">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="page.timeModal1 = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog1.save(editPausecode.timeValue)">
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>
                </v-layout>
                <v-card-text  v-if="editPausecode.status === 'Inactive'"  class="warning_hint"  >
                   
                  Are you sure you want to delete this Pause code? It will be removed from the associated module.
                 
                
               </v-card-text>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <!-- <v-select v-model="filter.payload.pause_code" :items="newArray" label="Pause Code" small></v-select> -->
            <v-autocomplete v-model="filter.payload.pause_code" :items="newArray" :loading="filter.isLoading"
              :search-input.sync="filter.search" label="Name" hide-no-data hide-selected></v-autocomplete>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()" :disabled="disabling(filter.payload.pause_code)">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      valid: true,
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "Pause Code",
        icon: "fa-pause fa-2x",
        loading: false,
        isAddPausecode: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true, autoCompletePC: true },
        addnew: [],
        isEditPausecode: false,
        temp: { key: "addpausecode", label: "Add PauseCode" },
        candelete: false,
        timeModal: false,
        timeModal1: false,
        canedit: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "Pause Code",
          align: "left",
          class: "p0",
          sortable: false,
          value: "pause_code",
        },
        {
          text: "Description",
          align: "left",
          class: "p0",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Duration",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },

        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          // width: "20%",
        },
      ],
      pausecodeData: [],
      //pagecount: 0,
      pagination: {
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      showRowData: false,
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      newArray: [],
      filter: {
        payload: {
          pause_code: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      // pagination: {
      // page: 0,
      // rowsPerPage: 5
      // },
      isProcessing: false,
      call_status: [],
      editPausecode: {
        id: "",
        pause_code: "",
        description: "",
        status: "",
        timeValue: ""
      },
      addPausecode: {
        valid: false,
        payload: {
          pause_code: "",
          description: "",
          status: "Active",
          timeValue: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
      },
      closeDateValidation:false,
      status: ["Active", "Inactive"],
      deletemsg:'',
      deleted_data:{}
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.filter.payload.pause_code = "";
      scope.getDataFromApi();
    },
    disabling(){
      // if(data ===""){
      //   return true
      // }
      return false
    },
    closeDatepopup(data){
    const scope = this 
    if(data !== undefined && data !== ""){
      scope.$refs.dialog.save(data)
      return
    }
    scope.closeDateValidation = true
    scope.page.timeModal = false
    
    },
    filterClick: function () {
      this.page.isFilterOpen = true;
    },

    reset() {
      var scope = this;
      scope.filter.payload.pause_code = "";
      scope.getDataFromApi();
      this.page.isFilterOpen = true;
    },
    filterFieldOptions() {
      var scope = this;
      let data = { model: "pause_codes", column: ["pause_code"] };
      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          scope.newArray = datas[0].pause_code;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDataFromApi() {
      var scope = this;
      scope.tableLoading = true;
      this.page.isFilterOpen = false;
      let take = this.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (this.pagination.page - 1);
      }
      // let query = {
      // skip: skip,
      // take: take
      // }
      scope.filter.payload["skip"] = skip;
      scope.filter.payload["take"] = take;
      scope.skip = skip;
      this.$store
        .dispatch("GetPausecode1", scope.filter.payload)
        .then((response) => {
          this.pagecount = response.count;
          if (response.status === 204) {
            scope.pausecodeData = [];
            scope.tableLoading = false;
          } else {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          if (datas !== "" && datas !== undefined && datas !== null) {
            scope.filtervalue = [];
            datas.forEach((element) => {
              scope.filtervalue.push(element["pause_code"]);
            });
          }
          let a = JSON.parse(localStorage.getItem("pause_code"));
          scope.newArray = scope.filtervalue.filter((e) => a.includes(e));
          scope.pausecodeData = datas;
        }
      })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
    },
    AddNewRule: function () {
      //var scope = this;
      this.closeDateValidation = false
      this.page.isAddPausecode = true;
    },
    sidebar_enable_disable(data){
      this.$root.$emit('sidebar_disable',data);
    },
    closeEditRules: function () {
      this.page.isEditPausecode = false;
    },
    closeAddPausecode: function () {
      this.page.isAddPausecode = false;
      this.$refs.pauseecode.reset();
    },
    saveEditRules() {
      var scope = this;
      scope.$store
        .dispatch("updatePausecode", scope.editPausecode)
        .then(() => {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("Updated Successfully");
          if(this.editPausecode.status == "Inactive"){
            let field = {
              "fields": [
                {
                  "name": "pause_code",
                  "value": this.editPausecode.pause_code
                }
              ]
            }
            this.$store.dispatch("CampaignRemove", field)
          }
          this.getDataFromApi();
          this.page.isEditPausecode = false;
        })
        .catch(function (error) {
          scope.page.isAddPausecode = false;
          scope.showSnackBar("error" + error);
          this.page.isEditPausecode = false;
        });
      this.getDataFromApi();
    },
    saveAddPausecode() {
      var scope = this;
      scope.$store

        .dispatch("insertPausecode", scope.addPausecode.payload)
        .then((res) => {
          if(res.data.statusCode === 201 ){
          scope.page.isAddPausecode = false;
          scope.showSnackBar("Created Successfully");
          this.getDataFromApi();
          this.$refs.pauseecode.reset();
          }else{
            scope.showSnackBar(res.data.message);
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.show = false;
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(data) {   
      this.delete_id = data.id;
      let scope = this
      scope.$store.dispatch("CheckDataCampaign", `parsecode=${data.pause_code}`).then((res) => {
        if(res.status == 200){
          scope.deletemsg = `Deleting this pausecode will affect associated campaigns. Proceed with deletion?`
          scope.deleted_data = data
        }else{
          scope.deletemsg = `Are you sure to delete this Pause code? It will be removed from the associated module.`
          scope.deleted_data = data
        }
      })
      this.DeleteRowdata = true;
    },
    editItem(item) {
      //var scope = this;
      this.valid = true
      this.page.isEditPausecode = true;
      this.editPausecode.id = item.id;
      this.editPausecode.pause_code = item.pause_code;
      this.editPausecode.description = item.description;
      this.editPausecode.timeValue = item.timeValue;

      if (item.status === "Ac") {
        this.editPausecode.status = "Active";
      } else {
        this.editPausecode.status = "Inactive";
      }
    },
    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      scope.filtervalue = [];
      this.$store
        .dispatch("deletePausecode", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            let field = {
              "fields": [
                {
                  "name": "pause_code",
                  "value": this.deleted_data.pause_code
                }
              ]
            }
            this.$store.dispatch("CampaignRemove", field)
            scope.getDataFromApi();
            scope.showSnackBar("Pause Code Deleted");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },
    closedelete() {
      this.DeleteRowdata = false;
    },
    closeShow() {
      this.showRowData = false;
    },
  },
  computed: {
    ...mapGetters(["CallFlow"]),
    validationRules() {
      const rules = [];
      if ( this.closeDateValidation === true ) {
        rules.push(value => !!value || 'Time is  required');
      }
      return rules;
    }
  },
  mounted() {
    let scope = this;
    scope.sidebar_enable_disable(false)
    this.page.loading = false;
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add Pause Code" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:pausecode:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    this.crmdomain = project_config.projects[0].config[0].crm_domain;
    this.getDataFromApi();
    this.filterFieldOptions();
  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {
      if (this.page.isFilterOpen) {
        let scope = this;
        val =  val == null ? undefined : val
        let data = {
          model: "pause_codes",
          column: ["pause_code"],
          search: val,
        };

        scope.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            scope.newArray = datas[0].pause_code;
          })
          .catch((err) => {
            this.filterFieldOptions();
            console.log(err);
          });
      }
    },
    "page.isAddPausecode"(data){
      this.sidebar_enable_disable(data);
    },
    "page.isEditPausecode"(data){
      this.sidebar_enable_disable(data);
    }
  },
};
</script>
<style>
.mr-4 {
  text-align: start;
}

.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>