<template>
  <div>
    <section-definition v-if="insert_form == false" :extraOptions="options" :title="page.title" :icon="page.icon"
      @addNew="NewContact" @openFilter="isFilter" :addNewData="page.addnew" @refreshPage="refreshThisPage"
      @plusPage="addcontact" @contactbulkdelete="contactbulkdelete"></section-definition>

    <div v-if="insert_form == false" class="main_view_ticket">
      <div class="ticket_body_view">
        <v-data-table :headers="headers" :items="ContactData" class="elevation-1" hide-default-header
          :headers-length="10" hide-actions>
          <template v-slot:headers="props" id="table-scroll">
            <tr style="height: 30px">
              <th style="height: 32px; width: 50px">
                <v-checkbox @change="selectAll()" style="padding-top: 12px; padding-left: 2px; height: 50px"
                  v-model="select_check" color="primary">
                </v-checkbox>
              </th>
              <th style="min-width: 250px" v-for="(head, i) in props.headers" :key="i">
                {{ head.text }}
              </th>
              <th style="text-align: center">Action</th>
            </tr>
          </template>
          <template style="text-align: center" v-slot:items="props">
            <tr v-if="ContactData.length > 0">
              <td>
                <v-checkbox v-model="props.item.checker" primary @change="newValue(props.item)" hide-details
                  color="primary"></v-checkbox>
              </td>

              <td style="min-width: 170px" class="checkEmpty" v-for="(property, index) in Object.keys(props.item)"
                :key="index">
                <p style="margin-top: 14px" v-if="property !== 'checker'">
                  {{
                  props.item[property] == "" ||
                  props.item[property] == null ||
                  props.item[property] == false ||
                  props.item[property] == true
                  ? " "
                  : headers[index].value == property && headers[index].masking ? ChartoMaskingChar(props.item[property])
                  : props.item[property]
                  }}
                </p>
                <div class="icon_section">
                  <template v-if="property == 'action'">
                    <v-icon v-show="page.canedit" small class="mr-4" @click="contact_info(props.item)">info</v-icon>

                    <v-icon color="red" @click="deletepopup(props.index)" title="Agent Assign" small
                      class="mr-4">delete</v-icon>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>

        <div class="pagination-div theme--light" style="height: 50px">
          <div flex id="row-selector">
            <div id="page-control">
              <span>{{ (currentPage - 1) * perPage + 1 }}-{{ endPage }} of
                {{ totalRows }}</span>
              <span class="action-icon">
                <v-icon medium class="move-page" @click="moveNextPage(false)">chevron_left</v-icon>
                <v-icon medium class="move-page" @click="moveNextPage(true)">chevron_right</v-icon>
              </span>
            </div>
          </div>
        </div>
      </div>

      <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
        {{ snackbar.text }}
        <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </div>

    <v-navigation-drawer fixed disable-resize-watcher right v-model="isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete v-model="namemodel" :items="items_" :loading="isLoading" :search-input.sync="search"
              hide-no-data hide-selected label="Contact Name" placeholder="search" item-text="name" item-value="value">
            </v-autocomplete>
          </div>
          <div class="autocompleteDiv">
            <v-autocomplete v-model="phoneNumberModel" :items="phoneItems_" :loading="isLoading"
              :search-input.sync="phoneNumberSearch" hide-no-data hide-selected label="Contact Number"
              placeholder="search" item-text="name" item-value="value">
            </v-autocomplete>
          </div>

        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fetchfilterdata()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>

    <v-dialog persistent v-model="page.isUploadContact" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Upload Contact</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <p>
            <span style="font-weight: bold">Not sure what to upload? </span>
            Click here to download the sample file. Format:
            <a @click="downloadSample('csv')">CSV</a>,
            <a @click="downloadSample('txt')">Txt(Tab-Delimited)</a>. Save the
            file with .csv /.txt extension and upload the file.
          </p>
          <div class="file-dummy" @click="$refs.file.click()" :style="{
            background: uploadContact.color + '66',
            'border-color': uploadContact.color,
            color: uploadContact.color,
          }">
            {{ uploadContact.label }}
          </div>
          <input type="file" id="file" ref="file" accept=".csv" v-on:change="handleFileUpload()" />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="CloseUploadContact(false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog presistent v-model="delete_popup" max-width="500px">
      <v-form ref="refassign">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Delete Contact</v-toolbar-title>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-card-text>Are you sure to delete this Contact ?</v-card-text>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat  @click="deleteCancelpopup()">Cancel</v-btn>
            <v-btn color="primary" @click="deleteContact()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog presistent v-model="Bulkdelete_popup" max-width="400px">
      <v-form ref="refassign">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Delete Bulk Contacts</v-toolbar-title>
          </v-toolbar>
          <v-container id="scroll-target" style="max-height: 350px" width="300px" class="scroll-y">
            Do you delete bulk contacts?
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="bulkdeleteCancelpopup()">Cancel</v-btn>
            <v-btn color="primary" @click="bulkdeleteAllContact(true)">All {{ totalRows }} Contacts</v-btn>
            <v-btn color="primary" @click="bulkdeleteContact()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-container fluid grid-list-xl page style="padding-top: 10px">
      <inserforms v-if="insert_form" :page_="true" :model="`CONTACT`" @customform="customform_insert($event)">
      </inserforms>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/utils/axios-util";
import inserforms from "./InsertAsterLead.vue";
import { MaskCharacter } from "@/utils/helpers";
function getDefaultData() {
  return {
    label: "Please select some files",
    color: "#2196f3",
  };
}
export default {
  components: {
    inserforms,
  },
  data() {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      namemodel: "",
      phoneNumberModel: "",
      items_: [],
      phoneItems_: [],
      phoneNumberSearch:"",
      search: "",
      page: {
        title: "Contacts",
        icon: "fa-address-card fa-2x",
        canedit: true,
        isUploadContact: false,
        userassign: false,
        addnew: [{ key: "upload", label: "Upload" }],
      },
      headers: [],
      ids: [],
      ContactData: [],
      bulk_edit_arr: [],
      perPage: 8,
      currentPage: 1,
      totalRows: 0,
      endPage: 0,
      options: {
        filter: true,
        plus: true,
        bulkdeleteContactIcon: true,
        editicon: false,
        uploadicon: false,
        downloadicon: false,
        selectedcurrent: "",
        refresh: true,
        deleteicon: true,
      },
      Bulkdelete_popup: false,
      select_label: "Select All",
      select_check: false,
      CloneContact: [],
      export_popup: false,
      exportType: ["CSV", "Txt(Tab-Delimited)"],
      insert_form: false,
      downloadData: [],
      user_email: JSON.parse(localStorage.apiInfo).user_email,
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      isFilterOpen: false,
      dynamicFields: [],
      fielddata: [],
      delete_popup: false,
      isfilterval: false,
      delete_id: "",
      uploadContact: {},
      Filename:"Contacts",
      masking:false
    };
  },
  watch: {
    search(val) {
      this.searchData("name", val);
    },
    phoneNumberSearch(val) {
    
      this.searchData("phone_number", val);
    },
    'page.isUploadContact': function(newVal) {
      this.sidebar_enable_disable(newVal);
    },
    'delete_popup': function(newVal) {
      this.sidebar_enable_disable(newVal);
    },
    'Bulkdelete_popup': function(newVal) {
      this.sidebar_enable_disable(newVal);
    }
  },
  computed: {
    ...mapGetters(["form","MaskingModules"]),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    NewContact() {
      this.page.isUploadContact = true;
    },
    sidebar_enable_disable(data){
      this.$root.$emit('sidebar_disable',data);
    },
    deleteCancelpopup() {
      let scope = this;
      scope.delete_popup = false;
      scope.delete_id = "";
    },
    bulkdeleteCancelpopup() {
      let scope = this;
      scope.Bulkdelete_popup = false;
    },
    refreshThisPage: function () {
      this.reset(true);
      this.bulk_edit_arr = [];
    },
    CloseUploadContact: function () {
      this.page.isUploadContact = false;

      this.uploadContact = getDefaultData();
      this.refreshThisPage()
    },
    selectAll() {
      var scope = this;
      let id = "";
      if (scope.select_check) {
        scope.select_label = "Select All";
        scope.bulk_edit_arr = [];
        for (let i = 0; i < scope.ContactData.length; i++) {
          scope.ContactData[i].checker = true;
          id = scope.ContactData[i]["phone_number"];
          scope.bulk_edit_arr.push(id);
        }
      } else {
        for (let j = 0; j < scope.ContactData.length; j++) {
          scope.ContactData[j].checker = false;
        }
        scope.bulk_edit_arr = [];
        scope.select_label = "Unselect All";
      }
    },

    newValue(row) {
      var scope = this;

      let id = row;
      if (!scope.bulk_edit_arr.includes(id.phone_number)) {
        scope.bulk_edit_arr.push(id.phone_number);
      } else {
        const index = scope.bulk_edit_arr.indexOf(id.phone_number);
        if (index > -1) {
          scope.bulk_edit_arr.splice(index, 1);
        }
      }
      if (this.ContactData.length == this.bulk_edit_arr.length) {
        scope.select_label = "Unselect All";
        scope.select_check = true;
      } else {
        scope.select_label = "Select All";
        scope.select_check = false;
      }
    },

    bulkdeleteAllContact(value) {
      let scope = this;
      let payload = {
        modified_by: scope.user_email,
        BulkContacts: scope.bulk_edit_arr,
        totalRows: scope.totalRows,
        alldelte: value,
      };

      scope.$store
        .dispatch("bulkdeleteAsterContact", payload)
        .then((response) => {
          if (response.status === 200) {
            scope.showSnackBar("Contact Deleted Successfully!");
            scope.supportdata(scope.currentsupport);
            scope.Bulkdelete_popup = false;
            scope.select_check = false;
            scope.bulk_edit_arr = [];
          } else if (response.status === 204) {
            scope.Bulkdelete_popup = false;
            scope.bulk_edit_arr = [];
            scope.showSnackBar("Contact Unable To Deleted!");
          } else {
            scope.Bulkdelete_popup = false;
            scope.bulk_edit_arr = [];
            scope.showSnackBar("Contact Delete failed!");
          }
        })
        .catch(function () {
          scope.showSnackBar("Contact Delete failed!");
          scope.Bulkdelete_popup = false;
          scope.bulk_edit_arr = [];
        });
    },
    bulkdeleteContact() {
      let scope = this;
      let payload = {
        modified_by: scope.user_email,
        BulkContacts: scope.bulk_edit_arr,
      };

      scope.$store
        .dispatch("bulkdeleteAsterContact", payload)
        .then((response) => {
          if (response.status === 200) {
            scope.showSnackBar("Contact Deleted Successfully!");
            scope.supportdata(scope.currentsupport);
            scope.Bulkdelete_popup = false;
            scope.select_check = false;
            scope.bulk_edit_arr = [];
          } else if (response.status === 204) {
            scope.Bulkdelete_popup = false;
            scope.bulk_edit_arr = [];
            scope.showSnackBar("Contact Unable To Deleted!");
          } else {
            scope.Bulkdelete_popup = false;
            scope.bulk_edit_arr = [];
            scope.showSnackBar("Contact Delete failed!");
          }
        })
        .catch(function () {
          scope.showSnackBar("Contact Delete failed!");
          scope.Bulkdelete_popup = false;
          scope.bulk_edit_arr = [];
        });
    },
    contactbulkdelete() {
      let scope = this;
      // scope.delete_id = scope.ids[data];
      if (scope.bulk_edit_arr.length > 0) {
        scope.Bulkdelete_popup = true;
      }
    },
    deleteContact() {
      let scope = this;
      let query = {
        modified_by: scope.user_email,
        id: scope.delete_id,
      };
      scope.$store
        .dispatch("deleteAsterContact", query)
        .then((response) => {
          if (response.status === 200) {
            scope.showSnackBar("Contact Deleted Successfully!");
            scope.supportdata(scope.currentsupport);
            scope.delete_popup = false;
            scope.delete_id = "";
          } else if (response.status === 204) {
            scope.delete_popup = false;
            scope.delete_id = "";
            scope.showSnackBar("Contact Unable To Deleted!");
          } else {
            scope.delete_popup = false;
            scope.delete_id = "";
            scope.showSnackBar("Contact Delete failed!");
          }
        })
        .catch(function () {
          scope.showSnackBar("Contact Delete failed!");
        });
    },
    deletepopup(data) {
      let scope = this;
      scope.delete_id = scope.ids[data];
      scope.delete_popup = true;
    },
    moveNextPage(next) {
      const scope = this;
      if (next && scope.totalRows >= scope.currentPage * scope.perPage + 1) {
        scope.currentPage = scope.currentPage + 1;
        scope.supportdata(scope.currentsupport);
      } else if (!next && scope.currentPage !== 1) {
        scope.currentPage = scope.currentPage - 1;
        scope.supportdata(scope.currentsupport);
      }
    },
    searchData(column, val) {
      let scope = this;
      val = val === null ? "" : val;
      let data = { model: "contacts", column: [column], search: val };

      scope.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.isLoading = false;
          if (response) {
            var datas = response.data;
            if (column === "name") {
              scope.items_ = datas[0].name;
            } else if (column === "phone_number") {
              if(scope.masking){
                  scope.phoneItems_ = datas[0].phone_number.map((elm)=>{
                    return {
                      name:this.ChartoMaskingChar(elm),
                      value:elm
                    }
                  });
              }else{
                scope.phoneItems_ = datas[0].phone_number
              }
            }
          } else {
            if (column === "name") {
              scope.items_ = [];
            } else if (column === "phone_number") {
              scope.phoneItems_ = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchfilterdata() {
      let scope = this;
      scope.isfilterval = true;
      scope.isFilterOpen = false;
      scope.currentPage = 1;
      scope.fetchFilter();
    },
    fetchFilter() {
      let scope = this;
      const payload = {
        value: this.namemodel || this.phoneNumberModel,
        combinedSearch: {name: this.namemodel, phone_number: this.phoneNumberModel},
        skip: this.perPage * (this.currentPage - 1),
        take: this.perPage,
      };
      this.$store
        .dispatch("GetAsterContacts", payload)
        .then((response) => {
          console.log(response,"response1")
          let datas = response.data;
          scope.CloneContact = [...datas];

          let temparr = [];
          datas.forEach((element) => {
            let tempobj = {};
            scope.headers.forEach((elm) => {
              if (element[elm.value] === undefined && elm.value !== "actions") {
                tempobj[elm.value] = "";
              }
              if (elm.value !== "actions" && element[elm.value] !== undefined) {
                if (elm.value == "assgin") {
                  elm.value = "user";
                }

                tempobj[elm.value] = element[elm.value];
              }
            });
            tempobj.action = true;
            temparr.push(tempobj);
          });

          scope.ContactData = temparr;

          if (response.count !== undefined) {
            scope.totalRows = response.count;
          } else {
            scope.totalRows = 0;
          }
          scope.endPage = scope.currentPage * scope.perPage;
          scope.endPage =
            scope.endPage < scope.totalRows ? scope.endPage : scope.totalRows;
        })
        .catch(function (err) {
          scope.showSnackBar(err);
        });
    },
    reset(closefilter) {
      let scope = this;
      scope.isfilterval = false;
      scope.select_check = false;
      scope.currentPage = 1;
      this.namemodel = "";
      this.phoneNumberModel="";
      this.supportdata();
      if(!closefilter){
        scope.isFilterOpen = true;
      }
    },
    isFilter() {
      let scope = this;
      this.isFilterOpen = false;
      scope.isFilterOpen = true;
    },
    downloadSample(type) {
      const scope = this;
      let ContactFields = "";
      scope.$store.dispatch("GetAsterContactsFields").then((response) => {
        var datas = response.fields;
        ContactFields = datas;
        // console.log(ContactFields,'thsi is contact field')
        const header = [];
        for (let i = 0; ContactFields.length > i; ++i) {
          let key = {
            key: ContactFields[i].model,
            label: ContactFields[i].label,
          };
          if (
            ContactFields[i].model !== "id" &&
            ContactFields[i].model !== "deleted" &&
            ContactFields[i].model !== "created_at" &&
            ContactFields[i].model !== "created_by" &&
            ContactFields[i].model !== "modified_at" &&
            ContactFields[i].model !== "modified_by"
          ) {
            header.push(ContactFields[i].model);
          }

          scope.fielddata.push(key);
          console.log(scope.fielddata, "Thsi is field datassss");
        }
        let csvContent = "";
        if (type === "csv") {
          csvContent += "data:text/csv;charset=utf-8,\uFEFF";
          csvContent += header.join(",");
        } else {
          csvContent += "data:text/txt;charset=utf-8,\uFEFF";
          csvContent += header.join("\t");
        }
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "samplefile" + "." + type);
        document.body.appendChild(link);
        link.click();
      });
    },

    handleFileUpload: function () {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      scope.uploadContact.color = "#A64452";
      scope.uploadContact.label = "Uploading";
      let formData = new FormData();
      console.log(formData, "this is form data");
      formData.append("file", scope.file);
      formData.append(
        "created_by",
        JSON.parse(localStorage.apiInfo).user_email
      );
      formData.append(
        "modified_by",
        JSON.parse(localStorage.apiInfo).user_email
      );
      api.defaults.headers["Content-Type"] = "multipart/form-data";
      console.log(formData, "actul form DAta");
      api
        .post("/contact/bulkupload", formData)
        .then((response) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadContact.color = "#4BB543";
          scope.uploadContact.label = "Uploaded Successfully";
          scope.$refs.file.value = "";
          return response;
        })
        .catch((error) => {
          api.defaults.headers["Content-Type"] = "application/json";
          scope.uploadContact.color = "#F51B00";
          scope.uploadContact.label = "Upload Error";
          return error;
        });
    },
    contact_info(val) {
      let userdata = this.CloneContact.find(
        (elm) => elm.phone_number == val.phone_number
      );

      localStorage.setItem("ticket_templet", JSON.stringify(userdata));

      this.$router.push({
        name: "crm/contactDetail",
      });
    },
    addcontact() {
      let scope = this;
      scope.insert_form = true;
      scope.ContactData = [];
      scope.headers = [];
      scope.bulk_edit_arr = [];
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },

    customform_insert(val) {
      if (val === 201) {
        this.insert_form = false;
        this.supportdata();
        this.showSnackBar("Contact Succesfully Created");
      } else if (val === false) {
        this.insert_form = false;
        this.supportdata();
      }
    },

    supportdata() {
      let scope = this;
      // this.search=""
      if (scope.isfilterval == true) {
        scope.fetchFilter();
      } else {
        this.$store
          .dispatch("GetAsterContactsFields")
          .then((response) => {
            var datas = response.fields;

            let AsterContactDetails = datas;
            datas.map((elm) => elm.model);

            let dynamicFields = [];
            scope.headers = [];
            let maskingvalues = {
              name:scope.Filename,
              value:[]
            }
            for (let i = 0; i < AsterContactDetails.length; i++) {
              if (AsterContactDetails[i].show_on_view === true) {
                if (
                  AsterContactDetails[i].model === "name" ||
                  AsterContactDetails[i].model === "phone_number" ||
                  AsterContactDetails[i].model === "alternative_number" ||
                  AsterContactDetails[i].model === "checker"
                ) {
                  let key = {
                    text: AsterContactDetails[i].label,
                    value: AsterContactDetails[i].model,
                    align: "center",
                    sortable: false,
                    masking: scope.masking && AsterContactDetails[i].type === "phone_number" 
                  };
                  if(scope.masking && AsterContactDetails[i].type === "phone_number"){
                     maskingvalues.value.push(AsterContactDetails[i].model)
                  }
                  // scope.headers.push(key);

                  if (AsterContactDetails[i].model === "name") {
                    scope.headers.splice(0, 0, key);
                  } else if (AsterContactDetails[i].model === "phone_number") {
                    scope.headers.splice(1, 0, key);
                  } else if (
                    AsterContactDetails[i].model === "alternative_number"
                  ) {
                    scope.headers.splice(2, 0, key);
                  } else if (AsterContactDetails[i].model === "checker") {
                    scope.headers.splice(2, 0, key);
                  }
                }
              }
            }
            this.$store.dispatch("MaskingModuleDataPush", maskingvalues)
            scope.dynamicFields = dynamicFields;

            const payload = {
              skip: scope.perPage * (scope.currentPage - 1),
              take: scope.perPage,
              // skip
              // take: 10,
            };
            scope.ids = [];
            scope.$store
              .dispatch("GetAsterContacts", payload)
              .then((response) => {
                console.log(response,"response2")

                let datas = response.data;
                scope.CloneContact = [...datas];

                let temparr = [];
                datas.forEach((element) => {
                  let tempobj = {};
                  // scope.ids.push(element.id);
                  // main
                  scope.headers.forEach((elm) => {
                    if (
                      element[elm.value] === undefined &&
                      elm.value !== "actions"
                    ) {
                      tempobj[elm.value] = "";
                    }
                    if (
                      elm.value !== "actions" &&
                      element[elm.value] !== undefined
                    ) {
                      if (elm.value == "assgin") {
                        elm.value = "user";
                      }

                      tempobj[elm.value] = element[elm.value];
                    }
                  });
                  scope.ids.push(element.id);
                  tempobj.action = true;
                  tempobj.checker = false;
                  temparr.push(tempobj);
                });

                scope.ContactData = temparr;

                if (response.count !== undefined) {
                  scope.totalRows = response.count;
                } else {
                  scope.totalRows = 0;
                }
                scope.endPage = scope.currentPage * scope.perPage;
                scope.endPage =
                  scope.endPage < scope.totalRows
                    ? scope.endPage
                    : scope.totalRows;
              })
              .catch(function (err) {
                console.log(err, "error1error1");
                scope.showSnackBar("error1");
              });
          })
          .catch(function () {
            scope.showSnackBar("error");
          });
      }
    },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    }
  },
  mounted() {
    let scope = this 
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:view"
          ) !== -1
        ) {
          scope.masking = true;
        }

      }.bind(scope),
      1
    );

    // this.search=""
    this.supportdata();
    this.uploadContact = getDefaultData();
  },
};
</script>
<style>
.tab_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  padding: 10px 15px 10px 20px;
}

.tab_button {
  background-color: #cacaca;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 11%;
  cursor: pointer;
  padding: 5px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid #cacaca;
}

.tab_icon {
  width: 20%;
  text-align: right;
}

.tab_txt {
  width: 80%;
  text-align: center;
  padding-right: 15px;
}

.main_view_ticket {
  display: flex;
  flex-direction: column;
}

.ticket_body_view {
  padding: 10px 20px 10px 20px;
}

.tab_button_swap {
  background-color: rgb(33, 150, 243);
  color: #fafafa;
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 11%;
  cursor: pointer;
  padding: 5px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid #cacaca;
}

.icon_section {
  align-content: center;
  padding: 0px 0px 0px 33px;
}

.exportflex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px;
}

.export {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 40%;
  height: 130px;
  text-align: center;
  padding: 20px;
  background-color: #cacaca;
  color: #ffffff;
  border-radius: 10px;
}

.hovericon {
  color: #2c3e50;
  cursor: pointer;
}

.asdff {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>
