
import api from "../../utils/axios-util";

const actions = {
    FetchData: (_, query) => {
        return new Promise((resolve, reject) => {
            try {
                

                let filter = "";
                if (query.title) {
                    filter += `&title=${query.title}`;
                }
                if (query.module) {
                    filter += `&module=${query.module}`;
                }
                if (query.is_active !== undefined) {
                    filter += `&is_active=${query.is_active}`;
                }
    
                const skip = query.skip;
                const take = query.take;
    
                api.get(`/title/fetch?skip=${skip}&take=${take}${filter}`, query)
                    .then(response => {
                        const meetingtitle = response.data;
                        resolve(meetingtitle);
                    })
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    
    insertMeetingtitle: (_, payload) => {
        console.log(payload,"CreatePayload");
        return new Promise((resolve, reject) => {
            try {
                api
                    .post(`/title/create`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    deleteMeetingTitle: (_, payload) => {
        return new Promise((resolve, reject) => {
            try {
                api
                    .delete(`/title/delete/${payload}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    },
    updateMeetingTitle: (_,payload) => {
        return new Promise((resolve, reject) => {
            try {
                const id = payload.id;
                delete payload.id
                api
                    .put(`/title/update/${id}`, payload)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            } catch (error) {
                reject(error);
            }
        });
    }
};
export default {
    actions
};