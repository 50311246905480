export default function config() {

    var environment = 'portal-dev-trial'; //change the environment before building the package
    var credentials = {};
    if (environment == 'development') { 
        credentials.clientId = 'ySM1U4InMt8rCpjahy3S2xg8h8cvIve4';
        credentials.domain = 'dev-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://report-sandbox.doocti.com/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://dev-api.doocti.com';
        credentials.common_api_url = 'https://api-dev.doocti.com';
        credentials.cryptosecuritykey="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" //change key same as backend api
        credentials.Api_trigger = "admin";
    } else if (environment == 'demo') {
        credentials.clientId = 'ySM1U4InMt8rCpjahy3S2xg8h8cvIve4';
        credentials.domain = 'dev-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://demo-portal.doocti.com/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://dev-api.doocti.com';
        credentials.common_api_url = 'https://api-demo.doocti.com';
        credentials.Api_trigger = "admin";
    } else if (environment == 'test') {
        credentials.clientId = 'Htiw39Tuhnwiu67ijphlh8iBFPn7qpsQ';
        credentials.domain = 'test-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://wallboard-test.doocti.com/doocti-report/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://testapi.doocti.com';
        credentials.common_api_url = 'https://test.doocti.com';
        credentials.Api_trigger = "admin";
    }
    else if (environment == 'sandboxv2') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'production' ? 'https://portal-sandboxv2.doocti.com/callback' : 'http://localhost:8080/callback';//qa 
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        // credentials.common_api_url = 'http://localhost:8888'; 
        credentials.common_api_url = 'https://api-v2.doocti.com';
        credentials.cryptosecuritykey="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" //change key same as backend api
        credentials.Api_trigger = "admin";
    }
    else if (environment == 'console-v2-v2.1.101') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'production' ? 'https://console-v2.doocti.com/v2.1.101/callback' : 'http://localhost:8080/callback';//qa 
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        // credentials.common_api_url = 'http://localhost:8888';
        credentials.common_api_url = 'https://api-v2.doocti.com';  
        credentials.cryptosecuritykey="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" //change key same as backend api
        credentials.Api_trigger = "admin";
    }
    else if (environment == 'production') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        // credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'http://localhost:8080/callback';
        // credentials.callbackUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/callback' : 'https://qa-portal.doocti.com/callback';  1 main
        // credentials.callbackUrl = process.env.NODE_ENV === 'production' ? 'http://localhost:8080/callback' :  'https://report-sandbox.doocti.com/callback';  2 submain
        credentials.callbackUrl = process.env.NODE_ENV === 'production' ? 'https://report-sandbox.doocti.com/callback' :  'http://localhost:8080/callback';
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        // credentials.common_api_url = 'https://api.doocti.com'; 
        // credentials.common_api_url = 'http://localhost:8888';
        credentials.common_api_url = 'https://api-v2.doocti.com';  
        credentials.cryptosecuritykey="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" //change key same as backend api
        credentials.Api_trigger = "admin";
    }
    else if (environment == 'portal-dev-trial') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'production' ? 'https://portal-dev.doocti.com/callback' : 'http://localhost:8080/callback';//qa 
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        // credentials.common_api_url = 'http://localhost:8101';
        credentials.common_api_url = 'https://api-v2.doocti.com';   
        credentials.cryptosecuritykey="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" //change key same as backend api
        credentials.Api_trigger = "admin";
    }
    else if (environment == 'portal-onboard') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'production' ? 'https://portal-onboard.doocti.com/callback' : 'http://localhost:8080/callback';//qa 
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        // credentials.common_api_url = 'http://localhost:8888'; 
        credentials.common_api_url = 'https://api-v2.doocti.com';  
        credentials.cryptosecuritykey="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" //change key same as backend api
        credentials.Api_trigger = "admin";
    }
     else if (environment == 'qa-production') {
        credentials.clientId = 'Ay7zS1GKiIWN3TVK6Y1gqKUvDOgDj4B7';
        credentials.domain = 'live-doocti.au.auth0.com';
        credentials.callbackUrl = process.env.NODE_ENV === 'production' ? 'https://qa-portal.doocti.com/callback' : 'http://localhost:8080/callback';//qa 
        credentials.apiUrl = 'API_IDENTIFIER';
        credentials.audience = 'https://prod-api.doocti.com';
        credentials.common_api_url = 'https://api.doocti.com'; 
        credentials.cryptosecuritykey="8d70521b7768cd722f0625e467a2422fe01c85ee00368d4b7197fc38" //change key same as backend api
        credentials.Api_trigger = "admin";
    }
    return credentials;
}