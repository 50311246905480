<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :exportOptions="page.exportOptions" @openFilter="systemcallsummary"
      @refreshPage="refreshReportPage" :isMaterialIconType="true" @exportData="exportReportReport"
      @toggleClick="toggleClick" :style="styleObject"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="getheaders" :items="systemcallsummary_data" class="elevation-1"
        :pagination.sync="pagination" :total-items="agenttotal" :rows-per-page-items="[5, 10, 25]">
        <template v-slot:items="props" >
            <td class="checkEmpty p0">
              {{ props.item.calldate }}
            </td>
            <td class="checkEmpty p0">{{ props.item.total_calls }}</td>
            <td class="checkEmpty p0">{{ props.item.total_inbound_calls }}</td>
            <td class="checkEmpty p0">{{ props.item.total_outbound_calls }}</td>
            <td class="checkEmpty p0">{{ props.item.in_success_calls }}</td>
            <td class="checkEmpty p0">{{ props.item.out_success_calls }}</td>
            <td class="checkEmpty p0">{{ props.item.in_fail_calls }}</td>
            <td class="checkEmpty">{{ props.item.out_fail_calls }}</td>
            <td class="checkEmpty">{{ props.item.in_ans_percent }}</td>
            <td class="checkEmpty">
              {{ props.item.out_ans_percent }}
            </td>
        </template>
      </v-data-table>
    </v-container>
    <!-- <div>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="1000">
          <pdfexport
            v-if="dialog"
            :header="pdf.header"
            :pdfdata="pdf_data"
            @closeModel="closeModel"
            title="Performance Report"
          ></pdfexport>
        </v-dialog>
      </v-layout>
    </div> -->
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen" class="filterNavigation">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div
        class="flex-column justify-sb height-100-exclude-toolbar overflow-auto"
      >
      <v-container class="sidenavContainer">
        <div class="row">
          <v-container class="pa-1">
            <v-menu v-model="page.menu.fromdate" :close-on-content-click="false" full-width max-width="290" small>
              <template v-slot:activator="{ on }">
                <v-text-field :value="appliedfilter.fromdate" label="From Date" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker color="primary" v-model="appliedfilter.fromdate" @change="page.menu.fromdate = false">
              </v-date-picker>
            </v-menu>
          </v-container>
          <v-container class="pa-1">
            <v-menu v-model="page.menu.todate" :close-on-content-click="false" full-width max-width="290" small>
              <template v-slot:activator="{ on }">
                <v-text-field :value="appliedfilter.todate" label="To Date" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker color="primary" v-model="appliedfilter.todate" @change="page.menu.todate = false">
              </v-date-picker>
            </v-menu>
          </v-container>
        </div>
      </v-container>
      <v-container row class="sidenavFooter">
        <v-btn flat @click="resetvalue()">Reset</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="getDataFrom()">Filter</v-btn>
      </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { mapGetters } from "vuex";
// import pdfexport from "./Components/pdfexport";
import moment from "moment";
export default {
  components: {
    loader,
    // pdfexport,
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.page.loading = false;
    this.filteragents = JSON.parse(localStorage.getItem("agent_emails"));
    // this.$store.dispatch("reportuserlist").then(() => {
    //   // this.getDataFromApi();
    //   this.dateformate();
    // });
    this.dateformate();
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "reports:callsummary:export"
          ) !== -1
        ) {
          scope.page.exportOptions = ["CSV", "Txt(Tab-Delimited)"];
        } else {
          scope.page.exportOptions = [];
        }
      }.bind(scope),
      1
    );
  },
  computed: {
    getheaders: function () {
      if (this.page.viewing == "1") {
        return this.headers1;
      } else {
        return this.headers2;
      }
    },
    ...mapGetters(["systemcallsummary_data", "filters"]),
  },
  data() {
    return {
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      page: {
        title: "System Call Summary",
        icon: "network_check",
        loading: true,
        materialicon: true,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        options: { filter: true, refresh: true, toggleButton: true, tab:['Day', 'Month'] },
        exportOptions: [],

        viewing: "1",
        menu: {
          fromdate: false,
          todate: false,
        },
      },
      payload: {
        from_date: "",
        to_date: "",
        skip: 0,
        take: 0,
        descending: "",
        export: "system_call_summary",
      },
      total: "",
      call_date: "",
      report: "",
      exports: [],
      pdf_data: [],
      excel_data_date: [],
      excel_data_month: [],
      txt_data_date: [],
      txt_data_month: [],
      filteragents: [],
      from_date: "",
      to_date: "",
      dialog: false,
      appliedfilter: {
        fromdate: "",
        todate: "",
        agent: "",
      },
      export_calls: "1",
      pagination: {
        descending:false,
        rowsPerPage: 10,
      },
      headers1: [
        {
          text: "Date",
          align: "left",
          sortable: true,
          class: "p0",
          value: "calldate",
        },
        {
          text: "Count",
          align: "left",
          sortable: false,
          class: "p0",
          value: "total_calls",
        },
        {
          text: "Inbound",
          align: "left",
          sortable: false,
          class: "p0",
          value: "total_inbound_calls",
        },
        {
          text: "Outbound",
          align: "left",
          sortable: false,
          class: "p0",
          value: "total_outbound_calls",
        },
        {
          text: "Inbound Answer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "in_success_calls",
        },
        {
          text: "Outbound Answer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "out_success_calls",
        },
        {
          text: "Inbound Abandon",
          align: "left",
          sortable: false,
          class: "p0",
          value: "in_fail_calls",
        },
        {
          text: "Outbound UnAnswer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "out_fail_calls",
        },
        {
          text: "Inbound Answer%",
          align: "left",
          sortable: false,
          class: "p0",
          value: "in_ans_percent",
        },
        {
          text: "Outbound Answer%",
          align: "left",
          sortable: false,
          class: "p0",
          value: "out_ans_percent",
        },
      ],
      headers2: [
        {
          text: "Month",
          align: "left",
          sortable: true,
          class: "p0",
          value: "calldate",
        },
        {
          text: "Count",
          align: "left",
          sortable: false,
          class: "p0",
          value: "total_calls",
        },
        {
          text: "Inbound",
          align: "left",
          sortable: false,
          class: "p0",
          value: "total_inbound_calls",
        },
        {
          text: "Outbound",
          align: "left",
          sortable: false,
          class: "p0",
          value: "total_outbound_calls",
        },
        {
          text: "Inbound Answer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "in_success_calls",
        },
        {
          text: "Outbound Answer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "out_success_calls",
        },
        {
          text: "Inbound Abandon",
          align: "left",
          sortable: false,
          class: "p0",
          value: "in_fail_calls",
        },
        {
          text: "Outbound UnAnswer",
          align: "left",
          sortable: false,
          class: "p0",
          value: "out_fail_calls",
        },
        {
          text: "Inbound Answer%",
          align: "left",
          sortable: false,
          class: "p0",
          value: "in_ans_percent",
        },
        {
          text: "Outbound Answer%",
          align: "left",
          sortable: false,
          class: "p0",
          value: "out_ans_percent",
        },
      ],
      agentperformancereport: [],
      agenttotal: 0,
      type:"day",
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    "appliedfilter.fromdate"(newVal) {
      var from_date = moment(newVal).format("YYYY-MM-DD");
      this.$store.commit("ACTION_FROMDATE", from_date);
      this.$store.commit("FILTERFROMDATE", from_date);
      this.skip = 0;
      // this.getDataFromApi();
    },
    "appliedfilter.todate"(newVal) {
      var to_date = moment(newVal).format("YYYY-MM-DD");
      this.$store.commit("ACTION_TODATE", to_date);
      this.$store.commit("FILTER_TODATE", to_date);
      this.skip = 0;
      // this.getDataFromApi();
    },
    "appliedfilter.agent"(newVal) {
      this.$store.commit("FILTER_AGENT", { agent: newVal });
      this.skip = 0;
      // this.getDataFromApi();
    },
  },
  methods: {
    systemcallsummary: function () {
      this.page.isFilterOpen = true;
    },
    refreshReportPage: function () {
      var scope = this;
      scope.dateformate();
      scope.getDataFromApi();
      scope.refreshvalue();
      scope.appliedfilter.agent = "";
    },
    exportReportReport: function (option) {
      if (option == "PDF" || option == "Txt(Tab-Delimited)") {
        this.dialog = true;
      }
      ``;
      //var export_option = {export_formate : option , viewing : this.page.viewing};
      var export_option = { export_formate: option };
      this.export(export_option);
    },
    toggleClick: function (option) {
      this.page.viewing = option;
      this.getDataFromApi();
      this.dateformate()
    },
    dateformate() {
      this.page.loading = false;
      this.to_date = new Date();
      this.to_date = moment(this.to_date).format("YYYY-MM-DD");
      var from_date1 = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date1.setTime(from_date1.getTime() - dateOffset);
      this.from_date = moment(from_date1).format("YYYY-MM-DD");
      this.appliedfilter.fromdate = this.from_date;
      this.appliedfilter.todate = this.to_date;
      this.$store.commit("FILTERFROMDATE", this.from_date);
      this.$store.commit("FILTER_TODATE", this.to_date);
    },
    getDataFromApi() {
      var scope = this;
      var { descending } = scope.pagination;
      scope.payload.to_date = scope.to_date;
      scope.payload.from_date = scope.from_date;
      scope.payload.datetype = this.page.viewing == "1" ? 'day' : 'month'
      var { page, rowsPerPage } = scope.pagination;
      //scope.$store
      //  .dispatch("reportuserlist")
      //  .then((response) => {
      //    let dataa = response.toString(",");
      scope.payload.agent = JSON.parse(localStorage.getItem("agent_emails"));
      if (rowsPerPage == -1) {
        scope.payload.take = scope.total;
      } else if (
        rowsPerPage != "" &&
        rowsPerPage != undefined &&
        rowsPerPage != null
      ) {
        scope.payload.take = rowsPerPage;
      } else {
        scope.payload.take = 5;
      }
      if (page != "" && page != undefined && page != null) {
        scope.skip = page;
      } else {
        scope.skip = 1;
      }
      scope.payload.skip = (scope.skip - 1) * scope.payload.take;

      scope.payload.descending = descending;

      scope.$store
        .dispatch("systemcallsummary", scope.payload)
        .then((response) => {
          scope.page.loading = false;
          scope.total = response.count;
          if (scope.page_flag) {
            scope.pagination.page = 1;
            scope.page_flag = false;
          }
          scope.agenttotal = scope.total;
          if (response.response.status === 401) {
            scope.$store.dispatch("checkingtoken");
          }
        });
      // });
      //})
    },
    getDataFrom() {
      this.skip = 0;
      this.getDataFromApi();
      this.page_flag = true;
      this.page.isFilterOpen=false
    },
    resetvalue() {
      this.skip = 0;
      var to_date = new Date();
      to_date = moment(to_date).format("YYYY-MM-DD");
      var from_date = new Date();
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      from_date.setTime(from_date.getTime() - dateOffset);
      from_date = moment(from_date).format("YYYY-MM-DD");
      this.$store.dispatch("fromdate", from_date);
      this.$store.dispatch("todate", to_date);
      this.appliedfilter.agent = "";
      this.appliedfilter.flag = true;
      this.appliedfilter.fromdate = "";
      this.appliedfilter.todate = "";
      if (this.appliedfilter.flag) {
        this.appliedfilter.fromdate = from_date;
        this.appliedfilter.todate = to_date;
        this.appliedfilter.flag = false;
      }
      this.getDataFromApi();
      this.page.isFilterOpen=true
        },
    closeModel: function () {
      this.dialog = false;
    },
    json2fileEncode(data, fields, type){
      if(type == "CSV"){
        const replacer = (key, value) => value === null ? '' : value; // handle null values
        const header = fields.map(field => field.text);
        const csv = data.map(row => fields.map(field => row[field.value], replacer).join(','));
        csv.unshift(header.join(',')); 
        return csv.join('\r\n');
      }else if(type == "Txt(Tab-Delimited)"){
        const replacer = (key, value) => value === null ? '' : value; // handle null values
        const header = fields.map(field => field.text);
        const tsv = data.map(row => fields.map(field => row[field.value], replacer).join('\t'));
        tsv.unshift(header.join('\t')); 
        return tsv.join('\r\n');
      }
    },
    export(option) {


      var export_date = {
        skip: 0,
        take: this.total,
        misseddetail: "system_call_summary",
        datetype : this.page.viewing == "1" ? 'day' : 'month'
      };

      this.$store.dispatch("exportdatafetch", export_date).then((response) => {

        if (option.export_formate == "CSV" ) {
          let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
          let heading = this.page.viewing === "1" ? this.headers1 : this.headers2
          csvContent += this.json2fileEncode(response ,heading, option.export_formate)
          let date = new Date();
          date = moment(date).format("YYYY-MM-DD hh-mm");
          let encodedUri = encodeURI(csvContent);
          let link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            "CallSummary" + date + ".csv"
          );
          document.body.appendChild(link);
          link.click();
        }

        if (option.export_formate == "Txt(Tab-Delimited)" ) {
          let txtContent = "";
          let heading = this.page.viewing === "1" ? this.headers1 : this.headers2
          txtContent += this.json2fileEncode(response ,heading, option.export_formate)
          var date2 = new Date();
          date2 = moment(date2).format("YYYY-MM-DD hh-mm");
          var encodedUri1 =
            "data:text/plain;charset=utf-8,\uFEFF" +
            encodeURIComponent(txtContent);
          var link1 = document.createElement("a");
          link1.setAttribute("href", encodedUri1);
          link1.setAttribute(
            "download",
            "CallSummary" + date2 + ".txt"
          );
          document.body.appendChild(link1);
          link1.click();
        }

      });
    },
    refreshvalue() {
      this.$store.commit("FILTER_AGENT", { agent: "" });
      this.page_flag = true;
    },
  },

  beforeDestroy() {
    this.$store.commit("FILTER_AGENT", { agent: "" });
  },
};
</script>
<style>
  .icons {
    height: 80px;
  }
  </style>