export default {
  liveagents: {
    response: [],

  },
  filter:{
        agent:[],
        queue:[],
        extension:[],
        availextension:[],
        status:["READY","CONNECT","PAUSE","Dispo"],
        type:["INBOUND","MANUAL","AUTO"],
        user:[],
        campaign:[]

    },
    token:{
      checkingtoken: false
    },
  liveagentmonitordata:{},
  liveagentmonitortype:"",
  callconnectstatus:false,
  CallActionflag:false
};