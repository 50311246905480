<template>
  <div>
    <section-definition :title="page.title" :extraOptions="page.options" :icon="page.icon"
      :breadcrumbs="page.breadcrumbs" :addNewData="page.addnew" :exportOptions="page.exportOptions"
      @openFilter="filterClick" @refreshPage="refreshThisPage" @addNew="AddNewRule" :style="styleObject"
      @opensettings="settingclick"></section-definition>
    <loader v-if="page.loading"></loader>
    <v-container v-else fluid grid-list-xl page>
      <v-data-table :headers="headers" :items="asterlistData" class="elevation-1 fixed-layout"
        :pagination.sync="pagination" :total-items="pagecount" :loading="tableLoading" :rows-per-page-items="[10]">
        <template v-slot:no-data style="text-align: center">
          <div style="text-align: center">No Data Available</div>
        </template>
        <template v-slot:items="props">
          <td class="checkEmpty p0">{{ props.item.list_id }}</td>
          <td class="checkEmpty p0">{{ props.item.name }}</td>
          <td class="checkEmpty p0">{{ props.item.campaign }}</td>
          <td class="checkEmpty p0">{{ props.item.template.slice('custom_'.length) }}</td>
          <td class="checkEmpty p0">{{ props.item.recycling }}</td>

          <td class="checkEmpty p0">{{ props.item.recycle_count }}</td>
          <td class="checkEmpty p0">{{ props.item.count }}</td>
          <td class="checkEmpty p0">
            {{ props.item.active == "Y" ? "Active" : "Inactive" }}
          </td>
          <!-- ///quick action are added by abdul///-->
          <td class="text-xs-center checkEmpty p0 sticky">
            <v-icon color="primary" small class="mr-4 " v-if="page.publish" :class="['fas', 'fa-globe']"
              @click="publishItem(props.item)"></v-icon>
            <v-icon title="Download List" block :disabled="downloadtooltip" color="blue" small
              class="mr-4 fas fa-download" v-if="page.canedit" @click="samplefiledownload(props.item.list_id)"></v-icon>
            <v-icon title="Buffer Reset" block :disabled="buffertooltip" color="green" small class="mr-4 fas fa-refresh"
              v-if="page.canedit" @click="bufferreset(props.item.list_id)"></v-icon>
            <v-icon title="List Reset" block :disabled="listtooltip" color="SlateBlue" small class="mr-4 fas fa-refresh"
              v-if="page.canedit" @click="listreset(props.item.list_id)"></v-icon>
            <v-icon title="Recycle" block :disabled="recycletooltip" color="blue" small
              class="mr-4 fas fa-circle-o-notch" v-if="page.canedit" @click="listrecycle(props.item.list_id)"></v-icon>
            <v-icon title="Edit" block :disabled="edittooltip" color="blue" small class="mr-4" v-if="page.canedit"
              @click="editItem(props.item)">edit</v-icon>
            <v-icon title="Delete" block :disabled="deletetooltip" color="red" small class="mr-4" v-if="page.candelete"
              @click="deleteItem(props.item.list_id)">delete</v-icon>


          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog persistent v-model="DeleteRowdata" max-width="500px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Delete List Info</span>
          </v-card-title>
        </v-toolbar>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-card-text>Are you sure to delete this List? It will be removed from the associated module.</v-card-text>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" flat @click="closedelete()">Cancel</v-btn>
          <v-btn color="primary" @click="savedelete()">Yes, Delete !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template>
      <v-dialog persistent :value="page.isTknPopup" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-card-title>
              <span class="headline">Token Generation</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="pauseecode" v-model="valid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="addPausecode.payload.user" label="User" type="text"
                      readonly="1"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="addPausecode.payload.tenant_id" label="Tenant_id" type="text"
                      readonly="1"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="addPausecode.payload.scope" required :items="addPausecode.options.scope"
                      label="scope" type="text" :rules="[(v) => !!v || 'scope is required']"></v-select>
                  </v-flex>

                  <!-- <v-flex xs12>
  <v-textarea v-model="portal_url" label="token" type="text" ></v-textarea>
  </v-flex> -->

                  <div class="endpoint">
                    <div> Token</div>
                    <v-icon title="token" :color="('blue')" small class="mr-4 " :class="['fas', 'fa-copy', 'clipicon']"
                      @click="Text()"></v-icon>
                  </div>

                  <div>
                    <input id="example" v-model="portal_url" readonly="true" ref="portal_url" />
                  </div>

                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closetoken()">Close</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="saveAddPausecode()">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <v-dialog persistent v-model="isTknUrl" scrollable max-width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-card-title>
            <span class="headline">Generate url</span>
          </v-card-title>
        </v-toolbar>
        <v-form ref="createform" v-model="createvalid">
          <v-container>
            Endpoint Url
            <v-icon title="generate url" :color="('blue')" small class="mr-4 " :class="['fas', 'fa-copy', 'clipicon']"
              @click="copyText()"></v-icon>
            <br />
            <br />
            <div>
              <input id="example" v-model="message" readonly="true" ref="message" />
            </div>
            <br />
            <br />

            method:Post
            <br />
            <br />
            body:
            {{ sampledata }}
            <br />
            <br />
            Headers:'Authorization':'Access Token',
            'Content-Type': 'application/json'


          </v-container>
        </v-form>
        <v-card-actions class="modal-footer--sticky">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closdelete()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template>
      <v-dialog persistent :value="page.isAddAsterlist" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Create List</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text style="height: 500px">
            <v-form ref="createform" v-model="createvalid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="addAsterlist.payload.list_id" required label="List ID" type="text" :rules="[
                      (v) => !!v || 'List ID is required',
                      (v) =>
                        (!isNaN(parseFloat(v)) && Number.isInteger(parseFloat(v)) && v > 0) ||
                        'List ID Must Be Number',
                    ]"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="addAsterlist.payload.name" required label="Name" type="text"
                      :rules="[(v) => !!v || 'Name is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="addAsterlist.payload.description" label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="addAsterlist.payload.campaign" :items="addAsterlist.options.campaign"
                      label="Campaign" item-text="name" item-value="value" return-string required
                      :rules="[(v) => !!v || 'Campaign is required']" persistent-hint></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-select v-model="addAsterlist.payload.recycling" :items="addAsterlist.options.recycling"
                      label="Auto Recycling Count" item-text="name" item-value="value" return-string
                      persistent-hint></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeAddPausecode()">Close</v-btn>
            <v-btn color="primary" :disabled="!createvalid" @click="saveAddAsterlist()">Create</v-btn>
            <!-- <v-btn :disabled="!isCreateListButtonDisabled" color="primary" @click="saveAddAsterlist()">Create</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <v-dialog persistent :value="page.isEditAsterlist" scrollable max-width="500px">
        <v-card>
          <v-toolbar dark color="primary" class="modal-header--sticky">
            <v-card-title>
              <span class="headline">Update List</span>
            </v-card-title>
          </v-toolbar>
          <v-card-text style="height: 500px">
            <v-form ref="updateform" v-model="updatevalid">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field readonly v-model="editAsterlist.list_id" required label="List ID" type="text" :rules="[
                      (v) => !!v || 'List ID is required',
                      (v) =>
                        (!isNaN(parseFloat(v)) && v > 0) ||
                        'List ID Must Be Number',
                    ]"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="editAsterlist.name" required label="Name" type="text" readonly
                      :rules="[(v) => !!v || 'Name is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="editAsterlist.description" required label="Description" type="text"
                      :rules="[(v) => !!v || 'Description is required']"></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editAsterlist.campaign" :items="addAsterlist.options.campaign" label="Campaign"
                      item-text="name" item-value="value" return-string required persistent-hint
                      :rules="[(v) => !!v || 'Campaign is required']" @change="validateActiveStatus"></v-select>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <v-select v-model="editAsterlist.active" :items="addAsterlist.options.active" label="Active"
                  item-text="name" item-value="value" return-string required persistent-hint
                  :rules="[(v) => validateActiveStatus()]"></v-select>
                  </v-flex> -->
                  <v-flex xs12>
                    <v-select v-model="editAsterlist.active" :items="addAsterlist.options.active" label="Active"
                      item-text="name" item-value="value" return-string required persistent-hint
                      :rules="[(v) => !!v || 'status is required']"></v-select>
                  </v-flex>
                  <v-flex xs12>
                    <v-select v-model="editAsterlist.recycling" :items="addAsterlist.options.recycling"
                      label="Auto Recycling Count" item-text="name" item-value="value" return-string
                      persistent-hint></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="modal-footer--sticky">
            <v-spacer></v-spacer>
            <v-btn color="light" flat @click="closeEditRules()">Close</v-btn>
            <v-btn color="primary" :disabled="!updatevalid || editAsterlist.campaign === '-'" flat
              @click="saveEditRules()">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-navigation-drawer fixed disable-resize-watcher right v-model="page.isFilterOpen">
      <v-toolbar small color="secondary" style="color: white">
        <v-toolbar-title>Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat color="white" @click="page.isFilterOpen = false">
          <i class="fas fa-close"></i>
        </v-btn>
      </v-toolbar>
      <div class="flex-column justify-sb height-100-exclude-toolbar overflow-auto">
        <v-container class="sidenavContainer">
          <div class="autocompleteDiv">
            <v-autocomplete v-model="filter.payload.name" :items="filtervalue" :loading="filter.isLoading"
              :search-input.sync="filter.search" label="Name" small hide-no-data hide-selected></v-autocomplete>
          </div>
          <!-- //////filter is added by abdul/////// -->
          <div class="autocompleteDiv">
            <v-select v-model="filter.payload.campaign" :items="addAsterlist.options.campaign" label="Campaign"
              small></v-select>
          </div>
          <div class="autocompleteDiv">
            <v-select v-model="filter.payload.status" :items="filter.options.status" label="Status" item-text="name"
              item-value="value" return-string persistent-hint></v-select>
          </div>
        </v-container>
        <v-container row class="sidenavFooter">
          <v-btn flat @click="reset()">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getDataFromApi()">Filter</v-btn>
        </v-container>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
      {{ snackbar.text }}
      <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="isProcessing" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import loader from "@/components/Widgets/loader";
import { MaskCharacter } from "@/utils/helpers";
//import api from "@/utils/axios-util";
import { mapGetters } from "vuex";
export default {
  components: {
    loader,
  },

  data() {
    return {
      activateCampaignDialog: false,
      sampledata: {},
      createvalid: true,
      updatevalid: true,
      activeErrorMessage: '',
      styleObject: {
        height: "80px",
        "margin-top": "-8px",
      },
      downloadtooltip: false,
      buffertooltip: false,
      listtooltip: false,
      recycletooltip: false,
      edittooltip: false,
      deletetooltip: false,
      page: {
        title: "List",
        icon: "fa-list fa-2x",
        loading: false,
        isAddAsterlist: false,
        isTknPopup: false,
        isToken: false,
        isgenrateurl: false,
        isFilterOpen: false,
        playingRecord: false,
        breadcrumbs: [],
        exportOptions: [],
        options: { filter: true, refresh: true, cog: false },
        addnew: [],
        isEditAsterlist: false,
        temp: { key: "addasterlist", label: "Add AsterList" },
        candelete: false,
        canedit: false,
        publish: false,
      },
      pagecount: 0,
      headers: [
        {
          text: "List ID",
          align: "left",
          class: "p0",
          sortable: false,
          value: "list_id",
        },
        {
          text: "Name",
          align: "left",
          class: "p0",
          sortable: false,
          value: "name",
          width: "12%",
        },
        {
          text: "Campaign",
          align: "left",
          class: "p0",
          sortable: false,
          value: "campaign",
        },
        {
          text: "Template",
          align: "left",
          class: "p0",
          sortable: false,
          value: "template",
        },
        {
          text: "Recycle Count",
          align: "left",
          class: "p0",
          sortable: false,
          value: "recycling",
        },
        {
          text: "Utilize Count",
          align: "left",
          class: "p0",
          sortable: false,
          value: "recycle_count",
        },
        {
          text: "Lead Count",
          align: "left",
          class: "p0",
          sortable: false,
          value: "count",
        },
        {
          text: "Status",
          align: "left",
          class: "p0",
          sortable: false,
          value: "status",
        },
        {
          text: "Action",
          class: "sticky",
          align: "center",
          sortable: false,
          value: "btn",
          width: "25%",
        },
      ],
      asterlistData: [],
      // pagecount: 0,
      pagination: {
        page: 0,
        rowsPerPage: 10,
      },
      snackbar: {
        show: false,
        timeout: 6000,
        text: "",
      },
      DeleteRowdata: false,
      token: false,
      isTknUrl: false,
      showRowData: false,
      portal_url: "",
      DataRow: false,
      tableLoading: false,
      crmdomain: "",
      filtervalue: [],
      filter: {
        payload: {
          name: "",
          campaign: "",
          scope: "",
        },
        options: {
          status: ["Active", "Inactive"],
        },
        isLoading: false,
        search: "",
      },
      isProcessing: false,
      call_status: [],
      editAsterlist: {
        list_id: "",
        name: "",
        campaign: "",
        description: "",
        active: "",
        recycling: "",
      },
      addAsterlist: {
        createValid: false,
        valid: false,
        payload: {
          list_id: "",
          name: "",
          campaign: "",
          description: "",
          active: { name: "Active", value: "Y" },
          recycling: "",
        },
        options: {
          campaign: [],
          active: [
            { name: "Active", value: "Y" },
            { name: "Inactive", value: "N" },
          ],
          recycling: [
            { name: "1", value: "1" },
            { name: "2", value: "2" },
            { name: "3", value: "3" },
            { name: "4", value: "4" },
            { name: "5", value: "5" },
            { name: "6", value: "6" },
          ],
        },
      },

      addPausecode: {
        valid: false,
        payload: {
          user: "Admin",
          tenant_id: localStorage.getItem('TenantCode'),
          scope: "api-v2"
        },
        options: {
          scope: ["api-v1", "api-v2", "api-v3"],
        },
      },
      Filename:"AsterList",
      masking:false,
      teams:JSON.parse(localStorage.getItem("Team"))
    };
  },
  methods: {
    refreshThisPage: function () {
      var scope = this;
      scope.page.loading = true;
      scope.pagination.page = 1;
      scope.filter.payload.name = "";
      scope.filter.payload.campaign = "";
      scope.filter.payload.status = "";
      scope.getDataFromApi();
    },

    samplefiledownload(id) {
      let AsterLeadFields = "";
      let scope = this
      this.$store.dispatch("downloadlistdata", id).then((response) => {
        if (response.status == 200) {
          this.showSnackBar("Downloaded Successfully");
        } else if (response.status == 204) {
          this.showSnackBar("No Content");
        }
        var datas = response.data.data;
        AsterLeadFields = datas;
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
        const keys = Object.keys(AsterLeadFields[0]);
        let row = keys.join(",");
        csvContent += row + "\r\n";
        AsterLeadFields.forEach(function (rows) {
          if(scope.masking){
              scope.MaskingModules.MaskingModules[scope.Filename].forEach((elm)=>{
              rows[elm] =  scope.ChartoMaskingChar(rows[elm])
            })
          }
          let data = Object.values(rows);
          let data1 = [];
          data.forEach((elm) => {
            if (typeof elm === "string") {
              data1.push(elm.replace(/[\\#^+()$~%,'";*?<>{}!]/g, " "));
              // data1.push(elm.replace(/'|"|#|,|;|\n/g, " "));
            } else {
              data1.push(elm);
            }
          });
          let row = data1.join(",");

          csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", id + ".csv");
        document.body.appendChild(link);
        link.click();
      });
    },

    filterClick: function () {
      this.page.isFilterOpen = true;
    },

    settingclick: function () {

      this.page.isTknPopup = true;
    },
    filterFeidlOptions() {
      let scope = this;

      let data = { model: "lists", column: ["name"] };
      if (this.teams.length > 0) {
          data.team = this.teams.map(elm => elm.name)
      }
      this.$store
        .dispatch("GetModuleNameList", data)
        .then((response) => {
          scope.tableLoading = false;
          scope.page.loading = false;
          var datas = response.data;
          this.filtervalue = datas[0].name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeActivateCampaign() {
      this.activateCampaignDialog = false;
      this.editAsterlist.active = 'Inactive';
    },
    reset() {
      var scope = this;
      scope.filter.payload.name = "";
      scope.filter.payload.campaign = "";
      scope.filter.payload.status = "";
      this.getDataFromApi();
      this.page.isFilterOpen = true;
    },

    Text() {
      const value = this.$refs.portal_url;

      value.select();
      value.setSelectionRange(0, 99999);
      console.log(value.setSelectionRange(0, 99999), "ssss")
      document.execCommand('copy');
    },

    copyText() {
      const element = this.$refs.message;

      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand('copy');
    },
    getDataFromApi() {
      var scope = this;

      scope.tableLoading = true;
      scope.page.isFilterOpen = false;

      let take = scope.pagination.rowsPerPage;
      let skip;
      if (this.pagination.page === 1) {
        skip = 0;
      } else {
        skip = this.pagination.rowsPerPage * (scope.pagination.page - 1);
      }
      if (skip == -10) {
        skip = 0;
      }
      let status = "";
      if (scope.filter.payload.status == "Active") {
        status = "Y";
      }
      if (scope.filter.payload.status == "Inactive") {
        status = "N";
      }
      let query = {
        skip: skip,
        take: take,
        list_name: scope.filter.payload.name,
        list_campaign: scope.filter.payload.campaign,
        list_status: status,
      };
      scope.skip = skip;

      if (this.teams.length > 0) {
        query.team = this.teams.map(elm => elm.name)
      }
      this.$store
        .dispatch("GetAsterList", query)
        .then((response) => {
          if (
            response.data !== undefined &&
            response.data !== "" &&
            response.data
          ) {
            scope.tableLoading = false;
            scope.page.loading = false;
            var datas = response.data;
            this.pagecount = response.count;
            datas.forEach((element) => {
              if (element.campaign == "") {
                element.campaign = "-";
              }
            });
            scope.asterlistData = datas;
          } else {
            scope.tableLoading = false;
            scope.page.loading = false;
            scope.asterlistData = [];
          }
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          scope.page.isEditAsterlist = false;
        });
    },
    AddNewRule: function () {
      this.page.isAddAsterlist = true;
    },
    validateActiveStatus() {
      let activeCampaigns = this.addAsterlist.options.campaign
      console.log('eeeeeeeeeeeee cmap is', this.editAsterlist.campaign)
      if (this.editAsterlist.active === 'Y' && !activeCampaigns.includes(this.editAsterlist.campaign)) {
        //return this.activeErrorMessage;
        this.activateCampaignDialog = true
        //return false
      }
      //this.activeErrorMessage = '';
      //this.activateCampaignDialog=false
      return true; // Validation passed
    },
    closeEditRules: function () {
      this.campaignSet(false)
      this.page.isEditAsterlist = false;
    },
    closeAddPausecode: function () {
      var scope = this;
      scope.page.isAddAsterlist = false;
      scope.addAsterlist.payload = {
        list_id: "",
        name: "",
        campaign: "",
        description: "",
        active: { name: "Active", value: "Y" },
        recycling: "",
      };
      scope.$refs.createform.reset();
    },
    saveEditRules() {
      var scope = this;
      this.$refs.updateform.validate();
      scope.page.isEditAsterlist = !this.$refs.updateform.validate()

      if (this.$refs.updateform.validate() && this.validateActiveStatus()) {
        scope.$store
          .dispatch("updateAsterList", scope.editAsterlist)
          .then(() => {
            scope.page.isAddAsterlist = false;
            this.campaignSet(false)
            scope.showSnackBar("Updateded Successfully");
            this.page.isEditAsterlist = false;
            scope.getDataFromApi();

          })
          .catch(function (error) {
            scope.page.isAddAsterlist = false;
            scope.showSnackBar("error" + error);
            this.page.isEditAsterlist = false;
          });
      }
      scope.getDataFromApi();
    },
    saveAddAsterlist() {
      var scope = this;

      scope.page.isAddAsterlist = !this.$refs.createform.validate();
      if (this.$refs.createform.validate()) {
        scope.$store
          .dispatch("insertAsterList", this.addAsterlist.payload)
          .then(() => {
            scope.showSnackBar("Created Succefully");
            scope.addAsterlist.payload.list_id = " ";
            scope.addAsterlist.payload.name = " ";
            scope.addAsterlist.payload.campaign = "";
            scope.addAsterlist.payload.description = "";
            // scope.addAsterlist.payload.active = "";
            scope.addAsterlist.payload.recycling = "";
            scope.getDataFromApi();
            this.$refs.createform.reset();
          })
          .catch(function (error) {
            scope.page.isAddAsterlist = false;
            scope.showSnackBar("error" + error);
          });
      }

      // this.getDataFromApi();
      scope.getDataFromApi();
    },
    showSnackBar(message) {
      let scope = this;
      scope.snackbar.text = message;
      scope.snackbar.show = true;
      setTimeout(() => {
        scope.snackbar.text = "";
      }, scope.snackbar.timeout);
    },
    deleteItem(list_id) {
      this.DeleteRowdata = true;
      this.delete_id = list_id;
    },
    publishItem(list) {

      // let url = "leadpush"
      this.message = JSON.parse(localStorage.apiInfo).portal_url + `/api?list_id=${list.list_id}&channel=lead`,
        this.$store
          .dispatch("GetAsterCampaigns", { campaign_name: list.campaign, skip: 0, take: 1 })
          .then((response) => {

            this.$store
              .dispatch("GetFormFields", response.data[0].template_name)
              .then((res) => {
              let fields = {};
              let duplicatecheck = {
              params: [
              { field: "first_name", condition: "or" },
              { field: "email", condition: "and" },
              { field: "phone_number", condition: "" }
            ]
          };
                for (let i = 0; i < res.data.data[0].fields.length; i++) {
                  const element = res.data.data[0].fields[i];
                  console.log(element, element.label)
                  if (element.model !== 'deleted' && element.model !== 'created_at' && element.model !== 'isclosed' && element.model !== 'hopper_status' && element.model !== 'modified_date' && element.model !== 'created_by' && element.model !== 'modified_by' && element.model !== 'list_id')
                  fields[element.model] = ``
                }
                this.sampledata = { 
                  fields: fields,
                  duplicatecheck: duplicatecheck
                };
                this.isTknUrl = true;
              }).catch(function (error) {
                console.log(error)
              });
          })
          .catch(function (error) {
            console.log(error)
          });

    },
    campaignSet(action, template) {
      var scope = this;
      let payload = {}
      if (action) {
        payload = { skip: 0, take: 0, campaign_status: 'Y', All: true, template: template.slice('custom_'.length) };
      } else {
        payload = { skip: 0, take: 0, campaign_status: 'Y', All: true };
      }
      if (this.teams.length > 0) {
        payload.team = this.teams.map(elm => elm.name)
      }

      scope.$store.dispatch("GetAsterCampaigns", payload)
        .then((response) => {
          if (response.statusCode == 200) {
            scope.addAsterlist.options.campaign = []
            response.data.forEach((campaign) => {
              scope.addAsterlist.options.campaign.push(campaign.campaign_name);
            });
          }
          else {
            scope.addAsterlist.options.campaign = []
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });
    },
    editItem(item) {
      if (item.count === 0) {
        this.campaignSet(false, item.template)
      } else {
        this.campaignSet(true, item.template)
      }

      this.page.isEditAsterlist = true;
      this.editAsterlist.id = item.id;
      this.editAsterlist.list_id = item.list_id;
      this.editAsterlist.name = item.name;
      this.editAsterlist.campaign = item.campaign;
      this.editAsterlist.description = item.description;
      this.editAsterlist.active = item.active;
      this.editAsterlist.recycling = item.recycling;
    },

    savedelete() {
      let scope = this;
      scope.isProcessing = true;
      this.$store
        .dispatch("deleteAsterList", this.delete_id)
        .then((response) => {
          scope.isProcessing = false;
          if (response.data.message == "success") {
            scope.getDataFromApi();
            scope.showSnackBar("List Deleted Successfully");
          } else {
            scope.showSnackBar("Error! Unable Delete list");
          }
        })
        .catch(() => {
          scope.isProcessing = false;
          scope.showSnackBar("Error! Unable Delete list");
        });
      this.closedelete();
    },

    listrecycle(id) {
      var scope = this;
      scope.$store
        .dispatch("updateAsterListrecyclecount", id)
        .then(() => {
          this.getDataFromApi();
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlist = false;
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });

    },

    listreset(id) {
      var scope = this;
      scope.$store
        .dispatch("listreset", id)
        .then(() => {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlist = false;
          this.getDataFromApi();
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });

    },
    saveAddPausecode() {
      if (this.$refs.pauseecode.validate()) {
        var scope = this;
        scope.$store
          .dispatch("token", scope.addPausecode.payload)
          .then((response) => {
            console.log(response, "response");
            this.portal_url = JSON.stringify(response.data).replace(/["]+/g, '');

            // scope.page.isTknPopup = false;
            scope.showSnackBar("token generated");
            this.getDataFromApi();

          })
          .catch(function (error) {
            console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
            scope.page.isTknPopup = false;
            scope.showSnackBar("error" + error);
          });
        this.getDataFromApi();
      }
    },

    bufferreset(id) {
      var scope = this;
      scope.$store
        .dispatch("bufferreset", id)
        .then(() => {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("Updated Successfully");
          this.page.isEditAsterlist = false;
        })
        .catch(function (error) {
          scope.page.isAddAsterlist = false;
          scope.showSnackBar("error" + error);
          this.page.isEditAsterlist = false;
        });
      this.getDataFromApi();
    },

    closedelete() {
      this.DeleteRowdata = false;
    },
    closetoken() {
      this.page.isTknPopup = false;
    },
    closdelete() {
      this.isTknUrl = false;
    },
    closeShow() {
      this.showRowData = false;
    },
    sidebar_enable_disable(data) {
      this.$root.$emit('sidebar_disable', data);
    },
    ChartoMaskingChar(data){
      let scope = this
      if(!scope.$store.getters.MaskType.includes(typeof(data))){
          return data
      }
      return MaskCharacter(data)
    }
  },
  computed: {
    ...mapGetters(["CallFlow","MaskingModules"]),
    //     isCreateListButtonDisabled() {
    //       const requiredFields = ['list_id', 'name', 'description', 'campaign'];
    //     return requiredFields.every(field => !!this.addAsterlist.payload[field]);
    // }

  },
  watch: {
    pagination: {
      handler() {
        if (this.pagination.page > 1 || this.skip >= 10) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    "filter.search"(val) {

      if (this.page.isFilterOpen && val != null) {
        let scope = this;

        let data = { model: "lists", column: ["name"], search: val };
        if (this.teams.length > 0) {
          data.team = this.teams.map(elm => elm.name)
        }
        this.$store
          .dispatch("GetModuleNameList", data)
          .then((response) => {
            scope.tableLoading = false;
            scope.page.loading = false;
            if (response) {
              var datas = response.data;
              this.filtervalue = datas[0].name;
            } else {
              this.filtervalue = []
            }

          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    'editAsterlist.active'(newVal) {
      let activeCampaigns = this.addAsterlist.options.campaign
      console.log("nnnn val ius", newVal)
      if (newVal === 'Y' && !activeCampaigns.includes(this.editAsterlist.campaign)) {
        // this.activateCampaignDialog = true;
        // this.activeErrorMessage = 'Please activate the mapped inactive campaign to active.';
      } else {
        // this.activeErrorMessage = ''; // Clear error message if the campaign is active
      }
    }
    ,
    "page.isAddAsterlist"(data) {
      this.sidebar_enable_disable(data);
    },
    "page.isEditAsterlist"(data) {
      this.sidebar_enable_disable(data);
    },
    "page.isTknPopup"(data) {
      this.sidebar_enable_disable(data);
    },
    "isTknUrl"(data) {
      this.sidebar_enable_disable(data);
    },
    "DeleteRowdata"(data) {
      this.sidebar_enable_disable(data);
    }
  },
  mounted() {
    let scope = this;
    this.page.loading = false;
    this.filterFeidlOptions();
    // let teams = JSON.parse(localStorage.getItem("Team"))
    // if (Object.keys(teams).length === 0) {

      let payload = { skip: 0, take: 0, campaign_status: 'Y', All: true };
      if (this.teams.length > 0) {
        payload.team = this.teams.map(elm => elm.name)
      }
      scope.$store.dispatch("GetAsterCampaigns", payload)
        .then((response) => {
          if (response.statusCode == 200) {
            response.data.forEach((campaign) => {
              scope.addAsterlist.options.campaign.push(campaign.campaign_name);
            });
          }
        })
        .catch(function (error) {
          scope.showSnackBar("error" + error);
        });


    // }
    // else {
    //   teams.campaign.forEach((elm) => {
    //     scope.addAsterlist.options.campaign.push(elm.name)
    //   })
    // }
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:list:create"
          ) !== -1
        ) {
          scope.page.addnew = [{ label: "Add List" }];
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:list:edit"
          ) !== -1
        ) {
          scope.page.canedit = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:list:delete"
          ) !== -1
        ) {
          scope.page.candelete = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:list:publish"
          ) !== -1
        ) {
          scope.page.publish = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "leadmanagement:token-generation:view"
          ) !== -1
        ) {
          scope.page.options.cog = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "configurations:masking:view"
          ) !== -1
        ) {
          scope.masking = true;
        }
      }.bind(scope),
      1
    );
    const project_config = JSON.parse(localStorage.projectConfig);
    const portal_url = JSON.parse(localStorage.apiInfo).portal_url;
    console.log(portal_url, "ddddddddddddddddd");
    this.crmdomain = project_config.projects[0].config[0].crm_domain;

    this.getDataFromApi();
  },
};
</script>
<style>
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

.modal-header--sticky {
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1055;
}

.modal-footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  z-index: 1055;
}

.v-card__text {
  padding: 16px;
  width: 100%;
  font-size: initial;
  font-size: 16px;
}


.endpoint {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 10px;
}

.clipicon {
  padding-left: 21px !important;
}

#example {
  border: none;
  /* font-size: 18px;
  font-weight: 500; */
  width: 100%;
}

#sample {
  border: none;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
}

#example:focus {
  border: none;
  outline: none;
}
</style>