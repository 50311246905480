import Vue from "vue";
import Router from "vue-router";
import { routes } from "./routes";
import { store } from "../store";
Vue.use(Router);

function createRouterInstance(baseurl) {
  console.log("inside instance baseurl", baseurl);
  return new Router({
    routes,
    base: baseurl,
    mode: "history",
    scrollBehaviour(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash
        };
      }
    }
  });
}

let customRouter = createRouterInstance(process.env.VUE_APP_BASE_URL);
console.log("instance", customRouter);

customRouter.beforeEach(async (to, from, next) => {
  console.log(`Navigation to: ${to.name}`);

  const isLoggedOut = !store.state.auth0.auth0.isLoggedIn && sessionStorage.getItem('isLoggedIn') !== 'true';

  // If the user is logged out and trying to access a protected route, redirect to login
  if (isLoggedOut && to.meta.auth) {
    console.log("User is logged out and trying to access a protected route. Redirecting to login.");
    next('/auth/login');
    return;
  }
  if (to.name === 'CallFlow') {
    const CALL_FLOW_URL = "https://callflow.doocti.com";
      window.open(CALL_FLOW_URL, '_blank');
      next(false);
      return;
    
  }

  if (top.location != self.location) {
    console.log("Redirecting to error page due to frame violation");
    top.location = '/error-page';
    return;
  }


  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  console.log("token is ", token);


  const isAuthenticated = store.state.auth0.auth0.isLoggedIn || sessionStorage.getItem('isLoggedIn') === 'true';
  const isAdminRoute = to.matched.some(record => record.meta.auth);
  const lastAdminRoute = sessionStorage.getItem('lastAdminRoute');

console.log(`isAuthenticated : ${isAuthenticated} AND adminroute: ${isAdminRoute} AND lastadminroute: ${lastAdminRoute}`);
  // If authenticated and navigating to a non-admin route, prevent and redirect to last admin route
  // if (isAuthenticated && !isAdminRoute && lastAdminRoute) {
  //   console.log('Preventing navigation outside admin portal');
  //   next(lastAdminRoute);
  //   return;
  // }

  // Auto-login logic
  if (to.name === 'doocti/Auth/Login' && to.query.token) {
    const loginDataParam = to.query.token;
    console.log("Auto-login attempt detected", loginDataParam);
    
    if (token) {
      console.log('Initiating auto-login process');
      localStorage.setItem('loginInitiated', 'true');
      
      try {
        console.log('Dispatching initiateAutoLogin action');
        await store.dispatch('initiateAutoLogin', loginDataParam);
        console.log('Auto-login successful');
        localStorage.setItem("socketConnectCount", 0);
        console.log('User permissions fetched');
        
        // Set login state in sessionStorage
        sessionStorage.setItem('isLoggedIn', 'true');
        
        console.log('Redirecting to LiveAgents');
        next({ name: 'realtime/LiveAgents' });
        return;
      } catch (error) {
        console.error('Auto-login failed:', error);
        localStorage.removeItem('loginInitiated');
        sessionStorage.removeItem('isLoggedIn');
        next('/auth/login');
        return;
      }
    } else {
      console.log('Auto-login already initiated, proceeding with navigation');
    }
  }

  // Authentication and permission check
  if (to.meta.auth) {
    console.log('Checking user permissions');
    let filtered = store.getters.UserPermissions.filter(permission => {
      return (to.meta.permission.access.indexOf(permission) !== -1);
    });
    console.log(`Filtered permissions: ${filtered.length}`);
    
    if (filtered.length > 0) {
      console.log('User has required permissions, allowing navigation');
      // Store the current admin route
      sessionStorage.setItem('lastAdminRoute', to.fullPath);
      next();
    } else if (isAuthenticated) {
      console.log("User logged in but lacks permission, redirecting to blank page");
      next('/blank-page');
    } else {
      console.log("User not logged in, redirecting to login page");
      localStorage.clear();
      sessionStorage.clear();
      next('/auth/login');
    }
  } else {
    console.log('No authentication required for this route');

    next();
  }
});

export default customRouter;