<template>
    <div class="main-view-cls">
    <div class="main_container_grid">
    <div class="grid1">
    <div class="header_cls">
    <div class="profil_pic">
    <div v-if="icon === ''" class="profil_txt" style="font-size:20px;padding-top:8px">N/A</div>
    <!-- <div v-else class="profil_txt">{{ "C" }}</div> -->

     <img v-if="apidata.profile_url.length > 0" :src="apidata.profile_url" class="profil_img" height="50" width="50" alt="Profile Image" @click="uploadProfile()" title="Image Upload"/>
     <img v-else src="/static/blueuser.png" class="profil_img" height="50" width="50" alt="Profile Image" @click="uploadProfile()" title="Image Upload"/>

    <div style="display: flex;flex-direction: column;margin: 8px;height: 40px;">
    <div style="font-weight: bold;font-size: 19px;" class="v-toolbar__title primary--text">{{apidata.name }}
    </div>
    <div style="color: #cacaca;font-style: oblique;">{{`Last Modified :
    ${apidata.modified_at}`}}</div>
    </div>
    </div>
   
    <div class="icon_flex">
    <div>
    <!-- <v-icon color="blue"  class="fa fa-file-image-o mr-4" style="font-size:30px" title="Image Upload">
    </v-icon> -->
    </div>
    <div>
    <!-- <v-icon color="blue" style="font-size:25px" @click="pushBack()"
    class="fas fa-arrow-left mr-4"></v-icon> -->
    </div>
    </div>
    </div>
    </div>
    <div class="grid2">
    
    <div class="timeline">
                    <timeline :module="`contact`" :id="this.apidata.id" :Filename="Filename" :masking="masking" :ChartoMaskingChar="ChartoMaskingChar"></timeline>

    </div> 
    </div>
            <div class="grid3">
                <div class="v-toolbar__title titlecls  titleclsflx"
                    style="font-size: medium;color:#ffffff;padding:10px 10px 10px 10px;text-align:center;background-color: rgb(33, 150, 243);">
                    <div class="titleclsflxlft">
                        CONTACT INFORMATIONS
                    </div>
                    <div class="titleclsflxrht">
                        <v-icon color="#ffffff" style="font-size: 20px" @click="pushBack()"
                            class="fas fa-arrow-left mr-4">
                        </v-icon>
                    </div>
                </div>

                <div class="form_data">
                    <preview :value_cls="true" :apidata="[apidata]" :contactalldata="contactalldata"
                        @update="update_fun($event)" :support="true" :Filename="Filename" :masking="masking" :ChartoMaskingChar="ChartoMaskingChar">
                    </preview>
                </div>
            </div>
        </div>  
        <v-snackbar v-model="snackbar.show" :bottom="true" :right="true" :timeout="snackbar.timeout">
            {{ snackbar.text }}
            <v-btn color="pink" flat @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
        <v-dialog persistent v-model="editcontactinfo" max-width="500px">
            <v-card>
                <v-toolbar dark color="primary">
                    <!-- <v-card-title style="width: 100% ;padding:0px!important"> -->
                    <div style=" display: flex;justify-content: space-between; width: 100%!important;padding: 5px;">
                        <div class="headline" style="width:90%;font-size:15px;margin-left: 10px;">Save changes</div>

                        <div style="margin: auto;" @click="closecontact()">
                            <!-- <v-btn icon flat color="white" "> -->
                            <i class="fas fa-close" style="font-size:20px"></i>
                            <!-- </v-btn> -->
                        </div>
                    </div>

                    <!-- </v-card-title> -->
                </v-toolbar>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-card-text>Save changes to this contact before closing?</v-card-text>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light" flat @click="closewithoutsave()">Close without Saving</v-btn>
                    <v-btn color="primary" @click="savecontact()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


            <v-dialog v-model="page.isuploadProfile" max-width="500px">
                <v-card>
                    <v-toolbar dark color="primary">
                    <v-card-title>
                        <span class="headline">Image Upload</span>
                    </v-card-title>
                    </v-toolbar>
                    <v-container grid-list-md>
                    <p>
                        <!-- <span style="font-weight: bold">Follow under terms ! </span><br> -->
                        <span style="font-size:14px">Upload image that are Format: PNG,JPEG,JPG,ICON,GIF.extension and within file size under 5MB.</span>          
                    </p>    
                    <div class="file-dummy" @click="$refs.file.click()"
                        :style="{ background: profileButton.color + '66', 'border-color': profileButton.color, color: profileButton.color, }">
                        {{ profileButton.label }}
                    </div>
                    <input type="file" id="file" ref="file" accept=".jpg" v-on:change="handleImageUpload()" />
                    </v-container>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="removeImage()">Remove Image</v-btn> 
                    <v-btn color="gray" flat @click="CloseuploadProfile(false)">Close</v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>


    </div>
</template>
<script>
import timeline from "../../components/crm/timeLine.vue"
import { mapGetters } from "vuex";
import preview from "./custom-form/Preview.vue";
import api from "@/utils/axios-util";
import { MaskCharacter } from "@/utils/helpers";
export default {
    components: {
        timeline,
        preview,
    },
    data() {
        return {
            page: {
                userassign: false,
                isuploadProfile:false,
            },
            profileButton:{
                label: "Please select image files",
                color: "#2196f3",
            },
            beforeDestroyedit: false,
            editcontactinfo: false,
            history: [],
            apidata: {
                profile_url:""
            },
            contactdata: {},
            contactalldata: [],
            contact_date: [],
            agents: [],
            snackbar: {
                show: false,
                timeout: 6000,
                text: "",
            },
            isShowAgentAssign: false,
            assignedDetails: {},
            currentsupport: "",
            final_data: [],
            required: [],
            count: 0,
            err_arr: [],

            user_email: JSON.parse(localStorage.apiInfo).user_email,
            updatestatus: false,
            Filename:"Contacts",
            masking:false
        };
    },
    computed: {
        ...mapGetters(["form"]),
    },
    watch:{
        'page.isuploadProfile': function(newVal) {
            this.sidebar_enable_disable(newVal);
        },
        'editcontactinfo': function(newVal) {
            this.sidebar_enable_disable(newVal);
    }
},
    mounted() {
        let scope = this
        scope.getApidata()




        setTimeout(
            function () {
                if (
                    scope.$store.getters.UserPermissions.indexOf(
                        "leadmanagement:tickets:userassign"
                    ) !== -1
                ) {
                    scope.page.userassign = true;
                }
                if (
                scope.$store.getters.UserPermissions.indexOf(
                    "configurations:masking:view"
                ) !== -1
                ) {
                scope.masking = true;
                }
            }.bind(scope),
            1
        );
    },
    methods: {

        sidebar_enable_disable(data) {
      this.$root.$emit('sidebar_disable', data);
    },
        getApidata() {
            let scope = this;
            scope.agents = JSON.parse(localStorage.getItem("agent_emails"));
            let obj = JSON.parse(localStorage.ticket_templet);
            let payload = {
                phone_number: obj.phone_number,
            }
            this.$store.dispatch("TicketPhoneSearch", payload).then((res) => {
                this.apidata = res.data[0];

            });


        },
        closecontact() {
            this.editcontactinfo = false;
        },
        closewithoutsave() {
            this.editcontactinfo = false;
            this.sidebar_enable_disable(false);
            this.$router.push({
                name: "crm/AsterContact",
            });
        },
        uploadProfile(){
            this.page.isuploadProfile = true;
        },
        CloseuploadProfile: function () {
         this.page.isuploadProfile = false;
         this.$refs.file.value = "";      
        this.profileButton.label="Please select image files"
        this.profileButton.color="#2196f3"
        },
        savecontact() {
            this.beforeDestroyedit = true;
            this.editcontactinfo = false;
            if (this.updatestatus == false) {
                this.editFunction(this.final_data);

            }

        },
      handleImageUpload: function () {
      var scope = this;
      scope.file = scope.$refs.file.files[0];
      scope.profileButton.color = "#A64452";
      scope.profileButton.label = "Uploading";
      let formData = new FormData();
      formData.append("file", scope.file);
      formData.append("id",scope.apidata.id);
      formData.append("module", "contacts");
      api
        .post("/attachment", formData)
        .then((response) => {
            scope.imageUrl=response.data.data.url
          api.defaults.headers["Content-Type"] = "application/json";
          scope.profileButton.color = "#4BB543";
          scope.profileButton.label = "Uploaded Successfully";
          scope.$refs.file.value = "";
          scope.uploadProfile()
        //   setTimeout(()=>{
          scope.getApidata()
        //   },1000)
        
        return response
        })
        .catch((error) => {
            api.defaults.headers["Content-Type"] = "application/json";
          scope.profileButton.color = "#F51B00";
          scope.profileButton.label = "Upload Error";
          return error;
        });
    },

removeImage: function () {  
      var scope = this;

      scope.profileButton.color = "#A64452";
      scope.profileButton.label = "Removing";
      let formData = new FormData();
      formData.append("id",scope.apidata.id);
      formData.append("module", "contacts");
      formData.append("action", "delete");
      api
        .post("/attachment", formData)
        .then((response) => {
        api.defaults.headers["Content-Type"] = "application/json";
          scope.profileButton.color = "#4BB543";
          scope.profileButton.label = "Removed Successfully";
          scope.$refs.file.value = "";
          scope.apidata.profile_url = "";
          scope.uploadProfile()
          return response;
        })
        .catch((error) => {
            api.defaults.headers["Content-Type"] = "application/json";
          scope.profileButton.color = "#F51B00";
          scope.profileButton.label = "Remove Error";
          return error;
        });
    },





        update_fun(val) {
            let scope = this
            console.log("val", val)
            let final_data1 = [];
            // let obj = {};
            let date = new Date();
            let yyyy = date.getFullYear()
            let mm = (date.getMonth() + 1)
            let dd = date.getDate()
            let hr = date.getHours()
            let min = date.getMinutes()
            let sec = date.getSeconds()
            if (hr < 10) hr = '0' + hr
            if (min < 10) min = '0' + min
            if (sec < 10) sec = '0' + sec
            if (mm < 10) mm = '0' + mm
            if (dd < 10) dd = '0' + dd
            let today = `${yyyy}-${mm}-${dd} ${hr}:${min}:${sec}`
            for (let i = 0; i < val[0].length; i++) {
                const element = val[0][i];

                if (element.value.name !== "created_by" && element.value.name !== "created_at" && element.value.name !== "user" && element.value.name !== "ticket_id" && element.value.name !== "is_merge" && element.value.name !== "is_duplicate" && element.value.name !== "merged_to" && element.value.name !== "merged_as") {

                    if (element.value.name === "modified_at") {
                        element.value.value = today
                    } else if ((element.value.name === "modified_by")) {
                        element.value.value = scope.user_email
                    }
                    final_data1.push(element.value);
                }

            }
            scope.final_data = final_data1

        },
        showSnackBar(message) {
            let scope = this;
            scope.snackbar.text = message;
            scope.snackbar.show = true;
            setTimeout(() => {
                scope.snackbar.text = "";
            }, scope.snackbar.timeout);
        },
        editFunction(val) {

            this.payload_edit(val)

        },
        payload_edit(val) {
            let scope = this
            scope.updatestatus = true
            if (val.check) {
                this.showSnackBar(`400 Bad Request: ${val.val.toString()} Is Required Field`);
            } else {
                this.count += 1
                let payload = {
                    contact_id: this.apidata.id,
                    fields: val,
                    // modified_by:scope.user_email,
                    // islead:"0",
                    // template_name:JSON.parse(localStorage.templetname).templetname
                }
                this.$store.dispatch("updateAsterContact", payload).then((response) => {

                    if (response.status === 200 || response.status === 204) {

                        this.showSnackBar("Contact Updated successfully!");
                        this.$router.push({
                            name: "crm/AsterContact",
                        });
                    }
                })
            }
        },
        pushBack() {
            console.log("Ssssssss")
            this.editcontactinfo = true;
        },
        ChartoMaskingChar(data){
            let scope = this
            if(!scope.$store.getters.MaskType.includes(typeof(data))){
                return data
            }
            return MaskCharacter(data)
        },


    },

};
</script>
 
 
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap");

/* .v-toolbar__content{
    padding: 0px !important;
} */
.grid1 {
    grid-area: header;
}

.grid2 {
    grid-area: menu;
}

.grid3 {
    grid-area: main;
}

.titleclsflx {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.titleclsflxrht {
    width: 20px;
}

.titleclsflxlft {

    width: calc(100% - 20px)
}

.main_container_grid {
    display: grid;
    grid-template-areas:
        "header header header header header header"
        "menu main main main main main";
    gap: 10px;

    padding: 10px;
}

.grid1 {
    padding: 5px;
    border-radius: 5px;
}

.grid2 {
    /* height: 500px; */
    border: 1px solid #cacaca;
    border-radius: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 100px;
}

.timeline {
    min-width: 240px;
    height: 500px;
    /* padding: 5px; */
    overflow: auto;
}

.grid3 {
    /* height: 500px; */

    overflow: auto;
    border: 1px solid #cacaca;
    border-radius: 5px;
    display: flex;
    overflow: hidden;

    flex-direction: column;
}

.form_data {
    min-width: 800px;
    overflow: auto;
}

.profil_pic {
    display: flex;
    flex-direction: row;
}

.profil_txt {
    width: 48px;
    height: 48px;
    background-color: rgb(33, 150, 243);
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%),
        0 13px 24px -11px rgb(33 150 243 / 60%);
}

.header_cls {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.icon_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 5px 5px 5px;
}

.titlecls:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.titlecls:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


.content_cls {
    padding: 20px 20px 20px 0px;
}

.timelinetrigger {
    cursor: pointer;
}
.profil_img {
  border-radius: 50%;
}
.file-dummy {
  width: 100%;
  padding: 30px;
  border: 2px dashed;
  border-color: blue;
  text-align: center;
  transition: #2196f333 0.3s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}
</style>