<template>
  <div class="wallboarddiv">
    <div class="campaigndiv" v-if="page.displayCampaign">
      <div style="padding: 0px 20px">
        <div class="row justify-sb">
          <v-subheader>Campaign Status</v-subheader>
          <div class="row" style="padding-right: 15px; width: 200px">
            <v-btn
              flat
              small
              fab
              icon
              color="primary"
              @click="refreshcampaignpage()"
            >
              <i class="fas fa-refresh"></i>
            </v-btn>
            <v-btn flat small fab icon @click="FullScreen">
              <v-icon>{{ screenChangeIcon }}</v-icon>
            </v-btn>
            <v-btn
              flat
              small
              fab
              icon
              :color="page.campaignView == 'Graph' ? 'primary' : 'light'"
              @click="page.campaignView = 'Graph'"
            >
              <i class="fas fa-chart-bar"></i>
            </v-btn>
            <v-btn
              flat
              small
              fab
              icon
              :color="page.campaignView == 'Table' ? 'primary' : 'light'"
              @click="page.campaignView = 'Table'"
            >
              <i class="fas fa-table"></i>
            </v-btn>
          </div>
        </div>
        <CampaignStatus v-if="page.campaignView == 'Graph'"></CampaignStatus>
        <CampaignStatusTable v-else></CampaignStatusTable>
      </div>
    </div>
    <div class="queuediv" v-if="page.displayQueue">
      <div style="padding: 0px 20px">
        <div class="row justify-sb">
          <v-subheader>Queue Status</v-subheader>
          <div style="padding-right: 15px; width: 200px">
            <v-btn
              flat
              small
              fab
              icon
              color="primary"
              @click="refreshqueuepage()"
            >
              <i class="fas fa-refresh"></i>
            </v-btn>
            <v-btn
              flat
              small
              fab
              icon
              :color="page.queueView == 'Graph' ? 'primary' : 'light'"
              @click="page.queueView = 'Graph'"
            >
              <i class="fas fa-chart-bar"></i>
            </v-btn>
            <v-btn
              flat
              small
              fab
              icon
              :color="page.queueView == 'Table' ? 'primary' : 'light'"
              @click="page.queueView = 'Table'"
            >
              <i class="fas fa-table"></i>
            </v-btn>
          </div>
        </div>
        <QueueStatus v-if="page.queueView == 'Graph'"></QueueStatus>
        <QueueStatusTable v-else></QueueStatusTable>
      </div>
    </div>
  </div>
</template>
<script>
import QueueStatus from "./QueueStatus.vue";
import QueueStatusTable from "./QueueStatusTable.vue";
import CampaignStatus from "./CampaignStatus.vue";
import CampaignStatusTable from "./CampaignStatusTable.vue";
export default {
  data() {
    return {
      page: {
        campaignView: "Table",
        queueView: "Table",
        fullscreen: false,
        displayCampaign: false,
        displayQueue: false,
      },
      livecallsLoaders: "",
      timeout_campaign: "",
      Timeout_statu: "",
      campaign_clear: true,
      queue_clear: true,
      scope: this,
    };
  },
  computed: {
    screenChangeIcon: function () {
      if (this.page.fullscreen === false) {
        return "fullscreen";
      } else {
        return "fullscreen_exit";
      }
    },
  },
  components: {
    QueueStatus,
    QueueStatusTable,
    CampaignStatus,
    CampaignStatusTable,
  },

  methods: {
    getAgentWidth(count, item) {
      var total = item.pause + item.oncall + item.available + item.waiting;
      var per = (count / total) * 100;
      return per + "%";
    },
    getTopBorder(color) {
      return "2px solid " + color;
    },
    campaign_status() {
      var scope = this;
      this.$store.dispatch("campaign").then(() => {
        if (scope.campaign_clear) {
          this.timeout_campaign = setTimeout(function () {
            scope.campaign_status();
          }, 10000);
        }
      });
    },
    queuestatus() {
      var scope = this;
      this.$store.dispatch("queuestatusaction", this.payload).then(() => {
        if (scope.queue_clear) {
          this.timeout_queue = setTimeout(function () {
            scope.queuestatus();
          }, 5000);
        }
      });
    },
    FullScreen() {
      if (this.page.fullscreen === false) {
        this.$store.dispatch("toggleDrawer", false);
        this.$store.dispatch("toggleToolbar", false);
        this.page.fullscreen = true;
      } else {
        this.$store.dispatch("toggleDrawer", true);
        this.$store.dispatch("toggleToolbar", true);
        this.page.fullscreen = false;
      }
    },
    refreshLiveAgentsPage: function () {
      var scope = this;
      scope.livecallsLoaders = true;
      setTimeout(
        function () {
          scope.livecallsLoaders = false;
        }.bind(scope),
        4000
      );
    },
    refreshcampaignpage() {
      this.campaign_status();
      //this.queuestatus();
    },
    refreshqueuepage() {
      //this.campaign_status();
      this.queuestatus();
    },
  },
  mounted() {
    let scope = this;
    this.campaign_status();
    this.refreshLiveAgentsPage();
    this.queuestatus();
    setTimeout(
      function () {
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "realtime:livestatus:campaignview"
          ) !== -1
        ) {
          scope.page.displayCampaign = true;
        }
        if (
          scope.$store.getters.UserPermissions.indexOf(
            "realtime:livestatus:queueview"
          ) !== -1
        ) {
          scope.page.displayQueue = true;
        }
      }.bind(scope),
      1000
    );
  },
  beforeDestroy() {
    var scope = this;
    this.$store.dispatch("emptycampaignstatus");
    this.$store.dispatch("emptycampaignstatu");
    scope.campaign_clear = false;
    scope.queue_clear = false;
    clearTimeout(scope.timeout_campaign);
    clearTimeout(scope.timeout_queue);
  },
};
</script>
<style scoped>
.wallboarddiv {
  display: flex;
  flex-direction: column;
}

.campaigndiv {
  display: flex;
  flex-direction: column;
  height: 45%;
  justify-content: center;
  padding: 5px;
}

.queuediv {
  display: flex;
  flex-direction: column;
  height: 45%;
  padding: 5px;
}
</style>